import { useState } from "react"
import NavigationContext from "../contexts/navigationContext"

const NavigationProvider = ({ children }) => {
  const [currentTarget, setCurrentTarget] = useState("")
  const [showMenu, setShowMenu] = useState(false)

  const contextValue = {
    currentTarget,
    showMenu,
    navigateTo: (target) => setCurrentTarget(target),
    openMobileMenu: () => {
      setShowMenu(true);
    },
    closeMobileMenu: () => {
      setShowMenu(false);
    }
  }

  return (
    <NavigationContext.Provider value={contextValue}>
      {children}
    </NavigationContext.Provider>
  )
}

export default NavigationProvider