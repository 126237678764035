import DropDownIcon from "../../assets/new_images/CaretCircleDown.svg";

export default function DropDownElt({ showDropDown, setShowDropDown }) {


  const toggleDropdown = () => {
    setShowDropDown(!showDropDown);
  }

  return (
    <img alt={"dropdown"} src={DropDownIcon}
         className={showDropDown ? "musicinput_header__icon rotate" : "musicinput_header__icon"}
         onClick={toggleDropdown}/>
  );
}