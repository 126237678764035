import { useState } from "react";
import PodcastsContext from "../contexts/podcasts";

const PodcastsProvider = ({ children }) => {
  const [podcasts, setPodcasts] = useState(null);

  // Some handlers
  const addPodcasts = (podcasts) => {
    setPodcasts(podcasts);
  }

  const contextValue = {
    podcasts,
    addPodcasts
  }

  return (
    <PodcastsContext.Provider value={contextValue}>
      { children }
    </PodcastsContext.Provider>
  )
}

export default PodcastsProvider