import translate from "../../../../i18n/translate";
import {top5music} from "../../../../utils/functions";
import React, {useContext} from "react";
import CurrentUserContext from "../../../../datamanager/contexts/currentUser";
import SinglesContext from "../../../../datamanager/contexts/singles";
import MostListennedListElt from "./MostListennedListElt";

export default function MostListennedList() {

  const {currentUser} = useContext(CurrentUserContext);
  const {singles} = useContext(SinglesContext);

  return (
    <div className="inst-data">
      <span className="inst-data-elt-title">{translate('runningmusics')}</span>
      {singles && singles.count > 0 && top5music(singles.results).map((single, index) => (
        <MostListennedListElt key={index} cover={single.cover} title={single.title} nb_of_listening={single.nb_of_listening} name={currentUser.alias} />
      ))}
    </div>
  );
}