import M_modal from "./M_modal";
import {SpinnerCircularFixed} from "spinners-react";
import lib from "../../../../assets/music-library.gif";
import DataTable from "react-data-table-component-with-filter";
import React from "react";

export default function Details_page({
                                         userData,
                                         loading,
                                         pay_info,
                                         canSell,
                                         dataPay,
                                         openModal,
                                         setOpenModal,
                                         resetPaginationToggle,
                                         albumSingle,
                                         handleSelectSingle,
                                         columns,
                                         date
                                     }) {
    return <div className="w-full h-full">
        {openModal && <M_modal closeModal={setOpenModal}/>}
        {loading && (
            <div className="flex items-center justify-center h-screen">
                <SpinnerCircularFixed
                    size={50}
                    thickness={121}
                    speed={150}
                    color="rgba(133, 19, 78, 1)"
                    secondaryColor="rgba(231, 208, 220, 1)"
                />
            </div>
        )}

        {!loading && (
            <>
                <div id="root-modal" className="p-4 w-full h-screen max-h-screen flex flex-col items-center justify-start">
                    <div className="w-full flex items-start justify-start">
                        <div className="pt-10 w-52 h-52">
                            <img src={userData.cover} className="object-cover rounded-2xl" />
                        </div>

                        <div className="h-52 flex flex-col items-start justify-end mx-5 ">
                            <h5 className="text-sm text-slate-500 font-semibold">Album</h5>
                            <h1 className="text-6xl text-slate-500 font-bold my-1.5">
                                {userData.title}
                            </h1>

                            <div className="flex items-center justify-center mt-2">
                                <div className="w-6 h-6 ">
                                    <img
                                        src={userData.artist.photo}
                                        className="rounded-full object-cover"
                                    />
                                </div>
                                <h5 className="text-sm text-slate-500 font-semibold mx-2 rounded-xl">
                                    {userData.artist.alias}
                                </h5>
                            </div>
                        </div>
                    </div>

                    <div className="w-full flex flex-col items-start justify-start">
                        <div className="flex items-center justify-start mt-20 pt-5">
                            <button className="cursor-pointer hover:bg-gray-200 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 mr-1.5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                                <span>Modifier</span>
                            </button>

                            <button className="cursor-pointer hover:bg-gray-200 text-gray-800 font-bold py-2 px-2 rounded-full inline-flex items-center mx-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-7 w-7 cursor-pointer text-gray-500 hover:text-gray-700"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                                    />
                                </svg>
                            </button>
                        </div>

                        {/* Hero */}
                        {canSell && (
                            <div className="rdc w-full flex flex-col items-center justify-start  shadow rounded-lg overflow-clip mb-5">
                                <div className="flex items-center justify-between w-full h-3/4 pl-6 pt-3 pb-14">
                                    <div className="flex flex-col h-full w-2/4 p-1">
                                        <h3 className="text-sm font-normal text-gray-400 mb-1.5">
                                            Paiement le plus recent
                                        </h3>
                                        <div className="flex items-center justify-start">
                                            <h1 className="text-4xl text-slate-600 font-bold">
                                                {pay_info.amount} {pay_info.currency}
                                            </h1>
                                            <div className="flex items-center justify-center bg-green-600 w-12 h-6 px-1 py-0.5 mx-2 rounded text-white text-xs font-semibold">
                                                Soldé
                                            </div>
                                        </div>
                                        <h3 className="text-xl my-1 font-normal text-slate-100 font-bold">
                                            {pay_info.number} Ventes
                                        </h3>
                                        <h4 className="text-xs my-1 font-normal text-slate-100">
                                            Envoyé le {date}.
                                        </h4>
                                    </div>

                                    <div className="flex flex-col h-full w-2/4 p-1">
                                        <h3 className="text-sm font-normal text-gray-400 mb-1.5">
                                            Prochain paiement
                                        </h3>
                                        <div className="flex items-center justify-start">
                                            <h1 className="text-4xl text-gray-400 font-bold">
                                                0.000 FCFA
                                            </h1>
                                            <div className="flex items-center justify-center bg-gray-300 w-12 h-6 px-1 py-0.5 mx-2 rounded text-white text-xs font-semibold">
                                                Estimé
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="flex items-center justify-between w-full h-1/2ujj pl-6 pt-3 pb-14">

                                    {dataPay.map((info, index) => (
                                        <div className="flex flex-col h-full w-2/4 p-1">
                                            <h3 className="text-sm font-normal text-gray-400 mb-1.5">
                                                {info.channel}
                                            </h3>
                                            <div className="flex items-center justify-start">
                                                <h1 className="text-sm text-slate-600 font-bold">
                                                    {info.amount} {info.currency}
                                                </h1>
                                                <div className="flex items-center justify-center bg-pink-800  h-6 px-1 py-0.5 mx-2 rounded text-white text-xs font-semibold">
                                                    {info.number} vente(s)
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        )}


                        <div className=" w-full flex flex-col items-center justify-center">

                            <div className="w-28 h-28 my-1">
                                <img src={lib} className="object-cover" />
                            </div>

                            <p className="text-center text-base font-medium my-5 w-72">
                                Ajouter des sons a votre album pour avoir des statistiques.
                            </p>

                            <button
                                className="cursor-pointer bg-pink-800 hover:bg-pink-900 text-white font-bold py-2 px-4 rounded"
                                onClick={()=>{
                                    setOpenModal(true)
                                }}
                            >
                                Ajouter les sons
                            </button>

                            {/* ajouter la liste des musiques de l'album */}

                            <div className="card-panel w-full mt-5">
                                <DataTable
                                    columns={columns}
                                    data={albumSingle}
                                    onRowClicked={(row) => {
                                        handleSelectSingle(row.id);
                                    }}
                                    progressPending={loading}
                                    progressComponent={
                                        <SpinnerCircularFixed
                                            size={50}
                                            thickness={121}
                                            speed={150}
                                            color="rgba(133, 19, 78, 1)"
                                            secondaryColor="rgba(231, 208, 220, 1)"
                                            className="mt-12"
                                        />
                                    }
                                    // pagination={albumSingle.length > 10}
                                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight={"h-full"} // 600px
                                    persistTableHead
                                    pointerOnHover
                                    responsive
                                />
                                {/*{albumSingle.length}*/}
                                {/*{albumSingle.map(singless => (*/}
                                {/*    <p>{singless.title}</p>*/}
                                {/*))}*/}
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )}
    </div>;
}