import { useContext, useState } from 'react';
import UserApi from '../../../api/users';
import CurrentUserContext from '../../../datamanager/contexts/currentUser';
import ModalContext from '../../../datamanager/contexts/modalContext';
import styles from '../styles/modal.module.css';
import profileStyles from '../styles/profile.module.css';

const ProfileContentModal = () => {
  // Get user data from context
  const { currentUser: user, login: updateUser } = useContext(CurrentUserContext);
  const { closeModal } = useContext(ModalContext);

  // Set local state with initial URLs
  const [alias, setAlias] = useState(user.alias);
  const [cover, setCover] = useState(user.cover); // Assuming user.photo is the initial URL
  const [loading, setLoading] = useState(false);

  // Some handlers
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    switch (name) {
      case 'alias':
        setAlias(value);
        break;
      case 'cover':
        setCover(files[0] || value); // Use files[0] if a new file is selected, otherwise use the existing URL
        break;
      default:
        return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!loading && verification()) {
      setLoading(true);

      // Update user data
      const token = localStorage.getItem('access_token');

      const userApi = new UserApi(token);

      // Create FormData to handle file uploads
      const formData = new FormData();
      formData.append('alias', alias);
      formData.append('cover', cover);
      formData.append('photo', cover);

      const response = await userApi.updateProfile(user.id, formData);

      if (response.data) {
        const { data } = await userApi.getCurrent();

        if (data) {
          updateUser(data);
        }

        closeModal();
      }

      setLoading(false);
    }
  };

  const verification = () => {
    if (alias === user.alias && cover === user.cover) {
      return false;
    } else {
      if (alias === '' || cover === null) {
        return false;
      }
      return true;
    }
  };

  return (
      <div className={styles.modalContent}>
        <div className={profileStyles.formContainer}>
          <label className={profileStyles.formLabel}>Alias</label>

          <input
              type="text"
              name="alias"
              className={profileStyles.formInput}
              onChange={handleChange}
              placeholder="Noms"
              value={alias}
          />
        </div>

        <div className={profileStyles.formContainer}>
          <label className={profileStyles.formLabel}>Cover</label>

          <input
              type="file"
              accept="image/jpeg, image/png"
              name="cover"
              className={profileStyles.formInput}
              onChange={handleChange}
          />
          {/* Display current cover */}
          {cover && typeof cover === 'string' && <img src={cover} alt="current cover" className={profileStyles.imagePreview}/>}
        </div>

        <div className={profileStyles.formBtnContainer}>
          <button className={profileStyles.formBtn} onClick={handleSubmit}>
            {loading ? 'En cours...' : 'Enregistrer'}
          </button>
        </div>
      </div>
  );
};

export default ProfileContentModal;
