import React, {createContext, useCallback, useContext, useState} from 'react';

const UploadSpeedContext = createContext();

export const useUploadSpeed = () => useContext(UploadSpeedContext);

export const UploadSpeedProvider = ({ children }) => {
  const [uploadSpeed, setUploadSpeed] = useState(0);

  const handleSetUploadSpeed = useCallback((value) => {
    if (value !== uploadSpeed) {
      setUploadSpeed(value);
    }
  }, [uploadSpeed]);
  return (
    <UploadSpeedContext.Provider value={{ uploadSpeed, handleSetUploadSpeed }}>
      {children}
    </UploadSpeedContext.Provider>
  );
};
