import React, { useContext, useEffect, useState } from "react";
import gsap from "gsap";
import SinglesContext from "../../../datamanager/contexts/singles";
import AlbumsContext from "../../../datamanager/contexts/albums";
import translate from "../../../i18n/translate";
import "./styles/dashboard.module.css"
import Loader from "../../../components/Loader";
import NoContent from "../../../components/NoContent";
import GreedUser from "./components/GreedUser";
import DataCardList from "../audio/component/DataCardList";
import DataChart from "./components/DataChart";
import { useLoadingContext } from "../../../datamanager/contexts/locals/Dashboard/loading";
import { transformUrl } from "../../../utils/functions";
import CurrentUserContext from "../../../datamanager/contexts/currentUser";

import circleD from '../../../assets/icons/circle.svg'
import calendar from '../../../assets/icons/calendar.svg'
import up from '../../../assets/icons/up.svg'
import down from '../../../assets/icons/down.svg'
import cameroon from '../../../assets/icons/cameroon.svg'
import ci from '../../../assets/icons/ci.svg'
import right from '../../../assets/icons/arrow-right.svg'
import album1 from '../../../assets/images/a1.png'
import album2 from '../../../assets/images/a2.png'
import albumImg from '../../../assets/images/album-default.jpg'

import { Link } from "react-router-dom";
import SideBarProfile from "../../../components/SideBarComponents/SideBarProfile";
import DashTopNav from "../../../components/DashTopNav/DashTopNav";
import SearchBar from "../posts/components/SearcBar";

import '../../../styles/dashboard/dashboard.scss'
import SelectInput from "../../../components/SelectInput";
import { useStats } from "../../../datamanager/contexts/Stats";
import { formatNumber } from "../../../utils/FormatValues";
import { countryInfo } from "../../../utils/AllCountries";

function Dashboard() {
  const { stat } = useStats();

  console.log(stat);

  const [open, setOpen] = useState(false);
  const [selectValue, setSelectValue] = useState('');
  const [active, setActive] = useState('streams')
  const [streams, setStreams] = useState(stat && stat.top_songs)
  const [achats, setAchats] = useState(null)
  const [abonnees, setAbonnees] = useState(null)

  const { currentUser } = useContext(CurrentUserContext);
  // Get data from the global context
  const { singles } = useContext(SinglesContext);
  const { albums } = useContext(AlbumsContext);

  const { loading, handleSetLoading } = useLoadingContext();

  const handleOpen = () => {
    gsap.fromTo(
      "#mob-nav",
      {
        x: '-100%',
      },
      {
        x: '0',
        duration: 0.75,
      }
    );
    setOpen(!open)
  }

  // UseEffect section
  useEffect(() => {
    if (singles && albums) {
      handleSetLoading(false)
    }
  }, [singles, albums, handleSetLoading])

  useEffect(() => {
    if (stat) {
      setStreams(stat.top_songs)
    }
  }, [stat])


  // useEffect(() => {
  //   return () => {
  //     if (stat) {
  //       setStreams(stat?.top_songs)
  //     }
  //   }
  // }, [stat])
  
  
  return (
    <>
      {loading ? <Loader /> :
        albums.count || singles.count ? (
          <div className="main-dash dashboard">
            <DashTopNav />

            <div className="p-6">
              <GreedUser />
            </div>
            <div className="datas">
              <div className="main-side">
                <span className="main-side-title">{translate('yourdata')}</span>
                <DataCardList stat={stat} />
                <div className="main-side-elt">
                  <span className="main-side-title">{translate('ouraudience')}</span>
                  <DataChart />
                </div>

                <div className="table mt-8">
                  <h4 className="text-white font-normal text-2xl leading-9 text-start">Top fans</h4>

                  <div className="table_container">
                    <div className="head">
                      <h3 className="text-white font-bold text-2xl text-start">Album acheté</h3>

                      {/* REMOVED FOR NOW */}
                      {/* <div className="search-cont gap-4">
                        <SearchBar searchPlaceholder={"Rechercher un single"} />
                        <span className="order">Classer par</span>

                        <SelectInput optionList={[{ name: 'Popularité' }, { name: 'Ventes' }, { name: 'Date' }]} setFieldValue={setSelectValue}
                          inputPlaceholder={'Popularité'}
                          value={selectValue} />
                      </div> */}
                    </div>
                    <table>
                      <thead>
                        <th className="flex flex-1">Fan</th>
                        <th className="w-40">Nombre d’achats</th>
                      </thead>
                      <tbody>
                        {
                          stat ?
                            stat.top_buyers.map((buyer, index) => (
                              <tr key={buyer.id}>
                                <td className="flex items-center justify-start gap-3">
                                  {index + 1}
                                  <img src={buyer.photo} alt="Album 1" width={48} height={48} className="rounded-full"/>
                                  <div className="flex flex-col gap-1">
                                    <p className="text-white name">{buyer.username}</p>
                                    <p className="person flex-wrap flex gap-2 text-gray-400" style={{color: '#9ca3af'}}>
                                      {/* <span>rickfallo237</span> */}
                                      {/* <span>{buyer.email}</span> */}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="flex flex-wrap items-center justify-start gap-3 text-white">
                                    {buyer.purchase_count}
                                    {
                                      buyer.purchased_albums.map(album => (
                                        <img key={album.id} src={album.cover} alt={album.title} width={48} height={48} className="rounded-xl"/>
                                      ))
                                    }
                                  </div>
                                </td>
                              </tr>
                            ))
                            :
                            <>Loading</>
                        }
                      </tbody>
                    </table>
                  </div>

                  <div className="stats">
                    <aside className="group">
                      <h4 className="text-white font-normal text-2xl leading-9 text-start">Singles et albums les plus attractifs</h4>
                      <aside>
                        <div className="head" style={{ alignItems: 'flex-end' }}>
                          <div className="gap-4">
                            <p className="stats" style={{ justifyContent: 'flex-start' }}>Total des achats</p>
                            <p className="text-white font-medium text-4xl text-left">{stat && formatNumber(stat.total_album_sold.all_time.count)}</p>
                            <p className="stats gap-3" style={{ justifyContent: 'flex-start' }}><img src={up} alt="up" /> {stat && stat.total_album_sold.change_30d} ces 30 derniers jours</p>
                          </div>
                        </div>
                        <section className="main-body">
                          <div className="head">
                            <div className="toggler">
                              <span className={active === 'streams' ? 'active' : ''} onClick={() => {
                                setActive('streams')
                                setStreams(stat && stat.top_songs)
                                setAchats(null)
                                setAbonnees(null)
                              }}>Streams</span>
                              <span className={active === 'achats' ? 'active' : ''} onClick={() => {
                                setActive('achats')
                                setStreams(null)
                                setAchats(stat && stat.top_albums)
                                setAbonnees(null)
                              }}>Achats</span>
                              <span className={active === 'abonnés' ? 'active' : ''} onClick={() => {
                                setActive('abonnés')
                                setStreams(null)
                                setAchats(null)
                                setAbonnees(stat && stat.top_favorite_songs)
                              }}>Abonnés</span>
                            </div>

                            {/* REMOVED FOR NOW */}
                            {/* <div className="search-cont gap-4">
                              <SelectInput optionList={[{ name: '12 derniers mois' }, { name: '30 derniers jours' }, { name: '24hr recents' }]} setFieldValue={setSelectValue}
                                inputPlaceholder={'12 derniers mois'}
                                value={selectValue} />
                            </div> */}
                          </div>
                          {
                            streams && streams.map((val, index) => (
                              <section key={index}>
                                <span className="text-gray-400">{index + 1}</span>
                                <img src={val.cover} width={48} height={48} alt="ci" className="ml-2 rounded-xl" />

                                <div className="flex-1">
                                  <p className="text-white font-medium text-base leading-5 text-left flex">{val.title}</p>
                                  <p className="white-50 font-medium text-xs text-left">{val.total_listens} écoutes <span>{val.change_1d}</span></p>
                                </div>
                                <div className="flex flex-col items-end">
                                  <span className="flex">
                                    {val.current_rank}
                                    {
                                      val.previous_rank && val.current_rank <= val.previous_rank &&
                                      <img src={up} alt="up" />
                                    }
                                    {
                                      val.previous_rank && val.current_rank > val.previous_rank &&
                                      <img src={down} alt="down" />
                                    }
                                  </span>

                                  <span className="white-50  font-medium text-xs text-right">{val.change_30d}</span>
                                </div>
                              </section>
                            ))
                          }
                          {
                            achats && achats.map((val, index) => (
                              <section key={index}>
                                <span>{index + 1}</span>
                                <img src={val.cover} width={48} height={48} alt="ci" className="ml-2 rounded-xl" />

                                <div className="flex-1">
                                  <p className="text-white font-medium text-base leading-5 text-left flex">{val.title}</p>
                                  <p className="white-50 font-medium text-xs text-left">{val.listens.all_time} écoutes</p>
                                </div>
                                <div className="flex flex-col justify-end">
                                  <span className="flex">
                                    {val.current_rank}
                                    {
                                      val.previous_rank && val.current_rank <= val.previous_rank &&
                                      <img src={up} alt="up" />
                                    }
                                    {
                                      val.previous_rank && val.current_rank > val.previous_rank &&
                                      <img src={down} alt="down" />
                                    }
                                  </span>

                                  <span className="white-50  font-medium text-xs text-right">{val.listens.change_30d}</span>
                                </div>
                              </section>
                            ))
                          }
                          {
                            abonnees && abonnees.map((val, index) => (
                              <section key={index}>
                                <span>{index + 1}</span>
                                <img src={val.cover} width={48} height={48} alt="ci" className="ml-2 rounded-xl" />

                                <div className="flex-1">
                                  <p className="text-white font-medium text-base leading-5 text-left flex">{val.title}</p>
                                  <p className="white-50 font-medium text-xs text-left">{val.favorite_count.all_time} écoutes</p>
                                </div>
                                <div>
                                  <span className="flex">
                                    {val.current_rank}
                                    {
                                      val.previous_rank && val.current_rank < val.previous_rank &&
                                      <img src={up} alt="up" />
                                    }
                                    {
                                      val.previous_rank && val.current_rank > val.previous_rank &&
                                      <img src={down} alt="down" />
                                    }
                                  </span>

                                  <span className="white-50  font-medium text-xs text-right">{val.favorite_count.change_30d
                                  }</span>
                                </div>
                              </section>
                            ))
                          }
                        </section>
                        <section className="tail">
                          <span>Voir le classement total</span>
                          <img src={right} alt="arrow right" />
                        </section>
                      </aside>
                    </aside>

                    <aside className="group">
                      <h4 className="text-white font-normal text-2xl leading-9 text-start">Origine de vos auditeurs</h4>
                      <aside>
                        <div className="head" style={{ alignItems: 'flex-end' }}>
                          <div className="gap-4">
                            <p className="stats" style={{ justifyContent: 'flex-start' }}>Origine de vos ecouteurs</p>
                            <p className="text-white font-medium text-4xl text-left">{stat && countryInfo(stat.listening_by_country[0].country).name}</p>
                            <div>
                              <p className="stats gap-2" style={{ justifyContent: 'flex-start' }}>1<img src={up} alt="up" />{stat && stat.listening_by_country[0].listens.change_1d}</p>
                              <p className="stats" style={{ justifyContent: 'flex-start' }}>{stat && stat.listening_by_country[0].listens.change_30d} ces 30 derniers jours</p>
                            </div>
                          </div>

                          {/* REMOVED FOR NOW */}
                          {/* <div className="search-cont gap-4">
                            <SelectInput optionList={[{ name: 'Par pays' }, { name: 'Par region' }, { name: 'Par ville' }]} setFieldValue={setSelectValue}
                              inputPlaceholder={'Par pays'}
                              value={selectValue} />
                          </div> */}
                        </div>

                        <section className="main-body">
                          {
                            stat && stat.listening_by_country.map((val, index) => (
                              index > 0 &&
                              <section key={val.index}>
                                <span>{index}</span>
                                <div className="flex-1">
                                  <p className="text-white font-medium text-base leading-5 text-left flex">{countryInfo(val.country).name}
                                    <img src={`https://flagsapi.com/${val.country}/flat/64.png`} alt={countryInfo(val.country).name + " flag"} width={'14px'} className="ml-2" />

                                  </p>
                                  <p className="white-64 font-medium text-xs text-left">{val.listens.all_time} auditeurs</p>
                                </div>
                                <span className="text-white font-medium text-xs text-right">{val.percentage}%</span>
                              </section>
                            ))
                          }
                        </section>
                        <section className="tail">
                          <span>Voir le classement total</span>
                          <img src={right} alt="arrow right" />
                        </section>
                      </aside>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : <NoContent path={"/app/publish/select-content-type"} contentType={"album ou audio"} />}
    </>
  );
}

export default Dashboard;
