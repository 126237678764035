import "./styles/MusicInput.css"
import React, {useContext, useEffect, useState} from "react";
import InputFieldCustom from "./InputFieldCustom";
import SelectInput from "./SelectInput";
import RadioInputExplicit from "./RadioInputExplicit";
import {useMetaData} from "../datamanager/contexts/locals/publishAudio/metaData";
import {ouiNon} from "../utils/Constants";
import MusicInputHeader from "./PublishAudio/MusicInputHeader";
import DropDownElt from "./PublishAudio/DropDownElt";
import translate from "../i18n/translate";
import {useAlbumCategories} from "../datamanager/contexts/locals/albumCategory";
import CurrentUserContext from "../datamanager/contexts/currentUser";
import TextAreaInputCustom from "./TextAreaInputCustom";
import {useSelectedAudio} from "../datamanager/contexts/locals/publishAudio/selectedAudio";
import SelectInputGender from "./SelectInputGender";

export default function MusicInput({ songTitle, songSize, songTime, index, total, values }){

  const [showDropDown, setShowDropDown] = useState(true);
  const [duration, setDuration] = useState(0);

  const { metaData } = useMetaData();
  const { albumCategories } = useAlbumCategories();

  songTime.then((res) => setDuration(res))

  return (
    <div className="musicinput_container">
      <MusicInputHeader index={index} duraation={duration} songSize={songSize} />
      <hr/>
      <div className="musicinput_header">
        <span className="musicinput_header_title__text">{translate("singleInformations")}</span>
        <DropDownElt showDropDown={showDropDown} setShowDropDown={setShowDropDown} />
      </div>
      <div className={ showDropDown ? "musicinput_dropdown" : "hide"}>
        <div className="musicinput_dropdown_art_tit">
          <InputFieldCustom inputName="metatitle" index={index} inputType={"text"} inputPlaceholder={songTitle} inputLabel={translate("Titre")} value={metaData[index].title} />
          <InputFieldCustom inputName="metaartist" index={index} inputType={"text"} inputPlaceholder={metaData[index].artist} inputLabel={translate("Artist_s")} disabled={true} value={metaData[index].artist} />
        </div>
        <div className="musicinput_dropdown_art_prod_gender">
          <InputFieldCustom inputName="metaauthor" index={index} inputType={"text"} inputPlaceholder={metaData[index].author} inputLabel={translate("AuteurCompositeur")} disabled={true} value={metaData[index].author} />
          <InputFieldCustom inputName="metaproducer" index={index} inputType={"text"} inputPlaceholder={metaData[index].producer} inputLabel={translate("Producteur")} value={metaData[index].producer} />
          <div className="musicinput_dropdown_art_prod_gender_select">{translate("gender")} <SelectInputGender index={index} bgColor="#303030" inputPlaceholder={translate("chooseGender")} optionList={albumCategories} value={metaData[index].gender} /></div>
        </div>
        <div className="musicinput_dropdown_expl">
          <div className="musicinput_dropdown_expl__text"> <span className="musicinput_dropdown_expl__text--title">{translate("explicit")}<span className="musicinput_dropdown_expl__text--required">*</span></span>
            <div className="musicinput_dropdown_expl__select"><RadioInputExplicit index={index} fieldName="metaexplicit" optionList={ ouiNon } /></div>
          </div>
          <InputFieldCustom inputName="metayear" index={index} inputPlaceholder={metaData[index].year} inputType={"text"} inputLabel={translate("year")} value={metaData[index].year} />
          <div className="musicinput_dropdown_pagination">
            <span>{translate("Pist")}</span>
            <span className="musicinput_dropdown_pagination__underline">{index+1}</span>
            <span>{translate("over")}</span>
            <span className="musicinput_dropdown_pagination__underline">{total}</span>
          </div>
        </div>
        <div className="musicinput_dropdown_art_descrp">
          <span
            className="publish_audio_form_fields_col2__subtitle">{translate("description")}</span>
          <TextAreaInputCustom inputName="metadescription" index={index}  inputPlaceholder={translate("descriptionPlaceHolder")}
                         value={metaData[index].description} />
        </div>
      </div>
    </div>
  );
}