import SidebarItems from "../SideBarItem";
import {Link, useLocation} from "react-router-dom";
import SideItem from "../SideItem";
import React, {useContext, useEffect, useState} from "react";
import {changeActiveIndex, getPath, getRole} from "../../utils/functions";
import {useMenuSwitchContext} from "../../datamanager/contexts/locals/menuSwitch";
import CurrentUserContext from "../../datamanager/contexts/currentUser";

export default function SideBarMenuList() {

  const { currentMenu, handleSwitchMenu } = useMenuSwitchContext();
  const { currentUser } = useContext(CurrentUserContext);

  const location = useLocation();

  //Load this string from localStorage
  const lastActiveIndexString = localStorage.getItem("lastActiveIndex");

  //Parse it to a number
  const lastActiveIndex = Number(lastActiveIndexString);

  //Store it in state
  const [activeIndex, setActiveIndex] = useState(
    lastActiveIndex || Number(currentMenu)
  );

  useEffect(() => {
    const activeItem = SidebarItems.findIndex(
      (item) => getPath(item.location) === getPath(location.pathname)
    );
    changeActiveIndex(activeItem, setActiveIndex);
  }, [location]);

  useEffect(() => {
    handleSwitchMenu(activeIndex + 1);
    // Cette dépendance assure que handleSwitchMenu ne sera appelé que lorsque activeIndex change
  }, [activeIndex, handleSwitchMenu]);
  
  return (
    <ul>
      {SidebarItems.map((item, index) => {
        if (getRole(currentUser) === item.user) {
          return (
            <Link className="linker" key={index} to={item.location}>
              <SideItem
                name={item.name}
                icon={item.icon}
                active={index === activeIndex}
              />
            </Link>
          );
        }
        return null; // Vous devriez retourner null ou un fragment si rien ne doit être rendu
      })}
    </ul>
  );
  
}