import { useContext, useEffect } from "react"
import CurrentUserContext from "../datamanager/contexts/currentUser"
import { useLoadingContext } from "../datamanager/contexts/locals/Dashboard/loading"
import { useComments as useComments_ } from "../datamanager/contexts/comments"
import {usePost} from "../datamanager/contexts/posts";

export default function useComments() {
  // Get data from the global context
  const { currentUser } = useContext(CurrentUserContext)
  const { setCommentsFetching } = useLoadingContext();
  const { fetchAllPostComments, handleOrderComments } = useComments_();
  const { posts } = usePost();

  // UseEffect section
  useEffect(() => {
    if (currentUser && posts && posts.results && posts.results.length > 0) {
      setCommentsFetching(true)

      // fetch all post comments
      posts && posts.results && posts.results.length > 0 && posts.results.forEach((post) => {
        console.log("post__________", post);
        fetchAllPostComments(post.id)
      })

      // order by date
      handleOrderComments()

      setCommentsFetching(false);
    }
  }, [currentUser, posts])

}