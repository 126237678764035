import React from 'react'
import bars from '../../../assets/icons/bars.svg'
import chart from '../../../assets/icons/chart.svg'
import cards from '../../../assets/icons/cards.svg'
import art1 from '../../../assets/images/art1.png'
import art2 from '../../../assets/images/art2.png'
import art3 from '../../../assets/images/art3.png'
import Button from '../../Button/Button'

import './left_bar_data_6.scss'
import { map } from 'lodash'
import { useStats } from '../../../datamanager/contexts/Stats'
import { SpinnerCircular } from 'spinners-react'
const LeftBarDatas6 = () => {
    const { stat } = useStats();

    console.log(stat);

    const followers =[
        {
            name: 'Lucie Antagana',
            email: 'Antaganalucie@gmail.com',
            amount: '81.000FCFA',
            img: art1
        },
        {
            name: 'Christian Kala Ndingana',
            email: 'KalaNdingana@yahoo.com',
            amount: '61.500FCFA',
            img: art2
        },
        {
            name: 'Valentin Samo',
            email: 'samoval@yahoo.com',
            amount: '61.500FCFA',
            img: art3
        },
        {
            name: 'Vanessa Abomo',
            email: 'abomonessa@yahoo.com',
            amount: '48.500FCFA',
            img: art1
        },
        {
            name: 'Rodrigue Milango',
            email: 'RodrigueMilango@yahoo.fr',
            amount: '36.500FCFA',
            img: art1
        },
        {
            name: 'Filipe Ding',
            email: 'dingfelipe@gmail.com',
            amount: '36.500FCFA',
            img: art1
        },
        {
            name: 'Felicia Mekuoa',
            email: 'femekoua96@gmail.com',
            amount: '36.500FCFA',
            img: art1
        }
    ]
    return (
        <aside className='left-bar-data-6'>
            <h2 className='text-white font-bold text-base leading-5 text-start'>Les 10 fans les plus actifs</h2>
            { stat ?
                stat.top_buyers.map((buyer, index) => (  
                    <section className='flex gap-3 flex-wrap' key={index}>
                        <img src={buyer.photo} alt="art1" className="intro-img" width={'48px'} height={'48px'} />
                        <div className='flex flex-1 flex-col gap-3'>
                            <p className='text-white font-normal text-sm leading-5 text-start'>{buyer.username}</p>
                            <p className='font-normal text-sm leading-5 text-start email'>{buyer.email}</p>
                        </div>
                        <p className='text-white font-normal text-sm leading-5 '>{buyer.total_spent}FCFA</p>
        
                    </section>  

                ))
                :
                <SpinnerCircular color="#FF5620"/>
            }

        </aside>
    )
}

export default LeftBarDatas6