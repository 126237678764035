import React, {createContext, useCallback, useContext, useState} from 'react';

const BackErrContext = createContext();

export const useBackErr = () => useContext(BackErrContext);

export const BackErrProvider = ({ children }) => {
  const [backErr, setBackErr] = useState(false);

  const handleSetBackErr = useCallback((value) => {
    if (value !== backErr) {
      console.log('llllllllll',value);
      setBackErr(value);
    }
  }, [backErr]);
  return (
    <BackErrContext.Provider value={{ backErr, handleSetBackErr }}>
      {children}
    </BackErrContext.Provider>
  );
};
