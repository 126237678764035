import translate from "../../../i18n/translate";
import React from "react";
import image from "../../../assets/new_images/macbook.png"
export default function ConnexionUIRight() {
  return (
    <div className="secondside">
      <div className="secondsidetitle">{translate('boost')}</div><br/>
      <div className="secondsidetext">{translate('boostunder')}</div><br/>
      <img src={image} alt="macbook"  />
      <div className="secondsidecopyright">
        <div>{translate('copyright')}</div>
        <div className="secondsideterms">
          <a href="https://www.colorfol.com/cgu-premium" target="_blank">{translate("terms")}</a>
          <br/>
          <a href="https://www.colorfol.com/privacy-policy" target="_blank">{translate("privacy")}</a>
          <br/>
        </div>
      </div>
    </div>
  );
}