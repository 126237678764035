import { createContext } from "react";

const NavigationContext = createContext({
  currentTarget: "",
  showMenu: false,
  navigateTo: (target) => {},
  openMobileMenu: () => {},
  closeMobileMenu: () => {}
})

export default NavigationContext