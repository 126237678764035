import { LOCALES } from '../locales'

export default {
  [LOCALES.ENGLISH]: {
    "termDef": {
      "upc": 'This is a unique code used to identify a product, such as an album or a single.\n',
      "isrc": 'The ISRC is a standardized identification code that can be used to identify audio recordings (phonograms) and music audiovisual recordings (videomusic) so that each recording can be uniquely and unambiguously designated.',
    },


    // =================================
    // upload single translate
    "uploadDirectives": "You can upload one or more files, or drag and drop them here. to add them to your album.",
    "noback": "Once the upload is initiated, it cannot be cancelled. Do you want to continue?",
    "backtoAudio": "Back to your Music",
    "update": "Update",
    "reload": "Reload",
    "updateSucessAlbumCover": "Your album cover has been updated, reload the page to see it",
    "noPost": "No post available, go to the mobile app to make posts.",
    "backtoAlbum": "Back to your Album",
    "uploadSingleSucess": "Your Single has been uploaded 🥳🥳🥳",
    "uploadAlbumSucess": "Your Album has been uploaded 🥳🥳🥳",
    "YourNewMusic": "Your New Music",
    "cancel": "Cancel",
    "YourNewAlbum": "Your New Album",
    "free": "Free",
    "publicationDate": "Publication Date",
    "enterInformations": "Enter informations about adding your single on the platform",
    "enterInformationsAlbum": "Enter informations about adding your album on the platform",
    "defineUlustrations": "Define your single's Cover",
    "chooseFile": "Choose File",
    "chooseFiles": "Choose Files",
    "defineUlustrationsAlbum": "Define your album's Cover",
    "dragAndDropYourCoverHereOrChooseOne": "Drag and drop your cover here or choose one",
    "dragAndDropFilesHereOrChooseFiles": "Drag and drop your files here or choose files",
    "dragCoverSucess": "Drag cover success",
    "dragAndDropFileHereOrChooseOne": "Drag and drop your file here or choose one",
    "dragFIleSucess": "Drag file success",
    "singleDetails": "Single Details",
    "singleDetailsAlbum": "Album Details",
    "singleInformationsAlbum": "Album Informations",
    "singleInformations": "Single Informations",
    "singleTItle": "Single Title",
    "singleTItleAlbum": "Album Title",
    "singlePlaceholder": "Enter your single's title",
    "categoryPlaceholder": "Select your single's category",
    "categoryPlaceHolderAlbum": "Select your album's category",
    "category": "Category",
    "distributionMode": "Distribution Mode",
    "streaming": "Streaming",
    "downloadable": "Downloadable",
    "ForSale": "For Sale",
    "price": "Price",
    "description": "Description",
    "descriptionPlaceHolder": "Enter your single's description",
    "coverDirectives": "Cover Directives",
    "singleDirectives": "Single Directives",
    "albumDirectives": "Album Directives",
    "coverDirectivesPlaceholder": "Square shape, minimum 500×500px. File format: PNG (favorites) or JPEG. Compliance with these rules is essential for your publication to be supported.",
    "previewOnMobilePlayer": "Preview on Mobile Player",
    "Cancel": "Cancel",
    "uploadCancel": "Cancel Upload",
    "submitNoBack": "Submit without back",
    "DefineInformations": "Define Informations",
    "configureMusic": "Configure your music",
    "publish": "Publish",
    "chooseGender": "Choose Gender",
    "gender": "Gender",
    "explicit": "Explicit",
    "Oui": "Yes",
    "uploadDirectives2": "You can upload one or multiple files, All files must be uploaded at once, and no additions will be possible afterwards",
    "Non": "No",
    "Title": "Title",
    "pubOnColorfol": "Published on ColorFol",
    "label": "Label",
    "noLabel": "No Label",
    "albumStat": "Album Statistics",
    "noCategory": "No Category",
    "Artist_s": "Artist(s)",
    "AuteurCompositeur": "Author/Composer",
    "Producteur": "Producer",
    "Year": "Year",
    "calculating": "Calculating",
    "addSingleToAlbum": "Upload New Audio Files",
    "Pist": "Track",
    "hybrid": "hybrid",
    "over": "over",
    "singlePreveiew": "Single Preveiew",
    "albumPreveiew": "Album Preveiew",
    "uploadSucss": "Congratulations, you have added your new album with success!",
    "noData": "No data available at the moment.",
    "Lectures": "Listening",
    "musicSales": "Music Sales",
    "IncomesEstimation": "Incomes Estimation",
    "relistenningRate": "Relistenning Rate",
    "thisMonth": "This Month",
    "singles": "Singles",
    "media": "Media",
    "post": "Post",
    "albums": "Albums",
    "reachNumber": "You have reached the number of singles you can add ",
    "listenningOnColorfol": "Listenning on Colorfol",
    "albumsSales": "Albums Sales",
    "congrats": "Congratulations ! You have reached the number of albums of ",
    "year": "year",
    "Titre": "Titre",
    "commingsoon": "COMING SOON ....",
    "commingsoonbtn": "Back",
    // ===============================

    // translation for sidebar
    'menu': "PRINCIPAL MENU",
    'dash': "Dashboard",
    'yourmusic': "Your Music",
    'publication': "Publications",
    'interactions': "Interactions",
    'marketing': "Marketing",
    'incomes': "Incomes",
    'paramters': "Parameters",
    'gethelp': "Get Help",
    'disconnect': "Disconnect",
    'your_incomes': "Your Income",
    "with_colorfol": "With Colorfol, you have all your income. You can pay when you have it without trying.",
    'details_on_incomes_by_sales': "Details on your income by album's sales",

    // translation for search bar
    'searchholder': "enter a keyword to search...",

    // translate main side
    'hello': "Hello",
    'welcome': "Welcome back on your personal and dedicated space",
    'yourdata': 'Your Data',
    'listenning': 'Listening',
    'numberofsales': 'Number of sales',
    'thismonth': 'This month',
    'estimatedrevenue': 'Estimation of incomes',
    'subscribers': 'Subscribers',
    'listenners': 'Listenners',
    'ouraudience': 'Our audience',
    'totallistenning': 'Total listenning',
    'from': 'From',
    'topfans': 'Top Fans',
    'purchasealbums': 'Purchase albums',
    'searchasingle': 'Search a single',
    'orderby': 'Order by',
    'title': 'Title',
    'salesnumber': 'Sales number',
    'mostattractedsingleandalbums': 'Most Tracted Single and Albums',
    'listennersorigin': 'Listenners Origin',
    'totalpurchases': 'Total purchases',
    'streams': 'Streams',
    'purchases': 'Purchases',
    'folowers': 'Followers',
    'seefullclassification': 'See full classification',
    'no_contents': "No contents available",
    'publish_content': "Publish content",

    // translate for leftbar

    'televerser': "Televerse",
    'draganddrophereorchoosefilestoadd': "Drag and drop here or choose files to add",
    'realtime': "Realtime",
    'televersement': "Televersement",
    'yourinstantdatas': "Your instant datas",
    'yourinformations': 'Your informations today',
    'runningmusics': 'Running musics',

    // translate the Your Music section
    "your_music": "Your Music",
    "your_recent_contents": "Your recent contents",
    "apparition": "Apparition",
    "ordering": "Ordering",
    "appearing_in_playlists": "Appearing in playlists",
    'see_more': "See more",
    "you_best_fans": "You best fans",
    "replay_rate": "Replay rate",
    "downloads_list": "Downloads list",
    "add_informations": "Add informations",
    "last_uploads": "Last uploads",
    "delete": "Delete",
    "edit": "Edit",
    "revenue": "Incomes",

    // ============================================================================================================================================
    // translate login page

    'enterdatas': "Enter your data to access your workspace",
    'artistname': "Artist Name",
    "artistemail": "Artist Email",
    'enterartistname': "Enter the artist name",
    'password': "Password",
    'enterpassword': "Enter the password",
    'connect': "Connect",
    'byclicking1': "By clicking \"Connect\", you acknowledge that your data will be processed in accordance with the ",
    'forgot': "Forgot password ?",
    'byclicking2': "privacy policy",
    'byclicking3': " of",
    'notartistyet': "Don't have an artist account yet?",
    "artistyet": "Already have an artist account?",
    'create': "Register",
    "SignIn": "Sign In",
    'retry': "Retry",
    'connexiontocolorfol': "Connecting to Colorfol ...",
    'welcomebackoncolorfolforartists': "Welcome back on Colorfol for artists",

    'boost': "Boost your artistic content with Colorfol",
    'boostunder': "We have helped over 1000 artists create countless content in the fields of music, podcasts, events, and more. Let's see how Colorfol can work for you.",
    // =============================================================================================================================================
    /// Navbar ///
    'obj': "Goals",
    'dis': "Discover",
    'aid': "Support",
    'con': "Log in",

    // =================================

    'disc': "Discover Colorfol",
    'prem': "Colofol Premium",
    'colart': "Colofol for Artist",
    'freedown': "Free Download",
    'join': "Join Colorfol For Artists",

    'hqa': "Colorfol, one place to manage everything",
    'subhqa': "We work with artists to build the solution they need to grow their careers on their own terms",
    'disc_buy': "Sell Directly to your Fans",
    'subdisc_buy': "Connect directly with your fans, build your community, sell your music independently",
    'pass_event': "Fan Marketing",
    'subpass_event': ": Keep your fans engaged, know who they are, and develop your artist-fan relationship over time.",
    'playlist_fy': "Immediate Monetization",
    'subplaylist_fy': "Get paid instantly for every sale you make.",
    'play_off': "Legal Downloads",
    'subplay_off': "Offer your audience a legal and accessible way to download your music.",
    'supp': "Colorfol, one place to manage everything",
    'subsupp': "We work with artists to build the solution they need to grow their careers on their own terms",
    'suppp': "Join the Colorfol Family",
    'subsuppp': "A vibrant community of over 3000 creative and passionnate independent artists",
    'getprem': "Get started with Colorfol",
    'knowmore': "Want to know more about us",
    'conta': "Contact us for any questions or more information about us",
    'contactus': "Contact Us",


    // =============================

    ///Hero section ///
    'value': 'Make a living',
    'value1': 'from your passion',
    'covalue': "We are a direct-to-fan solution that makes it easy for you to sell directly to your fans and monetize your music.",
    'fcta': 'First steps',
    'join': "Join Colorfol For Artists",
    'discover': "Discover the app",
    /// Goals section ///
    'gs_mt': "Our goals",
    'fs_ob': "Show your talent to the world",
    'fs_ds': "Distribute your podcast on the most popular listening apps, including Colorfol, with a single tap and host unlimited content for free, forever.",
    'sc_ob': "Monetize your contents",
    'sc_ds': "Distribute your podcast on the most popular listening apps, including Colorfol, with a single tap and host unlimited content for free, forever.",
    'th_ob': "Support your decision making",
    'th_ds': "Distribute your podcast on the most popular listening apps, including Colorfol, with a single tap and host unlimited content for free, forever.",

    /// Second CTA  section ///
    'sa_mt': "Ready to let the world enjoy your talent through Colorfol Artist ?",
    'scta': "Add your content",

    /*
     * -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    * Mise en place de la gestion du dashboard
    * */
    'esp_taf': "Workspace",
    'general': "General",
    'tab_bord': "Dashboard",
    'singl': "Singles",
    'album': "Albums",
    'redev': "Redevances",
    'pub_content': "Publish your first content",
    'no_content': 'No available content!',
    'no_single': 'No available audio here!',
    'pub_single': 'Publish your first single here',
    'no_album': 'No available album here',
    'pub_album': 'Publish your first album',
    'edit_prof': 'Edit your profile',
    'next': "next",
    'dashboard': "Dashboard",
    'under_title_dashboard': "Track the data generated by your content on Colorfol Artist",
    'upload': "Upload",
    'new_content': "New media",
    'total_listenning': "Total Listening",
    'total_album': 'Uniques listens',
    'total_song': "Total Musics",
    "saveAsDraft": "Save as draft",
    "trackListOrder": "Track List Order",
    "downloadedFiles": "Downloaded Files",

    /*
    * -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    * Mise en place de la page de publish
    * */
    'pub_page_title': "Publish content on Colorfol Music",
    'pub_pag_sub_title': "Choose the content type you want to publish.",
    /*-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/


    /// Footer ///
    'ft_fmt': "Services",
    'fmt_fi': "Music and Podcasts Streaming",
    'fmt_si': "Video Streaming",
    'fmt_ti': "Events",
    'fmt_foi': "Networked Music",
    'fmt_fii': "Reviews",
    'fmt_fii': "Reviews",
    'fmt_fiii': "News",

    'help': "Help",
    'subpricing': "Subscription and Pricing",
    'downapp': "Download the app",
    'getstart': "Get started",


    'diss': "Discover",

    'ft_smt': "Contact Us",
    'smt_fi': "Contact.cm@colorfol.com",
    'smt_si': "(+237) 690 81 72 84",
    'smt_ti': "Akwa, Douala, Cameroon",
    'smt_foi': "Contact.ci@colorfol.com",
    'smt_fii': "(+225) 0 787 458 582",
    'smt_sii': "Cocody Angré Petro Ivoire Abidjan- Côte d’Ivoire",
    'smt_sei': "Contact.fr@colorfol.com",
    'smt_hui': "(+33) 6 05 87 57 04 ",
    'smt_nei': "Paris, France",


    'ourcompany': "Our company",
    'waw': "Who are we?",
    'ov': "Our Vision",
    'ot': "Our team",
    'fu': "Follow us",
    'lang': "English",
    'privacy': "Privacy policy",
    'terms': "Terms of use",
    'copyright': "Copyright ©2024 All rights reserved Colorfol SARL",
    "finishing": "Finilizing your content...",
    /*
* ------------------------------------------ CGU ( condition generale d'utilisateur --------------------------------------------------
* */

    'cgu_title': "Colorfol's small print",
    'cgu_sub': "Welcome to Colorfol®, a service provided by COLORFOL SARL (\"COLORFOL SARL\").",
    'cgu_1_par': "These Terms of Use govern your use of colorfol.com, our mobile and desktop applications (our \"Apps\") and all readers, widgets, tools, applications, data, Software, APIs (which may also be subject to separate API Terms of Service) and other services provided by COLORFOL SARL (the \"Services\").",
    'cgu_2_par': "These Terms of Use, together with our Privacy Policy, Cookie Policy, Community Guidelines and any other provisions expressly referred to in these documents constitute a legally binding agreement (the \"Agreement\") between you and Colorfol relating to your use of the Website, Applications and Services (together, the \"Platform\").",
    'cgu_intro_cu': "These terms of use are divided as follows:",
    'cgu_1_art': "Acceptance of Terms of  Use Basically, by using Colorfol®, you accept our Terms of Service, Privacy Policy, Cookies Policy and Community Privacy Policy and agree to abide by them.",
    'cgu_2_art_title': "INTELLECTUAL PROPERTY",
    'cgu_2_atr_1_par': "The platform and its editorial, graphic, photographic, audio and video elements are protected under the provisions of the Intellectual Property Code. The platform and its associated databases are also deposited with the Program Protection Agency (APP).\n",
    'cgu_2_atr_2_par': "More generally, copyrights and related rights are reserved and Colorfol is the exclusive owner or licensee of all intellectual property elements composing the Site, such as logos, trademarks, drawings and models, audio and video files, texts, images, technologies and know-how.",
    'cgu_2_atr_3_par': "As such, any deterioration, as well as, in the absence of authorization, any representation, reproduction, modification, commercial use, total or partial, of the various elements of the Site is prohibited and exposes its author (s) to prosecution. These T&Cs do not imply in any way authorization to represent, reproduce, modify or commercially use the various elements of the Site. Thus, any total or partial reproduction of the Site and/or its elements for purposes other than use in accordance with its intended purpose is strictly prohibited: any resale, exchange, rental of files or transfer to third parties is strictly prohibited.",
    'cgu_2_atr_4_par': "Similarly, the various possibilities of engraving or transfer proposed to the Customer as part of the offers do not in any way imply waiver by the rights holders of their rights to their files subject to Colorfol's services.",
    'cgu_2_atr_5_par': "Colorfol also remains the sole owner of the right to distribute these files, which are protected by copyright worldwide.",
    'cgu_2_atr_6_par': "The Customer hereby undertakes not to infringe (modification, deletion ...) any technical measures for the protection of rights, not to obstruct, modify, or delete any information relating to the rights regime on the products, nor to encourage or assist third parties to perform such acts. Likewise, it undertakes not to try to circumvent or modify the software necessary for the use of the services.",
    'cgu_3_art': "Changes to Terms of  Use This section explains that our Terms of Use (TOS) may change from time to time.",
    'cgu_4_art': "Platform Description This section provides a general description of the platform, its features, and functionality.",
    'cgu_5_art': "Your Colorfol Account This section explains your responsibilities if you choose to sign up for a Colorfol® account.",
    'cgu_6_art': "Your Use of the Platform This section describes your right to use the Platform and the terms that apply to your use of the Platform.",
    'cgu_1_nb': "Colorfol draws the user's attention to the comments that he may publish on the Site.",
    'cgu_2_nb': "The Site has interactive features that allow you to publish comments and make some of your information available to the public, on the Internet or on social networks. Colorfol can in no way be held responsible for the Customer's choices to make some of his information publicly accessible.",
    'cgu_3_nb': "The Customer is also informed that the spaces reserved for comments on the Site are moderated and that comments prohibited by law and/or by these Terms, as well as those infringing the rights of third parties may be deleted permanently, without notice or compensation of any kind. To this end, it is prohibited, and in a non-exhaustive manner, to publish comments likely to:",
    'cgu_a_nb': "constitute an infringement of the intellectual property rights of third parties; in this respect, the member undertakes not to reproduce or communicate to the public on the Site elements protected by copyright, registered as a trademark or more generally protected by any other intellectual property right held by third parties without the prior consent of the holder(s) of such property rights;",
    'cgu_b_nb': "contain computer viruses likely to interrupt, destroy or alter the functionality of the Site;",
    'cgu_c_nb': "glorify crimes or contain illegal, threatening, paedophilic, pornographic, defamatory, obscene, hateful, racist, xenophobic, revisionist or contrary to public order or morality;",
    'cgu_d_nb': "violate the privacy or respect for dignity of third parties;",
    'cgu_e_nb': "incitement to violence, fanaticism, crime, suicide, hatred on grounds of religion, race, gender, sexual orientation, ethnicity, disability;",
    'cgu_f_nb': "harass other members;",
    'cgu_g_nb': "promote or encourage any criminal activity or enterprise;",
    'cgu_h_nb': "solicit and/or communicate passwords and/or personal information for commercial or illegal purposes;",
    'cgu_i_nb': "transmit unwanted advertising messages or mail;",
    'cgu_j_nb': "include advertisements and/or solicitations in order to offer products and/or services for marketing through the Site;",
    'cgu_k_nb': "contain addresses or hypertext links to external sites whose content is contrary to the laws and regulations in force, which infringe the rights of third parties or which would be contrary to these conditions.",
    'cgu_7_art': "Your Content This section deals with ownership of your content and includes your consent not to import anything that violates the rights of others.",
    'cgu_8_art': "License Grant This section explains how your content will be used on Colorfol® and the permissions you grant when uploading your content – for example, the right for other users to listen to your sounds.",
    'cgu_9_art': "Representations and warranties This section includes the important promises and warranties you give when uploading content to Colorfol®, in particular your promise that everything you upload and share is owned by you and does not infringe the rights of others.",
    'cgu_10_art': "Responsibility for content This section explains that Colorfol  is a hosting service and that its users are solely responsible for the content they upload to Colorfol®.",
    'cgu_11_art': "Reporting Violations This section explains how to notify us of any Colorfol® content that you believe infringes your copyright or other intellectual property right, or is illegal, abusive, defamatory, or violates our Terms of Use or Community Guidelines. Further information on copyright infringement can be found on our copyright information pages.",
    'cgu_12_art': "Blocking and Removal of Content This section clearly states that Colorfol may block or remove content from the platform.",
    'cgu_3_par': "Users who repeatedly infringe the rights of others or violate our Terms of Service or Community Guidelines may be suspended or have their Colorfol® accounts terminated, as explained in this section.",
    'cgu_4_par': "The subscriber may interrupt his subscription to the Colorfol offer to which he has subscribed by going to the \"My Account\" section.",
    'cgu_5_par': "The consideration of the termination will be notified by e-mail to the Customer but will only be effective at the end of the current subscription period provided that its notification has been sent at least 48 hours before its end.",
    'cgu_6_par': "In case of non-compliance with the cancellation period, the subscription will be automatically renewed.",
    'cgu_7_par': "Unless the account is permanently deleted, any subscription remains valid for the duration of the subscribed or offered offer (monthly or annual), even in the event of unsubscription by the Customer before the end of this period.",
    'cgu_8_par': "In case of manifest violation of the GTCU by the Customer, Colorfol reserves the right to terminate the current subscription, delete and / or temporarily deactivate the Customer's account, without notice or compensation, in particular in the event of:\n",
    'cgu_1_li': "harm to the interests of Colorfol and / or its intellectual property rights;",
    'cgu_2_li': "provision of erroneous information when registering for the Site;",
    'cgu_3_li': "circumvention or attempted circumvention of the technical protection measures put in place on the Site;",
    'cgu_4_li': "total or partial non-payment of fees addressed to the user.",
    'cgu_9_par': "The Customer's attention is drawn to the fact that the deletion of the account will permanently and irrevocably prevent him from finding the information associated with the account and the associated purchases (playlists, albums, tracks, login information, etc.).",
    'cgu_13_art': "This section explains that COLORFOL SARL® cannot guarantee that the platform will always be available - sometimes even a platform as great as ours will have some problems.",
    'cgu_14_art': "Limitation of Liability This section explains some of the things for which Colorfol will not be liable. Please read and understand this section.",
    'cgu_10_par': "COLORFOL SARL can in no way be held responsible for technical problems inherent in the operation of the Internet. A non-exhaustive list of such issues includes problems with Site access speeds, download speeds, suspension or inaccessibility of the Services, or fraudulent use by third parties of any information contained on the Site.",
    'cgu_11_par': "These T&Cs clearly explain the technical terms of use of the streaming services offered by Colorfol.",
    'cgu_12_par': "In case of technical incompatibility of said services with the Customer's equipment, Colorfol can in no way be held responsible, and no refund can be made. In addition, the protection of the Customer's computer equipment, in particular against viruses, can in no way be the responsibility of Colorfol.\n" +
      "The Site may contain hypertext links to third-party websites. Colorfol cannot be held liable under any circumstances in the event that said third-party sites include illegal elements.\n",
    'cgu_15_art': "Indemnification If you use the Platform in a way that causes damage to us, you shall be responsible for this.",
    'cgu_16_art': "Data protection, privacy and cookies It is very important for you to understand how we use your personal information. All information is collected, stored and used in accordance with our Privacy Policy, so make sure you read and understand this policy. Like most other websites, we also use cookies to help us analyze how people use Colorfol, so we can continue to improve our service. Our use of cookies is explained in our Cookie Policy. Please note that if you choose not to disable cookies in your browser, you will indicate your consent to the use of cookies as described in our Cookie Policy, so be sure to read the policy.",
    'cgu_17_art': "Contests and other promotions This section deals with competitions, contests and sweepstakes on Colorfol®. These are not managed by Colorfol, and therefore we cannot be responsible for them. If you want to SignUp your own competition on Colorfol, make sure you read and understand our contest conditions.",
    'cgu_13_par': "Can use our players and widgets – basically, don't try to use our players to create a new music or audio streaming service. Subscriptions and Gift Codes This section gives you information about purchasing \"Pre\" and \"Pre Unlimited\" plans and \"Colorfol\" subscriptions as well as gift codes and how you can cancel your purchases in certain circumstances. Changes to the Platform, Accounts, and Prices From time to time, we may need to change Colorfol®. This section explains your rights in this situation.",
    'cgu_18_art': "This section provides information about Colorfol, including How to Contact Us.",
    'cgu_19_art': "Acceptance of Terms of Use  Please read these Terms of Use, Privacy Policy, Cookie Policy and Community Guidelines carefully. If you do not agree to any of the terms set forth in these documents, you must not use the Website, Apps or any of the Services. By accessing or using the Platform, registering an account, viewing, accessing, streaming, downloading or uploading information or content to or from the Platform, you represent and warrant that you have read and understood the Terms of Use, Cookie Policy and Community Guidelines,  will comply and you are 18 years of age or older, or are the age of majority in your jurisdiction or if you are under 18 or the age of majority, You are 13 years of age or older and have permission from your parent or legal guardian to use the Platform.",
    'cgu_14_par': "Changes to the Terms of Use We reserve the right to modify, replace or modify these Terms of Use. Use anytime. The date of the last modification is indicated at the end of these Terms of Use. It is your responsibility to check this page from time to time for updates. When we make updates to these Terms of Use, we will highlight that fact on the Website. In addition, if you register an account and these Terms of Use are subsequently amended in any material respect (e.g. for security, legal or regulatory reasons), we will notify you in advance by sending a message to your Colorfol® account and/or an email to the email address you have provided to us,  and the revised Terms of Use will be effective one (1) week after such notification. You will have no obligation to continue using the Platform following such notice, but if you do not terminate your account as described in the termination section below within such one (1) week period, your continued use of the Platform after the end of this one (1) week period will constitute your acceptance of the revised Terms of Use.\n" +
      "Description of the Platform The Platform is a hosting service. Registered users of the Platform may submit, upload and display audio, text, photos, images, graphics, comments and other content, data or information (\"Content\") that will be stored by Colorfol at the request of such registered users, and may be shared and distributed by such registered users and other users of the Platform,  using the tools and features provided as part of the Platform and accessible through the Website, Apps and elsewhere. The Platform also allows registered users to interact with each other and contribute to discussions and allows any user of the Website, Applications or certain Services (who may or may not be registered users of the Platform) to view, listen to and share content uploaded and made available by registered users. We may from time to time release new tools and resources on the Website, publish new versions of our Applications or introduce other services and/or features for the Platform. All new services and features will be subject to these Terms of Service as well as any other terms of use we may post for those specific services or features. Your Colorfol account You are not required to register to use the platform. However, access to applications and certain services is only available to registered users. For example, our application, Profile, FollowME, Chat, allowing registered users to post media that download and make their content available to other users, receive instant feedback on their posts, communicate with their listeners and manage their content\n" +
      "Anytime. When you register to use the Platform, you will provide us with your email address, phone number and choose a username and password for your account. You must ensure that the email and telephone address you provide is and remains valid. Your email address, telephone number and any other information you have chosen to provide will be treated to you in accordance with our Privacy Policy. You are solely responsible for the confidentiality and security of your username and password and you remain responsible for all use of your username password and all activities arising under your account, whether such activity has been authorized by you. If your username or password is lost or stolen, or if you believe that your account has been accessed by unauthorized third parties, notify Colorfol in writing and change your password as soon as possible. We reserve the right to refuse, cancel, delete or reassign certain usernames and permalinks in appropriate circumstances, as determined by us in our sole discretion. Without notice, suspend or terminate your account if any activity occurs on that account that, in our sole discretion, would constitute or may constitute a violation of these Terms of Use or our Community Guidelines or a violation of the rights of a third party, or any applicable law or regulation.\n" +
      "You may terminate your account at any time as described in the Termination section below. Your use of the Subject to your strict compliance with these Terms of Use and our Community Guidelines at any time during your Use of the Platform, Colorfol grants you a limited, personal, non-exclusive, revocable, non-assignable and non-transferable right and license to use the Platform to display content uploaded and displayed on the Website,  to listen to audio content streamed on the Platform or offline and to share and download audio content using the features of the Platform where the appropriate functionality has been enabled by the user who uploaded the relevant Content (the \"Sending Program\") and subject to the territorial availability of such functionality and audio content. In addition, if you register for use of the Platform and subject to your strict compliance with these Terms of Use and our Community Guidelines at any time during your use of the Platform, Colorfol grants you a limitation, Non-exclusive, revocable, non-transferable and non-transferable and:\n",
    'cgu_5_li': "submit, upload or display Content and maintain it on the Platform strictly in accordance with these Terms of Use and any other terms posted on the Website from time to time,",
    'cgu_6_li': "participate in community areas and communicate with other members of the Colorfol® community in strict accordance with these Terms of Use and our Community Guidelines; And",
    'cgu_7_li': "use Apps and other Services provided under the Platform strictly in accordance with these Terms of Use and any other terms applicable to such Apps or Services from time to time.",
    'cgu_15_par': "The above licenses are conditional on your strict compliance with these Terms of Service and our Community Guidelines at all times during your use of the Platform, including, without limitation, the following:\n" +
      " (i) You must not copy, rip or capture, or attempt to copy, Audio from the Platform or any part of the Platform, other than by downloading or storing for offline listening in circumstances where the Uploader has chosen to allow offline downloading or listening of the relevant Content. Adapt, copy, republish, make available or otherwise communicate to the public, display, perform, transfer, share, distribute or otherwise use or exploit any Content on or from the Platform at any time, except (i) Your Content at any time and for the duration of your use of the applicable Content, or (ii) as permitted by these Terms of Use,  and in the settings set by the Uploader (for example, under the terms of the Creative Commons licenses selected by the Uploader (iii) You must not use any Content (other than Your Content) in any manner designed to create a separate content service or that replicates any part of the Platform offering (iv) You must not use scraping or similar techniques (V) You must not use techniques or use services, automated or not, designed to distort the popularity of your content on the Platform or to distort your content. Apps, plugins, extensions, or other automated means to register accounts, log in, add followers to your account, read content, follow or scroll through other users, send Messages, post comments, or otherwise act on your behalf.\n",
    'cgu_20_art': "MEANS OF PAYMENT",
    'cgu_16_par': "Payment is made by credit card or any other means accepted by the payment providers.\n" +
      " \n" +
      "The Customer proceeds to the payment of his orders by communicating to Colorfol, via its certified partners, his bank details, it being specified that the transaction is secured thanks to the secure online payment system of the service providers, which ensures that the transaction is carried out according to the security standards of the banking sector.\n" +
      " \n" +
      "The debit occurs at the time of the order or up to 24 hours after.\n" +
      " \n" +
      "The payment method used to subscribe to the Colorfol premium (CP) offer will be reused when the subscription is automatically renewed, unless the user changes this payment method in the \"My Account\" section. This change of payment method can be made at any time.\n" +
      " \n" +
      "Colorfol reserves the right to cancel or refuse any order from a Customer with whom there is a dispute (unpaid...).\n",
    'cgu_21_art': "OBLIGATIONS OF THE CUSTOMER",
    'cgu_17_par': "The user declares on his honor to be of legal age and to have full legal capacity to place an order on the Site.\n" +
      " \n" +
      "The Customer declares to accept these Terms.\n" +
      " \n" +
      "In accordance with the Article of these Terms, the Customer expressly undertakes to comply with these terms of use, not to infringe or attempt to undermine file protection measures and to comply with the legislation in force relating to intellectual property rights.\n" +
      " \n" +
      "The user expressly agrees not to use the Site or its Services for illegal purposes.\n" +
      " \n" +
      "The user expressly undertakes not to make commercial or illegal use of the information, services and content provided on the Site, under penalty of criminal or civil liability.\n" +
      " \n" +
      "The user declares himself solely responsible for the use he makes of the Service. XANDRIE SA can in no way be held responsible in the context of a procedure opened against one of its Customers who has made an erroneous use of its Services.\n" +
      " \n" +
      "The user undertakes to be personally responsible for any claim or procedure brought against Colorfol, which would be related to misuse of the Service.\n" +
      " \n" +
      "In any event, Colorfol can only be held liable to the extent of its obligation to deliver in accordance with it. In this respect, this liability will be limited to the price of the order placed by the user.\n",
    'cgu_22_art': "PRICES",
    'cgu_18_par': "The price of the offer \"Colorfol Premium\" and \"Colorfol Unlimited\" is indicated on the Site in the currency of the country Cameroon.\n" +
      "\n" +
      "The price includes the subscription price plus any applicable VAT (in force on the date of subscription).\n" +
      " \n" +
      "The price does not include connection and communication costs relating to the use of an online sales service, the latter being the sole responsibility of the user.\n" +
      " \n" +
      "Colorfol reserves the right to modify the price or these General Terms and Conditions of Use at any time; The service is invoiced according to the rates in force at the time of registration of the user's order.\n" +
      " \n" +
      "Colorfol reserves the right to offer free of charge to any user and subscribers of the Service, with the exception of its subscribers who have subscribed for a fixed period to one of the streaming subscription offers offered on the Site, one-off trial and discovery offers of its music streaming service.\n",
    'cgu_23_art': "PURPOSE",
    'cgu_19_par': "The Site offers the Customer online audio/video services, the precise conditions of which are detailed in these Terms.\n" +
      " \n" +
      "The \"Colorfol.com\" service is reserved for adults or minors holding parental authorization, and may not be used for professional purposes. In this respect, these provisions are without prejudice to the legal provisions protecting the consumer, in particular provided for in the Consumer Code.\n" +
      " \n" +
      "Unless proven otherwise, the data recorded by Colorfol constitutes proof of all transactions between Colorfol and its users.\n",
    'cgu_24_art': "DESCRIPTION OF THE \"Colorfol Premium\" offer",
    'cgu_20_par': "The  \"Colorfol Premium\" offer  is a paid subscription offer to the Colorfol streaming service without advertising, allowing the Customer to listen to all the titles of the Site's music catalog in 320 kbps (Mp3) quality on a single listening device (personal or mobile computer).\n" +
      " \n" +
      "The streaming service is accessible 24 hours a day, 7 days a week.\n" +
      " \n" +
      "Nevertheless, due to the complexity of the Internet network, unforeseen service interruptions may occur (problems due to the Internet service provider, external maintenance, ...) without the responsibility of COLORFOL SARL being engaged in such a case. Colorfol nevertheless undertakes, as far as possible, to inform its Customers of any foreseeable interruption of its services by means of a message posted on its Site.\n" +
      " \n" +
      "Colorfol reserves the right to make any modification or improvement to its services that it deems necessary and useful, and can not be held responsible for any damage, of any nature whatsoever, that may result from this fact.\n" +
      " \n" +
      "Similarly, Colorfol reserves the right, without notice or compensation, to definitively stop a service or all the services offered on the Site, and can not be held responsible for any damage, of any nature whatsoever, that may result from this fact.\n",
    'cgu_25_art': "CONDITIONS OF USE OF THE  \"Colorfol Premium\" offer",
    'cgu_21_par': "The Colorfol Premium streaming service will be accessible by the Customer on his personal computer (PC or Mac) via the Site, Mobile App.\n" +
      " \n" +
      "The use of the unlimited streaming service requires a high-speed internet connection.\n" +
      " \n" +
      "The connection and communication costs relating to the use of an online sales service are not borne by COLORFOL SARL in accordance with Article 10 of these GTCUS.\n" +
      " \n" +
      "For the Colorfol Premium offer, access to the streaming service is allowed on a single mobile or non-determined listening device (PC, MAC, App).\n" +
      " \n" +
      "Access to the Colorfol Premium streaming service is strictly personal and cannot be used on multiple Listening Devices at once.\n" +
      " \n" +
      "In accordance with Article 18 of these GTCUS, all files offered via the Service are protected by copyright.\n" +
      " \n" +
      "Their use can therefore only be done strictly in a private and free setting, in full compliance with the conditions defined by COLORFOL SARL;\n" +
      " \n" +
      "In this respect, in accordance with Article 12 of these GTUS and the legislation in force, it is strictly forbidden for the Customer to attempt to circumvent, delete or alter the technical measures for the protection of the works or any device of equivalent purpose.\n" +
      " \n" +
      "Colorfol grants subscribers of its streaming service a limited, non-exclusive, revocable license to listen in real time, without permission to download (unless specified by the author), in their country of residence. \n" +
      " \n" +
      "The tracks and albums composing the musical catalog of the Site available for listening to subscribers to the Colorfol Premium offer are contractually governed between COLORFOL SARL and the rights holders and are therefore likely to vary.\n" +
      " \n" +
      "As such, COLRTECH can in no way guarantee the presence of a title, album of any singer-artist in the catalog it offers to its Customers and can in no way be held responsible for any deletion of titles, albums or catalog of any performers on its Site.\n" +
      " \n" +
      "For more information on how to use the QOBUZ streaming service, the Customer will refer to the \"help, FAQ\" section.\n",
    'cgu_26_art': "CONDITION OF ACCESS TO THE  \"Colorfol Premium Unlimited\" offer",
    'cgu_22_part': "To be able to benefit from the Colorfol Premium Unlimited offer, the Customer must create his account. To do this, he simply clicks on the \"My Account\" button, available on the home page of the Site. He then follows the instructions indicated to him by the Site.\n" +
      " \n" +
      "Among other things, the Customer is asked to choose a username (nickname) and a password. These elements are strictly confidential, and the Customer is invited to keep them with the utmost caution. The Customer remains solely responsible for the consequences of the use of his account, until the latter is deactivated.\n" +
      " \n" +
      "Any account created is personal and nominative and unique. The Customer can only create one account.\n" +
      " \n" +
      "In order to fulfill the legal obligations regarding invoicing, the Customer will be asked for a certain amount of personal information (title, surname, first name, e-mail address, postal code, etc.). The Customer undertakes to provide accurate and sincere information, and to inform Colorfol of any changes in this regard. If it turns out that the Customer has provided inaccurate information with fraudulent intent, COLORFOL SARL reserves the right to permanently close his account, and to prosecute him both civilly and criminally if necessary.\n" +
      " \n" +
      "Once he has provided the requested information, the Customer ticks a box \"I confirm having read and accepted the general conditions of Use and sale of the site Colorfol.net. Upon validation of this field, the Customer is deemed to have read and accepted these GTCUS, and may therefore be held responsible for the non-performance of the various obligations resulting therefrom.\n",


    // ---------------------------------------------------------------------------------------------------------------------------------------------

    'ft_tmt': "Get the app",
  }
}