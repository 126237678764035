import Stepper from "../../../../components/Stepper";
import React from "react";
import { uploadSteps } from "../../../../utils/Constants";
import translate from "../../../../i18n/translate";
import DropFilesZonesSingle from "../../../../components/PublishAudio/DropFilesZonesSingle";
import FieldSectionSingle from "../../../../components/PublishAudio/FieldSectionSingle";
import StepperActionsSingle from "./StepperActionsSingle";


export default function InfoDefinitionSingle({errors, setFieldValue, values, handleChange, handleSubmit, isValid}) {

  return (
    <div className="publish_audio_form">
      <div className="publish_audio_form_fields">
        <DropFilesZonesSingle errors={errors}
          setFieldValue={setFieldValue}
          isValid={isValid}
          text1={translate("defineUlustrations")}
          text2={translate("chooseFile")}
          values={values}
        />
        <div className="publish_audio_form_fields_col2">
          <span
            className="publish_audio_form_fields_col1__title">{translate("singleDetails")}</span>
          <span className="publish_audio_form_fields_col2__subtitle">{translate("singleInformations")}</span>
          
          <FieldSectionSingle errors={errors} values={values} handleChange={handleChange} setFieldValue={setFieldValue} isValid={isValid} />
        </div>
      </div>
      <div className="publish_audio_form_stepper">
        <Stepper steps={uploadSteps} />
        <StepperActionsSingle values={values} errors={errors} handleSubmit={handleSubmit} isValid = {isValid} />
      </div>
    </div>
  );
}