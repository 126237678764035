import React, { useContext } from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { SpinnerCircularFixed } from "spinners-react";
import { useLocation, Navigate } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import { Play, Pause } from "react-ionicons";
import DataTable from "react-data-table-component-with-filter";
import moment from "moment";
import "moment/locale/fr";
import SinglesContext from "../../../datamanager/contexts/singles";
import MyContextMenu from "../../../components/contextMenu";
moment.locale("fr");

function SingleDetails() {
  // Get data from global context
  const { selectedSingle } = useContext(SinglesContext)
  const [sound, setSound] = useState(null)

  const [isPlaying, setIsPlaying] = useState(false);

  // UseEffect section
  useEffect(() => {
    if (selectedSingle) {
      setSound(new Audio(selectedSingle.src))
    }
  }, [selectedSingle])

  useEffect(() => {
    if (sound) {
      if (isPlaying) {
        handlePlay()
      } else {
        handlePause()
      }
    }
  }, [isPlaying])

  // Some handlers
  const toggle = () => {
    setIsPlaying(!isPlaying);
  };

  const handlePlay = () => {
    sound.play();
  }

  const handlePause = () => {
    sound.pause();
  }
  const handleToggle = () => {
    setIsPlaying(!isPlaying);
    toggle(); // Call the toggle function
  };

  const handleShare = async () => {
    const redirectingLink = "https://yourapp.com/redirect";
    try {
      if (navigator.share) {
        await navigator.share({
          title: `${selectedSingle.author.username} invite you to listen to his new song ${selectedSingle.title}`,
          text: 'Check out this amazing link!',
          url: redirectingLink, // Replace with your redirecting link
        });
      } else {
        // Fallback: Copy to clipboard
        const tempInput = document.createElement('input');
        tempInput.value = redirectingLink;
        document.body.appendChild(tempInput);
        tempInput.select();
        navigator.clipboard.writeText('Text to copy')
          .then(() => {
            console.log('Text copied to clipboard');
          })
          .catch(err => {
            console.error('Failed to copy: ', err);
          });
        document.body.removeChild(tempInput);

        // Display pop-up message to the user
        alert('Link copied to clipboard!');
        // throw new Error('Web Share API not supported');
      }
    } catch (error) {
      console.error('Error sharing:', error.message);
      // Fallback to other methods (e.g., copy to clipboard) if the Web Share API is not supported
    }
  };

  const columns = [
    {
      name: "Cover",
      grow: 0,
      cell: (row) => (
        <img height="76px" width="76px" alt={row.title} src={row.cover} />
      ),
    },
    {
      name: "Titre",
      selector: (row) => row.title,
    },
    {
      name: "Streams",
      selector: (row) => row.nb_of_listening,
    },
    {
      name: "Telechargements",
      selector: (row) => row.nb_of_download,
    },
    {
      name: "Publie le",
      selector: (row) => row.publication_date,
      cell: (row) => (
        <div>{moment(row.publication_date).format("Do MMMM  YYYY")}</div>
      ),
    },
  ];

  return (
    <>
    {
      selectedSingle ? (
        <div className="p-4 w-full h-full max-h-screen flex flex-col items-center justify-start">
          <div className="w-full flex items-start justify-start">
            <div className="w-52 h-52 relative overflow-hidden">
              <img
                  src={selectedSingle.cover}
                  className="object-cover w-full h-full transition-transform transform hover:scale-105 ease-in-out cursor-pointer"
                  alt="cover"
                  onClick={handleToggle}
              />
              <div
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  style={{opacity: isPlaying ? 1 : 0, transition: 'opacity 0.3s ease-in-out'}}
              >
                <button className="ppbut" onClick={toggle}>

                  {isPlaying ? (
                      <Pause
                          color={"#fff"}
                          className="ml-px"
                          title={"shuffle"}
                          height="20.7px"
                          width="20.7px"
                      />
                  ) : (
                      <Play
                          color={"#fff"}
                          className="ml-px"
                          title={"shuffle"}
                          height="20px"
                          width="20px"
                      />
                  )}
                </button>
              </div>
            </div>

            <div className="h-52 flex flex-col items-start justify-end mx-5 ">
              <h5 className="text-sm text-slate-500 font-semibold">Single</h5>
              <h1 className="text-6xl text-slate-500 font-bold my-1.5">
                {selectedSingle.title}
              </h1>

              <div className="flex items-center justify-center mt-2">
                <div className="w-6 h-6 ">
                  <img
                      src={selectedSingle.author.photo}
                      className="rounded-full object-cover"
                  />
                </div>
                <h5 className="text-sm text-slate-500 font-semibold mx-2 rounded-xl">
                  {selectedSingle.author.username}
                </h5>
                <MyContextMenu share={handleShare}/>
              </div>
            </div>
          </div>

          {/*<div className="h-96 my-3 w-full flex flex-col items-SignUp justify-SignUp">*/}
          {/*  <div className="flex items-center justify-SignUp mt-4 mb-8">*/}
          {/*    <button className="ppbut" onClick={toggle}>*/}
          {/*      {isPlaying ? (*/}
          {/*          <Pause*/}
          {/*              color={"#fff"}*/}
          {/*              className="ml-px"*/}
          {/*              title={"shuffle"}*/}
          {/*              height="20.7px"*/}
          {/*              width="20.7px"*/}
          {/*          />*/}
          {/*      ) : (*/}
          {/*          <Play*/}
          {/*              color={"#fff"}*/}
          {/*              className="ml-px"*/}
          {/*              title={"shuffle"}*/}
          {/*              height="20px"*/}
          {/*          width="20px"*/}
          {/*        />*/}
          {/*      )}*/}
          {/*    </button>*/}

          {/*  </div>*/}

            {/*<div className="w-full flex flex-col items-center justify-center">*/}
            {/*  <DataTable*/}
            {/*    columns={columns}*/}
            {/*    data={[selectedSingle]}*/}
            {/*    progressPending={false}*/}
            {/*    progressComponent={*/}
            {/*      <SpinnerCircularFixed*/}
            {/*        size={50}*/}
            {/*        thickness={121}*/}
            {/*        speed={150}*/}
            {/*        color="rgba(133, 19, 78, 1)"*/}
            {/*        secondaryColor="rgba(231, 208, 220, 1)"*/}
            {/*        className="mt-12"*/}
            {/*      />*/}
            {/*    }*/}
            {/*    fixedHeader={true}*/}
            {/*    fixedHeaderScrollHeight={"408px"}*/}
            {/*    pointerOnHover*/}
            {/*    responsive*/}
            {/*  />*/}
            {/*</div>*/}
          {/*</div>*/}
        </div>
      ) : <Navigate to="/app/audio" />
    }
    </>
  );
}
export default SingleDetails;
