import DataCard from "./DataCard";
import React, { useContext, useEffect } from "react";
import SinglesContext from "../../../../datamanager/contexts/singles";
import { formatNumber, truncateDecimal } from "../../../../utils/FormatValues";
import { useStats } from "../../../../datamanager/contexts/Stats";
import DataCardFooterEvolution from "./DataCardFooterEvolution";
import DataCardFooterSimple from "./DataCardFooterSimple";
import SalesChart from "../../dashboard/components/SalesChart";
import { salesData } from "../../../../utils/Constants";
import translate from "../../../../i18n/translate";
import { useLoadingContext } from "../../../../datamanager/contexts/locals/Dashboard/loading";
import { SpinnerCircular, SpinnerDotted } from "spinners-react";

export default function DataCardList() {

  const { stat } = useStats();
  const { singles } = useContext(SinglesContext);
  const { loading, handleSetLoading } = useLoadingContext();

  console.log(stat);
  console.log(singles);


  const datas = [
    {
      title: translate("Lectures"),
      value: stat ? formatNumber(stat?.nb_listenning): <SpinnerCircular color="#FF5620" size={30} />,
      infoTooltip: null,
      foot: <DataCardFooterEvolution value={stat ? stat.nb_listenning ? formatNumber(stat.nb_listenning) : 0 : 0} periodicity={translate("thisMonth")} variationDirection={"top"} />
    },
    {
      title: translate("musicSales"),
      value: stat ? formatNumber(stat?.total_album_sold?.all_time?.count) : <SpinnerCircular color="#FF5620" size={30} />,
      infoTooltip: null,
      foot: <DataCardFooterEvolution value={stat ? stat?.total_album_sold?.all_time?.count ? formatNumber(stat?.total_album_sold?.all_time?.count) : 0 : 0} periodicity={translate("thisMonth")} variationDirection={"down"} />
    },
    {
      title: translate("IncomesEstimation"),
      value: stat ? formatNumber(stat?.total_album_sales?.all_time?.amount) : <SpinnerCircular color="#FF5620" size={30} />,
      infoTooltip: null,
      foot: <DataCardFooterSimple value={"FCFA"} />
    },
    {
      title: translate("relistenningRate"),
      value: stat  ? `${truncateDecimal((stat.nb_uniq_listenning / stat.nb_listenning))}%`  : <SpinnerCircular color="#FF5620" size={30} />,
      infoTooltip: "taux de reecoute",
      foot: <SalesChart salesData={salesData} display_y={false} display_x={false} display_label={false} pointRadius={0} />
    }
  ];

  return (
    <div className="monit flex flex-wrap">
      {
        datas.map((data, index) => (
          <DataCard
            key={index}
            title={data.title}
            value={data.value}
            infoTooltip={data.infoTooltip}
            foot={data.foot}
          />
        ))
      }
    </div>
  );
}