import "./styles/UploadSongListElt.css";
import songUploadOptions from "../assets/new_images/songUploadOptions.svg"
import songUploadAdd from "../assets/new_images/songUploadAdd.svg"
import heart from "../assets/new_images/orangeHeart.svg"
import {formatTime} from "../utils/functions";
import {useEffect, useState} from "react";

export default function UploadSongListElt({liked, songName, songDuration, index}){

  const [duraation, setDuration] = useState(0);

  useEffect(() => {
    songDuration.then(value => setDuration(value));
  }, [])

  return (
    <div className="listelt">
      {liked ? <img alt={"songlike"} src={heart} className="songliked"/> : <span className="songindex">{index + 1}</span>}
      <div className="songname truncate">{songName}</div>
      <div className="songduration">{formatTime(duraation)}</div>
    </div>
  );
}