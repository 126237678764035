import "../../../styles/ContentTypeSelect.css";
import ColorfolLogoTypo from "../../../assets/new_images/logo_typo.svg";
import CloseIcon from "../../../assets/new_images/close_.svg"
import {Link, useLocation} from "react-router-dom";
import { AlbumSharp, AudioFileSharp } from "@mui/icons-material";
import {useUploadFileType} from "../../../datamanager/contexts/FileTypeUpload";

export default function ContentTypeSelect({}) {

  const { setUploadFileType } = useUploadFileType();

  return <div className="content-type-select">
    <div className="content-type-select-background"></div>
    <div className="content-type-select-background2"></div>
    <div className="content-type-select-background3"></div>
    <div className="content-type-select-top">
      <img alt="logo" src={ColorfolLogoTypo} className="content-type-select-logo"/>
      <Link to="/app/dashboard" className="link"><img alt="close-ico" src={CloseIcon} className="content-type-select-close"/></Link>
    </div>
    <span className="content-type-select-title-text">Choisissez le contenu que vous souhaitez publier</span>
    <div className="content-type-select-select">
      <Link to="/app/publish/audio"
            className="content-type-select-elt"
            onClick={() => setUploadFileType("single")}
      >
        <AlbumSharp className="content-type-select-elt-img" />
        <span className="content-type-select-elt-txt">Single</span>
      </Link>
      <Link to="/app/publish/album" onClick={() => {}} className="content-type-select-elt">
        <AudioFileSharp className="content-type-select-elt-img" />
        <span className="content-type-select-elt-txt">Album</span>
      </Link>
    </div>
  </div>;
}