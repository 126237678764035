
export const truncateDecimal = (number) => {
  // check if number is NaN
  if (Number.isNaN(number)) {
    return 0;
  }
  return (number * 100).toFixed(2);
}

export const formatNumber = (number) => {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + 'M';
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + 'K';
  } else {
    return number;
  }
}