export default function FillFormData(values, metaData, user, selectedAudio) {
  console.log('values', values, 'metaData', metaData);
  let formData = new FormData();
  formData.append("title", values.title);
  formData.append("publication_date", values.publication_date);
  formData.append("price", values.price);
  formData.append("cover", values.cover);
  if ( values.src.length > 0) {
    formData.append("src", values.src[0]);
  }else {
    formData.append("src", selectedAudio[0]);
  }
  formData.append("description", values.description);
  formData.append("is_single", true); // Si 'is_single' est toujours vrai, sinon passez-le en tant que paramètre
  let my_metadata = metaData[0];
  formData.append("metadata", JSON.stringify(my_metadata)); // Utilisez metaData directement
  formData.append("author", user.id);
  formData.append("category", values.category);
  formData.append("artist", user.id);

  return formData;
}