import React, {useCallback} from 'react'
import Dropzone from 'react-dropzone'
import downn from "../assets/new_images/Download.svg";
import {useSelectedImage} from "../datamanager/contexts/locals/publishAudio/selectedImage";

export function DropImageSingle({setFieldValue, text, textSucess}) {

  const { selectedImage, handleSetSelectedImage } = useSelectedImage();

  const onDrop = useCallback((droppedFiles) => {
    const fileToUpload = droppedFiles[0];
    setFieldValue('cover', fileToUpload);
    handleSetSelectedImage(fileToUpload);
  }, [handleSetSelectedImage])

  return (
    <div className="my-dropzone w-full">
      <Dropzone
        accept={
          {

            'image/jpeg': [],
            'image/png': [],
            'image/jpg': [],
            'image/gif': [],
            'image/webp': [],
            'image/bmp': [],
            'image/svg+xml': [],
            'image/tiff': [],
          }
        }
        onDrop={onDrop}
        maxFiles={1}
        multiple={false}
        maxSize={10 * 1024 * 1024}
        noKeyboard
        minSize={0}
        onDropRejected={(event) => {
          console.log("Invalid file types or maximum number of files detected");
        }}
        onDropAccepted={(event) => console.log("Drop accepted", event)}
      >
        {({getRootProps, getInputProps, isDragActive}) => (
          <div {...getRootProps({
            onDrop: (event) => event.preventDefault(),
          })}>
            <input {...getInputProps()} />
            <div className="publish_audio_form_fields_col1__dropzone">
              <div className={selectedImage ? "dropz--success" : "dropz"}>
                {
                  selectedImage && selectedImage ? (
                    <span className="dropztitle-success">{textSucess}</span>
                  ) : (<>
                      <img alt={"drop"} src={downn} className="dropzimg"/>
                      <span
                        className="dropztitle">{text}</span>
                    </>
                  )
                }
              </div>
            </div>
            {isDragActive && <div className="overlay_black" />}
          </div>
        )}
      </Dropzone>
    </div>
  )
}