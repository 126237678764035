import { createContext } from "react";

const SinglesContext = createContext({
  singles: null,
  selectedSingle: null,
  addSingles: (singles) => {},
  addSinglesAtEnd: (singles) => {},
  selectSingle: (id) => {}
})

export default SinglesContext;