import MusicApi from "../../../../api/musics";
import {useEffect, useState} from "react";
import SingleElt from "./SingleElt";
import translate from "../../../../i18n/translate";
import styles from "../styles/album.module.css"
import LoaderSqueleton from "./LoaderSqueleton";

export default function SingleList({ album }) {

  const [albumSingle, setAlbumSingle] = useState([]);
  const access_token = localStorage.getItem("access_token");
  const [loading, setLoading] = useState(true);

  const handleGetAllAlbumSingle = async () => {
    const musicApi = new MusicApi(access_token);

    const response = await musicApi.getAllAlbumsSingle(album.id);

    if (response.data) {
      setAlbumSingle(response.data);
    }
  }

  useEffect(()=>{
    if (albumSingle.length === 0) {
      handleGetAllAlbumSingle().then(
        (result) => {
          console.log(result);
          setLoading(false);
        },
        (error) => {
          console.log(error)
        }
      );
    }
  }, [albumSingle])

  if (loading)
    return <LoaderSqueleton />
  else
    return (<div className={styles.singles_list}>
       {
         albumSingle.map((single, index) => (
        <SingleElt
          key={single.id}
          single={single}
          index={index}
        />
      ))}
    </div>);
}