import translate from "../../../../i18n/translate";
import React, {useContext} from "react";
import CurrentUserContext from "../../../../datamanager/contexts/currentUser";
import Greed from "./Greed";

export default function GreedUser() {

  const { currentUser } = useContext(CurrentUserContext);

  return (
    <Greed
      content1={translate("hello")}
      content2={translate("welcome")}
      content3={currentUser.alias}
    />
  );
}