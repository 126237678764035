
import screen from '../../assets/illustrations/screen.png'
import translate from '../../i18n/translate'
import './services.scss'
const Services = () => {
    return (
        <main className='services w-full flex items-center justify-center'>
            <section className="texts">
                <h2>{translate('hqa')}</h2>
                <p>{translate('subhqa')}</p>
                <div className="sub-texts">
                    <div>
                        <h3>{translate('disc_buy')}</h3>
                        <p>{translate('subdisc_buy')}</p>
                    </div>
                    <div>
                        <h3>{translate('play_off')}</h3>
                        <p>{translate('subplay_off')}</p>
                    </div>
                    <div>
                        <h3>{translate('playlist_fy')}</h3>
                        <p>{translate('subplaylist_fy')}</p>
                    </div>
                    <div>
                        <h3>{translate('pass_event')}</h3>
                        <p>{translate('subpass_event')}</p>
                    </div>
                </div>
            </section>

            <section className="image">
                <img src={screen} className="screen" alt="screen" />
            </section>
        </main>
    )
}

export default Services