import FileDownloadCard from "../FileDownloadCard";
import AlbumLoader from "../../pages/app/publish/components/albumLoader";
import {useSelectedAudio} from "../../datamanager/contexts/locals/publishAudio/selectedAudio";
import {useMetaData} from "../../datamanager/contexts/locals/publishAudio/metaData";
import {formatBytes} from "../../utils/functions";
import {useFinishedUpload} from "../../datamanager/contexts/locals/publishAudio/finishedUpload";
import { useSubmitting } from "../../datamanager/contexts/locals/publishAudio/isSubmitting";
import { useUploadPercentage } from "../../datamanager/contexts/locals/publishAudio/uploadPercent";
import { useBackErr } from "../../datamanager/contexts/locals/publishAudio/backErr";
import {useUploadSpeed} from "../../datamanager/contexts/locals/publishAudio/uploadSpeed";
import {useUploadedFiles} from "../../datamanager/contexts/locals/publishAlbum/uploadedFiles";
import React from "react";

export default function FileDownloadCardList() {
  const { selectedAudio } = useSelectedAudio();
  const { isFinishedUpload } = useFinishedUpload();
  const { uploadPercentage } = useUploadPercentage();
  const { uploadSpeed } = useUploadSpeed();
  const { metaData } = useMetaData();
  const { isSubmitting } = useSubmitting();
  const { backErr } = useBackErr();
  const { uploadedFiles, getLastIndex } = useUploadedFiles();

  const showPercentage = (index) => {
    const lastIndex = getLastIndex();

    if (lastIndex === -1) {
      return 0;
    } else if (index < lastIndex) {
      return 100;
    } else if (index === lastIndex) {
      return uploadPercentage;
    } else {
      return 0;
    }
  }

  const showUploadSpeed = (index) => {
    const lastIndex = getLastIndex();

    if (lastIndex === -1) {
      return 0;
    } else if (index < lastIndex) {
      return 0;
    } else if (index === lastIndex) {
      return uploadSpeed;
    } else {
      return 0;
    }
  }

  const checkEnd = () => {
    const lastIndex = getLastIndex();
    return lastIndex === selectedAudio.length - 1;
  }

  console.log('ffffffffffffff =============================== \n', isFinishedUpload);
  console.log("uploaded files ", uploadedFiles);
  console.log("last index ", getLastIndex());
  return (
    <div className="publish_audio_end_container_col1">
      {isSubmitting && !isFinishedUpload && (
        <span className="flex align-left text-lg text-white align-center">
          <span className="mr-2">
            <AlbumLoader />
          </span>
          <span className="mr-4">Create base informations</span>
        </span>
      )}
      {isFinishedUpload && checkEnd() && uploadPercentage === 100 && (
        <span className="publish_audio_end_container_col1_title">Félicitation, vous avez ajouté votre nouvel Album avec succès</span>
      )}
      {selectedAudio && selectedAudio.map((item, index) => {
        return (
          <FileDownloadCard
            key={index}
            index={index}
            musicTitle={metaData[index].title}
            musicSize={formatBytes(item.size)}
            musicDownloadSpeed={showUploadSpeed(index)}
            musicDownloadPercent={showPercentage(index)}
            error={backErr}
            isFinishedUpload={isFinishedUpload}
          ></FileDownloadCard>
        );
      })}
    </div>
  );
}
