import React, {createContext, useCallback, useContext, useState} from 'react';
import {sleep} from "../../../../utils/functions";
import {useMetaData} from "./metaData";

const SelectedAudioContext = createContext();

export const useSelectedAudio = () => useContext(SelectedAudioContext);

export const SelectedAudioProvider = ({ children }) => {
  const [selectedAudio, setSelectedAudio] = useState(null);

  const handleSetSelectedAudio = useCallback((value) => {
    if (value !== selectedAudio) {
      setSelectedAudio(value);
    }
  }, [selectedAudio]);

  // function to delete audio in the list
  /**
   * paramètre : index
   * */
  const handleDeleteAudio = (index) => {
    const newSelectedAudio = [...selectedAudio];
    newSelectedAudio.splice(index, 1);
    setSelectedAudio(newSelectedAudio);
  };


  const handleAddAudio = (audio) => {
    const newSelectedAudio = [...selectedAudio];
    newSelectedAudio.push(audio);
    setSelectedAudio(newSelectedAudio);
    return newSelectedAudio;
  };

  const handleAddAudios = (setFieldValue, audios) => {
    const newSelectedAudio = [...selectedAudio];
    newSelectedAudio.push(...audios);
    setSelectedAudio(newSelectedAudio);
    setFieldValue('src', newSelectedAudio);
    return newSelectedAudio;
  };

  // function to reorder audio in list
  const handleReorderAudio = (dragIndex, hoverIndex) => {
    const newSelectedAudio = [...selectedAudio];
    const draggedItem = newSelectedAudio[dragIndex];
    newSelectedAudio.splice(dragIndex, 1);
    newSelectedAudio.splice(hoverIndex, 0, draggedItem);
    setSelectedAudio(newSelectedAudio);
  }

  return (
    <SelectedAudioContext.Provider value={{ selectedAudio, handleSetSelectedAudio, setSelectedAudio, handleDeleteAudio, handleAddAudio, handleAddAudios, handleReorderAudio }}>
      {children}
    </SelectedAudioContext.Provider>
  );
};
