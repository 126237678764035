import InfoDefinition from "./InfoDefinition";
import AudioSettings from "./AudioSettings";
import UploadProcessEnd from "./UploadProcessEnd";
import Preview from "./Preview";
import React from "react";
import { useCurrentPage } from "../../../../datamanager/contexts/locals/publishAudio/currentPage";
import translate from "../../../../i18n/translate";
import back from "../../../../assets/icons/ArrowLeft.svg";

export default function FormPages({ handleChange, errors, setFieldValue, values, handleSubmit, handleReset, isValid }) {

  const { currentPage, handleSetCurrentPage } = useCurrentPage();

  return (
    <div className={currentPage === 3 ? "publish_audio_2" : "publish_audio"}>
      <div className={`publish_audio__container ${currentPage === 3 && "album_publish"}`}>
        <div className="publish_audio__container_header">
          <div className="publish_audio__container__header__info">
            <span className="publish_audio__container__header__title">{translate("YourNewAlbum")}</span>
            <span className="publish_audio__container__header__subtitle">{translate("enterInformationsAlbum")}</span>
          </div>
        </div>
        {currentPage === 1 && (
          <InfoDefinition
            errors={errors}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            values={values}
            isValid={isValid}
          />
        )}
        {currentPage === 2 && (
          <AudioSettings
            errors={errors}
            values={values}
            handleSubmit={handleSubmit}
            isValid={isValid}
            setFieldValue={setFieldValue}
          />
        )}
        {currentPage === 3 && (
          <>
            <div className="px-4  mx-auto mt-8" style={{ width: '95%' }}>
              <img src={back} alt="back" className="" onClick={() => handleSetCurrentPage(2)} />
            </div>
            <UploadProcessEnd
              values={values}
              errors={errors}
              handleSubmit={handleSubmit}
              isValid={isValid}
              handleReset={handleReset}
            />
          </>
        )}
      </div>
      {currentPage !== 3 && (
        <Preview
          values={values}
          handleReset={handleReset}
        />
      )}
    </div>
  );
}