import charttt from "../../assets/new_images/chart.svg";
import React from "react";

export default function NotificationElt({ priority, info1, mainInfo, info2, info3 }) {
  return (
    <div className={ priority === 1 ? "greed2elt2" : "greed2elt1"}>
      <img alt="chart" src={charttt} className="greed2img"/>
      <span className="greed2text1">{info1}<span className="greed2text2"></span>{info2}<span className="greed2text2">{mainInfo}</span> {info3}</span>
    </div>
  );
}