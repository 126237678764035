import React, { useContext, useState } from "react";
import "../styles/App.css";
import "./styles/layout.css"
import { Outlet } from "react-router-dom";
import useUser from "../hooks/useUser";
import SideBar from "../components/SideBar";
import CurrentUserContext from "../datamanager/contexts/currentUser";
import useSingles from "../hooks/useSingles";
import useAlbums from "../hooks/useAlbums";
import ModalCore from "./modals/ModalCore";
import ModalContext from "../datamanager/contexts/modalContext";
import useEpisodes from "../hooks/useEpisodes";
import usePodcasts from "../hooks/usePodcasts";
import useArtists from "../hooks/useArtists";
import LeftBar from "./LeftBar";
import useStats_hook from "../hooks/useStats";
import {RealtimeSwitchProvider} from "../datamanager/contexts/locals/realTimeSwitch";
import {MenuSwitchProvider, useMenuSwitchContext} from "../datamanager/contexts/locals/menuSwitch";
import usePosts from "../hooks/usePosts";
import useComments from "../hooks/useComments";

export default function Layout() {
  // UseHooks section
  useUser("home")
  useSingles()
  useAlbums()
  useEpisodes()
  usePodcasts()
  useArtists()
  usePosts()
  useStats_hook()
  useComments()

  // Get data from the global context
  const { currentUser: user } = useContext(CurrentUserContext);
  const { open, closeModal } = useContext(ModalContext);

  return (
    <>
      {
        user && (
          <div className="main-con mainapp">
            <div className="corpus max-h-screen overflow-hidden">
                <SideBar />

                <div className="rsco">
                  <Outlet />
                </div>
                <RealtimeSwitchProvider>
                  <LeftBar />
                </RealtimeSwitchProvider>
            </div>

            <ModalCore open={open} onClose={closeModal} />
          </div>
        )
      }
    </>
  );
}
