import React from "react";
import {useAlbumCategories} from "../../datamanager/contexts/locals/albumCategory";
import {useMetaData} from "../../datamanager/contexts/locals/publishAudio/metaData";

export default function Tags({ values }){

  const { albumCategories } = useAlbumCategories();
  const { metaData } = useMetaData();

  const hasExplicitMetaData = metaData && metaData.some(
    (metaDataItem) => metaDataItem.explicit === true
  );

  return (
    <div className="publish_audio_end_container_col2_container_header__tags">
      <div
        className="publish_audio_end_container_col2_container_header__tag">
        <span className="publish_audio_end_container_col2_container_header__tag_text">{albumCategories && values.category && albumCategories.find((category) => category.id === values.category).name}</span>
      </div>

      { hasExplicitMetaData && <div
        className="publish_audio_end_container_col2_container_header__tag">
        <span className="publish_audio_end_container_col2_container_header__tag_text">Explicit</span>
      </div> }
    </div>
  );
}