import React, { createContext, useCallback, useContext, useState } from 'react';
import { removeAudioFileExtension } from "../../../../utils/functions"

const MetaDataContext = createContext();

export const useMetaData = () => useContext(MetaDataContext);

export const MetaDataProvider = ({ children }) => {

  // declare the metaData state with the length of selectedAudio
  const [metaData, setMetaData] = useState([]);

  const handleSetMetaData = useCallback((value) => {
    console.log(`Set metaData`, value)
    if (value !== metaData) {
      setMetaData(value);
    }
  }, [metaData]);

  //funtion lors de l'ajout des audios
  /**
   * Initialisation des metatas
   */

  const handleInitMetaData = (length, titles, category, year, userID, userAlias, description) => {
    console.log(`set new metadatas ${length}`);

    // initializing meta datas using the Array.from to create an array of element initializing the meta data
    const metaDataArray = Array.from({ length }, (_, index) => ({
      title: removeAudioFileExtension(titles[index]),
      producer: userAlias,
      explicit: false,
      gender: category,
      year:year,
      author: userID,
      artist: userID,
      description: description
    }));

    // Set the metaData state with the newly created array of objects
    setMetaData(metaDataArray);
  };


  //fontion lors de l'ajout d'un audio
  /**
   * L'ajout d'un audio se fait en queue, donc on initialise une metasata en queue
   */
  // function to add a new meta data
  const handleAddMetaData = ({title, category, year, userId, userAlias}) => {
    const newMetaData = {
      title: title,
      producer: userAlias,
      explicit: false,
      gender: category,
      year: year,
      author: userId,
      artist: userId,
      description: ""
    };
    setMetaData([...metaData, newMetaData]);
  }



  //fucntion lors de la suppression d'un audio
  /**
   * params: position//index
   * 
   * On supprime la metadonnée qui se trouve à la mm position sur l'index
   */
  // create function to delete a metaData
  const handleDeleteMetaData = (index) => {
    const newMetaData = [...metaData];
    newMetaData.splice(index, 1);
    setMetaData(newMetaData);
  };


  // function to reorder the metaData
  /**
   * params: fromIndex, toIndex
   *
   * On fait une copie de la liste des metadonnees et on inverse la position de 2 elements
   * */
  const handleReorderMetaData = (fromIndex, toIndex) => {
    // const newMetaData = [...metaData];
    console.log(`OLD METADAT`, metaData)
    const temp = metaData[toIndex];
    console.log(`REMOVED DATA `, temp)
    metaData[toIndex] = metaData[fromIndex];
    metaData[fromIndex] = temp;
    console.log(`New METADAT`, metaData)


  };

  // function to clear metaData
  const handleClearMetaData = () => {
    setMetaData(null);
  }

  return (
    <MetaDataContext.Provider value={{ metaData, handleSetMetaData, setMetaData, handleClearMetaData, handleInitMetaData, handleAddMetaData, handleDeleteMetaData, handleReorderMetaData}}>
      {children}
    </MetaDataContext.Provider>
  );
};