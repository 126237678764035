import SideBarActionButton from "./SideBarActionButton";
import param from "../../assets/new_images/PokerChip.svg";
import translate from "../../i18n/translate";
import hellp from "../../assets/new_images/Headset.svg";
import deco from "../../assets/new_images/UserCircleMinus.svg";
import React, {useContext} from "react";
import ModalContext from "../../datamanager/contexts/modalContext";

export default function SideBarBottomActionsButtonsList() {

  const { openModal } = useContext(ModalContext);

  const handleOpenExitModal = () => {
    openModal("Exit", "Log Out");
  }

  return (
    <ul className="logout lg:absolute lg:bottom-0 lg:w-full">
      <li className="w-full self-center font-bold">V1.1.5</li>
      {/*<SideBarActionButton type={0} icon={param} onClick={() => {}} text={translate("paramters")} />*/}
      {/*<SideBarActionButton type={0} icon={hellp} onClick={() => {}} text={translate("gethelp")} />*/}
      <SideBarActionButton type={1} icon={deco} onClick={() => {
        handleOpenExitModal();
      }} text={translate("disconnect")} />
    </ul>
  );
}