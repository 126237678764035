import { useState } from "react"
import CurrentUserContext from "../contexts/currentUser"

const CurrentUserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)

  // Some handlers
  const handleLogin = user => {
    setCurrentUser(user)
  }

  const handleLogout = () => {
    setCurrentUser(null)
  }

  const contextValue = {
    currentUser,
    login: handleLogin,
    logout: handleLogout
  }

  return (
    <CurrentUserContext.Provider value={contextValue}>
      {children}
    </CurrentUserContext.Provider>
  )
}

export default CurrentUserProvider