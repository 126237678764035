import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { SpinnerCircularFixed } from "spinners-react";
import "../../../styles/App.css";
import { Link } from "react-router-dom";
import uil from "../../../assets/single.png";
import Audiotables from "../../../components/audiotables";
import EpisodesContext from "../../../datamanager/contexts/episodes";
import styles from "./styles/episode.module.css";


function Episodes({ isActive }) {
  // Get data from the global context
  const { episodes } = useContext(EpisodesContext);

  const [loading, setLoading] = useState(true);

  // UseEffect section
  useEffect(() => {
    if (episodes) {
      setLoading(false);
    }
  }, [episodes]);

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center h-screen">
          <SpinnerCircularFixed
            size={50}
            thickness={121}
            speed={150}
            color="rgba(133, 19, 78, 1)"
            secondaryColor="rgba(231, 208, 220, 1)"
          />
        </div>
      ) : episodes.count > 0 ? (
        <>
          <div className="flex flex-col items-start justify-start w-full min-h-screen p-5">
            <div className="flex flex-col items-start justify-start w-full relative" style={{ marginTop: "90px" }}>
              <h1 className="text-5xl font-bold text-slate-500 text-left">
                episodes
              </h1>
              <h4 className="text-xs font-medium text-left text-gray-500 mt-2">
                Trackez les donnees genere par vos episode audio sur Colorfol
                Artist
              </h4>

              <Link to="/app/publishPodcasts/episode">
                <button className={styles.btnAddEpisode}>Ajouter un episode</button>
              </Link>
            </div>
            <div className="my-auto w-full">
              <Audiotables />
            </div>
          </div>
        </>
      ) : (
        <>
          <img src={uil} className="esi" />
          <span className="est">Aucun contenu Episode disponible ici !</span>
          <Link exact to="/app/publishPodcasts/episode" className="link">
            <span className="escta">Publiez votre premier episode</span>
          </Link>
        </>
      )}
    </>
  );
}
export default Episodes;
