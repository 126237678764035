import up from "../../../../assets/new_images/Up.svg";
import down from "../../../../assets/new_images/Down.svg";
import React from "react";
import {formatNumber} from "../../../../utils/FormatValues";
import SingleEltCover from "./SingleEltCover";

export default function SingleElt({ single, evolution, index, type="topfan" }) {
  return (
    <div className="topfan-titles">
      <span className="topfan-r text-xs">
        <span className="text-gray-600 text-xs">{index + 1}</span>
        <SingleEltCover single={single}  />
        <span className="topfan-r1 text-xs">
          <span className="text-xs">{type === "topfan" ? single.title : single.album_title}</span>
        </span>
      </span>
      <span className="text-xs text-gray-600 font-bold">{formatNumber(single.nb_of_listening)}</span>
      <span className="text-xs text-gray-600 font-bold">+10%</span>
      <span className="topfan-r !text-xs">{index}<img alt={"evo"} className="topsingvalimg text-xs" src={evolution === "up" ? up : down}/></span>
    </div>
  );
}