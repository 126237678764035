import { Box, Modal, Slide } from "@mui/material"
import { CloseSharp } from "@mui/icons-material";
import styles from './styles/modal.module.css'
import { useContext } from "react"
import ModalContext from '../../datamanager/contexts/modalContext'
import ProfileContentModal from './components/profileContentModal'
import UploadPhotoContentModal from "./components/uploadPhotoContentModal"
import ExitModal from "./components/exitModal";
import UploadSingleModal from "./components/uploadSingleModal";
import UploadAlbumModal from "./components/UploadAlbumModal";
import RespondCommentModal from "./components/RespondCommentModal";
import SignUpPopUp from "./components/SignUpPopUp";
import UpdateAlbumCover from "./components/UpdateAlbumCover";
import UpdateAlbumInfo from "./components/UpdateAlbumInfo";

const ModalCore = ({ open, onClose }) => {
  // Get data from global context
  const { modalData: { modalTitle, modalType, data } } = useContext(ModalContext)

  // Some handlers
  const handleGenerateModalContent = () => {
    switch (modalType.toLowerCase()) {
      case "profile": {
        return <ProfileContentModal />
      }

      case "upload": {
        return <UploadPhotoContentModal />
      }

      case "exit": {
        return <ExitModal />
      }

      case "uploadsingle": {
        return <UploadSingleModal handleUpload={data.handleUpload} />
      }

      case "uploadalbum" : {
        return <UploadAlbumModal handleUpload={data.handleUpload} />
      }

      case "respondtocomment" : {
        return <RespondCommentModal user={data} />
      }

      case "singnuppopup" : {
        return <SignUpPopUp/>
      }

      case "updatealbumcover" : {
        return <UpdateAlbumCover albumID={data.albumID} albumCover={data.albumCover} albumTitle={data.albumTitle} albumDescription={data.albumDescription} />
      }

      case "updatealbuminfo" : {
        return <UpdateAlbumInfo albumID={data.albumID} albumTitle={data.albumTitle} albumDescription={data.albumDescription} albumCategory={data.albumCategory} albumLabel={data.albumLabel} albumMetadata={data.albumMetadata} />
      }

      default: return null
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Slide direction="down" in={open} mountOnEnter unmountOnExit>
        <Box
          sx={{
            backgroundColor: "#1F1F1F",
            color: "white",
          }}
          className={styles.modalContainer}
        >
          <header className={styles.modalHeader}>
            <h1 className={styles.modalHeaderTitle}>{modalTitle}</h1>
            <CloseSharp alt={"close icon"} onClick={onClose} className={styles.formBtn}/>
          </header>

          <main className={styles.modalMain}>
            {handleGenerateModalContent()}
          </main>

          <footer className={styles.modalFooter}>
          </footer>
        </Box>
      </Slide>
    </Modal>
  )
}

export default ModalCore