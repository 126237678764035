import warmingIcon from "../../assets/new_images/Headset.svg";
import React from "react";
import translate from "../../i18n/translate";

export default function FileUploadDirectivesAudios({}){
  return (
    <div className="publish_audio_form_fields_col1__upload_instructions">
      <div className="publish_audio_form_fields_col1__upload_instructions__warming">
        <img alt={"warmingIcon"}
             className="publish_audio_form_fields_col1__upload_instructions__warming__icon"
             src={warmingIcon}/>
        <span
          className="publish_audio_form_fields_col1__upload_instructions__warming__text">{translate("singleDirectives")}</span>
      </div>
      <span
        className="publish_audio_form_fields_col1__upload_instructions__instruction">{translate("dragAndDropFileHereOrChooseOne")}</span>
    </div>
  );
}