import PreviewTop from "./previewTop";
import UploadSongList2 from "./UploadSongList2";
import React from "react";
import translate from "../../i18n/translate";
import FileDownloadCardListSingle from "./FileDownloadCardListSingle";

export default function PreviewRightSideSingle({
  values, isFinishedUpload, uploadPercentage, uploadSpeed
  , handleSetUploadPercentage, handleSetUploadSpeed
  , handleSetSelectedAudio, handleSetSelectedImage
  , handleSetMetaData, handleSetBackErr, handleSetMsg, backErr, resetForm, isSubmitting
}){

  return (
    <div className="publish_audio_end_container">
      <FileDownloadCardListSingle
        isFinishedUpload={isFinishedUpload}
        uploadPercentage = {uploadPercentage}
        uploadSpeed = {uploadSpeed}
        handleSetUploadPercentage = {handleSetUploadPercentage}
        handleSetUploadSpeed = {handleSetUploadSpeed}
        handleSetSelectedAudio = {handleSetSelectedAudio}
        handleSetSelectedImage = {handleSetSelectedImage}
        handleSetMetaData = {handleSetMetaData}
        handleSetBackErr = {handleSetBackErr}
        handleSetMsg = {handleSetMsg}
        resetForm={resetForm}
        isSubmitting={isSubmitting}
      />
      <div className="publish_audio_end_container_col2">
        <div className="publish_audio_end_container_col2_fond2"></div>
        <div className="publish_audio_end_container_col2_container">
          <span className="publish_audio_end_container_col2_container_title">{translate("singlePreveiew")}</span>
          <PreviewTop values={values}/>
          <hr className="publish_audio_end_container_col2_container_hr"/>
          <span className="publish_audio_end_container_col2_container_title1">{translate("Title")}</span>
          <UploadSongList2/>
        </div>
      </div>
    </div>
  );
}