import { useState } from "react";
import SinglesContext from "../contexts/singles";

const SinglesProvider = ({ children }) => {
  const [singles, setSingles] = useState(null);
  const [selectedSingle, setSelectedSingle] = useState(null);

  // Some handlers
  const addSingles = (singles) => {
    setSingles(singles);
  }

  const addSinglesAtEnd = (singles_) => {
    // Ensure singles state is not null before updating
    if (!singles_) return;
  
    // Update state using callback to ensure latest state is used
    setSingles(prevSingles => {
      // Create a copy of the previous singles object to avoid mutation
      const updatedSingles = { ...prevSingles };
  
      // Update count by adding the count of newSingles to the count of originalSingles
      updatedSingles.count += singles_.count;
    
      // Update next URL with the next URL from the newSingles
      updatedSingles.next = singles_.next;
    
      // Merge the results from both previous singles and newSingles
      updatedSingles.results = [ ...prevSingles.results, ...singles_.results ];
    
      // Return the updated singles object
      return updatedSingles;
    });
  }
  

  const selectSingle = (id) => {
    const selectedSingle = singles.results.find(single => +single.id === +id);

    setSelectedSingle(selectedSingle);
  }

  const contextValue = {
    singles,
    selectedSingle,
    addSingles,
    addSinglesAtEnd,
    selectSingle
  }

  return (
    <SinglesContext.Provider value={contextValue}>
      { children }
    </SinglesContext.Provider>
  )
}

export default SinglesProvider