import React from 'react'
import bars from '../../../assets/icons/bars.svg'
import chart from '../../../assets/icons/chart.svg'
import cards from '../../../assets/icons/cards.svg'
import Button from '../../Button/Button'

import './left_bar_data_5.scss'
const LeftBarDatas5 = () => {
  return (
    <aside className='left-bar-data-5'>
      <div className='intro'>
        <img src={bars} alt="bars" className="intro-img" />
        <p>Vous avez dépensé 6790<span>FCFA</span> de frais de publicité au cour des 60 dernier jours.</p>
      </div>
      <Button text={'Promouvoir'} />
      <h3>Actions rapides</h3>
      <div className='messages'>
        <aside>
          <img src={chart} alt="chart" className="intro-img" />
          <div>
            <p>Creer une nouvelle publicité</p>
            <p>Generez du trafic est des écoutes en mettant en avant vos contenus</p>
          </div>
        </aside>
        <aside>
          <img src={cards} alt="cards" className="intro-img" />
          <div>
            <p>Paramètres de paiement</p>
            <p>Gérerz vos paramètres de paiement et gérer vos factures</p>
          </div>
        </aside>
      </div>
    </aside>
  )
}

export default LeftBarDatas5