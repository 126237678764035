import Stepper from "../../../../components/Stepper";
import React from "react";
import { uploadSteps } from "../../../../utils/Constants";
import StepperActions from "./StepperActions";
import FieldSection from "../../../../components/PublishAudio/FieldSection";
import DropFilesZones from "../../../../components/PublishAudio/DropFilesZones";
import translate from "../../../../i18n/translate";

export default function InfoDefinition({errors, setFieldValue, values, handleChange, handleSubmit, isValid}) {

  return (
    <div className="publish_audio_form">
      <div className="publish_audio_form_fields">
        <DropFilesZones errors={errors}
                        setFieldValue={setFieldValue}
                        isValid={isValid}
                        text1={ translate("defineUlustrationsAlbum")}
                        text2={ translate("chooseFiles") }
                        values={values}
        />
        <div className="publish_audio_form_fields_col2">
          <span
            className="publish_audio_form_fields_col1__title">{ translate("singleDetailsAlbum") }</span>
          <span className="publish_audio_form_fields_col2__subtitle">{ translate("singleInformationsAlbum") }</span>
          <FieldSection errors={errors} values={values} handleChange={handleChange} setFieldValue={setFieldValue} isValid={isValid} />
        </div>
      </div>
      <div className="publish_audio_form_stepper">
        <Stepper steps={uploadSteps} />
        <StepperActions values={values} errors={errors} handleSubmit={handleSubmit} isValid = {isValid} />
      </div>
    </div>
  );
}