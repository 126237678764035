import "./styles/FileDownloadCard.css"
import musicIco from "../assets/new_images/music_ico.svg"
import dot from "../assets/new_images/dot.svg"
import closeIco from "../assets/new_images/close.svg"
import {convertTime, formatDownloadSpeed} from "../utils/functions";
import React, {useContext} from "react";
import CurrentUserContext from "../datamanager/contexts/currentUser";
import translate from "../i18n/translate";
import {useSelectedAudio} from "../datamanager/contexts/locals/publishAudio/selectedAudio";
import {Link} from "react-router-dom";
import {useSelectedImage} from "../datamanager/contexts/locals/publishAudio/selectedImage";
import {useUploadSpeed} from "../datamanager/contexts/locals/publishAudio/uploadSpeed";
import {useUploadPercentage} from "../datamanager/contexts/locals/publishAudio/uploadPercent";
import {useMetaData} from "../datamanager/contexts/locals/publishAudio/metaData";
import {useBackErr} from "../datamanager/contexts/locals/publishAudio/backErr";
import {useMsg} from "../datamanager/contexts/locals/publishAudio/msg";
import {useCurrentPage} from "../datamanager/contexts/locals/publishAudio/currentPage";
import {useMenuSwitchContext} from "../datamanager/contexts/locals/menuSwitch";
export default function FileDownloadCard({ musicTitle, index, musicSize, musicDownloadSpeed, musicDownloadPercent,error,isFinishedUpload}) {

  const { currentUser} = useContext(CurrentUserContext);
  const { selectedAudio, handleSetSelectedAudio} = useSelectedAudio();
  const { handleSetSelectedImage } = useSelectedImage();
  const { handleSetUploadSpeed } = useUploadSpeed();
  const { handleSetUploadPercentage } = useUploadPercentage();
  const { handleClearMetaData } = useMetaData();
  const { handleSetBackErr } = useBackErr();
  const { handleSetMsg } = useMsg();
  const { handleSetCurrentPage } = useCurrentPage();
  const { handleSwitchMenu } = useMenuSwitchContext();

  console.log('uuuuuu+++++++++++++++++++++++', error);
  console.log("index +++++++++++++++ : ", index)
  if (musicDownloadPercent === 100 && index === selectedAudio.length - 1){
    return (
      <>
        <Link onClick={() => {
          handleSetUploadPercentage(0);
          handleSetUploadSpeed(0);
          handleSetSelectedAudio(null);
          handleSetSelectedImage(null);
          handleClearMetaData();
          handleSetBackErr(false);
          handleSetMsg(null);
          handleSetCurrentPage(1);
          handleSwitchMenu(1);
        }}
              className="publish_audio_end_container_col1_button"
              to={"/app/audio/"}
              state={{from: "publish"}}
              replace={true}
        >
          {translate("backtoAudio")}
        </Link>
      </>
    );
  }
  return (
    <div className="file-download-card">
      <div className="file-download-card-info">
        <div className="file-download-card-icoo">
          <img alt={"music"} src={musicIco} className="file-download-card-ico"/>
          <div className="file-download-card-text">
            <div className="file-download-card-titles">
              <div className="file-download-card-title truncate">{currentUser.alias}</div>
              <hr className="file-download-card-hr"/>
              <div className="file-download-card-titlee truncate">{musicTitle}</div>
            </div>
            <div className="file-download-card-titles-2">
              <div className="file-download-card-titlees-2">
                <div className="file-download-card-title-2">{musicSize}</div>
              </div>
              <img alt={"dot"} src={dot} className="file-download-card-dot"/>
              <span className="file-download-card-title-3">{formatDownloadSpeed(musicDownloadSpeed)}</span>
            </div>
          </div>
        </div>
        <div className="file-download-card-closee">
          <img alt={"file-download-card-close"} src={closeIco} className="file-download-card-close"/>
          <div className="file-download-card-title-2">{musicDownloadPercent}%</div>
        </div>
      </div>
      <div className="file-download-card-bar-1 mb-2">
        {musicDownloadPercent !== 100 && (<div className="file-download-card-bar-2" style={{width: `${musicDownloadPercent}%`}}></div>)}
        {isFinishedUpload && musicDownloadPercent === 100 &&(<div className="file-download-card-bar-3 text-center text-white" style={{width: `${musicDownloadPercent}%`}}>succès</div>)}
        {error && !isFinishedUpload && musicDownloadPercent !== 100 && (<div className="file-download-card-bar-4 text-center text-white" style={{width: `${musicDownloadPercent}%`}}>Erreur</div>)}
      </div>
    </div>
  );
}
