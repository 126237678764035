import React, {useContext} from "react";
import { Navigate } from "react-router-dom";
import "../../../styles/App.css";
import "../../../styles/publish.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Formik} from "formik";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import en from "date-fns/locale/en-US";
import moment from "moment";
import "moment/locale/fr";
import CurrentUserContext from "../../../datamanager/contexts/currentUser";
import BackErr from "./components/BackErr";
import Submitting from "./components/Submitting";
import Uploaded from "./components/Uploaded";
import FormPages from "./components/FormPages";
import {SignUpSchema} from "./components/SignUpSchema";
import {InitialValues} from "./components/InitialValues";
import useFetchAlbumCategories from "../../../hooks/useFetchAlbumCategories";
import {useBackErr} from "../../../datamanager/contexts/locals/publishAudio/backErr";
import {useSubmitting} from "../../../datamanager/contexts/locals/publishAudio/isSubmitting";
import {useConnected} from "../../../datamanager/contexts/locals/publishAudio/connected";
import {useUploadPercentage} from "../../../datamanager/contexts/locals/publishAudio/uploadPercent";
import {BaseFormAPICall} from "./components/BaseFormAPICall";
import {useUploadSpeed} from "../../../datamanager/contexts/locals/publishAudio/uploadSpeed";
import SubmitAlbum from "./components/SubmitAlbum";
import { useMetaData } from "../../../datamanager/contexts/locals/publishAudio/metaData";
import { useMsg } from "../../../datamanager/contexts/locals/publishAudio/msg";
import { useFinishedUpload } from "../../../datamanager/contexts/locals/publishAudio/finishedUpload";
import { useSelectedAudio } from "../../../datamanager/contexts/locals/publishAudio/selectedAudio";
import {useUploadedFiles} from "../../../datamanager/contexts/locals/publishAlbum/uploadedFiles";
import ModalCore from "../../../components/modals/ModalCore";
import ModalContext from "../../../datamanager/contexts/modalContext";

moment.locale("fr");
registerLocale("en", en);

export default function PublishAlbum() {

  useFetchAlbumCategories();

  // Get data from the global state
  const { currentUser: user } = useContext(CurrentUserContext);
  const { connected, handleSetConnected } = useConnected();
  const { isFinishedUpload, handleSetIsFinishedUpload } = useFinishedUpload();
  const { handleSetSubmitting,isSubmitting } = useSubmitting();
  const { handleSetUploadPercentage } = useUploadPercentage();
  const { open, closeModal } = useContext(ModalContext);
  const { handleSetUploadSpeed } = useUploadSpeed();
  const { musicApi, AxiosConfig } = BaseFormAPICall(
    handleSetUploadPercentage,
    handleSetSubmitting,
    handleSetUploadSpeed,
    handleSetConnected,
    handleSetIsFinishedUpload
  );
  const { backErr, handleSetBackErr } = useBackErr();
  const { handleSetMsg } = useMsg();
  const { metaData } = useMetaData();
  const { selectedAudio } = useSelectedAudio();
  const { handleAddUploadedFiles } = useUploadedFiles();

  const handleSubmit = (values) => {
    console.log('INPUT VALUES: ',values);

    SubmitAlbum(
      values,
      handleSetSubmitting,
      handleSetUploadPercentage,
      handleSetUploadSpeed,
      musicApi,
      AxiosConfig,
      metaData,
      handleSetBackErr, 
      handleSetMsg,
      handleSetConnected,
      isFinishedUpload,
      handleSetIsFinishedUpload,
      selectedAudio,
      handleAddUploadedFiles,
      user.id
    ).then(
      (result) => {
        console.log('RESULT: ',result);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  return (
    <div>
      {
        user ? (
          <Formik
            initialValues={InitialValues}
            validationSchema={SignUpSchema}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({
                errors,
                values,
                setFieldValue,
                handleSubmit,
                handleChange,
                resetForm,
                isValid
              }) => (
              <form>
                <FormPages
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    handleReset = {resetForm}
                    isValid = {isValid}
                />
              </form>
            )}
          </Formik>
        ) : <Navigate to="/app/dashboard"/>
      }
      <ModalCore open={open} onClose={closeModal} />
    </div>
  );
}