import React, {createContext, useCallback, useContext, useState} from 'react';

const LoadingContext = createContext();

export const useLoadingContext = () => useContext(LoadingContext);

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  // varaible for single fetching request loading state
  const [ isSingleFetching, setSingleFetching ] = useState(true);

  // varaible for albums fetching request loading state
  const [ isAlbumsFetching, setAlbumsFetching ] = useState(true);

  // variable for Post fetching request loading state
  const [ isPostFetching, setPostFetching ] = useState(true);

  // variable for Comments fetching request loading state
  const [ isCommentsFetching, setCommentsFetching ] = useState(null);

  const handleSetLoading = useCallback((value) => {
    if (value !== loading) {
      setLoading(value);
    }
  }, [loading]);

  return (
    <LoadingContext.Provider value={{ loading, handleSetLoading, isSingleFetching, setSingleFetching, isAlbumsFetching, setAlbumsFetching, isPostFetching, setPostFetching, isCommentsFetching, setCommentsFetching }}>
      {children}
    </LoadingContext.Provider>
  );
};
