import {createContext, useCallback, useContext, useEffect, useState} from 'react';
import MusicApi from "../../api/musics";

const PostContext = createContext();

export const usePost = () => useContext(PostContext);

export const PostProvider = ({ children }) => {

  // declare the metaData state with the length of selectedAudio
  const [ posts, setPosts ] = useState([]);

  const [ postAlbumDetails, setPostAlbumDetails ] = useState([])

  const handleSetPosts = useCallback((value) => {
    if (value !== posts) {
      setPosts(value);
    }
  }, [posts]);

  const access_token = localStorage.getItem("access_token");

  // function to add elementin postAlbumDetails
  const addPostAlbum = (data, index) => {
    if (data) {
      setPostAlbumDetails([...postAlbumDetails, {count: data.count, singles: data.data, postIndex:index}]);
    }
  }

  // get albums details of posts
  const handleGetAllAlbumSingle = async (albumID, index) => {
    const musicApi = new MusicApi(access_token);

    const response = await musicApi.getAllAlbumsSingle(albumID);

    if (response.data) {
      addPostAlbum(response, index);
    }
  }

  useEffect(() => {
    if (posts && posts.results) {
      posts.results.map((post, index) => {
        if (post.type === "ALBUM") {
          handleGetAllAlbumSingle(post.album.id, index).then(
            () => console.log("post added")
          )
        }
      })
    }
  }, [posts]);



  return (
    <PostContext.Provider value={{ posts, handleSetPosts, postAlbumDetails }}>
      {children}
    </PostContext.Provider>
  );
};