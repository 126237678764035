import Stepper from "../../../../components/Stepper";
import React from "react";
import { uploadSteps } from "../../../../utils/Constants";
import translate from "../../../../i18n/translate";
import UploadSongListSingle from "../../../../components/PublishAudio/UploadSongListSingle";
import StepperActionsSingle from "./StepperActionsSingle";

export default function AudioSettingsSingle({ errors, values, handleSubmit, isValid, setFieldValue, handleChange }) {

  return (
    <div className="publish_audio_form">
      <div className="publish_audio_form_medias_container">
        <div className="publish_audio_form_medias">
          <span className="publish_audio_form_medias__title">{translate("trackListOrder")}</span>
        </div>
        <span className="publish_audio_form_medias__subtitle">{translate("downloadedFiles")}</span>


        <UploadSongListSingle values={values} setFieldValue={setFieldValue} handleChange={handleChange} />

      </div>
      <div className="publish_audio_form_stepper">
        <Stepper steps={uploadSteps}/>
        <StepperActionsSingle errors={errors} values={values} handleSubmit={handleSubmit} isValid={isValid}/>
      </div>
    </div>
  );
}
