import { useContext, useEffect } from "react"
import CurrentUserContext from "../datamanager/contexts/currentUser"
import PostAPI from "../api/posts";
import { useLoadingContext } from "../datamanager/contexts/locals/Dashboard/loading"
import {usePost} from "../datamanager/contexts/posts";

export default function usePosts() {
  // Get data from the global context
  const { currentUser } = useContext(CurrentUserContext)
  const { setPostFetching } = useLoadingContext();
  const { handleSetPosts } = usePost();

  // UseEffect section
  useEffect(() => {
    if (currentUser) {
      setPostFetching(true)
      handleFetchPosts().then(
        (result) => {
          console.log(result);
          setPostFetching(false);
        },
        (error) => {
          console.log(error);
        }
      )
    }
  }, [currentUser])

  const handleFetchPosts = async () => {
    const token = localStorage.getItem("access_token")

    const postAPI = new PostAPI(token)

    const response = await postAPI.getUserPosts(currentUser.id)

    if (response.data) {
      handleSetPosts(response.data)
    }
  }
}