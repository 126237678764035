import {handleErrors, handleErrors2, sleep} from "../../../../utils/functions";
import axios from "axios";
import {TestEnvAPI} from "../../../../envs";


export default async (response, handleSetBackErr, handleSetMsg, handleSetSubmitting) => {

  const err = response.error;
  console.log('err', err, "response", response);
  const errors = Object.values(response.error.response.data.errors);

  handleErrors2(errors);

  // Access Token was expired
  if (
    err ===
    "Failed to load resource: the server responded with a status of 401 (Unauthorized)"
  ) {
    const storedToken = JSON.parse(
      localStorage.getItem("auth").data
    );

    try {
      const rs = await axios.post(
        `${TestEnvAPI}/user-api/auth/token/refresh/`,
        {
          refresh: storedToken.refresh_token,
        }
      );

      const { access } = rs.data;

      localStorage.setItem("access_token", access);

      return;
    } catch (_error) {
      return Promise.reject(_error);
    }
  }
  if (err === "Error: Request failed with status code 400") {
    handleSetMsg(
      "Oupss...les informations que vous avez fournis sont incorrectes !"
    );
  } else {
    handleSetMsg(
      "Oupss...une erreur est survenue ! Verifier votre connection internet."
    );
  }
  await sleep(800);
  handleSetSubmitting(false);
  handleSetBackErr(true);
}