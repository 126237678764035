import React from 'react';

function resizeImage(url, size) {
    // Récupérer l'extension de l'image
    if(url){
        const extension = url.split('.').pop();
        let suffix = '';
        if (size === 'sml') {
            suffix = '_size_sml';
        } else if (size === 'normal') {
            suffix = '_size_normal';
        } else if (size === 'lg') {
            suffix = '_size_lg';
        }
        return url.replace(`.${extension}`, `${suffix}.jpg`);
    }
    return url;
}

const ImageComponent = ({ imageUrl, size }) => {
    const resizedUrl = resizeImage(imageUrl, size);
    if(!resizedUrl){
        return <img src={imageUrl+ '?t=' + new Date().getTime()} className="cover-image" alt="Cover"/>;
    }
    return (
      <img src={resizedUrl} className="cover-image" alt="Cover"/>
    );
};

export default ImageComponent;
