import {useContext} from "react";
import CurrentUserContext from "../../../datamanager/contexts/currentUser";
import style from "../styles/editModal.module.css"
import ModalContext from "../../../datamanager/contexts/modalContext";
import UserApi from "../../../api/users";

export default function ExitModal(){

  const { currentUser } = useContext(CurrentUserContext);
  const { closeModal } = useContext(ModalContext);

  const handleLogout = async () => {
    const token = localStorage.getItem("access_token");

    const userApi = new UserApi(token)

    const response = await userApi.logout()

    if (response.data) {
      localStorage.removeItem("access_token");

      window.location.href = "/app/access"
    }
  }


  return (
    <div className={style.exitmodal}>
      <p className={style.exitmodal__text}> {currentUser.alias}, Souhaitez-vous quitter votre dashboard ? </p>
      <div className={style.exitmodal__actions}>
        <div className={style.exitmodal__actions__logout} onClick={() => handleLogout()}>Log Out</div>
      </div>
    </div>
  );
}