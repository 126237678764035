import style from "../styles/components/Comments.module.css"
import Fond1 from "../../../../assets/new_images/back1.png";
import FavoriteNotif from "./FavoriteNotif";
import CommentNotif from "./CommentNotif";
import ModalCore from "../../../../components/modals/ModalCore";
import React, {useContext} from "react";
import ModalContext from "../../../../datamanager/contexts/modalContext";
import {useComments} from "../../../../datamanager/contexts/comments";
import {useLoadingContext} from "../../../../datamanager/contexts/locals/Dashboard/loading";
import {SpinnerDiamond} from "spinners-react";
import {transformUrl} from "../../../../utils/functions";

export default function Comments({}){
  const { open, closeModal } = useContext(ModalContext);

  const { isCommentsFetching } = useLoadingContext();

  const { comments, handleOrderComments } = useComments();

  console.log(comments)

  const generatedComment = `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
    Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
  `;
  const generatedCommentPostTime = new Date('2024-05-15T12:00:00'); // Example post time
  const generatedCommentPostTime2 = new Date('2024-05-12T12:00:00'); // Example post time
  const generatedCommentPostTime3 = new Date('2024-02-15T12:00:00'); // Example post time

  return (
    <div className={style.commentsContainner}>
      <span className={style.commentsTitle}>Notifications</span>

      {/* from comments lists generate comment notif */}
      {isCommentsFetching ?
        <SpinnerDiamond
          size={30}
          thickness={100}
          speed={60}
          color="rgba(1, 0.337, 0.125, 1)"
          secondaryColor="rgba(255, 86, 32, 1)"
          className="justify-center"
        /> :
        comments && comments.length > 0 && comments.slice().reverse().map((comment, index) => {
        return <CommentNotif
          key={index}
          commentID={comment.id}
          userID={comment.user.id}
          userName={comment.user.alias}
          userComment={comment.content}
          userPostTime={comment.date}
          userProfile={transformUrl(comment.user.photo, "sml")}
        />
      })}

      {/*{Array.from({ length: 2 }, (_, index) => (*/}
      {/*  <FavoriteNotif key={index} userName={"Juns"} userProfile={Fond1} userPostTime={generatedCommentPostTime} />*/}
      {/*))}*/}
      {/*{Array.from({ length: 3 }, (_, index) => (*/}
      {/*  <CommentNotif key={index} commentID={"1"} userID={"1"} userName={"Sashu"}  userComment={generatedComment}  userPostTime={generatedCommentPostTime2} userProfile={Fond1} />*/}
      {/*))}*/}
      {/*{Array.from({ length: 2 }, (_, index) => (*/}
      {/*  <FavoriteNotif key={index} userProfile={Fond1} userPostTime={generatedCommentPostTime3} userName={"Juns"} />*/}
      {/*))}*/}
      {/*{Array.from({ length: 3 }, (_, index) => (*/}
      {/*  <CommentNotif key={index} commentID={"1"} userID={"1"}  userName={"Sashu"} userComment={generatedComment}  userPostTime={generatedCommentPostTime3} userProfile={Fond1} />*/}
      {/*))}*/}

      <ModalCore open={open} onClose={closeModal} />
    </div>
  );
}