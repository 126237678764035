import { useState } from "react";
import VideosContext from "../contexts/video";

const VideosProvider = ({ children }) => {
  const [videos, setVideos] = useState(null);

  // Some handlers
  const addVideos = (videos) => {
    setVideos(videos);
  }

  const contextValue = {
    videos,
    addVideos
  }

  return (
    <VideosContext.Provider value={contextValue}>
      { children }
    </VideosContext.Provider>
  )
}

export default VideosProvider