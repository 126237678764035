import axios from "axios";
import uploadRefresh from "./uploadRefresh";
import FillFormData from "./fillFormData";
import uploadSuccessSingle from "./UploadSuccessSingle";
const submitAudio = async (
  values,
  handleSetSubmitting, // Ces fonctions de rappel seront passées en tant que paramètres
  handleSetUploadPercentage,
  handleSetUploadSpeed,
  handleSetBackErr,
  handleSetMsg,
  handleSetConnected,
  metaData,
  musicApi,
  AxiosConfig,
  user,
  selectedAudio,
  controller,
  source,
  handleSetIsFinishedUpload,
  isFinishedUpload
) => {
  try {
    const response = await musicApi.publishAudio(
      FillFormData(values, metaData, user, selectedAudio),
      AxiosConfig,
      controller,
      source
    );

    if (response.data) {
      uploadSuccessSingle(handleSetIsFinishedUpload, handleSetSubmitting, isFinishedUpload);
    }else{
      uploadRefresh(response, handleSetBackErr, handleSetMsg, handleSetSubmitting);
    }
  } catch (error) {
    if (!axios.isCancel(error)) {
      // Gérez l'erreur ici
    }
    uploadRefresh(error, handleSetBackErr, handleSetMsg, handleSetSubmitting);
  }
};
export default submitAudio;