import axios from "axios";
import uploadSuccess from "./uploadSuccess";
import uploadRefresh from "./uploadRefresh";
import FillFormDataAlbum from "./FillFormDataAlbum";
import FillFormDataAlbumSingle from "./FillFormDataAlbumSingle";
import uploadRefreshAlbum from "./UploadRefreshAlbum";

const SubmitAlbum = async (
  values, handleSetSubmitting, handleSetUploadPercentage, handleSetUploadSpeed,
  musicApi, AxiosConfig, metaData, handleSetConnected, handleSetBackErr, handleSetMsg,isFinishedUpload,
  handleSetIsFinishedUpload,selectedAudio, handleAddUploadedFiles, userID
) => {
  try {
    const response = await musicApi.publishAlbum(FillFormDataAlbum(values, metaData, userID), AxiosConfig);
    if (response?.data) {
      // uploadSuccess(handleSetConnected, handleSetIsFinishedUpload, handleSetBackErr);
      handleSetIsFinishedUpload(true);
      handleSetBackErr(false);
      const albumID = response?.data?.id;
      if (selectedAudio?.length !== 0) {
        for (const single of selectedAudio) {
          const i = selectedAudio.indexOf(single);
          handleAddUploadedFiles(i);
          const res = await musicApi.publishAlbumWithSingle(
            FillFormDataAlbumSingle(values, metaData, i, albumID),
            albumID,
            AxiosConfig
          );
          if (res.data && i === selectedAudio.length - 1) {
            uploadSuccess(handleSetConnected, handleSetIsFinishedUpload, handleSetBackErr);
            handleSetIsFinishedUpload(true);
            handleSetBackErr(false);
          } else if (!res.data) {
            uploadRefreshAlbum(response, handleSetBackErr, handleSetMsg, handleSetSubmitting);
          }
        }
      }
    } else {
      if (!isFinishedUpload) {
        uploadRefresh(response, handleSetBackErr, handleSetMsg, handleSetSubmitting);
      }
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log(error);
    } else {
      console.log(error);

    }
  } finally {
    handleSetUploadPercentage(0);
    handleSetUploadSpeed(0);
    handleSetSubmitting(false);
    handleSetBackErr(false);
    handleSetIsFinishedUpload(false)
  }
};
export default SubmitAlbum;