// Importer Moment.js
import moment from 'moment';

export default function FillFormDataAlbum(values, metaData, userID) {

  let formData = new FormData();

  // definition du titre dans le formData
  formData.append("title", values.title);

  // Utiliser Moment.js pour analyser la date
  const dateStr = values.date;
  const dateObj = moment(dateStr, 'DD MMM. YYYY');

  // Formater la date dans un format spécifique
  const formattedDate = dateObj.format('YYYY-MM-DDTHH:mm');

  // definition du prix du single
  formData.append("price", values.price);

  // definition de la cover
  formData.append("cover", values.cover);

  // definition de la description
  formData.append("description", values.description);

  // definition de l'auteur
  formData.append("author", userID);

  // definition de la cathegorie
  formData.append("category", values?.category);

  formData.append("metadata", JSON.stringify(metaData));

  formData.append(
    "date",
    formattedDate
  );

  const hasExplicitMetaData = metaData.some(
    (metaDataItem) => metaDataItem.explicit === true
  );

  formData.append(
    "explicit", hasExplicitMetaData
  );

  formData.append(
    "artist",
    userID
  );

  return formData;
}
