import React, { useState } from "react";
import "./styles/InputField.css";
import "./styles/TextAreaInput.css";
import {useMetaData} from "../datamanager/contexts/locals/publishAudio/metaData";

export default function TextAreaInputCustom({ placeHolder="", value, index, inputName }) {

  const [characterCount, setCharacterCount] = useState(placeHolder.length);
  const { metaData, handleSetMetaData } = useMetaData();


  const handleChange = (event) => {
    setCharacterCount(event.target.value.length);

    // remove the "meta" prefix on the inputName
    const inputNameWithoutPrefix = inputName.replace("meta", "");

    // update the value of the inputName in the storedData.metaData[index].inputName
    const updatedMetaData = [...metaData];
    updatedMetaData[index][inputNameWithoutPrefix] = event.target.value;
    handleSetMetaData(updatedMetaData);
  };

  return (
    <div className="input-container">
      <textarea
        name={inputName}
        placeholder={placeHolder}
        className="input-field"
        onChange={handleChange}
        value={value}
      />
      <span className="character-count">{characterCount}</span>
    </div>
  );
}