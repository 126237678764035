import { BaseApi } from "..";

export default class PodcastApi extends BaseApi {
    constructor(token = null) {
        super();

        this.token = token;
    }

    async getAllEpisodes(uid) {
        try {
            const response = await this.insertToken(this.token).get(
                `user-api/users/${uid}/audio-musics`
            );
            return { data: response.data };
        } catch (err) {
            return { error: err };
        }
    }

    async getAllPodcasts(uid) {
        try {
            const response = await this.insertToken(this.token).get(
                `user-api/users/${uid}/albums`
            );
            return { data: response.data};
        } catch (err) {
            return { error: err };
        }
    }

    async getAllPodcastSingles(aid) {
        try {
            const response = await this.insertToken(this.token).get(
                `media-api/albums/${aid}/content`
            );
            return { data: response.data.results};
        } catch (err) {
            return { error: err };
        }
    }

    async getAllUserPodcasts(uid) {
        try {
            const response = await this.insertToken(this.token).get(
                `user-api/users/${uid}/albums`
            );
            return { data: response.data.results};
        } catch (err) {
            return { error: err };
        }
    }

    async getPodcastsCategories() {
        try {
            const response = await this.insertToken(this.token).get(
                `media-api/album-sell-categories/`
            )
            return { data: response.data.results };
        } catch (err) {
            return { error: err };
        }
    }

    async getEpisodeCategories() {
        try {
            const response = await this.insertToken(this.token).get(
                `media-api/music-categories/`
            );

            return { data: response.data.results };
        } catch (err) {
            return { error: err };
        }
    }

    async publishEpisode(data, config) {
        try {
            const response = await this.insertToken(this.token).post(
                `/media-api/podcast-episodes/`,
                data,
                config
            );

            return { data: response.data };
        } catch (err) {
            return { error: err };
        }
    }

    async publishPodcast(data, config) {
        try {
            const response = await this.insertToken(this.token).post(
                `/media-api/podcasts/`,
                data,
                config
            );

            return { data: response.data };
        } catch (err) {
            return { error: err };
        }
    }
}
