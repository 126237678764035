import { useContext, useEffect } from "react";
import UserApi from "../api/users";
import CurrentUserContext from "../datamanager/contexts/currentUser";

export default function useUser(page = "dashboard") {
  // Get data from the global context
  const { login } = useContext(CurrentUserContext);

  const token = localStorage.getItem("access_token");
  const userApi = new UserApi(token);

  // UseEffect section
  useEffect(() => {
    handleGetCurrentUser().then(
      (result) => {
        console.log(result);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  // Some handlers
  const handleGetCurrentUser = async () => {
    const response = await userApi.getCurrent();
    
    if (response.data) {
      login(response.data);
    } else {
      // if (page !== "home")
      //   document.location.href = "/app/access";
    }
  };

  return null;
}
