import React, {useCallback, useMemo} from 'react'
import {useDropzone} from 'react-dropzone'
import downn from "../assets/new_images/Download.svg";
import {useSelectedImage} from "../datamanager/contexts/locals/publishAudio/selectedImage";

export function MyDropCoverZone({setFieldValue, acceptedFiles= "image/jpg, image/jpeg, image/png", text, textSucess}) {

  const { selectedImage, handleSetSelectedImage } = useSelectedImage();

  const onDrop = useCallback((droppedFiles) => {
    const fileToUpload = droppedFiles[0];
    setFieldValue('cover', fileToUpload);
    handleSetSelectedImage(fileToUpload);
  }, [handleSetSelectedImage])
  const {getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive} = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': []
    },
    maxFiles: 1
  })

  const defaultStyle = {
    borderColor: '#FF56209E',
    backgroundColor: "#1D1614",
  }

  const acceptStytle = {
    borderColor: 'green',
    backgroundColor: "darkgreen",
  }

  const rejectStyle = {
    borderColor: 'yellow',
    backgroundColor: "darkyellow",
  }

  const boxstyle = useMemo(() => ({
    ...defaultStyle,
    ...(isDragAccept ? acceptStytle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [isDragActive, isDragAccept, isDragReject])

  return (
    <div className="my-dropzone w-full">
      <div {...getRootProps({boxstyle})}>
      <input {...getInputProps({ accept: acceptedFiles })}
             accept={acceptedFiles}
      />
      <div className="publish_audio_form_fields_col1__dropzone">
            <div className={selectedImage ? "dropz--success" : "dropz"}>
              {
                selectedImage && selectedImage ? (
                  <span className="dropztitle-success">{textSucess}</span>
                ) : (<>

                    <img alt={"drop"} src={downn} className="dropzimg"/>
                    <span
                      className="dropztitle">{text}</span>
                  </>
                )
              }
            </div>
          </div>
      </div>
    </div>
  )
}