import "./styles/FileDownloadCard.css"
import musicIco from "../assets/new_images/music_ico.svg"
import dot from "../assets/new_images/dot.svg"
import closeIco from "../assets/new_images/close.svg"
import {formatDownloadSpeed} from "../utils/functions";
import React, {useContext} from "react";
import CurrentUserContext from "../datamanager/contexts/currentUser";
import translate from "../i18n/translate";
import {Link, Navigate} from "react-router-dom";
import {useCurrentPage} from "../datamanager/contexts/locals/publishAudio/currentPage";
import {useMenuSwitchContext} from "../datamanager/contexts/locals/menuSwitch";
import {SpinnerCircularFixed} from "spinners-react";
import {useFinishedUpload} from "../datamanager/contexts/locals/publishAudio/finishedUpload";

export default function FileDownloadCardSingle({
  musicTitle, musicSize, musicRemainTime, musicDownloadPercent, musicDownloadSpeed
  , handleSetUploadPercentage, handleSetUploadSpeed
  , handleSetSelectedAudio, handleSetSelectedImage
  , handleSetMetaData, handleSetBackErr, handleSetMsg, resetForm, isFinishedUpload, isSubmitting
}) {

  const { currentUser } = useContext(CurrentUserContext);

  return (
    <div className="file-download-card">
      <div className="file-download-card-info">
        <div className="file-download-card-icoo">
          <img alt={"music"} src={musicIco} className="file-download-card-ico"/>
          <div className="file-download-card-text">
            <div className="file-download-card-titles">
              <div className="file-download-card-title">{currentUser.alias}</div>
              <hr className="file-download-card-hr"/>
              <div className="file-download-card-titlee">{musicTitle}</div>
            </div>
            <div className="file-download-card-titles-2">
              <div className="file-download-card-titlees-2">
                <div className="file-download-card-title-2">{musicSize}</div>
              </div>
              <img alt={"dot"} src={dot} className="file-download-card-dot"/>
              <span className="file-download-card-title-3">{formatDownloadSpeed(musicDownloadSpeed)}</span>
            </div>
          </div>
        </div>
        <div className="file-download-card-closee">
          <div className="file-download-card-title-2">{musicDownloadPercent}%</div>
        </div>
      </div>
      <div className="file-download-card-bar-1">
        <div className="file-download-card-bar-2" style={{width: `${musicDownloadPercent}%`}}></div>
      </div>
    </div>
  );
}
