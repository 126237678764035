import "./styles/InputField.css";
import {useState} from "react";
import { NoEncryptionOutlined, EnhancedEncryptionOutlined } from "@mui/icons-material";

export default function InputPasswordField({inputLabel, inputType, inputName, inputPlaceholder, required, onChange, value, bgColor="#212018"}) {

  const style = {
    background: bgColor
  }

  const [showPass, hidePass] = useState(false)

  return (
    <div className={ `input-container ${style}` }>
      <p className="input-label">{inputLabel}{required ? <span className="input-required">*</span> : null}</p>
      <div className="input-field-container">
        <input
          className="input-field"
          type={showPass? "text" : inputType}
          name={inputName}
          placeholder={inputPlaceholder}
          onChange={onChange}
          value={value}
        />
        {showPass ? <EnhancedEncryptionOutlined onClick={() => hidePass(!showPass)} className={"encrypt"} /> : <NoEncryptionOutlined className={"encrypt"} onClick={() => hidePass(!showPass)} />}
      </div>
    </div>
  )
}