import {createContext, useCallback, useContext, useState} from 'react';
import {sleep} from "../../../../utils/functions";

const UploadedFilesContext = createContext();

export const useUploadedFiles = () => useContext(UploadedFilesContext);

export const UploadedFilesProvider = ({ children }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleSetUploadedFiles = useCallback((value) => {
    if (value !== uploadedFiles) {
      setUploadedFiles(value);
    }
  }, [uploadedFiles]);

  const handleAddUploadedFiles = useCallback((index) => {
    if (!uploadedFiles.includes(index)) {
      setUploadedFiles(uploadedFiles.concat(index));
    }
  }, [uploadedFiles]);

  const handleRemoveUploadedFiles = useCallback((index) => {
    if (uploadedFiles.includes(index)) {
      setUploadedFiles(uploadedFiles.filter((item) => item !== index));
    }
  }, [uploadedFiles]);

  const handleClearUploadedFiles = useCallback(() => {
    if (uploadedFiles.length) {
      setUploadedFiles([]);
    }
  }, [uploadedFiles]);

  const handleRemoveAllUploadedFiles = useCallback(() => {
    if (uploadedFiles.length) {
      setUploadedFiles([]);
    }
  }, [uploadedFiles]);

  const handleRemoveUploadedFilesByIndex = useCallback((index) => {
    if (uploadedFiles.includes(index)) {
      setUploadedFiles(uploadedFiles.filter((item) => item !== index));
    }
  }, [uploadedFiles]);

  const handleRemoveUploadedFilesByIndexes = useCallback((indexes) => {
    if (uploadedFiles.length) {
      setUploadedFiles(uploadedFiles.filter((item) => !indexes.includes(item)));
    }
  }, [uploadedFiles]);

  const getLastIndex = useCallback(() => {
    if (uploadedFiles.length) {
      return uploadedFiles[0];
    } else {
      return -1;
    }
  }, [uploadedFiles]);

  return (
    <UploadedFilesContext.Provider value={{ uploadedFiles, handleSetUploadedFiles, handleAddUploadedFiles, handleRemoveUploadedFiles, handleClearUploadedFiles, handleRemoveAllUploadedFiles, handleRemoveUploadedFilesByIndex, handleRemoveUploadedFilesByIndexes, getLastIndex }}>
      {children}
    </UploadedFilesContext.Provider>
  );
};
