import "./styles/Stepper.css";
import {useEffect, useState} from "react";
import {useCurrentPage} from "../datamanager/contexts/locals/publishAudio/currentPage";

export default function Stepper({ steps = [], uploadPercentage = null }) {
  const [complete, setComplete] = useState(new Array(steps.length).fill(false));
  const { currentPage, handleSetCurrentPage } = useCurrentPage();

  useEffect(() => {
    // when the current page changes, update the completion status
    setComplete(complete.map((_, i) => i < currentPage-1));
  }, [currentPage])

  const handleClick = (index) => {
    if( uploadPercentage === 0 || uploadPercentage === null ) {
      if (index === currentPage) {
        // Do nothing if clicking on the current step
        return;
      }

      const updatedComplete = complete.map((_, i) => {
        if (i === index - 1) {
          // Mark the clicked step and all previous steps as complete
          return true;
        } else {
          // Keep the completion status of other steps unchanged
          return complete[i];
        }
      });

      handleSetCurrentPage(index);

      // Update the complete state with the updatedComplete array
      setComplete(updatedComplete);
    }
  };


  return (
    <div className="stepper-container">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`stepper-step ${index + 1 === currentPage ? "active" : ""} ${complete[index] ? "complete" : ""} ${index + 1 > currentPage ? "inactive" : ""}`}
          onClick={() => handleClick(index + 1)}
        >
          <div className={`stepper-step-number ${index + 1 === currentPage ? "active" : ""} ${complete[index] ? "complete" : ""}`}>
            {index + 1}
          </div>
          <div className={`stepper-step-title ${index + 1 === currentPage ? "active" : ""} ${complete[index] ? "complete" : ""}`}>
            {step}
          </div>
        </div>
      ))}
    </div>
  );
}
