import React, { useContext } from "react";
import SinglesContext from "../../../../datamanager/contexts/singles";
import translate from "../../../../i18n/translate";
import SingleElt from "./SingleElt";
import { useLoadingContext } from "../../../../datamanager/contexts/locals/Dashboard/loading";
import { Skeleton } from "@mui/material";
import "../styles/single.module.css";
import MusicApi from "../../../../api/musics";
import { SpinnerCircularFixed } from "spinners-react";
import NoContent from "../../../../components/NoContent";

export default function SingleEltList({ data, isLoading, handleNext, type = "topfan" }) {
  console.log(data);

  if (type === "topfan") {
    if (data.count > 0) {
      return (
        <div>
          <div className="topfan-titles">
            <span className="topfan-titlee">{translate('title')}</span>
            <span className="topfan-titlee">{translate('listenning')}</span>
            <span className="topfan-titlee">{translate('apparition')}</span>
            <span className="topfan-titlee">{translate('ordering')}</span>
          </div>

          {isLoading ? <>
            <Skeleton className={"monit-main"} variant={"text"} width={"100%"} height={"2rem"} animation={"wave"} sx={{ bgcolor: "#141414" }} />
          </> : null}

          {data && data.results.map((single, index) => (
            <SingleElt
              key={index}
              single={single}
              evolution={"up"}
              index={index}
            />
          ))}

          {data && data.next &&
            <span onClick={() => {
              if (!isLoading) {
                handleNext().then((res) => {
                  console.log(res);
                  console.log("===========sinles=====", data)
                })
              }
            }} className="topfan-titles load-more ">
              <span className="load-more-title">load more</span>
              {isLoading && <SpinnerCircularFixed
                size={30}
                thickness={100}
                speed={60}
                color="rgba(1, 0.337, 0.125, 1)"
                secondaryColor="rgba(255, 86, 32, 1)"
                className="justify-center"
              />}
            </span>
          }
        </div>
      );
    } else {
      return (<NoContent path={"/app/publish/audio"} contentType={"audio"} />);
    }
  }
  else if (type === "songs") {
    if (data.length > 0) {
      return (
        <div>
          <div className="topfan-titles">
            <span className="topfan-titlee">{translate('title')}</span>
            <span className="topfan-titlee">{translate('listenning')}</span>
            <span className="topfan-titlee">{translate('apparition')}</span>
            <span className="topfan-titlee">{translate('ordering')}</span>
          </div>

          {isLoading ? <>
            <Skeleton className={"monit-main"} variant={"text"} width={"100%"} height={"2rem"} animation={"wave"} sx={{ bgcolor: "#141414" }} />
          </> : null}

          {data && data.map((single, index) => (
            <SingleElt
              key={index}
              single={single}
              evolution={"up"}
              index={index}
              type={type}
            />
          ))}
        </div>
      );
    } else {
      return (<NoContent path={"/app/publish/audio"} contentType={"audio"} />);
    }
  }
}