import React from "react";
import helpico from "../../../../assets/new_images/help.svg";

export default function DataCard({title, value, infoTooltip, foot }) {
  return (
    <div className="monit-elt">
      <div className="monit-elt-top">
        <span className="monit-title">{title}</span>
        { infoTooltip && <img alt={"tooltip"} onMouseOver={() => infoTooltip} src={helpico} className="monit-elt-ico"/> }
      </div>
      <span
        className="monit-main">{value}</span>
      <span className="monit-foot">
        {foot}
      </span>
    </div>
  );
}
