import { useState } from "react"
import ModalContext from "../contexts/modalContext"

const ModalProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [modalData, setModalData] = useState({
    modalType: "",
    modalTitle: "",
    data: null
  })

  // Some handlers
  const handleOpenModal = (modalType, modalTitle, data = null) => {
    const modalDataClone = { ...modalData };

    modalDataClone.modalType = modalType;
    modalDataClone.modalTitle = modalTitle;
    modalDataClone.data = data;

    setModalData(modalDataClone);
    setOpen(true);
  }

  const contextValue = {
    open,
    modalData,
    openModal: handleOpenModal,
    closeModal: () => setOpen(false)
  }

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  )
}

export default ModalProvider