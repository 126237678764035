import style from "../styles/updateAlbumCover.module.css"
import translate from "../../../i18n/translate";
import MusicApi from "../../../api/musics";
import {useContext, useState} from "react";
import {transformUrl} from "../../../utils/functions";
import { UploadFileOutlined, ReplayOutlined } from "@mui/icons-material";
import CurrentUserContext from "../../../datamanager/contexts/currentUser";
import Loader from "react-loader-spinner";
import AlbumsContext from "../../../datamanager/contexts/albums";
import {white} from "tailwindcss/colors";

export default function UpdateAlbumCover({ albumID, albumCover, albumDescription=null, albumTitle=null }) {

  const { currentUser } = useContext(CurrentUserContext);
  const payload = new FormData();
  const [file, setFile] = useState(null);
  const [ isLoading, setIsLoading] = useState(false);
  const [ msg, setMsg] = useState(null);
  const { albums, updateAlbums } = useContext(AlbumsContext);

  const fillPayload = (file) => {
    payload.append("cover", file);
    payload.append("description", albumDescription);
    payload.append("title", albumTitle);
    payload.append("artist", currentUser.id);
  }

  const hanldleUpdateAlbumCover = async () => {
    const token = localStorage.getItem("access_token")
    const musicApi = new MusicApi(token)
    setIsLoading(true)
    fillPayload(file)
    const response = await musicApi.updateAlbumInfo(albumID, payload)
    if (response.data) {
      setIsLoading(false)
      setMsg("successfully updated, changes will be seen after page reload")
      // find album with albumID and update it with response.data in albums
      const newAlbumsResult = albums.results.map((album) => {
        if (album.id === albumID) {
          return response.data
        } else {
          return album
        }
      });
      const newAlbums = {
        ...albums,
        results: newAlbumsResult
      }
      updateAlbums(newAlbums)
    }
  }

  return (
    <div className={style.container}>
      <div className={style.covers}>
        <img className={style.cover} src={ transformUrl(albumCover, "sml")} alt="cover"/>
        <div className={style.cover + " " + style.inputContainer} >
          <UploadFileOutlined className={style.fileIcon} />
          <input
            type="file"
            accept="image/*"
            className={style.file}
            onChange={(e) => setFile(e.target.files[0])}
          />
          {file && <img className={style.cover + " " + style.coverPreview} src={URL.createObjectURL(file)} alt="cover"/> }
        </div>
      </div>
      {msg ? <div className={style.msgContainer}>
        <p className={style.msg}>{msg}</p>
        <div className={style.reload} onClick={() => window.location.reload()}>{translate("reload")}<ReplayOutlined color={white} className={style.reloadIcon} /></div>
      </div> : <div className={style.submit_button}
                                                       onClick={() => hanldleUpdateAlbumCover()}>{translate("update")} {isLoading &&
        <Loader type={"CradleLoader"} color={"white"} height={20} width={20}/>} </div>}
    </div>
  );
}