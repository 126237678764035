import React from 'react'
import translate from '../../i18n/translate'


import './hero.scss'
import Button from '../../components/Button/Button'

import arrowTopRight from '../../assets/icons/arrowTopRight.svg'
import arrowDown from '../../assets/icons/arrowDown.svg'
import laptop from '../../assets/illustrations/laptop.png'
const Hero = () => {
    return (
        <main className='hero w-full'>
            <main className="container container-wrapper">
                <section className="intro gap-8 flex flex-col items-center justify-center">
                    <h1>{translate('value')} {translate('value1')}</h1>
                    <p>{translate('covalue')}</p>
                    <div className="buttons">
                        <a href="/app/access"><Button text={translate('join')} icon={<img src={arrowTopRight} alt="arrow top right" />} /> </a> 
                        <a href="http://store.colorfol.com/"><Button text={translate('discover')}  icon={<img src={arrowDown} alt="arrow down" />} bg='transparent' border='1px solid white' /></a>
                    </div>
                </section>
                <section className="image">
                    <img src={laptop} className="laptop" alt="laptop" />
                </section>

            </main>
        </main>
    )
}

export default Hero