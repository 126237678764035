import React from 'react'
import bars from '../../../assets/icons/bars.svg'
import chart from '../../../assets/icons/chart.svg'
import cards from '../../../assets/icons/cards.svg'
import art1 from '../../../assets/images/art1.png'
import art2 from '../../../assets/images/art2.png'
import art3 from '../../../assets/images/art3.png'
import like from '../../../assets/icons/like.svg'
import comment from '../../../assets/icons/comment.svg'
import Button from '../../Button/Button'

import './left_bar_data_3.scss'
import { map } from 'lodash'
const LeftBarDatas3 = () => {
    const notifications = [
        {
            name: 'Rick Fallo',
            description: 'à commenter votre publication',
            comment: 'Lorem ipsum dolor asset nemet mur consentur dolar furir tyner fotren aved',
            icon: comment,
            date: '10 h',
            img: art1
        },
        {
            name: 'User Name',
            description: 'a aimé votre publication',
            icon: like,
            date: '10 h',
            img: art1
        },
        {
            name: 'Jean Pass',
            description: 'a répondu à votre publication',
            comment: 'Lorem ipsum dolor asset nemet mur consentur dolar furir tyner fotren aved',
            icon: comment,
            date: '10 h',
            img: art1
        },
        {
            name: 'Nael Fonke',
            description: 'a répondu à votre publication',
            comment: 'Lorem ipsum dolor asset nemet mur consentur dolar furir tyner fotren aved',
            icon: comment,
            date: '10 h',
            img: art1
        },
        {
            name: 'Peter Oba',
            description: 'a répondu à votre publication',
            comment: 'Lorem ipsum dolor asset nemet mur consentur dolar furir tyner fotren aved',
            icon: comment,
            date: '10 h',
            img: art1
        },
        {
            name: 'Michaela Iris',
            description: 'a répondu à votre publication',
            comment: 'Lorem ipsum dolor asset nemet mur consentur dolar furir tyner fotren aved',
            icon: comment,
            date: '10 h',
            img: art1
        },
    ]
    return (
        <aside className='left-bar-data-3'>
            <h2 className='text-white font-bold text-base leading-5 text-start'>Notifications</h2>
            {
                notifications.map((notification, index) => (
                    <section className='flex gap-3 flex-wrap items-start justify-start' key={index}>
                        <img src={notification.icon} alt="art1" width={'24px'} height={'24px'} />
                        <div className='flex flex-1 flex-col gap-1'>
                            <img src={notification.img} alt="art1" className="intro-img" width={'32px'} height={'32px'} />
                            <div className='flex'>
                                <p className='text-white font-normal text-sm leading-5 text-start'>{notification.name}</p>
                                <p className='ml-1 font-normal text-sm leading-5 text-start gray'>{notification.description}</p>

                            </div>
                            <p className='text-white font-normal text-sm leading-5 '>{notification.comment}</p>
                            <p className='text-white font-normal text-sm leading-5 gray'>{notification.date}</p>
                        </div>

                    </section>

                ))
            }

        </aside>
    )
}

export default LeftBarDatas3