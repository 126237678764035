import React, {createContext, useCallback, useContext, useState} from 'react';

const MenuSwitchContext = createContext();

export const useMenuSwitchContext = () => useContext(MenuSwitchContext);

export const MenuSwitchProvider = ({ children }) => {
  const [currentMenu, setCurrentMenu] = useState(1);

  const handleSwitchMenu = useCallback((value) => {
    if (value !== currentMenu) {
      setCurrentMenu(value);
    }
  }, [currentMenu]);

  return (
    <MenuSwitchContext.Provider value={{ currentMenu, handleSwitchMenu }}>
      {children}
    </MenuSwitchContext.Provider>
  );
};
