import { useContext, useEffect } from "react"
import CurrentUserContext from "../datamanager/contexts/currentUser"
import SinglesContext from "../datamanager/contexts/singles"
import MusicApi from "../api/musics"
import { useLoadingContext } from "../datamanager/contexts/locals/Dashboard/loading"

const useSingles = () => {
  // Get data from the global context
  const { addSingles } = useContext(SinglesContext)
  const { currentUser } = useContext(CurrentUserContext)
  const { setSingleFetching } = useLoadingContext();

  // UseEffect section
  useEffect(() => {
    if (currentUser) {
      setSingleFetching(true)
      handleFetchSingles().then(
        (result) => {
          console.log(result);
          setSingleFetching(false);
        },
        (error) => {
          console.log(error);
        }
      )
    }
  }, [currentUser])

  const handleFetchSingles = async () => {
    const token = localStorage.getItem("access_token")

    const musicApi = new MusicApi(token)

    const response = await musicApi.getAllSingles(currentUser.id)

    if (response.data) {
      addSingles(response.data)
    }
  }
}

export default useSingles