import "./styles/InputFieldCustom.css";
import {useStoreData} from "../datamanager/contexts/StoreDatas";
import {useMetaData} from "../datamanager/contexts/locals/publishAudio/metaData";
export default function InputFieldCustom({ inputLabel, inputType, inputName, index, inputPlaceholder, inputEndIcon, required, onChange, value, disabled = false }) {

  const { metaData, handleSetMetaData } = useMetaData();

  const handleInputChange2 = (event) => {
    if (inputName.startsWith("meta")) {

      // remove the "meta" prefix on the inputName
      const inputNameWithoutPrefix = inputName.replace("meta", "");

      // update the value of the inputName in the storedData.metaData[index].inputName
      const updatedMetaData = [...metaData];
      updatedMetaData[index][inputNameWithoutPrefix] = event.target.value;
      handleSetMetaData(updatedMetaData);

      // if inputNameWithoutPrefix is title then
      if (inputNameWithoutPrefix === "title") {
        console.log(onChange)
        onChange(event);
      }
    } else {
      onChange(event);
    }
  };

  return (
    <div className="custominput-container" >
      <p className="custominput-label">{inputLabel}{required && <span className="custominput-required">*</span>}</p>
      <div className="custominput-field-container">
        {inputEndIcon && <img src={inputEndIcon} className="input-end-icon" alt="End Icon" />}
        <input
          autoComplete="off"
          className="custominput-field"
          disabled={disabled}
          type={inputType}
          name={inputName}
          placeholder={inputPlaceholder}
          onChange={handleInputChange2}
          value={value}
        />
      </div>
    </div>
  );
}
