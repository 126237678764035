import {MyDropzone} from "../dropZone";
import RealtimeSwitch from "./RealtimeSwitch";
import translate from "../../i18n/translate";
import LeftData from "../../pages/app/dashboard/components/LeftData";
import SalesChart from "../../pages/app/dashboard/components/SalesChart";
import {salesData} from "../../utils/Constants";
import MostListennedList from "../../pages/app/dashboard/components/MostListennedList";
import React from "react";
import {useStoreData} from "../../datamanager/contexts/StoreDatas";
import {useNavigate} from "react-router-dom";
import {useRealtimeSwitchContext} from "../../datamanager/contexts/locals/realTimeSwitch";

export default function LeftBarDatas1() {

  const { isRealtime } = useRealtimeSwitchContext()

  const { handleUpdateStoreData } = useStoreData();

  let navigate = useNavigate();

  const handleUploadFromDashboard = (acceptedFiles) => {
    const AcceptedFiles = {
      acceptedFiles: acceptedFiles
    }

    handleUpdateStoreData(AcceptedFiles);
    navigate('/app/publish/select-content-type');
  }


  return (
    <div className="side-panel">
      <MyDropzone callback={handleUploadFromDashboard} text={translate("dragAndDropFileHereOrChooseOne")} textSucess={translate("dragFIleSucess")}/>
      <RealtimeSwitch/>
      {isRealtime ? (
        <>
          <div className="inst-data">
            <span className="insta-data-title">{translate('yourinstantdatas')}</span>
            <span className="inst-data-subtitle"><div className="inst-data-dot"></div>
              {translate('yourinformations')}</span>
            <hr className="divv"/>
          </div>
          <div className="inst-data">
            <LeftData/>
            <hr className="divv"/>
            <br/>
            <SalesChart salesData={salesData} display_y={false}/>
            <hr className="divv"/>
          </div>
          <MostListennedList/>
        </>
      ) : (
        <span className="noinfo">
          {translate("noData")}
        </span>
      )}
    </div>
  );
}