import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {LOCALES} from "../../i18n";
import ReactGA from "react-ga";
import useUser from "../../hooks/useUser";

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(LOCALES.ENGLISH);
  const [locale, setLocale] = useState('en');
  const languageList = [
    {
      code: "Français",
      value: LOCALES.FRANCAIS,
    },
    {
      code: "English",
      value: LOCALES.ENGLISH,
    },
    {
      code: "عرب",
      value: LOCALES.ARABE,
    },
  ];

  useEffect(() => {
    ReactGA.initialize("UA-142748287-1");

    ReactGA.pageview(window.location.pathname);
  }, []);


  const updateHotLanguage = (event) => {
    handleChangeLanguage(event.target.value);
  };

  const handleChangeLanguage = useCallback((newLanguage) => {
    if ( newLanguage !== language ) {
      setLanguage(newLanguage);
      // extract the locale from the language
      const newLocale = newLanguage.split('-')[0];
      setLocale(newLocale);
    }
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, languageList, updateHotLanguage, locale }}>
      {children}
    </LanguageContext.Provider>
  );
};
