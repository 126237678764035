import React, {createContext, useCallback, useContext, useState} from 'react';

const SwitchUnderlinedContext = createContext();

export const useSwitchUnderlinedContext = () => useContext(SwitchUnderlinedContext);

export const SwitchUnderlinedProvider = ({ children }) => {
  const [selected, setSelected] = useState(1);

  const handleSwitchChange = useCallback((value) => {
    if (value !== selected) {
      setSelected(value);
    }
  }, [selected]);

  return (
    <SwitchUnderlinedContext.Provider value={{ selected, handleSwitchChange }}>
      {children}
    </SwitchUnderlinedContext.Provider>
  );
};
