import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DataTable from "react-data-table-component-with-filter";
import axios from "axios";
import useUser from "../hooks/useUser";
import { SpinnerCircularFixed } from "spinners-react";
import AlbumsContext from "../datamanager/contexts/albums";

const TextField = styled.input`
  height: 34px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 8px 32px 8px 16px;

  &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #6a0f3e;
  color: white;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Cherher un album"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

function Albumstable() {
  // Get data from the global context
  const { albums } = useContext(AlbumsContext);

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    if (albums) {
      setLoading(false);
    }
  }, [albums]);

  const fakeUsers = [
    {
      id: 1,
      title: "Beetlejuice",
      year: "1988",
    },
    {
      id: 2,
      title: "Ghostbusters",
      year: "1984",
    },
  ];

  const columns = [
    {
      name: "Cover",
      grow: 0,
      cell: (row) => (
        <img height="76px" width="76px" alt={row.title} src={row.cover} />
      ),
    },
    {
      name: "Podcast",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "En vente",
      selector: (row) => row.can_sell,
      cell: (row) => <div>{row.can_sell == true ? "Oui" : "Non"}</div>,
    },
    {
      name: "Prix",
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: "Categorie",
      selector: (row) => row.sell_category.name,
      sortable: true,
    },
    {
      name: "Telechargements",
      selector: (row) => row.nb_of_download,
      sortable: true,
    }
  ];

  const filteredItems = fakeUsers.filter(
    (item) =>
      item.title && item.title.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      title="Albums"
      columns={columns}
      data={albums.results}
      onRowClicked={(row) => {
        navigate("/app/albumdetails", { state: { id: row.id } });
      }}
      progressPending={loading}
      progressComponent={
        <SpinnerCircularFixed
          size={50}
          thickness={121}
          speed={150}
          color="rgba(133, 19, 78, 1)"
          secondaryColor="rgba(231, 208, 220, 1)"
          className="mt-12"
        />
      }
      pagination={albums.count > 10 ? true : false}
      paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      persistTableHead
      pointerOnHover
      responsive
    />
  );
}

export default Albumstable;
