import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import "../../../styles/App.css";
import "react-phone-number-input/style.css";
import {
  CheckmarkCircle, Warning
} from "react-ionicons";
import * as Yup from "yup";
import { SpinnerCircularFixed } from "spinners-react";
import logotypo from "../../../assets/new_images/logo_typo.svg";
import InputField from "../../../components/InputField";
import translate from "../../../i18n/translate";
import { handleSubmitForm } from "./ConnexionFormSubmit";
import ConnexionUIRight from "./ConnexionUIRight";
import { Link, useNavigate } from "react-router-dom";
import InputPasswordField from "../../../components/InputPasswordField";

function ConMusic() {
  const [backErr, setBackErr] = useState(false);
  const [msg, setMsg] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [connected, setConnected] = useState(false);

  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if the user is logged in
    const token = localStorage.getItem("token");
    if (token) {
      // Redirect to the dashboard
      navigate("app/dashboard");
    } else {
      // Set isLoggedIn to false
      setIsLoggedIn(false);
    }
  }, [navigate]);

  const SignupSchema = Yup.object().shape({
    username: Yup.string()
      .min(4, "*L’identifiant doit faire entre 4 et 50 caractères")
      // .max(50, "*L’identifiant doit faire entre 4 et 50 caractères")
      .matches(/^[a-zA-Z0-9\s]+$/, "*Les noms d’utilisateur ne peuvent contenir que des caractères alphanumériques.")
      .required("*Veuillez remplir ce champs"), password: Yup.string()
        .min(8, "*Votre mot de passe doit contenir 8+ caractères.")
        .max(150, "*Votre mot de passe est trop long.")
        .required("*Veuillez remplir ce champs"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: SignupSchema,
    onSubmit: (values) => handleSubmitForm({ payload: values, setIsSubmitting: setIsSubmitting, setConnected: setConnected, setMsg: setMsg, setBackErr: setBackErr }),
  });

  return (
    <div className="loginpage">
      <div className="loginfond">
        <div className="logincontent">
          <div className="firstside">
            <div className="firstside2">
              <img alt={"label"} className="firstsideimage" src={logotypo} />
              <form onSubmit={formik.handleSubmit}>
                <div className="firstsidecard">
                  <p className="firstsidetitle">{translate('enterdatas')}</p>

                  {isSubmitting ? (<>
                    {connected ? (<CheckmarkCircle
                      color={"rgba(133, 19, 78, 1)"}
                      title={"Connecte"}
                      height="50px"
                      width="50px"
                    />) : (<>
                      <InputField
                        inputName="username"
                        inputLabel={translate('artistname')}
                        inputPlaceholder={"Enter your artist name"}
                        inputType="text"
                        onChange={formik.handleChange}
                        value={formik.values.username}
                      />
                      {formik.errors.username ? (<span className="emt">
                        {formik.errors.username}
                      </span>) : null}

                      <InputPasswordField
                        inputName="password"
                        inputLabel={translate('password')}
                        inputPlaceholder={""}
                        inputType="password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                      />
                      {formik.errors.password ? (<span className="emt">
                        {formik.errors.password}
                      </span>) : null}

                      {JSON.stringify(formik.errors).length > 2 ? (<input
                        className="firstsidebutton-off"
                        type="submit"
                        value={connected ? "welcome back on colorfol for artists" : "connexion to colorfol"}
                        onClick={() => {
                          setBackErr(false);
                        }}
                      />) : (<input
                        className="firstsidebutton"
                        type="submit"
                        value={connected ? "welcome back on colorfol for artists" : "connexion to colorfol"}
                        onClick={() => {
                          setBackErr(false);
                        }}
                      />)}
                      <SpinnerCircularFixed
                        size={50}
                        thickness={121}
                        speed={150}
                        color="#FF5620"
                        secondaryColor="rgba(231, 208, 220, 1)"
                        className="firstside-spinner"
                      />

                      <span className="firstsideconfidentialitetext">{translate('byclicking1')}
                        <span className="firstsideconfidentialite">
                          {translate('byclicking2')}
                        </span>{translate('byclicking3')}Colorfol.
                      </span>
                    </>)}
                  </>) : backErr ? (<>
                    <InputField
                      inputName="username"
                      inputLabel={translate('artistname')}
                      inputPlaceholder={"Enter your artist name"}
                      inputType="text"
                      onChange={formik.handleChange}
                      value={formik.values.username}
                    />
                    {formik.errors.username ? (<span className="emt">
                      {formik.errors.username}
                    </span>) : null}

                    <InputPasswordField
                      inputName="password"
                      inputLabel={translate('password')}
                      inputPlaceholder={""}
                      inputType="password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                    {formik.errors.password ? (<span className="emt">
                      {formik.errors.password}
                    </span>) : null}


                    <div className="warning__">
                      <Warning
                        color={"#FF5620"}
                        title={"Connecte"}
                        height="2rem"
                        width="2rem"
                      />
                      <span className="emt no__margin">
                        {msg}
                      </span>
                    </div>


                    {JSON.stringify(formik.errors).length > 2 ? (<input
                      className="firstsidebutton-off"
                      type="submit"
                      value={"Retry"}
                      onClick={() => {
                        setBackErr(false);
                      }}
                    />) : (<input
                      className="firstsidebutton"
                      type="submit"
                      value={"Retry"}
                      onClick={() => {
                        setBackErr(false);
                      }}
                    />)}

                    <span className="firstsideconfidentialitetext">{translate('byclicking1')}
                      <span className="firstsideconfidentialite">
                        {translate('byclicking2')}
                      </span>{translate('byclicking3')}Colorfol.
                    </span>
                  </>) : (<>
                    <InputField
                      inputName="username"
                      inputLabel={translate('artistname')}
                      inputPlaceholder={"Enter your artist name"}
                      inputType="text"
                      onChange={formik.handleChange}
                      value={formik.values.username}
                    />
                    {formik.errors.username ? (<span className="emt">
                      {formik.errors.username}
                    </span>) : null}

                    <InputPasswordField
                      inputName="password"
                      inputLabel={translate('password')}
                      inputPlaceholder={""}
                      inputType="password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                    {formik.errors.password ? (<span className="emt">
                      {formik.errors.password}
                    </span>) : null}
                    <span style={{ color: '#FF5620' }}>{translate('forgot')}</span>
                    <span className="firstsideconfidentialitetext mb-6">{translate('byclicking1')}
                      <span className="firstsideconfidentialite">
                        {translate('byclicking2')}
                      </span> {translate('byclicking3')} Colorfol.
                    </span>
                    {JSON.stringify(formik.errors).length > 2 ? (<input
                      className="firstsidebutton-off"
                      type="submit"
                      value={"Connect"}
                    />) : (<input
                      className="firstsidebutton"
                      type="submit"
                      value={"Connect"}
                    />)}
                  </>)}
                  <p className="firstsidenoaccount">{translate('notartistyet')} <Link to={"/start/music"}
                    className="firstsidecreate"> {translate('create')}</Link></p>
                </div>
              </form>
            </div>
          </div>
          <ConnexionUIRight />
        </div>
      </div>
    </div>);
}

export default ConMusic;
