import React, { useContext } from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { SpinnerCircularFixed } from "spinners-react";
import { useLocation, Navigate } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import { Play, Pause } from "react-ionicons";
import DataTable from "react-data-table-component-with-filter";
import moment from "moment";
import "moment/locale/fr";
import EpisodesContext from "../../../datamanager/contexts/episodes";
moment.locale("fr");

function EpisodeDetails() {
  // Get data from global context
  const { selectedEpisode } = useContext(EpisodesContext)
  const [sound, setSound] = useState(null)

  const [isPlaying, setIsPlaying] = useState(false);

  // UseEffect section
  useEffect(() => {
    if (selectedEpisode) {
      setSound(new Audio(selectedEpisode.src))
    }
  }, [selectedEpisode])

  useEffect(() => {
    if (sound) {
      if (isPlaying) {
        handlePlay()
      } else {
        handlePause()
      }
    }
  }, [isPlaying])

  // Some handlers
  const toggle = () => {
    setIsPlaying(!isPlaying);
  };

  const handlePlay = () => {
    sound.play();
  }

  const handlePause = () => {
    sound.pause();
  }
  
  const columns = [
    {
      name: "Cover",
      grow: 0,
      cell: (row) => (
        <img height="76px" width="76px" alt={row.title} src={row.cover} />
      ),
    },
    {
      name: "Titre",
      selector: (row) => row.title,
    },
    {
      name: "Streams",
      selector: (row) => row.nb_of_listening,
    },
    {
      name: "Telechargements",
      selector: (row) => row.nb_of_download,
    },
    {
      name: "Publie le",
      selector: (row) => row.publication_date,
      cell: (row) => (
        <div>{moment(row.publication_date).format("Do MMMM  YYYY")}</div>
      ),
    },
  ];

  return (
    <>
    {
      selectedEpisode ? (
        <div className="p-4 w-full h-full max-h-screen flex flex-col items-center justify-start mt-24">
          <div className="w-full flex items-start justify-start">
            <div className="w-52 h-52">
              <img src={selectedEpisode.cover} className="object-cover" />
            </div>

            <div className="h-52 flex flex-col items-start justify-end mx-5 ">
              <h5 className="text-sm text-slate-500 font-semibold">Episode</h5>
              <h1 className="text-6xl text-slate-500 font-bold my-1.5">
                {selectedEpisode.title}
              </h1>

              <div className="flex items-center justify-center mt-2">
                <div className="w-6 h-6 ">
                  <img
                    src={selectedEpisode.author.photo}
                    className="rounded-full object-cover"
                  />
                </div>
                <h5 className="text-sm text-slate-500 font-semibold mx-2 rounded-xl">
                  {selectedEpisode.author.username}
                </h5>
              </div>
            </div>
          </div>

          <div className="h-96 my-3 w-full flex flex-col items-start justify-start">
            <div className="flex items-center justify-start mt-4 mb-8">
              <button className="ppbut" onClick={toggle}>
                {isPlaying ? (
                  <Pause
                    color={"#fff"}
                    className="ml-px"
                    title={"shuffle"}
                    height="20.7px"
                    width="20.7px"
                  />
                ) : (
                  <Play
                    color={"#fff"}
                    className="ml-px"
                    title={"shuffle"}
                    height="20px"
                    width="20px"
                  />
                )}
              </button>

              <button className="cursor-pointer hover:bg-gray-200 text-gray-800 font-bold py-2 px-2 rounded-full inline-flex items-center mx-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-7 w-7 cursor-pointer text-gray-500 hover:text-gray-700"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                  />
                </svg>
              </button>
            </div>

            <div className="w-full flex flex-col items-center justify-center">
              <DataTable
                columns={columns}
                data={[selectedEpisode]}
                progressPending={false}
                progressComponent={
                  <SpinnerCircularFixed
                    size={50}
                    thickness={121}
                    speed={150}
                    color="rgba(133, 19, 78, 1)"
                    secondaryColor="rgba(231, 208, 220, 1)"
                    className="mt-12"
                  />
                }
                fixedHeader={true}
                fixedHeaderScrollHeight={"408px"}
                pointerOnHover
                responsive
              />
            </div>
          </div>
        </div>
      ) : <Navigate to="/app/audio" />
    }
    </>
  );
}
export default EpisodeDetails;
