import React, { useContext, useEffect, useState } from "react";
import gsap from "gsap";
import SinglesContext from "../../../datamanager/contexts/singles";
import AlbumsContext from "../../../datamanager/contexts/albums";
import translate from "../../../i18n/translate";
import styles from "../../app/posts/styles/posts.module.css";
import "../../app/dashboard/styles/dashboard.module.css"
import Loader from "../../../components/Loader";
import NoContent from "../../../components/NoContent";
import { useLoadingContext } from "../../../datamanager/contexts/locals/Dashboard/loading";
import CurrentUserContext from "../../../datamanager/contexts/currentUser";

import DashTopNav from "../../../components/DashTopNav/DashTopNav";
import SearchBar from "../../app/posts/components/SearcBar";


import '../../../styles/dashboard/income.scss'
import SummaryCard from "../../../components/Cards/SummaryCard/SummaryCard";
import calendar from '../../../assets/icons/calendar.svg'
import up from '../../../assets/icons/up.svg'
import cameroon from '../../../assets/icons/cameroon.svg'
import ci from '../../../assets/icons/ci.svg'
import right from '../../../assets/icons/arrow-right.svg'
import album1 from '../../../assets/images/a1.png'
import album2 from '../../../assets/images/a2.png'
import LeftBarDatas6 from "../../../components/DashSideCards/LeftBarData6/LeftBarData6";
import { useStats } from "../../../datamanager/contexts/Stats";
import { formatNumber } from "../../../utils/FormatValues";

function Income() {
    const { stat } = useStats();

    console.log(stat);
    
    const [open, setOpen] = useState(false);

    const { currentUser } = useContext(CurrentUserContext);
    // Get data from the global context
    const { singles } = useContext(SinglesContext);
    const { albums } = useContext(AlbumsContext);

    const { loading, handleSetLoading } = useLoadingContext();

    const [radius, setRadius] = React.useState(50);
    const [itemNb, setItemNb] = React.useState(5);
    const [skipAnimation, setSkipAnimation] = React.useState(false);

    const handleItemNbChange = (event, newValue) => {
        if (typeof newValue !== 'number') {
            return;
        }
        setItemNb(newValue);
    };
    const handleRadius = (event, newValue) => {
        if (typeof newValue !== 'number') {
            return;
        }
        setRadius(newValue);
    };
    const handleOpen = () => {
        gsap.fromTo(
            "#mob-nav",
            {
                x: '-100%',
            },
            {
                x: '0',
                duration: 0.75,
            }
        );
        setOpen(!open)
    }

    // UseEffect section
    useEffect(() => {
        if (stat) {
            handleSetLoading(false)
        }
    }, [stat])

    const origin = [
        {
            id: 2,
            country: 'Côte d’ivoire',
            flag: ci,
            stat: "17.800 ventes",
            percent: '22%'
        },
        {
            id: 3,
            country: 'Gabon',
            flag: ci,
            stat: "17.800 ventes",
            percent: '22%'
        },
        {
            id: 4,
            country: 'Rep. Dem. Congo',
            flag: ci,
            stat: "17.800 ventes",
            percent: '22%'
        },
        {
            id: 5,
            country: 'Tunisie',
            flag: ci,
            stat: "17.800 ventes",
            percent: '22%'
        },
        {
            id: 6,
            country: 'Maroc',
            flag: ci,
            stat: "17.800 ventes",
            percent: '22%'
        },
        {
            id: 7,
            country: 'France',
            flag: ci,
            stat: "17.800 ventes",
            percent: '22%'
        }
    ]

    return (
        // <div className="h-screen w-screen flex flex-col items-center justify-center text-white text-2xl font-mono">
        //     <p>In development <span className="animate-spin spinner">⚙️</span>...</p> 
        //     <p>Available soon</p>
        // </div>
        <>
            {loading ? <Loader /> :
                stat ? (
                    <div className="main-dash">
                        <DashTopNav />

                        <div className={`${styles.searchcontainer} mt-3`} >
                            <SearchBar searchPlaceholder={"Taper un mot clé à rechercher"} />
                        </div>

                        <div className="income_datas">
                            <div className="main-side mt-14">
                                <h1 className="text-white font-bold text-4xl leading-10 text-start">Vos revenus</h1>
                                <h2 className=" font-normal text-lg text-start">Avec Colorfol, vous avez le contrôle total de vos revenus. Vous pouvez être payé quand vous le souhaitez, sans restriction ni attente.</h2>

                                <div className="income mt-7">
                                    <p className="text-white font-normal text-2xl leading-9 text-start">Vos données</p>

                                    <div className="income_container">
                                        <SummaryCard title={'Estimation de revenu'} stat={formatNumber(stat.total_album_sales.all_time.amount)} rating={'FCFA'} />
                                        <SummaryCard title={'Paiement le plus recent'} stat={formatNumber(stat.latest_earning_amount)} rating={`${formatNumber(stat.latest_earning_amount)}FCFA le ${stat.latest_earning_date.substring(0, 19).replace('T', ' à ')}`} />
                                        <SummaryCard title={'Achats de musique'} stat={formatNumber(stat.total_album_sold.all_time.count)} direction={'down'} rating={`${formatNumber(stat.total_album_sold.change_30d)} ce mois`} />
                                    </div>
                                </div>

                                <div className="mobile-left-bar-data-5">
                                    <LeftBarDatas6 />
                                </div>

                                {/* <div className="table mt-4">
                                    <h4 className="text-white font-normal text-2xl leading-9 text-start">Détails sur les revenus générés par vos ventes d’album</h4>

                                    <div className="table_container">
                                        <div className="head">
                                            <h3 className="text-white font-bold text-2xl text-start">Vos albums</h3>
                                            <div className="search-cont">
                                                <SearchBar searchPlaceholder={"Rechercher un single"} />
                                                <span className="date">
                                                    <img src={calendar} alt="calender" />
                                                    <span>Février 2024</span>
                                                </span>
                                            </div>
                                        </div>
                                        <table>
                                            <thead>
                                                <th>Titre</th>
                                                <th>Prix</th>
                                                <th>Ce mois</th>
                                                <th>Total</th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="flex items-center justify-start gap-3">
                                                        1
                                                        <img src={album1} alt="Album 1" />
                                                        Rédemption
                                                        <span>E</span>
                                                    </td>
                                                    <td>
                                                        5000FCFA
                                                    </td>
                                                    <td>
                                                        207.890FCFA
                                                    </td>
                                                    <td>
                                                        1.314.890FCFA
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="flex items-center justify-start gap-3">
                                                        2
                                                        <img src={album2} alt="Album 2" />
                                                        Illusion
                                                        <span>E</span>
                                                    </td>
                                                    <td>
                                                        5000FCFA
                                                    </td>
                                                    <td>
                                                        207.890FCFA
                                                    </td>
                                                    <td>
                                                        1.314.890FCFA
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="stats">
                                        <aside className="group">
                                            <h4>Origine de vos acheteurs </h4>
                                            <aside>
                                                <div className="head">
                                                    <div>
                                                        <p className="text-white font-medium text-4xl text-left">Cameroun</p>
                                                        <img src={cameroon} alt="cameroon" width={'22px'} />
                                                    </div>
                                                    <div>
                                                        <p className="text-white font-medium text-4xl text-right">17.800</p>
                                                        <p className="stats">
                                                            1
                                                            <img src={up} alt="up" />
                                                            48%
                                                        </p>
                                                        <p className="stats">+22% ces 30 derniers jours</p>
                                                    </div>
                                                </div>
                                                <section className="main-body">
                                                    {
                                                        origin.map((val) => (
                                                            <section key={val.id}>
                                                                <span>{val.id}</span>
                                                                <div className="flex-1">
                                                                    <p className="text-white font-medium text-base leading-5 text-left flex">{val.country}
                                                                        <img src={val.flag} alt="ci" width={'14px'} className="ml-2" />

                                                                    </p>
                                                                    <p className="text-white font-medium text-xs text-left">{val.stat}</p>
                                                                </div>
                                                                <span className="text-white font-medium text-xs text-right">{val.percent}</span>
                                                            </section>
                                                        ))
                                                    }
                                                </section>
                                                <section className="tail">
                                                    <span>Voir le classement total</span>
                                                    <img src={right} alt="arrow right" />
                                                </section>
                                            </aside>
                                        </aside>
                                        <aside className="group">
                                            <h4>Repartition par genre</h4>
                                            <aside>
                                                <div className="head" style={{ alignItems: 'flex-end' }}>
                                                    <div className="gap-4">
                                                        <p className="stats" style={{ justifyContent: 'flex-start' }}>Vente total</p>
                                                        <p className="text-white font-medium text-4xl text-left">30.563</p>
                                                        <p className="stats" style={{ justifyContent: 'flex-start' }}>Ces 30 derniers jours</p>
                                                    </div>
                                                    <span className="date">
                                                        <img src={calendar} alt="calender" />
                                                        <span>Février 2024</span>
                                                    </span>
                                                </div>
                                            </aside>
                                            <section className="charts">
                                                
                                            </section>
                                        </aside>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                ) : <NoContent path={"/app/publish/select-content-type"} contentType={"album ou audio"} />}
        
        </>
    );
}

export default Income;
