import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneInputField = ({ value, onChange }) => {
  return (
    <PhoneInput
      id="phone"
      name="phone"
      className="phoneInput custom-phone-input" // Add custom class here
      buttonStyle={{
        backgroundColor: "transparent",
        borderRadius: "1rem",
        border: "0px solid #FFFFFF1F",
      }}
      country={"cm"}
      value={value}
      onChange={onChange}
    />
  );
};

export default PhoneInputField;
