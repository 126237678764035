import BrouillonIcon from "../../../../assets/new_images/BrouillonIcon.svg";
import React, {useContext, useEffect, useState} from "react";
import {useBackErr} from "../../../../datamanager/contexts/locals/publishAudio/backErr";
import {Link} from "react-router-dom";
import StateButtonWithImage from "../../../../components/StateButtonWithImage";
import translate from "../../../../i18n/translate";
import ModalContext from "../../../../datamanager/contexts/modalContext";
import {useConnected} from "../../../../datamanager/contexts/locals/publishAudio/connected";
import {useSubmitting} from "../../../../datamanager/contexts/locals/publishAudio/isSubmitting";
import {useFinishedUpload} from "../../../../datamanager/contexts/locals/publishAudio/finishedUpload";
import {useUploadSpeed} from "../../../../datamanager/contexts/locals/publishAudio/uploadSpeed";
import {useUploadPercentage} from "../../../../datamanager/contexts/locals/publishAudio/uploadPercent";
import {useSelectedAudio} from "../../../../datamanager/contexts/locals/publishAudio/selectedAudio";
import {useSelectedImage} from "../../../../datamanager/contexts/locals/publishAudio/selectedImage";
import {useMsg} from "../../../../datamanager/contexts/locals/publishAudio/msg";
import {useMetaData} from "../../../../datamanager/contexts/locals/publishAudio/metaData";
import {useStoreData} from "../../../../datamanager/contexts/StoreDatas";
import {useCurrentPage} from "../../../../datamanager/contexts/locals/publishAudio/currentPage";

export default function StepperActionsLast({ handleSubmit, handleReset }) {

  const [hideButtons, setHideButtons] = useState(false);

  const { closeModal, openModal } = useContext(ModalContext);

  const { handleClearMetaData } = useMetaData();
  const { handleUpdateStoreData } = useStoreData();
  const { handleSetSelectedAudio } = useSelectedAudio();
  const { handleSetSelectedImage } = useSelectedImage();
  const { handleSetCurrentPage } = useCurrentPage();
  const { handleSetUploadSpeed } = useUploadSpeed();
  const { handleSetBackErr } = useBackErr();
  const { handleSetUploadPercentage } = useUploadPercentage();
  const { handleSetMsg } = useMsg();

  const handleSaveAsDraft = () => {
    console.log("saving draft");
    handleUpdateStoreData(null);
    handleSetSelectedAudio(null);
    handleSetSelectedImage(null);
    handleSetUploadPercentage(0);
    handleSetUploadSpeed(0);
    handleClearMetaData();
    handleSetBackErr(false);
    handleSetMsg(null);
    handleSetCurrentPage(1);
    handleReset();
  }

  const handleSubmitNoBack = () => {
    const params = {
      handleUpload: () => {
        closeModal();
        setHideButtons(true);
        handleSubmit();
      },
    };
    openModal("uploadAlbum", "Upload Album", params);
  }

  if(!hideButtons) {
    return <div className="publish_audio_form_stepper__actions">
      <Link
        to={"/app/dashboard"}
        className="publish_audio_form_stepper__actions__cancel"
        onClick={() => {
          handleSaveAsDraft();
        }}
      >
        {translate("saveAsDraft")}
        <img alt={"label"} src={BrouillonIcon}
             className="publish_audio_form_stepper__actions__cancel__icon"/>
      </Link>
      <StateButtonWithImage text={translate("publish")} action={() => handleSubmitNoBack()} enable={true} />
    </div>;
  } else {
    return null;
  }
}