import React, {createContext, useCallback, useContext, useState} from 'react';

const ConnectedContext = createContext();

export const useConnected = () => useContext(ConnectedContext);

export const ConnectedProvider = ({ children }) => {
  const [connected, setConnected] = useState(false);

  const handleSetConnected = useCallback((value) => {
    if (value !== connected) {
      setConnected(value);
    }
  }, [connected]);
  return (
    <ConnectedContext.Provider value={{ connected, handleSetConnected }}>
      {children}
    </ConnectedContext.Provider>
  );
};
