import React, {createContext, useCallback, useContext, useState} from 'react';

const FinishedUploadContext = createContext();

export const useFinishedUpload = () => useContext(FinishedUploadContext);

export const FinishedUploadProvider = ({ children }) => {
  const [isFinishedUpload, setIsFinishedUpload] = useState(false);

  const handleSetIsFinishedUpload = useCallback((value) => {
    if (value !== isFinishedUpload) {
      setIsFinishedUpload(value);
    }
  }, [isFinishedUpload]);
  return (
    <FinishedUploadContext.Provider value={{ isFinishedUpload, handleSetIsFinishedUpload, setIsFinishedUpload }}>
      {children}
    </FinishedUploadContext.Provider>
  );
};
