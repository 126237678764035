import React, { useContext } from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { SpinnerCircularFixed } from "spinners-react";
import {useLocation, useNavigate} from "react-router-dom";
import lib from "../../../assets/music-library.gif";
import CurrentUserContext from "../../../datamanager/contexts/currentUser";
import Modal from "react-modal"
import ReactDOM from "react-dom";
import M_modal from "./utils/M_modal";
import MusicApi from "../../../api/musics";
import moment from "moment/moment";
import DataTable from "react-data-table-component-with-filter";
import SinglesContext from "../../../datamanager/contexts/singles";
import Details_page from "./utils/details_page";
import {TestEnvAPI} from "../../../envs";

function AlbumDetails() {
  // Get data from the global context
  const { currentUser: user } = useContext(CurrentUserContext)

  const [albumSingle, setAlbumSingle] = useState([]);
  const [data, setData] = useState({});
  const [date, setDate] = useState();
  const [dataPay, setDataPay] = useState([]);
  const [pay_info , setPayInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [canSell, setCanSell] = useState(false);
  const access_token = localStorage.getItem("access_token");
  const location = useLocation();
  const [resetPaginationToggle, setResetPaginationToggle] =
      React.useState(false);

  let navigate = useNavigate();

  const { singles, selectSingle } = useContext(SinglesContext)

  useEffect(() => {

    // get Data from Tokenanout user
    const sleep = (milliseconds) => {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    };

    const fetchData = async () => {
      try {
        const { data: response } = await axios.get(
          `${TestEnvAPI}/media-api/albums/${location.state.id}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        await sleep(12000);
        setData(response);
        setCanSell(response.can_sell)
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData().then(r => {
      setLoading(false);
    });

    // get Date Information as String
    const getDate = () => {
      var today = new Date()
      var date_string =  today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
      setDate(date_string);
    }
    getDate()

    //GET Information about price and other
    const fetchPayInfo = async () => {
      var title = data.title
      try {
        var { data: response } = await axios.get(
          `${TestEnvAPI}/payement-api/paiements/group-sales-by-channel?album=${title}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        var resultPay = response.results
        try{
          var { data: response } = await axios.get(
            `${TestEnvAPI}/payement-api/paiements/group-sales-by-channel-solde?album=${title}`,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          );
        } catch (error2){
          console.error(error2);
        }
        //add price on Data Pay
        for(const elmt in response.results){
          resultPay[elmt].amount = response.results[elmt].amount
        }
        setDataPay(resultPay);
        //COMPUTE TOTAL
        var infos = {number: 0, amount:0}
        for(const elmt in resultPay){
          infos.number = infos.number + resultPay[elmt].number
          infos.amount = infos.amount + resultPay[elmt].amount
          infos.currency = resultPay[elmt].currency
        }
        setPayInfo(infos)
      } catch (error) {
        console.error(error);
      }
    };
    fetchPayInfo().then(r => {});


  }, []);

  const handleSelectSingle = (id) => {
    selectSingle(id)
    navigate("/app/singledetails")
  }

  const columns = [
    {
      name: "Cover",
      grow: 0,
      cell: (row) => (
          <img height="76px" width="76px" alt={row.title} src={row.cover} />
      ),
    },
    {
      name: "Single",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Ecouts",
      selector: (row) => row.nb_of_listening,
      sortable: true,
    },
    {
      name: "Categorie",
      selector: (row) => row.media_accessibility,
      sortable: true,
    },
    {
      name: "Telechargements",
      selector: (row) => row.nb_of_download,
      sortable: true,
    },
    {
      name: "Publie le",
      selector: (row) => row.publication_date,
      cell: (row) => (
          <div>{moment(row.publication_date).format("Do MMMM  YYYY")}</div>
      ),
    },
  ];

  const handleGetAllAlbumSingle = async () => {
    const musicApi = new MusicApi(access_token);

    const response = await musicApi.getAllAlbumsSingle(location.state.id);

    if (response.data) {
      setAlbumSingle(response.data);
    }
  }

  useEffect(()=>{
    if (albumSingle.length === 0) {
      handleGetAllAlbumSingle().then(
          (result) => {
            console.log(result)
          },
          (error) => {
            console.log(error)
          }
      );
    }
  }, [albumSingle])


  // -----------------------------------------------------------------------------------------------------------------
  //  Mise en place du modal de demande d'ajout de son

  const [openModal, setOpenModal] = useState(
      false
  )

  // -----------------------------------------------------------------------------------------------------------------
  if(data.title){
    return <Details_page
        userData={data}
        setOpenModal={setOpenModal}
        openModal={openModal}
        date={date}
        columns={columns}
        dataPay={dataPay}
        canSell={canSell}
        pay_info={pay_info}
        loading={loading}
        albumSingle={albumSingle}
        handleSelectSingle={handleSelectSingle}
        resetPaginationToggle={resetPaginationToggle} />
  }else{
    return <div className="text-center text-2xl font-bold"><p>Oups une erreur est survenue</p></div>
  }

}

export default AlbumDetails;
