import translate from "../i18n/translate";
import DataCardList from "../pages/app/audio/component/DataCardList";
import SwitchUnderlined from "./baseComponents/SwitchUnderlined";
import Search from "./baseComponents/Search";
import Select from "./baseComponents/Select";
import React, { useContext } from "react";
import { useSwitchUnderlinedContext } from "../datamanager/contexts/locals/switchUnderlined";
import SingleEltList from "../pages/app/audio/component/SingleEltList";
import AlbumEltList from "../pages/app/audio/component/AlbumEltList";
import { switchTabs } from "../utils/Constants";
import SwitchSlider from "./SwitchSlider";

import album1 from "../assets/img/playlist.png"
import album3 from "../assets/img/playlist3.png"
import view from "../assets/img/view.svg"
import SearchBar from "../pages/app/posts/components/SearcBar";
import SelectInput from "./SelectInput";
import { useStats } from "../datamanager/contexts/Stats";
import MusicApi from "../api/musics";
import SinglesContext from "../datamanager/contexts/singles";
import { useLoadingContext } from "../datamanager/contexts/locals/Dashboard/loading";
import AlbumsContext from "../datamanager/contexts/albums";

export default function YourData() {
  const { stat } = useStats();

  console.log(stat);
  console.log(stat?.top_songs);
  

  const { selected } = useSwitchUnderlinedContext();

  const { singles, addSinglesAtEnd } = useContext(SinglesContext);
  const { isSingleFetching, setSingleFetching } = useLoadingContext();

  const { albums } = useContext(AlbumsContext);
  const handleFetchNext20Singles = async () => {
    const token = localStorage.getItem("access_token")

    const musicApi = new MusicApi(token)

    setSingleFetching(true);

    const response = await musicApi.getNext20Singles(singles.next)

    if (response.data) {
      console.log("===================next function call =======", singles.next);
      addSinglesAtEnd(response.data);
      setSingleFetching(false);
    }
  }

  return (
    <div className="datas">
      <div className="main-side">
        <span className="main-side-title">{translate('yourdata')}</span>
        <DataCardList />
        {/* TODO */}
        <div className="main-side-elt">
          <span className="main-side-title">{translate('your_recent_contents')}</span>
          <div className="topfan">
            <SwitchSlider switchValues={switchTabs} />
            {selected === 1 && <SingleEltList data={singles} isLoading={isSingleFetching} handleNext={handleFetchNext20Singles} />}
            {selected === 2 && <AlbumEltList data={albums}/>}

          </div>
        </div>
        <div style={{ marginTop: '72.5px' }}>
          <p className="text-white">Apparition dans les playlists</p>
          <aside className="albums-data">
            <div>
              <img src={album1} alt="album1" />
              <p className="white-64 font-normal text-xs">17.9021 Lectures</p>
              <p className="white-64 font-normal text-xs">Avec Fally Ipupa, DidiB, Céline Banza</p>

            </div>
            <div>
              <img src={album1} alt="album1" />
              <p className="white-64 font-normal text-xs">17.9021 Lectures</p>
              <p className="white-64 font-normal text-xs">Avec Fally Ipupa, DidiB, Céline Banza</p>

            </div>
            <div>
              <img src={album3} alt="album3" />
              <p className="white-64 font-normal text-xs">17.9021 Lectures</p>
              <p className="white-64 font-normal text-xs">Avec Fally Ipupa, DidiB, Céline Banza</p>

            </div>
            <aside className="view-all">
              <img src={view} alt="view" />
            </aside>

          </aside>
        </div>
        <div className="main-side-elt">
          <span className="main-side-title">Vos meilleurs fans</span>
          <div className="topfan">
            <SwitchSlider switchValues={switchTabs} />
            {/* TODO -> top_songs_per_album */}
            {selected === 1 && stat && <SingleEltList type='songs' data={stat.top_songs_per_album}/>} 
            {/* TODO -> top_albums */}
            {selected === 2 && <AlbumEltList type='songs' data={stat.top_albums}/>}
          </div>
        </div>
      </div>
    </div>
  );
}