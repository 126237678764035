import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom'
import NavigationProvider from "./datamanager/providers/navigationProvider";
import {LanguageProvider} from "./datamanager/contexts/Language";

// Plugins css
import "react-datepicker/dist/react-datepicker.css";

ReactDOM.render(
    <React.StrictMode>
      <Router>
        <NavigationProvider>
          <LanguageProvider >
            <App />
          </LanguageProvider>
        </NavigationProvider>
      </Router>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want to SignUp measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
