import React from "react";
import {useSelectedAudio} from "../../datamanager/contexts/locals/publishAudio/selectedAudio";
import {useMetaData} from "../../datamanager/contexts/locals/publishAudio/metaData";
import {formatBytes} from "../../utils/functions";
import FileDownloadCardSingle from "../FileDownloadCardSingle";
import translate from "../../i18n/translate";
import {useBackErr} from "../../datamanager/contexts/locals/publishAudio/backErr";
import {useMsg} from "../../datamanager/contexts/locals/publishAudio/msg";
import {useSubmitting} from "../../datamanager/contexts/locals/publishAudio/isSubmitting";
import {SpinnerCircularFixed} from "spinners-react";
import {Link} from "react-router-dom";
import {useMenuSwitchContext} from "../../datamanager/contexts/locals/menuSwitch";
import {useCurrentPage} from "../../datamanager/contexts/locals/publishAudio/currentPage";
import {useFinishedUpload} from "../../datamanager/contexts/locals/publishAudio/finishedUpload";

export default function FileDownloadCardListSingle({
  uploadPercentage, isFinishedUpload, uploadSpeed
  , handleSetUploadPercentage, handleSetUploadSpeed
  , handleSetSelectedAudio, handleSetSelectedImage
  , handleSetMetaData, handleSetBackErr, handleSetMsg,
  resetForm
}) {

  const { selectedAudio } = useSelectedAudio();
  const { metaData } = useMetaData();
  const { backErr } = useBackErr();
  const { msg } = useMsg();
  const { isSubmitting, setIsSubmitting } = useSubmitting();
  const { handleSwitchMenu } = useMenuSwitchContext();
  const { handleSetCurrentPage } = useCurrentPage();
  const { setIsFinishedUpload } = useFinishedUpload();

  return (
    <div className="publish_audio_end_container_col1">
      { isFinishedUpload && !backErr && !msg && <span className="publish_audio_end_container_col1_title">Félicitation, vous avez ajouté votre nouveau Single avec succès</span> }
      { backErr && msg && msg.length > 0 && msg.map((item, index) => <span className="publish_audio_end_container_col1_subtitle" key={index}>{item}</span>)}
      { uploadPercentage === 100 && isSubmitting && !isFinishedUpload && <>
        <span className="publish_audio_end_container_col1_subtitle">{translate("finishing")}</span>
        <SpinnerCircularFixed
          size={30}
          thickness={100}
          speed={90}
          color="rgba(1, 0.337, 0.125, 1)"
          secondaryColor="rgba(255, 86, 32, 1)"
          className="justify-center items-center w-full"
        />
      </>}
      { uploadPercentage === 100 && !isSubmitting && !isFinishedUpload  && <div onClick={() => {
        handleSetUploadPercentage(0);
        handleSetUploadSpeed(0);
        handleSetSelectedAudio(null);
        handleSetSelectedImage(null);
        handleSetMetaData([]);
        handleSetBackErr(false);
        handleSetMsg(null);
        handleSetCurrentPage(1);
        setIsFinishedUpload(false);
        setIsSubmitting(false);
      }}
        className="publish_audio_end_container_col1_button_retry"
      >
        {translate("retry")}
      </div> }
      { uploadPercentage === 100 && !isSubmitting && <Link onClick={() => {
        handleSetUploadPercentage(0);
        handleSetUploadSpeed(0);
        handleSetSelectedAudio(null);
        handleSetSelectedImage(null);
        handleSetMetaData([]);
        handleSetBackErr(false);
        handleSetMsg(null);
        handleSetCurrentPage(1);
        handleSwitchMenu(1);
        setIsFinishedUpload(false);
        resetForm();
      }}
       className="publish_audio_end_container_col1_button"
       to={"/app/dashboard"}
        >
        {translate("backtoAudio")}
      </Link>}
      { uploadPercentage !== 100 &&!isFinishedUpload && selectedAudio && selectedAudio.map((item, index) => (
        <FileDownloadCardSingle key={index}
          musicTitle={metaData[index].title}
          musicSize={formatBytes(item.size)}
          musicRemainTime={null}
          musicDownloadPercent={uploadPercentage}
          musicDownloadSpeed={uploadSpeed}
          handleSetUploadPercentage = {handleSetUploadPercentage}
          handleSetUploadSpeed = {handleSetUploadSpeed}
          handleSetSelectedAudio = {handleSetSelectedAudio}
          handleSetSelectedImage = {handleSetSelectedImage}
          handleSetMetaData = {handleSetMetaData}
          handleSetBackErr = {handleSetBackErr}
          handleSetMsg = {handleSetMsg}
          resetForm={resetForm}
          isFinishedUpload={isFinishedUpload}
          isSubmitting={isSubmitting}
          backErr={backErr}
          msg={msg}
        />
      ))}
    </div>
  );
}