import BrouillonIcon from "../../../../assets/new_images/BrouillonIcon.svg";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import StateButtonWithImage from "../../../../components/StateButtonWithImage";
import translate from "../../../../i18n/translate";
import { useMetaData } from "../../../../datamanager/contexts/locals/publishAudio/metaData";
import { useStoreData } from "../../../../datamanager/contexts/StoreDatas";
import { useSelectedAudio } from "../../../../datamanager/contexts/locals/publishAudio/selectedAudio";
import { useSelectedImage } from "../../../../datamanager/contexts/locals/publishAudio/selectedImage";
import ModalContext from "../../../../datamanager/contexts/modalContext";
import { useCurrentPage } from "../../../../datamanager/contexts/locals/publishAudio/currentPage";
import { useUploadPercentage } from "../../../../datamanager/contexts/locals/publishAudio/uploadPercent";
import { useUploadSpeed } from "../../../../datamanager/contexts/locals/publishAudio/uploadSpeed";
import { useBackErr } from "../../../../datamanager/contexts/locals/publishAudio/backErr";
import { useMsg } from "../../../../datamanager/contexts/locals/publishAudio/msg";

export default function StepperActionsLastSingle({ handleSubmit, handleReset, handleSetUploadPercentage = null,
  uploadPercentage = null, controller = null, source = null
}) {

  const { handleClearMetaData } = useMetaData();
  const { handleUpdateStoreData } = useStoreData();
  const { handleSetSelectedAudio } = useSelectedAudio();
  const { handleSetSelectedImage } = useSelectedImage();
  const { handleSetCurrentPage } = useCurrentPage();
  const { handleSetUploadSpeed } = useUploadSpeed();
  const { handleSetBackErr } = useBackErr();
  const { handleSetMsg } = useMsg();

  const { closeModal, openModal } = useContext(ModalContext);

  const handleSubmitNoBack = () => {
    const params = {
      handleUpload: () => {
        closeModal();
        handleSubmit();
      },
    };

    openModal("uploadSingle", "upload single", params);
  }

  const [isCancelRequest, setIsCancelRequest] = useState(false);

  const handleSaveAsDraft = () => {
    console.log("saving draft");
    handleUpdateStoreData(null);
    handleSetSelectedAudio(null);
    handleSetSelectedImage(null);
    handleSetUploadPercentage(0);
    handleSetUploadSpeed(0);
    handleClearMetaData();
    handleSetBackErr(false);
    handleSetMsg(null);
    handleSetCurrentPage(1);
    handleReset();
  }

  const handleRequestCancellation = () => {
    controller?.abort();
    source?.cancel();
    setIsCancelRequest(true);
    handleSetUploadPercentage(0);
    console.log("is canceled ", isCancelRequest)
  }

  return (
    <div className="publish_audio_form_stepper__actions">
      {
        uploadPercentage === 0 || uploadPercentage === null ? (
          <>
            <Link
              to={"/app/dashboard"}
              className="publish_audio_form_stepper__actions__cancel"
              onClick={() => {
                console.log("in the callback ==================================")
                handleSaveAsDraft();
              }}
            >
              {translate("saveAsDraft")}
              <img alt={"label"} src={BrouillonIcon}
                className="publish_audio_form_stepper__actions__cancel__icon" />
            </Link>
            <StateButtonWithImage text={translate("publish")} action={() => handleSubmitNoBack()} enable={true} />
          </>
        ) : (
          <>
          </>
        )
      }
    </div>
  );
}