import { useContext, useEffect } from "react";
import CurrentUserContext from "../datamanager/contexts/currentUser";
import { useStats } from "../datamanager/contexts/Stats";
import MusicApi from "../api/musics";

const useStats_hook = () => {
  // Get data from the global context
  const { currentUser } = useContext(CurrentUserContext);
  const { setStats } = useStats();

  // UseEffect section
  useEffect(() => {
    if (currentUser) {
      handleFetchStats().then(() => {});
    }
  }, [currentUser])

  const handleFetchStats = async () => {
    const token = localStorage.getItem("access_token")

    const musicApi = new MusicApi(token)

    const response = await musicApi.getStats(currentUser.id)

    if (response.data) {
      setStats(response.data);
    } else {

    }
  }
}

export default useStats_hook