import React from "react";
import "../styles/App.css";
import "./styles/SideBar.css"
import logotypo from "../assets/new_images/logo_typo.svg";
import translate from "../i18n/translate";
import SideBarBottomActionsButtonsList from "./SideBarComponents/SideBarBottomActionsButtonsList";
import SideBarMenuList from "./SideBarComponents/SideBarMenuList";
import SideBarProfile from "./SideBarComponents/SideBarProfile";

export default function SideBar() {
  return (
    <div className="lsbar bxs lg:flex lg:flex-col lg:h-screen ">
      <div className="fond12"></div>
      <SideBarProfile />
      <div className="colart">
        <img alt={"collogo"} className="collogo" src={logotypo}/><span className="coltyp">For Artist</span>
      </div>
      <p className="menuP">{translate("menu")}</p>
      <div className="sbop lg:flex-1 lg:overflow-y-auto">
        <SideBarMenuList />
        <SideBarBottomActionsButtonsList  />
      </div>
    </div>
  );
}
