import { useContext, useEffect } from "react"
import CurrentUserContext from "../datamanager/contexts/currentUser"
import PodcastsContext from "../datamanager/contexts/podcasts"
import PodcastApi from "../api/podcast";

const usePodcasts = () => {
  // Get data from the global context
  const { addPodcasts } = useContext(PodcastsContext)
  const { currentUser } = useContext(CurrentUserContext)

  // UseEffect section
  useEffect(() => {
    if (currentUser) {
      handleFetchPodcasts().then(
        (result) => {
          console.log(result)
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }, [currentUser])

  const handleFetchPodcasts = async () => {
    const token = localStorage.getItem("access_token")

    const PodcastApi_ = new PodcastApi(token)

    const response = await PodcastApi_.getAllPodcasts(currentUser.id)

    if (response.data) {
      addPodcasts(response.data)
    }
  }
}

export default usePodcasts