import NextIcon from "../assets/new_images/ArrowCircleRight.svg";
import React from "react";

export default function StateButtonWithImage({ enable, action, text }) {


  return (
    <div className={enable ? "publish_audio_form_stepper__actions__publish" : "publish_audio_form_stepper__actions__publish--disable"} onClick={() => action()}
    >
      {text}
      <img alt={"label"} src={NextIcon} className="publish_audio_form_stepper__actions__publish__icon"/>
    </div>
  );
}