import "./styles/InputField.css";
import "./styles/SelectInput.css";
import DropDownIcon from "../assets/new_images/CaretCircleDown.svg";
import { useState, useRef } from "react";
import {useMetaData} from "../datamanager/contexts/locals/publishAudio/metaData";

export default function SelectInput({ inputPlaceholder, index, optionList, fieldName="category", setFieldValue, bgColor="#1D1D1D", value }) {
  const containerRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  const handleContainerClick = () => {
    setIsOptionsVisible(!isOptionsVisible);
  };


  console.log(`dropdown index: ${index}`, value, optionList?.find((option) => option?.id === value)?.name, selectedOption)

  const handleOptionClick = (option) => {
    setFieldValue(fieldName, option.id);
    setSelectedOption(option?.name);
    setIsOptionsVisible(false);
  };

  return (
    <div className="input-container" style={{ background: bgColor }} ref={containerRef}>
      <div className="selected-option" onClick={handleContainerClick}>
        <span className="selected-option-placeholder">{(value && optionList?.find((option) => option?.id === value)?.name) ||  inputPlaceholder}</span>
        <img alt={"dropdown"} src={DropDownIcon} className={isOptionsVisible ? "dropdown-icon rotate" : "dropdown-icon"} />
      </div>
      {isOptionsVisible && (
        <ul className={isOptionsVisible ? "options active" : "options"}>
          {optionList?.map((option, index) => (
            <li key={index} onClick={() => { handleOptionClick(option); }}>
              {option?.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}