import FileDownloadCardList from "./FileDownloadCardList";
import PreviewTop from "./previewTop";
import UploadSongList2 from "./UploadSongList2";
import React from "react";
import translate from "../../i18n/translate";

export default function PreviewRightSide({ values }){

  return (
    <div className="publish_audio_end_container">
      <FileDownloadCardList />
      <div className="publish_audio_end_container_col2">
        <div className="publish_audio_end_container_col2_fond2"></div>
        <div className="publish_audio_end_container_col2_container">
          <span className="publish_audio_end_container_col2_container_title">{ translate("albumPreveiew")}</span>
          <PreviewTop values={values}/>
          <hr className="publish_audio_end_container_col2_container_hr"/>
          <span className="publish_audio_end_container_col2_container_title1">{translate("Title")}</span>
          <UploadSongList2/>
        </div>
      </div>
    </div>
  );
}