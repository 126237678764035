import "../../../styles/App.css";
import {useLocation} from "react-router-dom";
import styles from './styles/album.module.css'
import translate from "../../../i18n/translate";
import React, {useContext, useEffect, useState} from "react";
import CurrentUserContext from "../../../datamanager/contexts/currentUser";
import {formatDateToString, sleep} from "../../../utils/functions";
import SingleList from "./utils/SingleList";
import {useStats} from "../../../datamanager/contexts/Stats";
import {formatNumber, truncateDecimal} from "../../../utils/FormatValues";
import ImageComponent from "../../../components/resizeImage";
import {Edit} from "@mui/icons-material";
import ModalCore from "../../../components/modals/ModalCore";
import ModalContext from "../../../datamanager/contexts/modalContext";
import AlbumsContext from "../../../datamanager/contexts/albums";

export default function Album({ isActive }) {

  const { closeModal, open, openModal } = useContext(ModalContext);
  const { albums: albumList } = useContext(AlbumsContext);

  const [coverImageKey, setCoverImageKey] = useState(0);

  useEffect(() => {
    sleep(5000).then(() => {
      setCoverImageKey(coverImageKey + 1);
      console.log("cover reloaded !! ====================");
    });
  }, [albumList]);


  const results = albumList && albumList.results ? albumList.results : [];

  const location = useLocation();
  const state = location.state;
  const { currentUser: user } = useContext(CurrentUserContext);
  const displayStat = false;

  const { stat } = useStats();

  const albumSate = state.album;

  const album = albumSate && results.length > 0 && results.find((a) => a.id === albumSate.id) ? results.find((a) => a.id === albumSate.id) : albumSate;

  const paramUpdateCover = {
    albumID: album && album.id,
    albumCover: album && album.cover,
    albumTitle: album && album.title,
    albumDescription: album && album.description,
  }

  const paramUpdateTitle = {
    albumID: album && album.id,
    albumTitle: album && album.title,
    albumDescription: album && album.description,
  }
  const paramUpdateCategory = {
    albumID: album && album.id,
    albumTitle: album && album.title,
    albumDescription: album && album.description,
    albumCategory: album && album.genre,
  }
  const paramUpdateLabel = {
    albumID: album && album.id,
    albumTitle: album && album.title,
    albumDescription: album && album.description,
    albumLabel: album && album.metadata && JSON.parse(album.metadata).label,
    albumMetadata: album && album.metadata && JSON.parse(album.metadata),
  }

  return (
    <div className={styles.album_main}>
      <div className={styles.top}>
        <div className={styles.card}>
          <div className={styles.card1}></div>
          <div className={styles.card2}></div>
          <div className={styles.album_img_container}>
            {album && album.cover && <ImageComponent key={coverImageKey} className={styles.album_img} imageUrl={album.cover} size="normal" />}
            {
              album.price ? (
                <div className={styles.price}>{album.price} <span className={styles.currency}>XAF</span></div>
              ) : (
                <div className={styles.price}>{translate('free')}</div>
              )
            }
            <Edit className={styles.editCover} onClick= {() => openModal("updatealbumcover", "Update Album Cover", paramUpdateCover)} />
          </div>
        </div>
        <div className={styles.album_info}>
          <h1 className={styles.album_author}>{user.alias}</h1>
          <div className={"flex items-center gap-3"}>
            <p className={styles.album_title}>{album.title}</p>
            <Edit className={styles.editTitle} onClick= {() => openModal("updatealbuminfo", "Update Album Title", paramUpdateTitle)}/>
          </div>
          <p
            className={styles.publication_date}>{translate("publicationDate")} : {formatDateToString(album.publication_date)}</p>
          <div className={styles.tags}>
            <p className={styles.tags_elt}>{album.genre ? album.genre : translate("noCategory")}</p>
            {album.explicit && <p className={styles.tags_elt}>Explicit</p>}
          </div>
        </div>
      </div>
      <div className={styles.singles}>
        <div className={styles.singles_header}>
          <p className={styles.singles_header_elt}>{translate("Title")}</p>
          <p className={styles.singles_header_elt}>{translate("ordering")}</p>
        </div>
        {album && <SingleList album={album}/>}
      </div>
      <div className={styles.singles_info}>
        <span
          className={styles.publication_date}><b
          className={styles.fields}>{translate("publicationDate")}</b> : {formatDateToString(album.publication_date)}</span>
        <div className={"flex items-center gap-3"}>
          <span className={styles.publication_date}><b
            className={styles.fields}>{translate("gender")}</b> : {album.genre ? album.genre : translate("noCategory")}</span>
          <Edit className={styles.editTitle} onClick= {() => openModal("updatealbuminfo", "Update Album Category", paramUpdateCategory)} />
        </div>
        <span className={styles.publication_date}><b
          className={styles.fields}>{translate("pubOnColorfol")}</b> : {formatDateToString(album.publication_date)}</span>
        <div className={"flex items-center gap-3"}>
          {album.metadata ? (
            <span className={styles.publication_date}><b
              className={styles.fields}>{translate("label")}</b> : {JSON.parse(album.metadata).label}</span>
          ) : (
            <span className={styles.publication_date}><b
              className={styles.fields}>{translate("label")}</b> : {translate("noLabel")}</span>
          )}
          {/*<Edit className={styles.editTitle} onClick= {() => openModal("updatealbuminfo", "Update Album Label", paramUpdateLabel)} />*/}
        </div>
      </div>
      {displayStat && <div className={styles.album_stat}>
        <p className={styles.album_stat_title}>{translate("albumStat")}</p>
        <div className={styles.album_stats}>
          <div className={styles.album_stats_cover}>
            <div className={styles.card}>
            <div className={styles.album_stat_cover_card1}></div>
              <div className={styles.album_stat_cover_card2}></div>
              <div className={styles.album_stat_cover_container}>
                <img className={styles.album_img} src={album.cover} alt="cover" />
              </div>
            </div>
          </div>
          <div className={styles.album_stats_elt}>
            <p className={styles.album_stats_info1}>{stat ? formatNumber(stat.nb_listenning) : 0}</p>
            <p className={styles.album_stats_info2}>{translate("Lectures")}</p>
          </div>
          <div className={styles.album_stats_elt}>
            <p className={styles.album_stats_info1}>{stat ? formatNumber(stat.total_album_sold) : 0}</p>
            <p className={styles.album_stats_info2}>{translate("musicSales")}</p>
          </div>
          <div className={styles.album_stats_elt}>
            <p className={styles.album_stats_info1}>{stat ? formatNumber(stat.total_album_sales) : 0}</p>
            <p className={styles.album_stats_info2}>{translate("IncomesEstimation")}</p>
          </div>
          <div className={styles.album_stats_elt}>
            <p
              className={styles.album_stats_info1}>{stat ? `${truncateDecimal((stat.nb_uniq_listenning / stat.nb_listenning))}%` : 0}</p>
            <p className={styles.album_stats_info2}>{translate("relistenningRate")}</p>
          </div>
        </div>
      </div>}
      <ModalCore open={open} onClose={closeModal} />
    </div>
  );
}
