import React, { createContext, useContext, useState } from 'react';

const StatsContext = createContext();

export const useStats = () => useContext(StatsContext);

export const StatsProvider = ({ children }) => {
  const [stat, setStats] = useState(null);

  return (
    <StatsContext.Provider value={{ stat, setStats }}>
      {children}
    </StatsContext.Provider>
  );
};
