import MusicApi from "../../../../api/musics";
import { token } from "../../../../utils/Constants";
import { sleep } from "../../../../utils/functions";

// BaseFormAPICall est maintenant une fonction utilitaire qui accepte des paramètres
export function BaseFormAPICall(handleSetUploadPercentage, handleSetSubmitting, handleSetUploadSpeed,handleSetConnected,handleSetIsFinishedUpload) {

  // Cette valeur devrait être définie en dehors de la fonction de progression
  // pour qu'elle ne soit pas réinitialisée à chaque appel.
  const startTime = Date.now();
  // handleSetSubmitting(true);
  const musicApi = new MusicApi(token);

  const AxiosConfig = {
    onUploadProgress: (progressEvent) => {
      const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      handleSetUploadPercentage(percentage);
      handleSetSubmitting(true);
      const currentTime = Date.now();
      const elapsedTime = (currentTime - startTime) / 1000; // in seconds
      const speed = progressEvent.loaded / elapsedTime; // bytes per second
      handleSetUploadSpeed(speed);
      if (percentage === 100) {
        handleSetConnected(true);
      }
    },
  };

  return {
    musicApi,
    AxiosConfig
  };
}