import React from "react";
import {usePlaying} from "../../datamanager/contexts/playing";
import {PauseSharp, PlayArrowSharp} from "@mui/icons-material";
import ProgressAudioBar from "./progressAudioBar";
import ImageComponent from "../../components/resizeImage";

export default function PlayingSong() {

  const { playing, handleSetPlaying } = usePlaying();

  return (
    <>
      { playing && playing.single ?
        <div className="playpreview">
          <div className="playpreview_musiccover">
          <ImageComponent imageUrl={playing.single.cover} size="sml" />
          </div>
          <div className="playpreview_content">
            <div className="playpreview_container">
              <span className="playpreview_title">{playing.single.title}</span>
              <ProgressAudioBar />
            </div>
            { playing.isGoing ?
              <PauseSharp className="playpreview_playpause"
                onClick={() => {
                  handleSetPlaying({
                    isGoing: playing? !playing.isGoing : false,
                    single: playing ? playing.single : null
                  });
                }}
              />
              : <PlayArrowSharp className="playpreview_playpause"
                onClick={() => {
                  handleSetPlaying({
                    isGoing: playing ? !playing.isGoing : true,
                    single: playing ? playing.single : null
                  });
                  }
                }
              />
            }
          </div>
        </div>
        : null
      }
    </>
  );
}