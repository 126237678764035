import React from "react";
import {usePlaying} from "../../../../datamanager/contexts/playing";
import {PauseSharp, PlayArrowSharp} from "@mui/icons-material";
import ImageComponent from "../../../../components/resizeImage";

export default function SingleEltCover({ single }) {

  const { playing, handleSetPlaying } = usePlaying();

  return (
    <div className={"toppfan-elt-img2__container topfan-elt-img2"}>
   {/*  <img alt="cover" src={single.cover} className="topfan-elt-img2"/> */}
    <ImageComponent imageUrl={single.cover} size="sml" />
      {/* { playing && playing.isGoing ?
        <PauseSharp className="topfan-elt-img2__hover"  onClick={() =>
          {
            handleSetPlaying({
                isGoing: !playing.isGoing,
                single: single
            });
          }
        } />
        : <PlayArrowSharp className="topfan-elt-img2__hover" onClick={() =>
          {
            handleSetPlaying({
                isGoing: playing ? !playing.isGoing : true,
                single: single
            });
          }
        } />
      } */}

      <PlayArrowSharp className="topfan-elt-img2__hover" onClick={() =>
        {
          handleSetPlaying({
              isGoing: playing ? !playing.isGoing : true,
              single: single
          });
        }
      } />

    </div>
  );
}