import React, {createContext, useCallback, useContext, useState} from 'react';

const SubmittingContext = createContext();

export const useSubmitting = () => useContext(SubmittingContext);

export const SubmittingProvider = ({ children }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSetSubmitting = useCallback((value) => {
    if (value !== isSubmitting) {
      setIsSubmitting(value);
    }
  }, [isSubmitting]);
  return (
    <SubmittingContext.Provider value={{ isSubmitting, handleSetSubmitting, setIsSubmitting }}>
      {children}
    </SubmittingContext.Provider>
  );
};
