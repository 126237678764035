import Preview from "./Preview";
import React from "react";
import { useCurrentPage } from "../../../../datamanager/contexts/locals/publishAudio/currentPage";
import translate from "../../../../i18n/translate";
import InfoDefinitionSingle from "./InfoDefinitionSingle";
import AudioSettingsSingle from "./AudioSettingsSingle";
import UploadProcessEndSingle from "./UploadProcessEndSingle";
import back from "../../../../assets/icons/ArrowLeft.svg";


export default function FormPagesSingle({
  handleChange, errors, setFieldValue, values, handleSubmit, handleReset,
  isValid, isFinishedUpload, uploadPercentage, uploadSpeed, handleSetBackErr,
  handleSetMsg, handleSetMetaData, handleSetSelectedAudio, handleSetSelectedImage,
  handleSetUploadPercentage, handleSetUploadSpeed, controller, source, handleSetConnected = null,
  handleSetSubmitting = null, selectedAudio = null, user = null, AxiosConfig = null, musicApi = null, metaData = null,
  handleSetFinishedUpload = null, resetForm = null, backErr = null, isSubmitting = null
}) {

  const { currentPage, handleSetCurrentPage } = useCurrentPage();


  return (
    <div className={currentPage === 3 ? "publish_audio_2 step_3" : "publish_audio"}>
      <div className="publish_audio__container">
        <div className="publish_audio__container_header">
          <div className="publish_audio__container__header__info">
            <span className="publish_audio__container__header__title">{translate("YourNewMusic")}</span>
            <span className="publish_audio__container__header__subtitle">{translate("enterInformations")}</span>
          </div>
        </div>
        {currentPage === 1 && (
          <InfoDefinitionSingle
            errors={errors}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            values={values}
            isValid={isValid}
          />
        )}
        {currentPage === 2 && (
          <>
            {/* <div className="px-4  mx-auto mt-8" style={{ width: '95%' }}>
              <a href="/">
                <img src={back} alt="back" className="" />
              </a>
            </div> */}
            <AudioSettingsSingle
              errors={errors}
              values={values}
              handleSubmit={handleSubmit}
              isValid={isValid}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
          </>
        )}
        {currentPage === 3 && (
          <>
            <div className="px-4  mx-auto mt-8" style={{ width: '95%' }}>
                <img src={back} alt="back" className="" onClick={() => handleSetCurrentPage(2)} />
            </div>
            <UploadProcessEndSingle
              values={values}
              errors={errors}
              handleSubmit={handleSubmit}
              isValid={isValid}
              handleReset={handleReset}
              isFinishedUpload={isFinishedUpload}
              uploadPercentage={uploadPercentage}
              uploadSpeed={uploadSpeed}
              handleSetUploadPercentage={handleSetUploadPercentage}
              handleSetUploadSpeed={handleSetUploadSpeed}
              handleSetSelectedAudio={handleSetSelectedAudio}
              handleSetSelectedImage={handleSetSelectedImage}
              handleSetMetaData={handleSetMetaData}
              handleSetBackErr={handleSetBackErr}
              handleSetMsg={handleSetMsg}
              controller={controller}
              source={source}
              handleSetFinishedUpload={handleSetFinishedUpload}
              resetForm={resetForm}
              handleSetConnected={handleSetConnected}
              handleSetSubmitting={handleSetSubmitting}
              selectedAudio={selectedAudio}
              user={user}
              AxiosConfig={AxiosConfig}
              musicApi={musicApi}
              metaData={metaData}
              backErr={backErr}
              isSubmitting={isSubmitting}
            />
          </>
        )}
      </div>
      {currentPage !== 3 && (
        <Preview
          values={values}
          handleReset={handleReset}
        />
      )}
    </div>
  );
}