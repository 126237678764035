import { BaseApi } from "..";
import axios from "axios";
import {TestEnvAPI} from "../../envs";

// Class that handles the users API

export default class UserApi extends BaseApi {
  constructor(token = null) {
    super();

    this.token = token;
  }

  // Register user
  async register(payload) {
    try {
      const response = await this.axiosInstance.post(
        "/user-api/register/",
        payload
      );

      return { data: response.data };
    } catch (err) {
      return { error: err };
    }
  }

  // Login user
  async login(payload) {
    try {
      const response = await this.axiosInstance.post(
        "/user-api/login/",
        payload,
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      return { data: response.data };
    } catch (err) {
      return { error: err };
    }
  }

  async getCurrent() {
    try {
      const response = await this.insertToken(this.token).get(
        "/user-api/auth/user/"
      );

      return { data: response.data };
    } catch (err) {
      return { error: err };
    }
  }

  async logout() {
    try {
      const response = await this.insertToken(this.token).get(
        "/user-api/logout/"
      );

      return { data: response.data };
    } catch (err) {
      return { error: err };
    }
  }

  async updateProfile(uid, payload, uploadOption = undefined) {
    try {
      const response = await this.insertToken(this.token).put(
        `/user-api/users/${uid}/`,
        payload,
        uploadOption
      );

      return { data: response.data };
    } catch (err) {
      return { error: err };
    }
  }

  async refresh(refreshToken) {
    try {
      const rs = await axios.post(
        `${TestEnvAPI}/api/token/refresh/`,
        {
          refresh: refreshToken,
        }
      );

      const {access} = rs.data;

      localStorage.setItem("access_token", access);

      return;
    } catch (_error) {
      return Promise.reject(_error);
    }
  }

  async updateProfilePic(uid, payload, uploadOption = undefined){
    try {
      const response = await this.insertToken(this.token).put(
          `/user-api/file-pics/${uid}/`,
          payload,
          uploadOption
      );

      return { data: response.data };
    }catch (err){
      return { error: err};
    }
  }
}
