import { BaseApi } from "..";

export default class MusicApi extends BaseApi {
  constructor(token = null) {
    super();

    this.token = token;
  }

  async getAllSingles(uid) {
    try {
      const response = await this.insertToken(this.token).get(
        `user-api/users/${uid}/audio-musics`
      );
      return { data: response.data };
    } catch (err) {
      return { error: err };
    }
  }

  async updateAlbumInfo(albumID, payload) {
    try {
      const response = await this.insertToken(this.token).patch(
        `media-api/albums/${albumID}/`,
        payload
      );
      return { data: response.data };
    } catch (err) {
      return { error: err };
    }
  }

  async getNext20Singles(next) {
    try {
      const response = await this.insertToken(this.token).get(next);
      return { data: response.data };
    } catch (err) {
      return { error: err };
    }
  }

  async getAllAlbums(uid) {
    try {
      const response = await this.insertToken(this.token).get(
        `user-api/users/${uid}/albums`
      );
      return { data: response.data};
    } catch (err) {
      return { error: err };
    }
  }

  async getStats(uid){
    try{
      const response = await this.insertToken(this.token).get(
          `/user-api/users/${uid}/stats`
      );
      return {data: response.data};
    }catch (err){
      return {error: err}
    }
  }

  async getAllAlbumsSingle(aid) {
    try {
      const response = await this.insertToken(this.token).get(
          `media-api/albums/${aid}/content`
      );
      return { data: response.data.results, count: response.data.count };
    } catch (err) {
      return { error: err };
    }
  }

  async getAllUserAlbums(uid) {
    try {
      const response = await this.insertToken(this.token).get(
          `user-api/users/${uid}/albums`
      );
      return { data: response.data.results};
    } catch (err) {
      return { error: err };
    }
  }

  async getAllVideos(uid) {
    try {
      const response = await this.insertToken(this.token).get(
        `user-api/users/${uid}/video-musics`
      );

      return { data: response.data };
    } catch (err) {
      return { error: err };
    }
  }

  async getAlbumSellCategories() {
    try {
      const response = await this.insertToken(this.token).get(
        `media-api/album-sell-categories/`  
      )
      return { data: response.data.results };
    } catch (err) {
      return { error: err };
    }
  }

  async getMusicCategories(page) {
    try {
      const response = await this.insertToken(this.token).get(
        `media-api/music-categories/?page=${page}`
      );

      return { data: response.data.results, next: response.data.next };
    } catch (err) {
      return { error: err };
    }
  }

  async publishSingle(data) {
    try {
      const response = await this.insertToken(this.token).post(
        `media-api/audio-musics/`,
        data
      );

      return { data: response.data };
    } catch (err) {
      return { error: err };
    }
  }

  async publishAudio(data, config, controller, source) {
    try {
      return this.insertToken(this.token).post(
        `media-api/audio-musics/`,
        data,
        config,
        {
          signal: AbortSignal.timeout(5000),
          cancelToken: source?.token
        }
      ).then(
        (response) => {
          if (response.data){
            return { data: response.data };
          }
        }
      ).catch(
        (err) => {
          return { error: err };
        }
      );

    } catch (err) {
      if (err.name === 'AbortError') {
        // La requête a été interrompue
        return { error: 'Request aborted' };
      }
      return { error: err };
    }
  }

  async publishAlbum(data, config) {
      console.log('debut de la creation album');
      try {
      const response = await this.insertToken(this.token).post(
          `media-api/albums/`,
          data,
          config
      );
      console.log('creation album avec succes',response.data);
      return { data: response.data };
    } catch (err) {
      console.log('creation album avec erreur');
      return { error: err };
    }
  }
  async publishAlbumWithSingle(data, id, config) {
      console.log('debut ajoute de single a un album');
      try {
      console.log('id de album',id);
      const response = await this.insertToken(this.token).post(
          `media-api/albums/${id}/add-new-song`,
          data,
          config
      );
      console.log('ajoute de single a un album avec succes',response.data);
      return { data: response.data };
    } catch (err) {
      console.log('Ajoute de single a un album avec erreur',err);
      return { error: err };
    }
  }

  async publishAlbumBulkAudio(data, config){
    try{
      const response = await this.insertToken(this.token).post(
          "media-api/album-bulk-audios/",
          data,
          config
      );
      return {data:response.data};
    }
    catch (err){
      return {error:err};
    }

  }

  async addArtist(data, config) {
    try {
      const response = await this.insertToken(this.token).post(
          `media-api/albums/`,
          data,
          config
      );

      return { data: response.data };
    } catch (err) {
      return { error: err };
    }
  }

  async publishVideo(data) {
    try {
      const response = await this.insertToken(this.token).post(
        `media-api/video-musics/`,
        data
      );

      return { data: response.data };
    } catch (err) {
      return { error: err };
    }
  }
}
