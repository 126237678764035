import {I18nPropvider, LOCALES} from "../i18n";
import {useState} from "react";
import translate from "../i18n/translate";
import logo from "../assets/colorfol artists.png";
import {useNavigate, useLocation} from "react-router-dom";



export default function Cgu({ setCguAccepted }){

    const [language, setLanguage] = useState(LOCALES.FRANCAIS);

    let history = useNavigate();

    return (
        <I18nPropvider locale={language}>
            <div className="flex-col  content-center m-auto p-32">
                <img src={logo} style={{width:"800px", margin:"auto"}} />
                <p className="text-6xl text-red-500 text-center">
                    {translate("cgu_title")}
                </p>
                <br/>
                <p className="text-3xl text-center">
                    {translate("cgu_sub")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_1_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_2_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_intro_cu")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 1:</span>{"  "}{translate("cgu_1_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 2:</span>{"  "}{translate("cgu_2_art_title")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_2_atr_1_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_2_atr_2_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_2_atr_3_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_2_atr_4_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_2_atr_5_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_2_atr_6_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 3:</span>{"  "}{translate("cgu_3_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 4:</span>{"  "}{translate("cgu_4_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 5:</span>{"  "}{translate("cgu_5_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 6:</span>{"  "}{translate("cgu_6_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_1_nb")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_2_nb")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_3_nb")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_a_nb")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_b_nb")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_c_nb")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_d_nb")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_e_nb")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_f_nb")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_g_nb")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_h_nb")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_i_nb")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_j_nb")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_k_nb")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 7:</span>{"  "}{translate("cgu_7_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 8:</span>{"  "}{translate("cgu_8_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 9:</span>{"  "}{translate("cgu_9_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 10:</span>{"  "}{translate("cgu_10_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 11:</span>{"  "}{translate("cgu_11_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 12:</span>{"  "}{translate("cgu_12_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_3_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_4_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_5_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_6_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_7_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_8_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_1_li")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_2_li")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_3_li")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_4_li")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_9_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 13:</span>{"  "}{translate("cgu_13_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 14:</span>{"  "}{translate("cgu_14_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_10_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_11_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_12_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 15:</span>{"  "}{translate("cgu_15_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 16:</span>{"  "}{translate("cgu_16_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 17:</span>{"  "}{translate("cgu_17_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_13_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 18:</span>{"  "}{translate("cgu_18_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 19:</span>{"  "}{translate("cgu_19_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_14_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_5_li")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_6_li")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_7_li")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_15_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 20:</span>{"  "}{translate("cgu_20_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_16_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 21:</span>{"  "}{translate("cgu_21_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_17_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 22:</span>{"  "}{translate("cgu_22_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_18_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 23:</span>{"  "}{translate("cgu_23_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_19_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 24:</span>{"  "}{translate("cgu_24_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_20_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 25:</span>{"  "}{translate("cgu_25_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_21_par")}
                </p>
                <br/>
                <p className="text-2xl">
                    <span className="text-3xl underline text-red-500">Article 25:</span>{"  "}{translate("cgu_25_art")}
                </p>
                <br/>
                <p className="text-2xl">
                    {translate("cgu_22_par")}
                </p><br/>
                <div className="flex justify-around ">
                    <button className="bg-green-900 p-16 text-white rounded-2xl font-bold text-2xl" onClick={()=>{
                        setCguAccepted(true);
                        history(-1, {replace: true});  }
                    }>{translate('accept')}</button>
                    <button className="bg-red-900 p-16 text-white rounded-2xl font-bold text-2xl" onClick={() => {
                        setCguAccepted(false);
                        history(-1, {replace: true});  }
                    }>{translate('decliner')}</button>
                </div>
            </div>
        </I18nPropvider>
    );
}