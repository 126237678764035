import toast from "react-hot-toast";
import translate from "../../../../i18n/translate";
import {sleep} from "../../../../utils/functions";

export default async (handleSetIsFinishedUpload, handleSetSubmitting, isFinishedUpload) => {
  // handleSetConnected(true);
  handleSetIsFinishedUpload(true);
  handleSetSubmitting(false);

  toast.success(translate("uploadSingleSucess"), {
    position: "bottom-left",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
  });
}