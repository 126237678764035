import {formatTime, getAudioDuration} from "../../../../utils/functions";
import AddIco from '../../../../assets/new_images/songUploadAdd.svg'
import play from '../../../../assets/new_images/play.svg'
import pause from '../../../../assets/new_images/pause.svg'
import MoreOptions from '../../../../assets/new_images/songUploadOptions.svg'
import styles from '../styles/album.module.css'
import {useState} from "react";
import { usePlaying } from "../../../../datamanager/contexts/playing";
import { PauseSharp, PlayArrowSharp } from "@mui/icons-material";

export default function SingleElt({ single, index }) {
  const [duration, setDuration] = useState(0);
  const { playing, handleSetPlaying } = usePlaying();
  getAudioDuration(single.src).then(duration => {
    setDuration(duration)
  })

  return (
    <div className={styles.singles_elt}>
      <div className={styles.singles_elt_left}>
        <div className={styles.singles_elt_index}>{index+1}</div>
        <p className={styles.singles_elt_title}>{single.title}</p>
      </div>
      <div className={styles.singles_elt_right}>
        <span className={styles.singles_elt_duration}>{formatTime(duration)}</span>
        <img src={AddIco} alt="addIcon" className={styles.singles_elt_add}/>
       <img src={pause} alt="pause" className={styles.singles_elt_add}
              onClick={() => {
                console.log('ttttttt',single);
                handleSetPlaying({
                  single: single
              });
              }}
              />
       {/*  { playing?.isGoing ?
              <img src={pause} alt="pause" className={styles.singles_elt_add}
              onClick={() => {
                console.log('ttttttt',single);
                handleSetPlaying({
                  isGoing: !playing.isGoing,
                  single: single
              });
              }}
              />
              : <img src={play} alt="play" className={styles.singles_elt_add}
                onClick={() => {
                  handleSetPlaying({
                    isGoing: playing ? !playing.isGoing : true,
                    single: single
                });
                  }
                }
              />
            } */}
        <img src={MoreOptions} alt="moreOptions" className={styles.singles_elt_more}/>
      </div>
    </div>
  );
}