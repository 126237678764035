import { useEffect, useState } from "react";
import { usePlaying } from "../../datamanager/contexts/playing";

export default function ProgressAudioBar() {
  const { audio } = usePlaying();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (audio) {
      const updateProgress = () => {
        const calculatedProgress = (audio.currentTime / audio.duration) * 100;
        setProgress(calculatedProgress);
      };

      // Add event listener for the timeupdate event
      audio.addEventListener("timeupdate", updateProgress);

      // Cleanup function to remove the event listener
      return () => {
        audio.removeEventListener("timeupdate", updateProgress);
      };
    }
  }, [audio]);

  return (
    <div className="playpreview_progressbar">
      <div className="playpreview_progressbar_fill" style={{ width: `${progress}%` }}></div>
    </div>
  );
}
