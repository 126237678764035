import React, {createContext, useCallback, useContext, useState} from 'react';

const RealtimeSwitchContext = createContext();

export const useRealtimeSwitchContext = () => useContext(RealtimeSwitchContext);

export const RealtimeSwitchProvider = ({ children }) => {
  const [isRealtime, setRealtime] = useState(1);

  const handleSwitchRealtime = useCallback((value) => {
    if (value !== isRealtime) {
      setRealtime(value);
    }
  }, [isRealtime]);

  return (
    <RealtimeSwitchContext.Provider value={{ isRealtime, handleSwitchRealtime }}>
      {children}
    </RealtimeSwitchContext.Provider>
  );
};
