import React from 'react'

import './join.scss'
import Button from '../../components/Button/Button'
import arrowTopRight from '../../assets/icons/arrowTopRight.svg'
import join from '../../assets/illustrations/join.png'
import join_mobile from '../../assets/illustrations/join_mobile.png'
import { useMediaQuery } from '@mui/material'
import translate from '../../i18n/translate'

const Join = () => {
    const ismobile = useMediaQuery('(max-width: 768px)');
    return (
        <main className='join-us w-full flex flex-col items-center justify-center'>
            <h2>{translate('suppp')}</h2>
            <p>{translate('subsuppp')}</p>


            <a href="/app/access"><Button text={translate('getprem')} icon={<img src={arrowTopRight} alt="arrow" />} bg='transparent' border='1px solid white' /></a>

            <img src={ismobile ? join_mobile: join} className="join" alt="join" />
        </main>
    )
}

export default Join