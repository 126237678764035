import { useSwitchUnderlinedContext } from "../../datamanager/contexts/locals/switchUnderlined";
import SearchBar from "../../pages/app/posts/components/SearcBar";
import SelectInput from "../SelectInput";

export default function SwitchUnderlined({ values }) {

  const { selected, handleSwitchChange } = useSwitchUnderlinedContext();

  return (
    <div className="topfan-titleee">
      <aside>
        {
          values.map((value, index) => (
            <span key={index} className={selected === index + 1 ? "topfan-title" : `topfan-title2`}
              onClick={() => handleSwitchChange(index + 1)}>{value}</span>))
        }
      </aside>

      <div className="search-cont gap-4 flex">
        <SearchBar searchPlaceholder={"Rechercher un single"} />
        <span className="order">Classer par</span>

        <SelectInput optionList={[{ name: 'Popularité' }, { name: 'Ventes' }, { name: 'Date' }]} setFieldValue={null}
          inputPlaceholder={'Popularité'}
          value={null} />
      </div>
    </div>
  );
}