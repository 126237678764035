import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import "../../styles/App.css";
import { Link } from "react-router-dom";
import uil from "../../assets/Uploading-rafiki.png";
import { SpinnerCircularFixed } from "spinners-react";
import useUser from "../../hooks/useUser";
import Audiotables from "../../components/audiotables";
import EpisodesContext from "../../datamanager/contexts/episodes";
import PodcastsContext from "../../datamanager/contexts/podcasts";

function Royalties({ isActive }) {
  // Get data from global context
  const { episodes } = useContext(EpisodesContext)
  const { podcasts } = useContext(PodcastsContext)
  
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (episodes && podcasts) {
      setLoading(false)
    }
  }, [episodes, podcasts])

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center h-screen">
          <SpinnerCircularFixed
            size={50}
            thickness={121}
            speed={150}
            color="rgba(133, 19, 78, 1)"
            secondaryColor="rgba(231, 208, 220, 1)"
          />
        </div>
      ) : podcasts.count || episodes.count > 0 ? (
        <div className="flex flex-col items-start justify-start w-full min-h-screen p-5">
          {/* Entetet */}
          <div className="flex flex-col items-start justify-start w-full mt-20">
            <h1 className="text-5xl font-bold text-slate-500 text-left">
              Redevances
            </h1>
            <h4 className="text-xs font-medium text-left text-gray-500 mt-2">
              Suivez l'argent genere par votre contenu sur Colorfol Artist
            </h4>
          </div>

          {/* Hero */}
          <div className="rdc flex flex-col items-center justify-start  shadow rounded-lg   my-12  overflow-clip">
            <div className="flex items-center justify-between w-full h-3/4 pl-6 pt-3 pb-14">
              <div className="flex flex-col h-full w-2/4 p-1">
                <h3 className="text-sm font-normal text-gray-400 mb-1.5">
                  Paiement le plus recent
                </h3>
                <div className="flex items-center justify-start">
                  <h1 className="text-4xl text-slate-600 font-bold">
                    0.000 FCFA
                  </h1>
                  <div className="flex items-center justify-center bg-green-600 w-12 h-6 px-1 py-0.5 mx-2 rounded text-white text-xs font-semibold">
                    Soldé
                  </div>
                </div>
                <h4 className="text-xs my-1 font-normal text-slate-100">
                  Envoyé le 28 mars 2022.
                </h4>
              </div>

              <div className="flex flex-col h-full w-2/4 p-1">
                <h3 className="text-sm font-normal text-gray-400 mb-1.5">
                  Prochain paiement
                </h3>
                <div className="flex items-center justify-start">
                  <h1 className="text-4xl text-gray-400 font-bold">
                    0.000 FCFA
                  </h1>
                  <div className="flex items-center justify-center bg-gray-300 w-12 h-6 px-1 py-0.5 mx-2 rounded text-white text-xs font-semibold">
                    Estimé
                  </div>
                </div>
                <h4 className="text-xs my-1 font-normal text-gray-300">
                  Sera envoyé le 30 mai 2022.
                </h4>
              </div>
            </div>
            <div className="flex items-center justify-start w-full h-1/4 bg-gray-100"></div>
          </div>

          {/* Par album */}
          {podcasts.count > 0 ? (
            <>
              <div className="flex items-center justify-between  w-full">
                <h1 className="text-3xl font-bold text-slate-500 text-left">
                  Par podcasts
                </h1>
                <div className="flex items-center justify-start">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={1.5}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <select className="flex items-center justify-center border border-2 rounded-lg border-gray-200 p-2 pr-1 text-sm font-medium mx-2">
                    <option>Mai 2022</option>
                    <option>Avril 2022</option>
                    <option>Mars 2022</option>
                    <option>Fevrier 2022</option>
                    <option>Janvier 2022</option>
                  </select>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-4  rounded-lg w-full h-auto py-10">
                {podcasts.results.map((album, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-start justify-start w-full"
                  >
                    <div className="h-36 w-36 ">
                      <img src={album.cover} className="object-cover" />
                    </div>
                    <h1 className="text-xl font-semibold text-slate-500 mt-1.5">
                      {album.title}
                    </h1>
                    <h3 className="text-base font-medium text-slate-300 mt-1">
                      {album.price}
                    </h3>
                  </div>
                ))}
              </div>
            </>
          ) : null}

          {/* Par single */}
          <div className="flex items-center justify-between  w-full">
            <h1 className="text-3xl font-bold text-slate-500 text-left">
              Par episodes
            </h1>
            <div className="flex items-center justify-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={1.5}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <select className="flex items-center justify-center border border-2 rounded-lg border-gray-200 p-2 pr-1 text-sm font-medium mx-2">
                <option>Mai 2022</option>
                <option>Avril 2022</option>
                <option>Mars 2022</option>
                <option>Fevrier 2022</option>
                <option>Janvier 2022</option>
              </select>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4  rounded-lg w-full h-auto py-10">
            {episodes.results.map((album, index) => (
              <div
                key={index}
                className="flex flex-col items-start justify-start w-full"
              >
                <div className=" h-36 w-36 ">
                  <img src={album.cover} className="object-cover" />
                </div>
                <h1 className="text-xl font-semibold text-slate-500 mt-1.5">
                  {album.title}
                </h1>
                <h3 className="text-base font-medium text-slate-300 mt-1">
                  {album.price} FCFA
                </h3>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          <img src={uil} className="esi" />
          <span className="est">Veuillez d'abord publier du contenu!</span>
          <Link exact to="/app/publishPodcasts" className="link">
            <span className="escta">Publiez votre premier contenu</span>
          </Link>
        </>
      )}
    </>
  );
}
export default Royalties;
