
import Button from '../../components/Button/Button'
import translate from '../../i18n/translate'
import './contact.scss'
const Contact = () => {
    return (
        <main className='contact-us flex flex-col justify-center items-center z-50'>
            <h4>{translate('knowmore')}</h4>
            <p>{translate('conta')}</p>
            <a href="https://wa.me/+33605875704" target='_blank' rel="noreferrer"><Button text={translate('contactus')} /></a>
        </main>
    )
}

export default Contact