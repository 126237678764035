import {SpinnerCircularFixed} from "spinners-react";
import React from "react";
import {Skeleton} from "@mui/material";
import GreedUser from "../pages/app/dashboard/components/GreedUser";
import translate from "../i18n/translate";

export default function Loader() {

  return (
    <div className="main-dash">
      <GreedUser/>
      <div className="datas">
        <div className="main-side">
          <span className="main-side-title">{translate('yourdata')}</span>
          <div className="monit">
            <div className="monit-elt">
              <div className="monit-elt-top">
                <span className="monit-title">{translate("Lectures")}</span>
              </div>
              <Skeleton className={"monit-main"} variant={"text"} animation={"wave"} sx={{bgcolor: "#141414"}}/>
              <Skeleton className={"monit-foot"} variant={"text"} animation={"wave"} sx={{bgcolor: "#141414"}}/>
            </div>
            <div className="monit-elt">
              <div className="monit-elt-top">
                <span className="monit-title">{translate("musicSales")}</span>
              </div>
              <Skeleton className={"monit-main"} variant={"text"} animation={"wave"} sx={{bgcolor: "#141414"}}/>
              <Skeleton className={"monit-foot"} variant={"text"} animation={"wave"} sx={{bgcolor: "#141414"}}/>
            </div>
            <div className="monit-elt">
              <div className="monit-elt-top">
                <span className="monit-title">{translate("IncomesEstimation")}</span>
              </div>
              <Skeleton className={"monit-main"} variant={"text"} animation={"wave"} sx={{bgcolor: "#141414"}}/>
              <Skeleton className={"monit-foot"} variant={"text"} animation={"wave"} sx={{bgcolor: "#141414"}}/>
            </div>
            <div className="monit-elt">
              <div className="monit-elt-top">
                <span className="monit-title">{translate("relistenningRate")}</span>
                <Skeleton className={"monit-elt-ico"} variant={"circular"}  width={"2rem"} height={"2rem"} animation={"wave"}
                          sx={{bgcolor: "#141414"}}/>
              </div>
              <Skeleton className={"monit-main"} variant={"text"} animation={"wave"} sx={{bgcolor: "#141414"}}/>
              <Skeleton className={"monit-foot"} variant={"text"} animation={"wave"} sx={{bgcolor: "#141414"}}/>
            </div>
          </div>
          <div className={"main-side-elt"}>
            <span className="main-side-title">{translate('ouraudience')}</span>
            <div className="audi">
              <div className="topsing">
                <span className="topsingtitle">
                  {translate('totallistenning')}
                </span>
                <Skeleton className={"topsingval"} variant={"text"} animation={"wave"} sx={{bgcolor: "#141414"}}/>
                <div className="topsingtitle">
                  <div className="audii">
                    <div className="audi-l">
                      <Skeleton className={"topsingvalimg"} variant={"circular"}  width={"2rem"} height={"2rem"} animation={"wave"}
                                sx={{bgcolor: "#141414"}}/>
                      <Skeleton className={"topsingvaldescp"} variant={"text"} animation={"wave"}
                                sx={{bgcolor: "#141414"}}/>
                    </div>
                  </div>
                </div>
                <Skeleton variant={"rounded"}  animation={"wave"} width={"100%"} height={"300px"}
                          sx={{bgcolor: "#141414"}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}