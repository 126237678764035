import {useContext, useEffect, useState} from "react";
import CurrentUserContext from "../datamanager/contexts/currentUser";
import {useAlbumCategories} from "../datamanager/contexts/locals/albumCategory";
import MusicApi from "../api/musics";

export default function useFetchAlbumCategories() {

  const { currentUser } = useContext(CurrentUserContext);
  const { handleSetAlbumCategories } = useAlbumCategories();
  const [page, setPage] = useState(1);

  // UseEffect section
  useEffect(() => {
    if (currentUser) {
      handleFetchStats().then(() => {});
    }
  }, [currentUser])

  const handleFetchStats = async () => {
    const token = localStorage.getItem("access_token");
    const musicApi = new MusicApi(token);

    let response;
    let allData = [];

    do {
      response = await musicApi.getMusicCategories(page);
      const data = response.data;

      if (data) {
        allData = [...allData, ...data];
        setPage(page + 1);
      } else {
        break;
      }
    } while (response.next);
    setPage(1);
    handleSetAlbumCategories(allData);
  };

}