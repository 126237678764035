import "./styles/InputField.css";
export default function InputField({inputLabel, inputType, inputName, inputPlaceholder, inputEndIcon, required, onChange, value, bgColor="#212018"}) {

  const style = {
    background: bgColor
  }

  return (
    <div className={ `input-container ${style}` }>
      <p className="input-label">{inputLabel}{required ? <span className="input-required">*</span> : null}</p>
      <div className="input-field-container">
        <input
          className="input-field"
          type={inputType}
          name={inputName}
          placeholder={inputPlaceholder}
          onChange={onChange}
          value={value}
        />
        {inputEndIcon && <img alt={"inputEndIcon"} src={inputEndIcon} className={ inputEndIcon ? "input-end-icon" : "display:none;"}/>}
      </div>
    </div>
  )
}