import musicIcon from "../../assets/new_images/music_ico.svg";
import {formatTime} from "../../utils/functions";
import {useMetaData} from "../../datamanager/contexts/locals/publishAudio/metaData";
import CurrentUserContext from "../../datamanager/contexts/currentUser";
import {useContext} from "react";

export default function MusicInputHeader({ songSize, duraation, index }){

  const { metaData } = useMetaData();
  const { currentUser } = useContext(CurrentUserContext);

  return (
    <div className="musicinput_header">
      <div className="musicinput_header_title">
        <img alt={"music"} src={musicIcon} className="musicinput_header_title__icon"/>
        <span className="musicinput_header_title__text">{currentUser.alias} -- {metaData[index].title}</span>
      </div>
      <div className="musicinput_header_info">
        <span className="musicinput_header_info__size">{songSize}</span>
        <span className="musicinput_header_info__time">{formatTime(duraation)}</span>
      </div>
    </div>
  );
}