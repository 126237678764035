import { useContext, useEffect, useRef, useState } from 'react'
import { CameraSharp, CloudUpload } from 'react-ionicons'
import ModalContext from '../../../datamanager/contexts/modalContext'
import styles from '../styles/uploadPhoto.module.css'
import UserApi from '../../../api/users'
import CurrentUserContext from '../../../datamanager/contexts/currentUser'

const ProgressBar = ({ percentage }) => {
  return (
    <div className={styles.progressBarContainer}>
      <p className={styles.progressBarTitle}>En cours de chargement...</p>

      <div className={styles.progressBars}>
        <span className={styles.progressBarItem}>
          <span style={{ width: `${percentage}%` }} className={styles.progressBarContent} />
        </span>

        <p className={styles.progressBarPercentage}>{ percentage + "%" }</p>
      </div>
    </div>
  )
}

const UploadPhotoContentModal = () => {
  // Get data from global context
  const { modalData: { data }, closeModal } = useContext(ModalContext)
  const { currentUser: user, login: updateUser } = useContext(CurrentUserContext)

  const [image, setImage] = useState(null)
  const [file, setFile] = useState(null)
  const [percentage, setPercentage] = useState(0)
  const [uploading, setUploading] = useState(false)

  // UseRef section
  const fileInput = useRef(null)

  useEffect(() => {
    if (data) {
      const { file } = data

      if (file) {
        setFile(file)

        const image = URL.createObjectURL(file)

        setImage(image)
      }
    }
  }, [data])

  //  Some handlers
  const handleOpenFileInput = () => {
    fileInput.current.click()
  }

  const handleGetPhoto = () => {
    const file = fileInput.current.files[0]

    if (file) {
      setFile(file)

      const image = URL.createObjectURL(file)

      setImage(image)
    }
  }

  // handle upload photo
  const handleUploadPhoto = async () => {
    // progressive loading
		const uploadOption = {
			onUploadProgress: (progressEvent) => {
				const { loaded, total } = progressEvent

				const percentage = Math.floor((loaded * 100) / total)
				setPercentage(percentage)
			}
		}

    if (file) {
      setUploading(true)

      const formData = new FormData()
  
      formData.append("photo", file)
  
      const token = localStorage.getItem("access_token")
  
      const userAPI = new UserApi(token)

      const response = await userAPI.updateProfile(user.id, formData, uploadOption)

      if (response.data) {        
        const { data } = await userAPI.getCurrent()

        if (data) {
          updateUser(data)
        }

        closeModal()
      }

      setUploading(false)
      setPercentage(0)
    }
  }

  return (
    <div className={styles.uploadContainer}>
      <img src={image} alt="Uploaded image" className={styles.image} />

      <div className={styles.controls}>
        <div className={styles.controlItem} onClick={handleOpenFileInput}>
          <CameraSharp color="#fff" size={40} />
          <span> Changer </span>
        </div>

        <div className={styles.controlItem} onClick={handleUploadPhoto}>
          <CloudUpload color="#fff" size={40} />
          <span> Enregistrer </span>
        </div>
      </div>

      <input ref={fileInput} type="file" hidden onChange={handleGetPhoto} accept="image/*" />

      {
        uploading && <ProgressBar percentage={percentage} />
      }
    </div>
  )
}

export default UploadPhotoContentModal