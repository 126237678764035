import { useContext, useEffect } from "react"
import CurrentUserContext from "../datamanager/contexts/currentUser"
import EpisodesContext from "../datamanager/contexts/episodes"
import EpisodeAPI from "../api/podcast"

const useEpisodes = () => {
  // Get data from the global context
  const { addEpisodes } = useContext(EpisodesContext)
  const { currentUser } = useContext(CurrentUserContext)

  // UseEffect section
  useEffect(() => {
    if (currentUser) {
      handleFetchEpisodes().then(
        (result) => {
          console.log(result)
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }, [currentUser])

  const handleFetchEpisodes = async () => {
    const token = localStorage.getItem("access_token")

    const episodeApi = new EpisodeAPI(token)

    const response = await episodeApi.getAllEpisodes(currentUser.id)

    if (response.data) {
      addEpisodes(response.data)
    }
  }
}

export default useEpisodes