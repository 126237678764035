import React from 'react'
import users from '../../../assets/icons/users.svg'
import up from '../../../assets/icons/up.svg'
import down from '../../../assets/icons/down.svg'

import './summary_card.scss'
const SummaryCard = ({ title, image, stat, direction, rating, comments }) => {
    return (
        <div className='summary_card'>
            <div>
                <span>{title}</span>
                {
                    image &&
                    <img src={image} alt={title + 'image'} />
                }
            </div>
            <p>{stat}</p>
            {
                comments &&
                <aside>
                    {
                        comments.map((val, id) => (
                            <aside key={id}>
                                <p>{val.score}</p>
                                <p>{val.comment}</p>
                            </aside>
                        ))
                    }

                </aside>
            }
            <div>
                {
                    direction &&
                    <img src={direction === 'up' ? up : down} alt={direction === 'up' ? 'up' : 'down'} />
                }
                <span className='ml-2'>{rating}</span>
            </div>
        </div>
    )
}

export default SummaryCard