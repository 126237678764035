import React, { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import styles from '../styles/dashboard.module.css'
import { useCallback } from 'react';
import {borderRadius} from "tailwindcss/lib/plugins";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const labels = [
  {
    name: 'Janvier',
    value: 31
  },
  {
    name: 'Fevrier',
    value: 29
  },
  {
    name: 'Mars',
    value: 31
  },
  {
    name: 'Avril',
    value: 30
  },
  {
    name: 'Mai',
    value: 31
  },
  {
    name: 'Juin',
    value: 30
  },
  {
    name: 'Juillet',
    value: 31
  },
  {
    name: 'Aout',
    value: 31
  },
  {
    name: 'Septembre',
    value: 30
  },
  {
    name: 'Octobre',
    value: 31
  },
  {
    name: 'Novembre',
    value: 30
  },
  {
    name: 'Decembre',
    value: 31
  }
];

export const data = {
  labels: labels.map(label => label.name),
  datasets: [
    {
      fill: true,
      label: 'Les ecoutes',
      data: labels.slice(0, 12).map(() => Math.floor(Math.random() * 100000)),
      borderColor: '#7c2c55',
      backgroundColor: 'rgb(243, 231, 237, 0.6)',
    },
  ],
};

const AreaChart = () => {
  const [filters, setFilters] = useState({
    type: "year",
    value: "2022"
  })

  // Some handlers
  const handleChange = (type) => {
    const value = type === "year" ? "2022" : "Janvier";

    setFilters(prev => ({ ...prev, type, value }));
  }

  const handleChangeValue = (value) => {
    setFilters(prev => ({ ...prev, value }));
  }

  // Get number of days of a month from the labels
  const getMonthNumber = (month) => {
    return labels.find(label => label.name === month).value;
  }

  // Generate array of numbers from a number
  const generateArray = (number) => {
    const array = [];
    for (let i = 0; i < number; i++) {
      array.push(i+1);
    }
    return array;
  }

  // Generate data for the chart
  const generateData = useCallback(() => {
    const newLabels = filters.type === "year" ? labels.map(label => label.name) : generateArray(getMonthNumber(filters.value));

    return {
      labels: newLabels,
      datasets: [
        {
          fill: false,
          label: 'Les ecoutes',
          data: labels.slice(0, 12).map(() => Math.floor(Math.random() * 100000)),
          borderColor: '#7c2c55',
          backgroundColor: 'rgb(243, 231, 237, 0.6)',
          tension:0.4,
        },
        {
          fill: false,
          label: 'Les ecoutes',
          data: labels.slice(0, 12).map(() => Math.floor(Math.random() * 100000)),
          borderColor: '#28772f',
          backgroundColor: 'rgb(243, 231, 237, 0.6)',
          tension:0.4,
        },
      ],
    }
  }, [filters])

  // Generate options for the chart
  const generateOption = useCallback(() => {
    const label = filters.type === "year" ? `Nombre d'ecoutes durant l'année ${filters.value}` : `Nombre d'ecoutes durant le mois de ${filters.value}`;

    const options = {
      responsive: true,
      radius: 8,
      pointHitRadius: 20,
      plugins: {
        legend: {
          display: true,
          position: 'top',
          align:'end',
          labels : {
            boxWidth: 10,
            boxHeight: 5,
            usePointStyle: true,
          }
        },
        title: {
          display: false,
          text: label,
        },
      },
      maintainAspectRatio: false,
    };

    return options
  }, [filters])

  const htmlLegendPlugin = {
    id: "htmlLegend",
    afterUpdate(chart) {
      const items = chart.options.plugins.legend.labels.generateLabels(chart);
      const ul = document.createElement("ul");
      items.forEach(item => {
        const li = document.createElement("li");
        const boxSpan = document.createElement("span");
        boxSpan.style.background = item.fillStyle;
        li.appendChild(boxSpan);
        li.appendChild(document.createTextNode(item.text));
        ul.appendChild(li);
      });
      const jsLegend = document.getElementById("js-legend");
      jsLegend.appendChild(ul);
    }
  };

  return (
      <div className={styles.areaChartContainer} >
        <Line options={generateOption()} data={generateData()} height={300} />
      </div>
  )
}

export default AreaChart