import UploadSongListElt from "../UploadSongListElt";
import React from "react";
import {useSelectedAudio} from "../../datamanager/contexts/locals/publishAudio/selectedAudio";
import {useMetaData} from "../../datamanager/contexts/locals/publishAudio/metaData";
import {getAudioDuration} from "../../utils/functions";
import translate from "../../i18n/translate";

export default function UploadSongList2(){

  const { selectedAudio } = useSelectedAudio();
  const { metaData } = useMetaData();

  return (
    <div className="publish_audio_end_container_col2_container_list">
      {selectedAudio && selectedAudio.map((item, index) => (
        <UploadSongListElt key={index} songName={metaData[index].title}
                           songDuration={getAudioDuration(URL.createObjectURL(item))} index={index}/>
      ))}
    </div>
  );
}