import styles from "./styles/profile.module.css";
import profilePic from "../../../assets/images/artist1.jpg";
import { Calendar, Mail, Pencil, People, Person } from "react-ionicons";
import { useContext, useRef, useState } from "react";
import CurrentUserContext from "../../../datamanager/contexts/currentUser";
import ModalContext from "../../../datamanager/contexts/modalContext";
import userApi from "../../../api/users";
import MusicApi from "../../../api/musics";
import UserApi from "../../../api/users";

const Profile2 = () => {
  // Get data from the context
  const { currentUser: user } = useContext(CurrentUserContext);
  const { openModal } = useContext(ModalContext);

  // Set local state
  const [hover, setHover] = useState(false);

  // UseRef section
  const fileInput = useRef(null);

  // Some functions
  const getSex = (sex) => {
    if (sex?.toLowerCase() === "male") return "Masculin";
    else if (sex?.toLowerCase() === "female") return "Feminin";

    return "";
  };

  const handleOpenModal = () => {
    openModal("Profile", "Editer le profil");
  };

  const handleHover = (value) => {
    setHover(value);
  };

  const token = localStorage.getItem("access_token");

  const handleUpdateProfilePicture = async (file) => {
    const userApi = new UserApi(token);

    const formData = new FormData();

    formData.append("id", user.id);
    formData.append("title", user.title);
    formData.append("photo", file);

    const response = await userApi.updateProfilePic(user.id, formData);
  };

  const handleGetPhoto = () => {
    handleOpenFileSystem()
    const file = fileInput.current.files[0];

    if (file) {
      openModal("Upload", "Changer la photo de profil", { file });
      handleUpdateProfilePicture(file).then(r => {});
    }
  };

  const handleOpenFileSystem = () => {
    fileInput.current.click();
  }

  const getBirthday = (date) => {
    const birthday = new Date(date);
    const months = [
      "Janvier",
      "Fevrier",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Aout",
      "Septembre",
      "Octobre",
      "Novembre",
      "Decembre",
    ];

    const year = birthday.getFullYear();
    const month = months[birthday.getMonth()];
    const day = birthday.getDate();

    return `${day} ${month} ${year}`;
  };

  return (
    <section className={styles.profileContainer}>
      <div className={styles.profileTop}>
        <div
          className={styles.profilePicContainer}
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
        >
          <img src={user.photo} className={styles.profilePic} />

          <div
            className={`${styles.profilEditIcon} ${
              hover ? styles.profilEditIconTranslate : ""
            }`}
            onClick={handleGetPhoto}
          >
            <Pencil size={50} color="#85134e" />
          </div>
        </div>

        <input ref={fileInput} type="file" hidden onChange={handleGetPhoto} accept="image/*" />

        <div className={styles.profilInfo}>
          <span className={styles.profileName}>{user.alias}</span>
        </div>

        <button className={styles.profileEdit} onClick={handleOpenModal}>
          Editer le profile
        </button>
      </div>

      {/*<div className={styles.profileBottom}>*/}
      {/*  <div className={styles.profileInfoItem}>*/}
      {/*    <Mail color="#888" size={25} />*/}
      {/*    <span className={styles.profileInfoTitle}>{user.email}</span>*/}
      {/*  </div>*/}

      {/*  <div className={styles.profileInfoItem}>*/}
      {/*    <Calendar color="#888" size={25} />*/}
      {/*    <span className={styles.profileInfoTitle}>*/}
      {/*      {getBirthday(user.birthday)}*/}
      {/*    </span>*/}
      {/*  </div>*/}

      {/*  <div className={styles.profileInfoItem}>*/}
      {/*    <Person color="#888" size={25} />*/}
      {/*    <span className={styles.profileInfoTitle}>{getSex(user.sex)}</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </section>
  );
};

export default Profile2;
