import {MyDropCoverZone} from "../dropImage";
import {MyDropzone} from "../dropZone";
import FileUploadDirectives from "./FileUploadDirectives";
import React from "react";
import translate from "../../i18n/translate";
import FileUploadDirectivesAlbum from "./FileUploadDirectivesAlbum";

export default function DropFilesZones({ errors, setFieldValue, isValid, text1, text2, values }){

  return (
    <div className="publish_audio_form_fields_col1">
      <span className="publish_audio_form_fields_col1__title">{text1}<span className="input-required">*</span></span>
      <FileUploadDirectives/>
      <MyDropCoverZone setFieldValue={setFieldValue} text={translate("dragAndDropYourCoverHereOrChooseOne")}
                       textSucess={translate("dragCoverSucess")}/>
      {/*{errors.cover ? (<span className="emt">*/}
      {/*                                    {errors.cover}*/}
      {/*                                  </span>) : null}*/}
      <span className="publish_audio_form_fields_col1__title">{text2}<span className="input-required">*</span></span>
      <FileUploadDirectivesAlbum/>
      <MyDropzone setFieldValue={setFieldValue} maxFiles={ 200 }
                  text={ translate("dragAndDropFilesHereOrChooseFiles") }
                  textSucess={translate("dragFIleSucess")} values={values} />
      {/*{errors.src ? (<span className="emt">*/}
      {/*                                    {errors.src}*/}
      {/*                                  </span>) : null}*/}
    </div>
  );
}