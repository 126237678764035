import { useEffect, useRef, useState} from "react";
import {useSelectedAudio} from "../../datamanager/contexts/locals/publishAudio/selectedAudio";
import UploadSongListElt from "./UploadSongListElt";
import {arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {useMetaData} from "../../datamanager/contexts/locals/publishAudio/metaData";

export default function UploadSongList(values) {

  const { selectedAudio, handleReorderAudio } = useSelectedAudio();
  const { handleReorderMetaData } = useMetaData();

  const [items, setItems] = useState([]);
  const listRef = useRef(null);

  useEffect(() => {
    if(items.length === 0){
      console.log(`on effect ${items.length}`)
       // Initialize items with the length of selectedAudio
      const initialItems = selectedAudio && selectedAudio.length > 0 && selectedAudio.map((_, index) => String(index));
      setItems(initialItems);
      //3️⃣ bring the last item into view
      listRef.current?.lastElementChild?.scrollIntoView();
    }
  }, [selectedAudio]);

  // console.log(metaDataSave);

  const handleDragEnd = (event) => {
    const { active, over } = event;


    if (active.id !== over.id) {

      handleReorderMetaData(active.id, over.id);

      handleReorderAudio(active.id, over.id);

      setItems((items) => {
        const oldIndex = Number(active.id);
        const newIndex = Number(over.id);
        const newArray = arrayMove(items, oldIndex, newIndex);
        console.log(`Execution ${oldIndex}->${newIndex} == ${newArray}`)
        return newArray
      });

    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  return (
    <div className="publish_audio_form_medias__audios"  ref={listRef}>
      <DndContext
        collisionDetection={closestCenter}
        sensors={sensors}
        onDragEnd={handleDragEnd}
      >
        {selectedAudio && selectedAudio.length > 0 &&
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {selectedAudio.map((audio, index) => (
              <UploadSongListElt
                audio={audio}
                key={index}
                index={index}
                total={selectedAudio.length}
                values={values} />
            ))}
        </SortableContext>
        }
      </DndContext>
    </div>
  );
}

