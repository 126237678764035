import { useState } from "react";
import AlbumsContext from "../contexts/albums";

const AlbumsProvider = ({ children }) => {
  const [albums, setAlbums] = useState(null);

  // Some handlers
  const addAlbums = (albums) => {
    setAlbums(albums);
  }

  const updateAlbums = (albums) => {
    setAlbums(albums);
  }

  const contextValue = {
    albums,
    addAlbums,
    updateAlbums
  }

  return (
    <AlbumsContext.Provider value={contextValue}>
      { children }
    </AlbumsContext.Provider>
  )
}

export default AlbumsProvider