import React from "react";
import LeftBarDatas1 from "./LeftBarDatas1";
import Comments from "../../pages/app/posts/components/Comments";
import LeftBarDatas5 from "../DashSideCards/LeftBarData5/LeftBarDatas5";
import LeftBarDatas6 from "../DashSideCards/LeftBarData6/LeftBarData6";
import LeftBarDatas3 from "../DashSideCards/LeftBarData3/LeftBarData3";

export default function SubMenuLeftBar({ subMenu }) {

  switch (subMenu) {
    case 1:
      return <LeftBarDatas1 />;
    case 2:
      return <LeftBarDatas1 />;
    case 3:
      return <></>;
      // return <LeftBarDatas3 />;
    case 5:
      return <LeftBarDatas5 />;
    case 6:
      return <LeftBarDatas6 />;
    case 9:
      return <Comments />;
    default:
      return <></>;
  }
}