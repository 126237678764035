import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import "../../../styles/App.css";
import logo from "../../../assets/colorfol artists.png";
import aw from "../../../assets/Rock band-pana.png";
import { Link } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-input-2";
import fr from "react-phone-number-input/locale/fr.json";
import CountrySelect from "../../../components/CounrtySelect";
import {EyeOutline, EyeOffOutline, RocketSharp, CheckmarkCircle, Warning} from "react-ionicons";
import * as Yup from "yup";
import { HashLink as Linka } from "react-router-hash-link";
import styles from "./styles/SignUp.module.css";
import { I18nPropvider, LOCALES } from "../../../i18n";
import Navbar from "../../home/components/Navbar";
import MobileMenu from "../../home/components/NavbarMobile";
import {SpinnerCircularFixed} from "spinners-react";
import UserApi from "../../../api/users";
import toast from "react-hot-toast";
import {SignupSchema} from "./SignUpSchema";

function LogLabel({ cguAccepted }) {
  const [pass, setPass] = useState(true);
  const [language, setLanguage] = useState(LOCALES.FRANCAIS);
  const [languageList, setLanguageList] = useState([]);
  const [open, setOpen] = useState(false);
  const [connected, setConnected] = useState(false);
  const [backErr, setBackErr] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [msg, setMsg] = useState(
      "Oupss...une erreur est survenue ! Verifier votre connection internet."
  );

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  // Some handlers
  const handleSubmit = async (values) => {
    setIsSubmitting(true);

    const token = localStorage.getItem("access_token");

    const userApi = new UserApi(token);

    const formData = new FormData();

    for (const property in values) {
      formData.append(property, values[property]);
    }

    formData.append("is_label", true);

    const response = await userApi.register(formData);

    if (!response.data) {
      setMsg("Oupss...une erreur est survenue !");

      const errors = Object.values(response.error.response.data)

      errors.forEach(error => {
        toast.error(error[0], {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })

      await sleep(800);
      setIsSubmitting(false);
      setBackErr(true);
    } else {
      setConnected(true);

      localStorage.setItem("access_token", response.data.access_token);

      await sleep(1800);
      if (localStorage.getItem("access_token")) {
        document.location = "/app/dashboardLabel";
      }
    }
  };

  const verification = () => {
    for (let errorsKey in formik.errors) {

    }

    const isValid = formik.isValid && cguAccepted;
    return isValid;
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      username: null,
      email: null,
      phone: null,
      password1: null,
      password2: null,
      sex: "male",
      birthday: null,
      is_artist: false,
      is_blogger: false,
      is_evenementiel: false,
    },
    validationSchema: SignupSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    setLanguageList([
      { code: "Français" },
      { code: "English" },
      { code: "عرب" },
    ]);
  }, []);

  const updateHotLanguage = (event) => {
    setLanguage(event.target.value.code);
  };

  return (
    <I18nPropvider locale={language}>
      <div>
        <Navbar
          language={language}
          onUpdateHotLanguage={updateHotLanguage}
          languageList={languageList}
          onOpen={handleOpen}
        />
        <MobileMenu open={open} onClose={handleClose} />

        <div className={`imcd ${styles.logMusicContainer}`}>
          <div className={`spdc ${styles.logMusicFirst}`}>
            <div className="flex items-start justify-start tca">
              <RocketSharp
                color={"#85134e"}
                title={""}
                height="36px"
                width="36px"
              />
              <h2>Propulsez les perfomances de votre label avec Colorfol.</h2>
            </div>
            <span className="lsp">
              Découvrez comment Colorfol contribue à l'amelioration de la
              productivite des labels musicaux. Voyons ce que Colorfol va vous
              apporter.
            </span>
            <img src={aw} width="380px" height="360px" />
          </div>
          <div className={`clc ${styles.logMusicSecond}`}>
            <div className={`sp-card bsh ${styles.logMusicForm}`}>
              {isSubmitting ? (
                  <>
                    {connected ? (
                        <CheckmarkCircle
                            color={"rgba(133, 19, 78, 1)"}
                            title={"Connecte"}
                            height="50px"
                            width="50px"
                        />
                    ) : (
                        <SpinnerCircularFixed
                            size={50}
                            thickness={121}
                            speed={150}
                            color="rgba(133, 19, 78, 1)"
                            secondaryColor="rgba(231, 208, 220, 1)"
                        />
                    )}
                    <p className="load-titler">
                      {connected
                          ? "Bienvenu(e) sur  Colorfol Artist."
                          : "Enregistrement sur Colorfol Artist..."}
                    </p>
                  </>
              ) : backErr ? (
                  <>
                    <Warning
                        color={"rgba(133, 19, 78, 1)"}
                        title={"Connecte"}
                        height="56px"
                        width="56px"
                    />
                    <p className="load-titler">{msg}</p>
                    <span
                        className="mt-16 form-sub"
                        onClick={() => {
                          setBackErr(false);
                        }}
                    >
                    Ressayer
                  </span>
                  </>
              ) : (
                  <div className="px-52">
                    <h3 className="spc-title">
                      Entrez vos données afin de créer votre compte Colorfol
                      Artists.
                    </h3>

                    <div className={`spc-form ${styles.loginFormContainer}`}>
                      <form onSubmit={formik.handleSubmit}>

                        <div className={`form-bar ${styles.loginFormBar}`}>
                          <div className={`form-lb ${styles.loginFormItem}`}>
                            <label htmlFor="username">Nom du label</label>
                            <input
                                id="username"
                                name="username"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.username}
                            />
                            {formik.errors.username ? (
                                <span className="emt">
                              {formik.errors.username}
                            </span>
                            ) : null}
                          </div>

                          <div
                              className={`form-lb ml-20 ${styles.loginFormItem}`}
                          >
                            <label htmlFor="email">E-mail</label>
                            <input
                                id="email"
                                name="email"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                            />
                            {formik.errors.email ? (
                                <span className="emt">{formik.errors.email}</span>
                            ) : null}
                          </div>
                        </div>

                        <div className={`form-bar ${styles.loginFormBar}`}>
                          <div className={`form-lb ${styles.loginFormItem}`}>
                            <label htmlFor="password1">Mot de passe</label>
                            {!pass ? (
                                <EyeOutline
                                    className="form-icp"
                                    onClick={() => setPass(!pass)}
                                    color={"#00000"}
                                    title={""}
                                    height="24px"
                                    width="24px"
                                />
                            ) : (
                                <EyeOffOutline
                                    className="form-icp"
                                    onClick={() => setPass(!pass)}
                                    color={"#00000"}
                                    title={""}
                                    height="24px"
                                    width="24px"
                                />
                            )}

                            <input
                                id="password1"
                                name="password1"
                                type={pass ? "password" : "text"}
                                onChange={(e) => {
                                  formik.handleChange(e.target.value);
                                  formik.setFieldValue("password1", e.target.value);
                                  formik.setFieldValue("password2", e.target.value);
                                }}
                                value={formik.values.password1}
                            />
                            {formik.errors.password1 ? (
                                <span className="emt">
                              {formik.errors.password1}
                            </span>
                            ) : null}
                          </div>

                          <div
                              className={`form-lb ml-20 ${styles.loginFormItem}`}
                          >
                            <label htmlFor="phone">Téléphone</label>
                            <PhoneInput
                                id="phone"
                                name="phone"
                                className="input"
                                inputStyle={{ paddingLeft: "46px" }}
                                buttonStyle={{ borderWidth: 0 }}
                                country={"cm"}
                                value={formik.values.phone}
                                onChange={(
                                    phone,
                                    country,
                                    event,
                                    formattedValue
                                ) => {
                                  formik.handleChange(
                                      phone /*phone.slice(data.dialCode.length)*/
                                  );
                                  formik.setFieldValue("phone", phone);
                                  formik.setFieldValue(
                                      "user_country",
                                      country.name
                                  );
                                }}
                            />

                            {formik.errors.phone ? (
                                <span className="emt">{formik.errors.phone}</span>
                            ) : null}
                          </div>
                        </div>

                        <div className={`form-bar ${styles.loginFormBar}`}>
                          <div className={`form-lb ${styles.loginFormItem}`}>
                            <label htmlFor="birthday">Date de creation du label</label>
                            <input
                                id="birthday"
                                name="birthday"
                                className="li"
                                type="date"
                                placeholder="Entrer votre date de naissance"
                                onChange={formik.handleChange}
                                value={formik.values.birthday}
                            />
                            {formik.errors.birthday ? (
                                <span className="emt">
                              {formik.errors.birthday}
                            </span>
                            ) : null}
                          </div>
                        </div>

                        <div className={`form-subcta ${styles.loginFormBar}`}>
                          <input
                              className={`form-sub ${
                                  verification() ? "" : styles.btnDisable
                              }`}
                              type="submit"
                              value="S'inscrire"
                              disabled={verification() ? false : true}
                          />

                          <span className="corpus">
                          <input
                              type="checkbox"
                              id="conditions"
                              className="logMusicCheckbox form-link"
                              checked={cguAccepted} //conditionAccepted
                              // onChange={(e) =>
                              //   setConditionAccepted(e.target.checked)
                              // }
                          />
                          En cliquant sur le bouton d'inscription, vous
                          reconnaissez que vos données seront traitées
                          conformément à notre{" "}
                            <span className="form-link" htmlFor="conditions">
                            <Link to={{pathname:'/cgu'}}>
                              <label htmlFor="conditions" className="form-link">
                                Politique de confidentialité
                              </label>
                            </Link>
                          </span>
                          , et vous autorisez Colorfol à partager vos données
                          pour des études marketing.
                        </span>
                        </div>
                      </form>
                    </div>
                  </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </I18nPropvider>
  );
}

export default LogLabel;
