import {SpinnerCircularFixed} from "spinners-react";
import { useUploadPercentage } from "../../../../datamanager/contexts/locals/publishAudio/uploadPercent";


export default function AlbumLoader(){
  const { uploadPercentage } = useUploadPercentage();
  return (
    <div className="m-auto text-3xl text-white">
    
          <SpinnerCircularFixed
            size={20}
            thickness={80}
            speed={90}
            color="rgba(1, 0.337, 0.125, 1)"
            secondaryColor="rgba(255, 86, 32, 1)"
            className="m-auto justify-center"
          />{/* {uploadPercentage.toFixed(2)} % */}
         
    </div>
  );
}
