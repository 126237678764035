import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const SalesChart = ({ salesData, display_y, display_x=true, display_label=true, pointRadius = 5 }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (!chartRef.current || !salesData || !chartInstance.current){
      const ctx = chartRef.current.getContext('2d');
      const gradient = ctx.createLinearGradient(0, chartRef.current.clientHeight, 0, 0); // Linear gradient from bottom to top
      gradient.addColorStop(1, '#4C1909');
      gradient.addColorStop(0, 'rgba(76, 25, 9, 0)');

      chartInstance.current = new Chart(
        ctx,
        {
          type: 'line',
          data: {
            labels: salesData.months,
            datasets: [{
              label: 'Sales',
              data: salesData.amounts,
              fill: {
                target: 'origin',
                above: gradient,
              },
              borderColor: '#FF5620', // Curve color
              tension: 0.4, // Smooth curve
              pointStyle: 'circle', // Use circle as the point style
              pointRadius: pointRadius, // Increase the point radius for better visibility
              pointBackgroundColor: '#FF5620', // Fill color of the data points
              pointBorderColor: '#FF5620', // Border color of the data points
              pointBorderWidth: 1, // Border width of the data points
            }]
          },
          options: {
            scales: {
              x: {
                display: display_x,
                grid: {
                  display: false, // Hide grid lines
                },
                ticks: {
                  display: display_label,
                  color: 'gray', // Color of x-axis ticks
                  font: {
                    family: 'Arial', // Font family
                    size: 12, // Font size
                    weight: 'bold', // Font weight
                    color: "gray",
                  },
                },
                color: "#4c1909",
                axis: {
                  lineColor: '#4c1909', // Color of the x-axis line
                  arrow: {
                    enabled: true, // Enable arrow at the end of the x-axis
                    size: 10, // Size of the arrow
                    offsetY: 10, // Offset of the arrow from the axis
                  },
                },
              },
              y: {
                display: display_y,
                min: 0,
                grid: {
                  display: false, // Hide grid lines
                },
                ticks: {
                  color: 'gray', // Color of y-axis ticks
                  font: {
                    family: 'Arial', // Font family
                    size: 12, // Font size
                    weight: 'bold', // Font weight
                    color: "gray",
                  },
                },
                color: "#4c1909",
                axis: {
                  lineColor: '#4c1909', // Color of the x-axis line
                  arrow: {
                    enabled: true, // Enable arrow at the end of the x-axis
                    size: 10, // Size of the arrow
                    offsetY: 10, // Offset of the arrow from the axis
                  },
                },
              },
            },
            elements: {
              line: {
                borderWidth: 2, // Set width of axis lines
                borderColor: 'red' // Set color of axis lines to red
              },
              point: {
                borderWidth: 2, // Set width of axis arrow lines
                borderColor: 'red', // Set color of axis arrow lines to red
                backgroundColor: 'red' // Set color of axis arrow points to red
              }
            },
            plugins: {
              afterDraw: (chart) => {
                const ctx = chart.ctx;
                const xAxis = chart.scales.x;
                const yAxis = chart.scales.y;

                // Draw arrow on x-axis
                ctx.beginPath();
                ctx.moveTo(xAxis.left, xAxis.top - 10); // Position of arrowhead
                ctx.lineTo(xAxis.left, xAxis.bottom + 10); // Tip of arrow
                ctx.lineTo(xAxis.left - 5, xAxis.bottom); // Side of arrow
                ctx.lineTo(xAxis.left + 5, xAxis.bottom); // Side of arrow
                ctx.closePath();
                ctx.fillStyle = '#4c1909'; // Arrow color
                ctx.fill();

                // Draw arrow on y-axis
                ctx.beginPath();
                ctx.moveTo(yAxis.left - 10, yAxis.top); // Position of arrowhead
                ctx.lineTo(yAxis.right + 10, yAxis.top); // Tip of arrow
                ctx.lineTo(yAxis.right, yAxis.top - 5); // Side of arrow
                ctx.lineTo(yAxis.right, yAxis.top + 5); // Side of arrow
                ctx.closePath();
                ctx.fillStyle = '#4c1909'; // Arrow color
                ctx.fill();
              },
              legend: {
                display: false, // Hide legend
              },
              tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false,
                backgroundColor: '#4C1909', // Background color of the tooltip
                borderColor: 'transparent', // Border color of the tooltip
                borderWidth: 0, // Border width of the tooltip
                borderRadius: 50, // Border radius of the tooltip
                titleFont: {
                  family: 'Arial',
                  size: 14,
                  weight: 'bold',
                },
                bodyFont: {
                  family: 'Arial',
                  size: 20,
                  weight: 'bold',
                },
                callbacks: {
                  label: function(context) {
                    return `+ ${context.parsed.y}`; // Customize the tooltip label
                    // return;
                  },
                  title: function(context) {
                    // return `Month: ${context[0].label}`; // Customize the tooltip title
                  },
                },
              },
            }
          }
        }
      );
    }else{
      // Update the chart data if salesData changes
      chartInstance.current.data.labels = salesData.months;
      chartInstance.current.data.datasets[0].data = salesData.amounts;
      chartInstance.current.update();
    }

    // Destroy previous chart instance if it exists
    // if (chartInstance.current) {
    //   chartInstance.current.destroy();
    // }

  }, [salesData]);

  return <canvas ref={chartRef} />;
};

export default SalesChart;
