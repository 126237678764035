import up from "../../../../assets/new_images/Up.svg";
import React from "react";
import down from "../../../../assets/new_images/Down.svg";

export default function DataCardFooterEvolution({ value, periodicity, variationDirection }) {
  const show = false;
  return <span className="monit-foot">
    {show && <img src={variationDirection === "top" ? up : down} alt={"direction"} className="monit-ico"/>}
    <img src={up} alt={"direction"} className="monit-ico"/><img src={ down } alt={"direction"} className="monit-ico"/>  {value} {periodicity}
  </span>;
}