import Tags from "./tags";
import React, {useContext} from "react";
import CurrentUserContext from "../../datamanager/contexts/currentUser";

export default function PreviewInfo({ values }){

  const { currentUser: user } = useContext(CurrentUserContext);

  return (
    <div className="publish_audio_end_container_col2_container_header__content">
      <span className="publish_audio_end_container_col2_container_header__artistname">{user.alias}</span>
      <span
        className="publish_audio_end_container_col2_container_header__songtitle">{values.title}</span>
      <span
        className="publish_audio_end_container_col2_container_header__outdate">Date de sortie : {values.date}</span>
      <Tags values={values}/>
    </div>
  );
}