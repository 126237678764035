import style from "../styles/updateAlbumInfo.module.css"
import translate from "../../../i18n/translate";
import {useContext, useState, useRef, useEffect} from "react";
import MusicApi from "../../../api/musics";
import CurrentUserContext from "../../../datamanager/contexts/currentUser";
import "../../styles/InputField.css";
import "../../styles/SelectInput.css";
import DropDownIcon from "../../../assets/new_images/CaretCircleDown.svg";
import {useAlbumCategories} from "../../../datamanager/contexts/locals/albumCategory";
import Loader from "react-loader-spinner";
import useFetchAlbumCategories from "../../../hooks/useFetchAlbumCategories";
import AlbumsContext from "../../../datamanager/contexts/albums";

export const SelectField = ({ inputPlaceholder, optionList, bgColor="#1D1D1D", value, setCategory }) =>  {
  const containerRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  const handleContainerClick = () => {
    setIsOptionsVisible(!isOptionsVisible);
  };

  const handleOptionClick = (option) => {
    setCategory(option.id);
    setSelectedOption(option?.name);
    setIsOptionsVisible(false);
  };

  return (
    <div className="input-container" style={{ background: bgColor }} ref={containerRef}>
      <div className="selected-option" onClick={handleContainerClick}>
        <span className="selected-option-placeholder">{(value && optionList?.find((option) => option?.id === value)?.name) ||  inputPlaceholder}</span>
        <img alt={"dropdown"} src={DropDownIcon} className={isOptionsVisible ? "dropdown-icon rotate" : "dropdown-icon"} />
      </div>
      {isOptionsVisible && (
        <ul className={isOptionsVisible ? "options active" : "options"}>
          {optionList?.map((option, index) => (
            <li key={index} onClick={() => { handleOptionClick(option); }}>
              {option?.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export const InputField = ({inputLabel, inputName, inputPlaceholder, value, bgColor="#212018", setFieldValue}) => {

  const style = {
    background: bgColor
  }

  return (
    <div className={ `input-container ${style}` }>
      <p className="input-label">{inputLabel}</p>
      <div className="input-field-container">
        <input
          className="input-field"
          type={"text"}
          name={inputName}
          placeholder={inputPlaceholder}
          onChange={(e) => setFieldValue(e.target.value)}
          value={value}
        />
      </div>
    </div>
  )
}

export default function UpdateAlbumInfo({ albumID, albumCategory, albumLabel, albumTitle, albumDescription, albumMetadata }) {

  const { currentUser } = useContext(CurrentUserContext);
  const { albumCategories } = useAlbumCategories();
  const { albums, updateAlbums } = useContext(AlbumsContext);

  useFetchAlbumCategories();

  const payload = new FormData();
  const [ title, setTitle ] = useState(albumTitle)
  const [ category, setCategory ] = useState(albumCategory)
  const [ label, setLabel ] = useState(albumLabel)
  const [ isLoading, setIsLoading] = useState(false);
  const [ msg, setMsg] = useState(null);

  const fillPayload = () => {
    payload.append("artist", currentUser.id);
    payload.append("description", albumDescription);
    if (title) {
      payload.append("title", title);
    }else{
      payload.append("title", albumTitle);
    }
    if (label) {
      const newMetadata = {
        ...albumMetadata,
        label: label
      }
      console.log(newMetadata)
      albumMetadata && payload.append("metadata", JSON.stringify(newMetadata));
    }
    if (category) {
      payload.append("category", category);
    }
  }

  const hanldleUpdateAlbumInfo = async () => {
    const token = localStorage.getItem("access_token")
    const musicApi = new MusicApi(token)
    setIsLoading(true)
    fillPayload()
    const response = await musicApi.updateAlbumInfo(albumID, payload)
    if (response.data) {
      setIsLoading(false)
      setMsg("successfully updated")
      // find album with albumID and update it with response.data in albums
      console.log(albums)
      const newAlbumsResult = albums.results.map((album) => {
        if (album.id === albumID) {
          return response.data
        } else {
          return album
        }
      });
      const newAlbums = {
        ...albums,
        results: newAlbumsResult
      }
      updateAlbums(newAlbums)
    }
  }

  if (albumTitle && !albumCategory && !albumMetadata) {
    return (
      <div className={style.container}>
        <div className={style.info}>Previous album title: {albumTitle}</div>
        <div className={style.info}>
          <InputField inputPlaceholder={albumTitle} value={title} setFieldValue={setTitle} inputName={"title"} inputLabel={"New album title"} />
        </div>
        {msg ? <p className={style.msg}>{msg}</p> : <div className={style.submit_button} onClick={() => hanldleUpdateAlbumInfo()}>{translate("update")}{isLoading && <Loader type={"Watch"} color={"white"} height={20} width={20}/>}</div>}
      </div>
    );
  } else if (albumCategory || albumMetadata) {
    if (albumCategory) {
      return (
        <div className={style.container}>
          <div className={style.info}>Previous category: {albumCategory}</div>
          <div className={style.info}>
            <SelectField inputPlaceholder={"Select the new Category"} optionList={albumCategories} value={category} setCategory={setCategory} />
          </div>
          {msg ? <p className={style.msg}>{msg}</p> : <div className={style.submit_button} onClick={() => hanldleUpdateAlbumInfo()}>{translate("update")}{isLoading && <Loader type={"Watch"} color={"white"} height={20} width={20}/>}</div>}
        </div>
      );
    } else {
      return (
        <div className={style.container}>
          <div className={style.info}>Previous label: {albumLabel}</div>
          <div className={style.info}>
            <input
              type="text"
              className={style.input}
              defaultValue={albumLabel}
              onChange={(e) => setLabel(e.target.value)}
            />
          </div>
          {msg ? <p className={style.msg}>{msg}</p> : <div className={style.submit_button} onClick={() => hanldleUpdateAlbumInfo()}>{translate("update")}{isLoading && <Loader type={"Watch"} color={"white"} height={20} width={20}/>}</div>}
        </div>
      );
    }
  }
}