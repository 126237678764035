import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import "../../../../styles/App.css";
import "../../../../styles/publish.css";
import {
    CloseOutline,
    CloudUploadOutline,
    Warning,
} from "react-ionicons";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {Field, Formik, useField, useFormikContext} from "formik";
import * as Yup from "yup";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import en from "date-fns/locale/en-US";
import moment from "moment";
import { SpinnerCircularFixed } from "spinners-react";
import Confetti from "react-confetti";
import "moment/locale/fr";
import MusicApi from "../../../../api/musics";
import toast from 'react-hot-toast';
import FormatDate from "../../../../utils/FormatDate";
import {TestEnvAPI} from "../../../../envs";
import CurrentUserContext from "../../../../datamanager/contexts/currentUser";
// ----------------------------------------------------------------------------
export default function M_modal({closeModal}){

    // --------------------------------------------------------------
    // definition des valeurs de champs oui non pour tout les boutton radio
    const radioOptions = [
        "true", "false"
    ];

    // --------------------------------------------------------------
    // definition du Hook de contexte utilisateur
    const { currentUser: user } = useContext(CurrentUserContext);
    // definition du timeout des requettes
    const sleep = (milliseconds) => {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    };
    // definition des Hooks de creation de variable d'etats et de references pour l'ajout de single
    const [page, setPage] = useState(1);
    const inputFile = useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [backErr, setBackErr] = useState(false);
    const [msg, setMsg] = useState(
        "Oupss...les informations que vous avez fournis sont incorrectes !"
    );
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [connected, setConnected] = useState(false);
    const [sellCategories, setSellCategories] = useState([]);


    // UseEffect section
    useEffect(() => {
      if (sellCategories.length === 0) {
        handleFetchSellCategories().then(
          (result) => {
            console.log(result);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }, [sellCategories]);

    const handleFetchSellCategories = async () => {
      const musicApi = new MusicApi(token);

      let response;
      let allData = [];

      do {
        response = await musicApi.getMusicCategories(page);
        const data = response.data;

        if (data) {
          allData = [...allData, ...data];
          setPage(page + 1);
        } else {
          break;
        }
      } while (response.next);

      setSellCategories(allData);
    };

    const format1 = "DD MMM YYYY";
    const format2 = "HH:mm";
    const format3 = "YYYY-MM-DDThh:mm";
    const token = localStorage.getItem("access_token");


    const DatePickerField = ({ ...props }) => {
        const { setFieldValue } = useFormikContext();
        const [field] = useField(props);
        const [startDate, setStartDate] = useState(new Date());
        return (
            <DatePicker
                locale="en"
                className="w-full h-1 p-10"
                showTimeSelect
                dateFormat="Pp"
                {...field}
                {...props}
                selected={(field.value && new Date(field.value)) || null}
                onChange={(val) => {
                    setFieldValue(field.name, moment(val).format(format3)).then(r => { });
                }}
            />
        );
    };

    const handleNextPage = (errors) => {
        const array = Object.entries(errors);

        if (array.length === 0) {
            setPage(prev => prev + 1);
        } else {
            for (let error of array) {
                toast(error.join(": "), {
                    type: "error",
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeButton: true,
                    newestOnTop: true,
                })
            }
        }
    }

    let history = useNavigate();
    const goToPreviousPath = () => {
        history(-1);
    };


    // -------------------------------------------------------------------------------------------
    moment.locale("fr");
    registerLocale("en", en);

    const SUPPORTED_FORMATS = ["image/jpeg", "image/jpg", "image/png"];
    const SignupSchema = Yup.object().shape({
        title: Yup.string()
            .min(2, "Trop court!")
            .max(50, "Trop long!")
            .required("Required"),
        description: Yup.string().min(2, "Trop court!").max(550, "Trop long!"),
        src: Yup.mixed()
            .nullable()
            .required("Veuillez uploader votre cover!")
            .test(
                "FILE_SIZE",
                "L'image est trop lourde pour etre publiee",
                (value) => !value || (value && value.size <= 8024 * 8024)
            )
            .test(
                "FILE_FORMAT",
                "Veuillez choisir uniquement les fichers ayant les formats suivants : JPG, JPEG et  PNG",
                (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
            ),
        can_sell: Yup.string().oneOf(radioOptions).required("definissez si votre single doit etre vendu ou non"),
        pre_order_end_date: Yup.date(),
        publication_date:Yup.date().required(),
        pre_order: Yup.string().oneOf(radioOptions).required("definissez le mode de vente de votre single"),
        category: Yup.number().required(),
        author:Yup.number().required(),
        explicit: Yup.string().oneOf(radioOptions),
        isrc: Yup.string(),
    });

    // --------------------------------------------------
    // Get data from the global state

    // -------------------------------------------------------------------------------------------

    return (
        <div className="absolute flex justify-center w-full h-full bg-gray-300 bg-opacity-50 top-0 left-0 items-center">
            <div className="bg-white mx-auto shadow-2xl rounded-2xl items-center justify-center">
                <div className="row flex w-full pt-5 items-center">
                    <div className="col s12 m6 l1">
                        <div className="flex justify-around w-full">
                            {page > 1 && <i
                                onClick={() => {
                                    setPage(page - 1);
                                }}
                                className="material-icons text-white bg-red-900 hover:bg-white hover:text-red-900 hover:border-2 hover:shadow-xl rounded-full"
                            >arrow_back</i>}
                        </div>
                    </div>
                    <div className="col s12 m6 l10">
                        <div className="flex justify-around w-full">
                            <span className="text-4xl text-gray font-bold pl-20">Ajouter Un Single</span>
                        </div>
                    </div>
                    <div className="col s12 m6 l1 text-1xl text-red-900 font-bolg">
                        <div className="flex justify-around items-center w-full">
                            {page}<i className="material-icons text-sm">radio_button_checked</i>3
                        </div>
                    </div>
                    <div className="col s12 m6 l1">
                        <div className="flex justify-around w-full">
                            <i
                                onClick={()=>{
                                    closeModal(false)
                                }}
                                className="material-icons text-white bg-red-900 hover:bg-white hover:text-red-900 hover:border-2 hover:shadow-xl rounded-full"
                            >close</i>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="row w-full">
                    <div className="row w-full">
                        <Formik
                            initialValues={{
                                length: 0,
                                album: 0,
                                publication_date: Date(),
                                src: "",
                                media_accessibility: null,
                                is_single: true,
                                pre_order_listen: null,
                                nb_of_listening: 0,
                                lyric: null,
                                author: user.id,
                                category: 0,
                                title: "",
                                cover: "",
                                description: "",
                                date: "",
                                nb_of_download: 0,
                                can_sell: "false",
                                price: 0,
                                pre_order: false,
                                pre_order_end_date: "",
                                artist: user?.id,
                            }}
                            validationSchema={SignupSchema}
                            onSubmit = {(values) => {
                                setIsSubmitting(true);
                                // creaton d'un type de corps pour la requette
                                let formData = new FormData();
                                // definition des valeurs de champs qui devrons etre envoyes a la requette
                                for (const property in values) {
                                    formData.append(property, values[property]);
                                }
                                // envoie de la requette

                                const musicApi = new MusicApi(token);

                                musicApi.publishSingle(formData).then(async (response) => {
                                    if (!response) {
                                        setMsg(
                                            "Oupss...une erreur est survenue ! Verifier votre connection internet."
                                        );
                                        await sleep(800);
                                        setIsSubmitting(false);
                                        setBackErr(true);
                                    } else {
                                        if (response.type === "opaque") {
                                            const storedToken = JSON.parse(localStorage.getItem("auth"))
                                                .data.refreshs_token;
                                            try {
                                                const rs = await axios.post(
                                                    `${TestEnvAPI}/user-api/auth/token/refresh/`,
                                                    {
                                                        refresh: storedToken,
                                                    },
                                                    {
                                                        mode: "no-cors",
                                                        headers: {
                                                            Authorization: `Bearer ${token}`,
                                                            "Content-Type": "multipart/form-data",
                                                        },
                                                    }
                                                );

                                                const { access } = rs.data;

                                                localStorage.setItem(
                                                    "auth".data.access_token,
                                                    JSON.stringify(access)
                                                );

                                                await sleep(120000000);
                                                document.location = "/app/dashboard";
                                            } catch (_error) {
                                                return Promise.reject(_error);
                                            }
                                        } else {
                                            await sleep(800);
                                            setConnected(true);
                                            await sleep(120000000);
                                            document.location = "/app/dashboard";
                                        }
                                    }
                                })
                                    .catch(async (err) => {
                                        // Access Token was expired
                                        if (
                                            err ===
                                            "Failed to load resource: the server responded with a status of 401 (Unauthorized)"
                                        ) {
                                            const storedToken = JSON.parse(
                                                localStorage.getItem("auth").data
                                            );

                                            try {
                                                const rs = await axios.post(
                                                    `${TestEnvAPI}/user-api/auth/token/refresh/`,
                                                    {
                                                        refresh: storedToken.refresh_token,
                                                    }
                                                );

                                                const { access } = rs.data;

                                                localStorage.setItem(
                                                    "auth".data.access_token,
                                                    JSON.stringify(access)
                                                );

                                                return;
                                            } catch (_error) {
                                                return Promise.reject(_error);
                                            }
                                        }
                                        if (err == "Error: Request failed with status code 400") {
                                            setMsg(
                                                "Oupss...les informations que vous avez fournis sont incorrectes !"
                                            );
                                        } else {
                                            setMsg(
                                                "Oupss...une erreur est survenue ! Verifier votre connection internet."
                                            );
                                        }
                                        await sleep(800);
                                        setIsSubmitting(false);
                                        setBackErr(true);
                                    });

                                // axios
                                //     .post(`${TestEnvAPI}/media-api/audio-musics/`, formData, {
                                //         mode: "no-cors",
                                //         headers: {
                                //             Authorization: `Bearer ${token}`,
                                //             "Content-Type": "multipart/form-data",
                                //         },
                                //     })
                                //     .then(async (response) => {
                                //         if (!response) {
                                //             setMsg(
                                //                 "Oupss...une erreur est survenue ! Verifier votre connection internet."
                                //             );
                                //             await sleep(800);
                                //             setIsSubmitting(false);
                                //             setBackErr(true);
                                //         } else {
                                //             if (response.type === "opaque") {
                                //                 const storedToken = JSON.parse(localStorage.getItem("auth"))
                                //                     .data.refreshs_token;
                                //                 try {
                                //                     const rs = await axios.post(
                                //                         `${TestEnvAPI}/user-api/auth/token/refresh/`,
                                //                         {
                                //                             refresh: storedToken,
                                //                         },
                                //                         {
                                //                             mode: "no-cors",
                                //                             headers: {
                                //                                 Authorization: `Bearer ${token}`,
                                //                                 "Content-Type": "multipart/form-data",
                                //                             },
                                //                         }
                                //                     );
                                //
                                //                     const { access } = rs.data;
                                //
                                //                     localStorage.setItem(
                                //                         "auth".data.access_token,
                                //                         JSON.stringify(access)
                                //                     );
                                //
                                //                     await sleep(120000000);
                                //                     document.location = "/app/dashboard";
                                //                     return;
                                //                 } catch (_error) {
                                //                     return Promise.reject(_error);
                                //                 }
                                //             } else {
                                //                 await sleep(800);
                                //                 setConnected(true);
                                //                 await sleep(120000000);
                                //                 document.location = "/app/dashboard";
                                //             }
                                //         }
                                //     })
                                //     .catch(async (err) => {
                                //         // Access Token was expired
                                //         if (
                                //             err ===
                                //             "Failed to load resource: the server responded with a status of 401 (Unauthorized)"
                                //         ) {
                                //             const storedToken = JSON.parse(
                                //                 localStorage.getItem("auth").data
                                //             );
                                //
                                //             try {
                                //                 const rs = await axios.post(
                                //                     `${TestEnvAPI}/user-api/auth/token/refresh/`,
                                //                     {
                                //                         refresh: storedToken.refresh_token,
                                //                     }
                                //                 );
                                //
                                //                 const { access } = rs.data;
                                //
                                //                 localStorage.setItem(
                                //                     "auth".data.access_token,
                                //                     JSON.stringify(access)
                                //                 );
                                //
                                //                 return;
                                //             } catch (_error) {
                                //                 return Promise.reject(_error);
                                //             }
                                //         }
                                //         if (err == "Error: Request failed with status code 400") {
                                //             setMsg(
                                //                 "Oupss...les informations que vous avez fournis sont incorrectes !"
                                //             );
                                //         } else {
                                //             setMsg(
                                //                 "Oupss...une erreur est survenue ! Verifier votre connection internet."
                                //             );
                                //         }
                                //         await sleep(800);
                                //         setIsSubmitting(false);
                                //         setBackErr(true);
                                //     });
                            }}
                        >
                            {({
                                  errors,
                                  touched,
                                  values,
                                  handleChange,
                                  setFieldValue,
                                  handleSubmit,
                                  isValid
                              }) => (
                                <form>
                                    {isSubmitting ? (
                                        <>
                                            <div className={connected ? "upss" : "lcd"}>
                                                {connected && (
                                                    <>
                                                        <Confetti
                                                            className="ziz"
                                                            width={1600}
                                                            height={620}
                                                            numberOfPieces={300}
                                                            colors={[
                                                                "#55476a",
                                                                "#ae3d63",
                                                                "#db3853",
                                                                "#f45c44",
                                                                "#f8b646",
                                                            ]}
                                                        />

                                                        <span className="rcov">
                                                            <img
                                                                alt="cover"
                                                                src={URL.createObjectURL(selectedImage)}
                                                            />
                                                        </span>
                                                        <span className="psttl">Vous y êtes</span>
                                                    </>
                                                )}
                                                {isSubmitting && !connected && (
                                                    <SpinnerCircularFixed
                                                        size={50}
                                                        thickness={121}
                                                        speed={150}
                                                        color="rgba(133, 19, 78, 1)"
                                                        secondaryColor="rgba(231, 208, 220, 1)"
                                                    />
                                                )}
                                                <p className="load-title">
                                                    {connected
                                                        ? ` "${values.title}" a bien ete publie sur Colorfol Artist.`
                                                        : `Ajout du single "${values.title}" sur Colorfol Artist...`}
                                                </p>
                                            </div>
                                        </>
                                    ) : backErr ? (
                                        <>
                                            <div className="lcd">
                                                <Warning
                                                    color={"rgba(133, 19, 78, 1)"}
                                                    title={"Connecte"}
                                                    height="56px"
                                                    width="56px"
                                                />
                                                <p className="load-title">{msg}</p>
                                                <span
                                                    className="mt-16 form-sub"
                                                    onClick={() => {
                                                        setBackErr(false);
                                                    }}
                                                >
                                                    Ressayer
                                                </span>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="pf-card">
                                            <div className="dyn-form">
                                                <div className="if-conp">
                                                    {/* definition de la premiere page du stepper */}
                                                    {page === 1 && (
                                                        <div className="form-barp">
                                                            <div className="form-lbp">
                                                                {/* definition de la section suppereieur avec squeleton de l'image ceci sera valable pour toute les pages du stepper*/}
                                                                <div className="flex-row-center mb-20">
                                                                    {/* Input d'upload de la cover */}
                                                                    <input
                                                                        id="cover"
                                                                        ref={inputFile}
                                                                        type="file"
                                                                        className="myImage"
                                                                        accept="image/png, image/jpg, image/jpeg"
                                                                        name="src"
                                                                        onChange={(event) => {
                                                                            let file = event.target.files[0];
                                                                            if (file && !file.name) {
                                                                                window.alert("Aucun fichier n'a ete selectionne");
                                                                            }
                                                                            if (file.size > 10e6) {
                                                                                window.alert("Veillez choisir une image de moins de 10 Mo");
                                                                            }
                                                                            handleChange(event);
                                                                            setFieldValue("src", file).then(
                                                                                () => {
                                                                                    setSelectedImage(file);
                                                                                }
                                                                            )
                                                                            }
                                                                        }
                                                                    />

                                                                    {/* affichage de la cover uploade */}
                                                                    {selectedImage && (
                                                                        <>
                                                                            <div
                                                                                className="cover-con"
                                                                                onClick={() => inputFile.current.click()}
                                                                            >
                                                                                <img
                                                                                    alt="cover"
                                                                                    src={URL.createObjectURL(selectedImage)}
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    )}

                                                                    {/* affichage de la cover avant l'upload */}
                                                                    {!selectedImage && (
                                                                        <>
                                                                            <div
                                                                                className="cover-i "
                                                                                onClick={() => inputFile.current.click()}
                                                                            >
                                                                                + Cover
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                    {/* enonce des directives de lacover */}
                                                                    <div className="imgl">
                                                                        <strong>Directives concernant l'image</strong>
                                                                        <ul>
                                                                            <li>Forme carrée, minimum 1600x1600px</li>
                                                                            <li>
                                                                                Format du fichier : PNG (favoris) ou JPEG
                                                                            </li>
                                                                        </ul>
                                                                        <p>
                                                                            Le respect de ses regles est primordiales pour
                                                                            la prise en charge de votre publication.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                {/* formulaire etape 1 */}
                                                                <label htmlFor="title">Quel est le titre de votre Single ?</label>
                                                                <input
                                                                    id="title"
                                                                    className="li"
                                                                    name="title"
                                                                    type="text"
                                                                    onChange={handleChange}
                                                                    value={values.title}
                                                                    placeholder="Entrer le titre de votre Single"
                                                                ></input>
                                                                {/* erreur titre */}
                                                                {errors.title ? <div>{errors.title}</div> : null}
                                                                {/* erreur titre */}
                                                                {errors.src ? <div>{errors.src}</div> : null}
                                                                {/* erreur titre */}
                                                                {errors.title ? <div>{errors.title}</div> : null}
                                                                {/* description du single */}
                                                                <label htmlFor="description" className="mt-12">
                                                                    Veuillez décrire votre Single
                                                                </label>
                                                                <div className="w-full">
                                                                    <textarea
                                                                        className="w-full h-32 border-2 border-gray-400 rounded p-3"
                                                                        id="description"
                                                                        name="description"
                                                                        placeholder="Veiller entrer la description du Single ici"
                                                                        onChange={handleChange}
                                                                        rows={12}
                                                                    ></textarea>
                                                                </div>
                                                                <label className="mt-12">
                                                                    Veuillez Choisir la date de sa
                                                                    publication
                                                                </label>
                                                                {/* date picker */}
                                                                <div className="w-full">
                                                                    <DatePickerField name="publication_date" />
                                                                </div>
                                                                {/* erreurs etape 1 */}
                                                                <div className="flex-row m-auto">
                                                                    {!errors.title &&
                                                                    values.src !== "" &&
                                                                    values.publication_date !== "" &&
                                                                    values.description !== "" ? (
                                                                        <button
                                                                            className="mt-5 fbv"
                                                                            type="button"
                                                                            onClick={() => handleNextPage(errors)}
                                                                        >
                                                                            Suivant
                                                                        </button>
                                                                    ) : (
                                                                        <button className="mt-5 fbvdi" type="button">
                                                                            Suivant
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {/* definition de la page 2 du stepper */}
                                                    {page === 2 && (
                                                        <>
                                                            <div className="form-bar">
                                                                <div className="form-lbp">
                                                                    {/* definition de la sectio superieure avec le squeleton de l'image et le texte descriptif*/}
                                                                    <div className="flex-row-center mb-12">
                                                                        <div className="cover-iu">
                                                                            <CloudUploadOutline
                                                                                color={"#6a0f3e"}
                                                                                title={"musique"}
                                                                                height="40px"
                                                                                width="40px"
                                                                                className="mb-12"
                                                                            />
                                                                            {values.title}
                                                                        </div>
                                                                        <div className="imgl">
                                                                            <strong>
                                                                                Directives concernant la publication
                                                                            </strong>
                                                                            <ul>
                                                                                <li>Prix de vente par defaut: 0 euro</li>
                                                                            </ul>

                                                                            <p>
                                                                                L'Single "{values.title}" seras sousmis a une
                                                                                verification par notre equipe avant d'etre
                                                                                publie.
                                                                            </p>
                                                                        </div>
                                                                    </div>{" "}
                                                                    {/* definition de la section radio button de selection du genre de vente de single */}
                                                                    <div className="flex-col">
                                                                        <label>Votre Single est-il en vente ?</label>
                                                                        <div className="flex-row ">
                                                                            <div>
                                                                                <label>
                                                                                    <Field
                                                                                        type="radio"
                                                                                        name="can_sell"
                                                                                        value="true"
                                                                                    />
                                                                                    Oui
                                                                                </label>
                                                                            </div>
                                                                            <div className="ml-5">
                                                                                <label>
                                                                                    <Field
                                                                                        type="radio"
                                                                                        name="can_sell"
                                                                                        value="false"
                                                                                    />
                                                                                    Non
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* definition de la section de selection du prix du single */}
                                                                    {values.can_sell === "true" &&
                                                                        <div className="flex-column mt-16">
                                                                            <label htmlFor="price">
                                                                                Definissez le prix de vente de votre Single
                                                                            </label>
                                                                            <input
                                                                                className="ml-12 sm-input"
                                                                                type="number"
                                                                                id="price"
                                                                                name="price"
                                                                                onChange={handleChange}
                                                                                placeholder="Prix en euro "
                                                                            />
                                                                        </div>
                                                                    }
                                                                    {/* definition du select Field*/}
                                                                    <div className="mt-5">
                                                                        <label htmlFor="email" style={{ display: "block" }}>
                                                                            Choisissez une cathegorie pour votre Single
                                                                        </label>
                                                                        <select
                                                                            id="email"
                                                                            name="category"
                                                                            value={values.category}
                                                                            onChange={handleChange}
                                                                            style={{ display: "block" }}
                                                                        >
                                                                            <option value="" label="choisir une categorie">
                                                                                Select a color{" "}
                                                                            </option>
                                                                            {sellCategories.map(cat => (
                                                                                <option key={cat.id} value={cat.id}>
                                                                                    {cat.name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    {/* definition du bouton suivant pour aller a la page suivante*/}
                                                                    <div className="m-auto  flex-row ">
                                                                        {!errors.price ? (
                                                                            <button
                                                                                className="mt-5 fbv"
                                                                                type="submit"
                                                                                onClick={() => handleNextPage(errors)}
                                                                            >
                                                                                Suivant
                                                                            </button>
                                                                        ) : (
                                                                            <button className="mt-5 fbvdi" type="button">
                                                                                Suivant
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {/* definition de la troisieme page du sepper */}
                                                    {page === 3 && (
                                                        <>
                                                            <div className="form-bar">
                                                                <div className="form-lbp">
                                                                    {/* Ajout de la zone supperieur qui constitue l'UI*/}
                                                                    <div className="flex-row-center mb-12">
                                                                        <div className="cover-iu">
                                                                            <CloudUploadOutline
                                                                                color={"#6a0f3e"}
                                                                                title={"musique"}
                                                                                height="40px"
                                                                                width="40px"
                                                                                className="mb-12"
                                                                            />
                                                                            {values.title}
                                                                        </div>
                                                                        <div className="imgl">
                                                                            <strong>
                                                                                Directives concernant la publication
                                                                            </strong>
                                                                            <ul>
                                                                                <li>Prix de vente par defaut: 0 euro</li>
                                                                                <li>
                                                                                    Date de la prevente conforme a celle de la
                                                                                    publication.
                                                                                </li>
                                                                            </ul>

                                                                            <p>
                                                                                L'Single "{values.title}" seras sousmis a une
                                                                                verification par notre equipe avant d'etre
                                                                                publie.
                                                                            </p>
                                                                        </div>
                                                                    </div>{" "}
                                                                    <p>titre : </p> {values.title}
                                                                    <p>publication date : </p> {values.publication_date}
                                                                    <p>src : </p> {values.src.toString()}
                                                                    <p>Auteur : </p> {values.author}
                                                                    <p>Category : </p> {values.category}
                                                                    {/* definition d'une section horizontale */}
                                                                    <div className="flex-row">
                                                                        {/* recupperation de la donnees sur le fait que ce soit en pre-achat ou pas*/}
                                                                        <div className="card-panel flex-col">
                                                                            <div className="flex-column ">
                                                                                <label>
                                                                                    Votre Single est-il en pre-ecoute ?
                                                                                </label>
                                                                                <div className="flex-center-row">
                                                                                    <label htmlFor="precoutrtrue">
                                                                                        <Field
                                                                                            id="precoutrtrue"
                                                                                            type="radio"
                                                                                            name="pre_order"
                                                                                            value="true"
                                                                                        /> Oui</label>
                                                                                    <label className="ml-5" htmlFor="precoutrFalse">
                                                                                        <Field
                                                                                            id="precoutrFalse"
                                                                                            type="radio"
                                                                                            name="pre_order"
                                                                                            value="false"
                                                                                        /> Non</label>
                                                                                </div>
                                                                            </div>
                                                                            {/*Selection de la date de fin de pre-achat*/}
                                                                            {values.pre_order === "true" && (
                                                                                <div className="flex-column mt-12">
                                                                                    <label>
                                                                                        Entrez la date de fin des pre-ecoutes
                                                                                    </label>
                                                                                    {/* date picker */}
                                                                                    <DatePickerField name="pre_order_end_date" />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        {/* section pour la definition de l'explicite du song */}
                                                                        <div className="card-panel ml-2" >
                                                                            <p className="text-lg font-semibold">Votre Single sera explicite ou Non ?</p>
                                                                            <label htmlFor="explicit" className="mr-2">
                                                                                Explicit
                                                                            </label>
                                                                            <Field
                                                                                name="explicit"
                                                                                type='radio'
                                                                                id="explicit"
                                                                                onChange={handleChange}
                                                                                value="true"/>
                                                                            <label htmlFor="nonexplicit" className="ml-5 mr-2">
                                                                                Non explicit
                                                                            </label>
                                                                            <Field
                                                                                name="explicit"
                                                                                type='radio'
                                                                                id="nonexplicit"
                                                                                onChange={handleChange}
                                                                                value="false"/>
                                                                        </div>
                                                                    </div>
                                                                    {/* boutton d'envoie du formulaire d'ajout de single*/}
                                                                    <div className="m-auto flex-row">
                                                                        <button
                                                                            className="fbv"
                                                                            type="submit"
                                                                            onClick={handleSubmit}
                                                                        >
                                                                            Publier
                                                                        </button>
                                                                        {/*<button className='ml-96 sbbv' type='button' onClick={() => setPage(page - 1)}><ArrowBackOutline color={'#f2f2f2'} title={"Meli-Melo"} height="28px" width="28px" /></button><button className='ml-12 snbv' type='button' onClick={() => setPage(page + 1)}>Suivant</button>*/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}