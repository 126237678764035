import React, {createContext, useCallback, useContext, useState} from 'react';
import {isEmptyObject} from "../../../../utils/functions";

const CurrentPageContext = createContext();

export const useCurrentPage = () => useContext(CurrentPageContext);

export const CurrentPageProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handleSetCurrentPage = useCallback((value) => {
    if (value !== currentPage) {
      setCurrentPage(value);
    }
  }, [currentPage]);

  const handleNextPage = (errors) => {
    if (!isEmptyObject(errors)) {
      console.log(errors);
      return;
    }
    setCurrentPage(currentPage + 1);
  }

  return (
    <CurrentPageContext.Provider value={{ currentPage, handleSetCurrentPage, handleNextPage }}>
      {children}
    </CurrentPageContext.Provider>
  );
};
