import Stepper from "../../../../components/Stepper";
import React from "react";
import {uploadSteps} from "../../../../utils/Constants";
import PreviewRightSideSingle from "../../../../components/PublishAudio/PreviewRightSideSingle";
import StepperActionsSingle from "./StepperActionsSingle";

export default function UploadProcessEndSingle({
  values, errors, handleSubmit, isValid, handleReset, isFinishedUpload, uploadPercentage, uploadSpeed
  , handleSetUploadPercentage, handleSetUploadSpeed
  , handleSetSelectedAudio, handleSetSelectedImage
  , handleSetMetaData, handleSetBackErr, handleSetMsg, controller, source, handleSetConnected = null,
   handleSetSubmitting = null, selectedAudio = null, user = null, AxiosConfig = null, musicApi = null, metaData = null,
   handleSetFinishedUpload = null, resetForm = null, backErr = null, isSubmitting = null
}){

  return (
    <div className="publish_audio_form">
      <PreviewRightSideSingle values={values}
        isFinishedUpload={isFinishedUpload}
        uploadPercentage = {uploadPercentage}
        uploadSpeed = {uploadSpeed}
        handleSetUploadPercentage = {handleSetUploadPercentage}
        handleSetUploadSpeed = {handleSetUploadSpeed}
        handleSetSelectedAudio = {handleSetSelectedAudio}
        handleSetSelectedImage = {handleSetSelectedImage}
        handleSetMetaData = {handleSetMetaData}
        handleSetBackErr = {handleSetBackErr}
        handleSetMsg = {handleSetMsg}
        backErr = {backErr}
        resetForm={resetForm}
        isSubmitting={isSubmitting}
      />
      <div className="publish_audio_form_stepper">
        {/* <Stepper steps={uploadSteps} uploadPercentage={uploadPercentage} /> */}
        <StepperActionsSingle handleSubmit={handleSubmit} handleReset={handleReset}
                        handleSetUploadPercentage={handleSetUploadPercentage} uploadPercentage={uploadPercentage} controller={controller}
                        source = {source} values={values} AxiosConfig={AxiosConfig} musicApi={musicApi} metaData={metaData}
                        handleSetMsg={handleSetMsg} handleSetBackErr={handleSetBackErr} handleSetConnected={handleSetConnected}
                        handleSetUploadSpeed={handleSetUploadSpeed} handleSetSubmitting={handleSetSubmitting} selectedAudio={selectedAudio}
                        user={user} handleSetFinishedUpload={handleSetFinishedUpload} resetForm={resetForm}
                        errors={errors} isValid={isValid}
        />
      </div>
    </div>
  );
}