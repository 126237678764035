import Hero from '../../containers/Hero/Hero'
import Services from '../../containers/Services/Services'
import '../../styles/home.scss'

import divider from '../../assets/illustrations/page_divider.png'
import Join from '../../containers/Join/Join'
import Contact from '../../containers/Contact/Contact'

const Home = () => {
    return (
        <main className='home'>
            <Hero />
            <Services />

            <section className="divider h-6 mb-10">
                <img src={divider} alt="divider" />
            </section>

            <Join />
            <Contact />
        </main>
    )
}

export default Home