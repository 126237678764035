import translate from "../i18n/translate";

export const salesData = {
  months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  amounts: [2000, 1800, 2100, 1900, 2200, 1800, 2000, 2100, 2200, 2300, 2500, 2700]
};

export const token = localStorage.getItem("access_token");

export const SUPPORTED_FORMATS = ["image/jpeg", "image/jpg", "image/png"];
export const format1 = "DD MMM YYYY";
export const format2 = "HH:mm A";
export const format3 = "YYYY-MM-DDThh:mm";

export const distrubutionMode = [
  translate("streaming"),
  translate("hybrid"),
  'Downloadable'
]
export const sellCategory = [
  translate("streaming"),
  translate("hybrid"),
  translate("ForSale")
]

export const filterSelect =  [
  "Popularite",
  "ecouts"
];

export const switchTabs = [
  translate("singles"),
  translate("albums")
];

export const switchMediaPost = [
  translate("media"),
  translate("post")
];

export const selectDataFilter = [
  "popilarity",
  "area"
]

export  const uploadSteps  = [
  translate("DefineInformations"),
  translate("configureMusic"),
  translate("publish")
]

export const genderOptions = [
  {
    id: 1,
    name: "Rock"
  },
  {
    id: 2,
    name: "Pop"
  },
  {
    id: 3,
    name: "Jazz"
  },
  {
    id: 4,
    name: "Classique"
  }
];

export const ouiNon = [
  translate("Oui"),
  translate("Non")
];