import React from "react";
import {useCurrentPage} from "../../../../datamanager/contexts/locals/publishAudio/currentPage";
import StepperActionsLast from "./StepperActionsLast";
import StepperActionsIntermediairy from "./StepperActionsIntermediairy";

export default function StepperActions({ values, errors, handleSubmit, isValid, handleReset }) {

  const { currentPage } = useCurrentPage();

  if (currentPage !== 3) {
    return <StepperActionsIntermediairy  values={values} errors={errors} isValid = {isValid} />;
  } else {
    return <StepperActionsLast handleSubmit={handleSubmit} handleReset={handleReset} />;
  }
}