import { useState } from "react";
import EpisodesContext from "../contexts/episodes";

const EpisodesProvider = ({ children }) => {
  const [episodes, setEpisodes] = useState(null);
  const [selectedEpisode, setSelectedEpisode] = useState(null);

  // Some handlers
  const addEpisodes = (episodes) => {
    setEpisodes(episodes);
  }

  const selectEpisode = (id) => {
    const selectedEpisode = episodes.results.find(episode => +episode.id === +id);

    setSelectedEpisode(selectedEpisode);
  }

  const contextValue = {
    episodes,
    selectedEpisode,
    addEpisodes,
    selectEpisode
  }

  return (
    <EpisodesContext.Provider value={contextValue}>
      { children }
    </EpisodesContext.Provider>
  )
}

export default EpisodesProvider