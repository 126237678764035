import * as Yup from "yup";

export const SignupSchema = Yup.object().shape({
  username: Yup.string()
    .min(2, "*L’identifiant doit faire entre 2 et 50 caractères")
    .max(50, "*L’identifiant doit faire entre 2 et 50 caractères")
    .matches(
      /^[a-zA-Z0-9\s]+$/,
      "*User names must be alphabetics "
    )
    .required("User Name required")
    .nullable(),
  phone: Yup.string()
    .required("*Phone Number required")
    .nullable()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "*Invalid Phone number"
    )
    .max(12),
  birthday: Yup.date()
    .max(new Date(2004, 1, 1), "*You must be 18 years or older.")
    .min( new Date(1900, 1, 1), "*Enter a valid date." )
    .required('*Birthday required')
    .nullable(),
  password1: Yup.string()
    .min(8, "*your password must content at least 8+ characters.")
    .max(150, "*too long.")
    .required("Password required")
    .nullable(),
  sex: Yup.string().required('Gender required').nullable(),
  // birthday: Yup.date().max(moment(new Date(2004, 1, 1)).toDate(),"*Vous devez avoir minimun 18 ans.").min( moment(new Date(1900, 1, 1)).toDate(), "*Veuillez saisir une date valide." ).required('*Veuillez remplir ce champs').nullable(),
  email: Yup.string()
    .email("*Invalid Email address")
    .required("Email required")
    .nullable(),
});