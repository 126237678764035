import {useContext, useState} from "react";
import CurrentUserContext from "../../../datamanager/contexts/currentUser";
import style from "../styles/RespondCommentModal.module.css"
import RichTextArea from "../../baseComponents/RichTextArea";
import PostAPI from "../../../api/posts";
import ModalContext from "../../../datamanager/contexts/modalContext";

export default function RespondCommentModal({ user }){

  const [comment, setComment ] = useState("");
  const { currentUser } = useContext(CurrentUserContext);

  const { closeModal } = useContext(ModalContext)

  const handleAnswerComment = async () => {
    const token = localStorage.getItem("access_token");

    const postAPI = new PostAPI(token)

    // creating payload formData
    const payload = new FormData();
    payload.append("content", comment);
    payload.append("user", currentUser.id);

    const response = await postAPI.answerPostComment(user.comment.id, payload)

    if (response.data) {
      console.log("answered comment")
    }
  }


  return (
    <div className={style.respondModal}>
      <div className={style.CommentContaier}>
        <div className={style.CommentHeader}>
          <div className={style.CommentProfile}>
            <img alt={"user profile"} className={style.CommentProfileImg} src={user.user.cover}/>
          </div>
          <span className={style.CommentName}>{user.user.alias}</span>
        </div>
        <div className={style.CommentContent}>
          {user.comment.description}
        </div>
      </div>
      <textarea
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        className={style.commentArea}
        placeholder="Write a comment..."
      ></textarea>
      <div className={style.sendComment} onClick={() => {
        handleAnswerComment().then(() => closeModal())
      }}>
        Answer
      </div>
    </div>
  );
}