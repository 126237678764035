import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DataTable from "react-data-table-component-with-filter";
import { SpinnerCircularFixed } from "spinners-react";
import moment from "moment";
import "moment/locale/fr";
import SinglesContext from "../datamanager/contexts/singles";
moment.locale("fr");

const TextField = styled.input`
  height: 34px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 8px 32px 8px 16px;

  &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #6a0f3e;
  color: white;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Cherher un album"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);

function Audiotables() {
  // Get data from global context
  const { singles, selectSingle } = useContext(SinglesContext)

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [loading, setLoading] = useState(true);
  
  let navigate = useNavigate();

  useEffect(() => {
    if (singles) {
      setLoading(false)
    }
  }, [singles]);

  // Some handlers
  const handleSelectSingle = (id) => {
    selectSingle(id)
    navigate("/app/singledetails")
  }

  const columns = [
    {
      name: "Cover",
      grow: 0,
      cell: (row) => (
        <img height="76px" width="76px" alt={row.title} src={row.cover} />
      ),
    },
    {
      name: "Single",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Prix",
      selector: (row) => (row.price === 0 ? "Free" : row.price),
      sortable: true,
    },
    {
      name: "Categorie",
      selector: (row) => row.media_accessibility,
      sortable: true,
    },
    {
      name: "Telechargements",
      selector: (row) => (row.nb_of_download === 0 ? "No Download" : row.nb_of_download),
      sortable: true,
    },
    {
      name: "Publie le",
      selector: (row) => row.publication_date,
      cell: (row) => (
        <div>{moment(row.publication_date).format("Do MMMM  YYYY")}</div>
      ),
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={singles.results}
      onRowClicked={(row) => {
        handleSelectSingle(row.id);
      }}
      progressPending={loading}
      progressComponent={
        <SpinnerCircularFixed
          size={50}
          thickness={121}
          speed={150}
          color="rgba(133, 19, 78, 1)"
          secondaryColor="rgba(231, 208, 220, 1)"
          className="mt-12"
        />
      }
      pagination={singles.count > 10}
      paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
      fixedHeader={true}
      fixedHeaderScrollHeight={"600px"}
      persistTableHead
      pointerOnHover
      responsive
    />
  );
}

export default Audiotables;
