import React, {createContext, useCallback, useContext, useState} from 'react';

const SelectedImageContext = createContext();

export const useSelectedImage = () => useContext(SelectedImageContext);

export const SelectedImageProvider = ({ children }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleSetSelectedImage = useCallback((value) => {
    if (value !== selectedImage) {
      setSelectedImage(value);
    }
  }, [selectedImage]);
  return (
    <SelectedImageContext.Provider value={{ selectedImage, handleSetSelectedImage }}>
      {children}
    </SelectedImageContext.Provider>
  );
};
