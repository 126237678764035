import React from "react";
import "../../../styles/App.css";
import { Link } from "react-router-dom";
import uil from "../../../assets/single.png";
function Playlist({ isActive }) {
  return (
    <>
      <img src={uil} className="esi" />
      <span className="est">Aucune Playlist disponible ici !</span>
      <Link exact to="/app/publish/audio" className="link">
        <span className="escta">Publiez votre premiere playlist</span>
      </Link>
    </>
  );
}
export default Playlist;
