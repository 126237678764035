import React, { useContext } from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { SpinnerCircularFixed } from "spinners-react";
import {useLocation, useNavigate} from "react-router-dom";
import lib from "../../../assets/music-library.gif";
import CurrentUserContext from "../../../datamanager/contexts/currentUser";
import Modal from "react-modal"
import ReactDOM from "react-dom";
import M_modal from "./utils/M_modal";
import ArtistAPI from "../../../api/artist";
import moment from "moment/moment";
import DataTable from "react-data-table-component-with-filter";
import SinglesContext from "../../../datamanager/contexts/singles";
import ArtistsContext from "../../../datamanager/contexts/artists";
import {TestEnvAPI} from "../../../envs";

function ArtistDetails() {
  // Get data from the global context
  const { currentUser: user } = useContext(CurrentUserContext)

  const [artist, setArtist] = useState([]);
  const [data, setData] = useState({});
  const [date, setDate] = useState();
  const [dataPay, setDataPay] = useState([]);
  const [pay_info , setPayInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [canSell, setCanSell] = useState(false);
  const access_token = localStorage.getItem("access_token");
  const location = useLocation();
  const [resetPaginationToggle, setResetPaginationToggle] =
      React.useState(false);

  let navigate = useNavigate();

  const { artists, selectArtist } = useContext(ArtistsContext)

  useEffect(() => {

    // get Data from Tokenanout user
    const fetchData = async () => {
      try {
        const { data: response } = await axios.get(
          `${TestEnvAPI}/media-api/artists/${location.state.id}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        setData(response);
        setCanSell(response.can_sell)
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };
    fetchData().then(r => {  });

    // get Date Information as String
    const getDate = () => {
      var today = new Date()
      var date_string =  today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
      setDate(date_string);
    }
    getDate()

    //GET Information about price and other
    const fetchPayInfo = async () => {
      var title = data.title
      try {
        var { data: response } = await axios.get(
          `${TestEnvAPI}/payement-api/paiements/group-sales-by-channel?artist=${title}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        var resultPay = response.results
        try{
          var { data: response } = await axios.get(
            `${TestEnvAPI}/payement-api/paiements/group-sales-by-channel-solde?artist=${title}`,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          );
        } catch (error2){
          console.error(error2);
        }
        //add price on Data Pay
        for(const elmt in response.results){
          resultPay[elmt].amount = response.results[elmt].amount
        }
        setDataPay(resultPay);
        //COMPUTE TOTAL
        var infos = {number: 0, amount:0}
        for(const elmt in resultPay){
          infos.number = infos.number + resultPay[elmt].number
          infos.amount = infos.amount + resultPay[elmt].amount
          infos.currency = resultPay[elmt].currency
        }
        setPayInfo(infos)
      } catch (error) {
        console.error(error);
      }
    };
    fetchPayInfo().then(
      (result) => {
        console.log(result)
      },
      (error) => {
        console.log(error)
      }
    );

  }, []);

  const handleSelectArtist = (id) => {
    selectArtist(id)
    navigate("/app/artistdetails")
  }

  const columns = [
    {
      name: "Cover",
      grow: 0,
      cell: (row) => (
          <img height="76px" width="76px" alt={row.title} src={row.cover} />
      ),
    },
    {
      name: "artist",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Ecouts",
      selector: (row) => row.nb_of_listening,
      sortable: true,
    },
    {
      name: "Categorie",
      selector: (row) => row.media_accessibility,
      sortable: true,
    },
    {
      name: "Telechargements",
      selector: (row) => row.nb_of_download,
      sortable: true,
    },
    {
      name: "Publie le",
      selector: (row) => row.publication_date,
      cell: (row) => (
          <div>{moment(row.publication_date).format("Do MMMM  YYYY")}</div>
      ),
    },
  ];

  const handleGetAllArtist = async () => {
    const artistApi = new ArtistAPI(access_token);

    const response = await artistApi.getAllArtists(location.state.id);

    if (response.data) {
      setArtist(response.data);
    }
  }

  useEffect(()=>{
    if (artist.length === 0) {
      handleGetAllArtist().then(
          (result) => {
            console.log(result)
          },
          (error) => {
            console.log(error)
          }
      );
    }
  }, [artist])


  // -----------------------------------------------------------------------------------------------------------------
  //  Mise en place du modal de demande d'ajout de son

  const [openModal, setOpenModal] = useState(
      false
  )

  // -----------------------------------------------------------------------------------------------------------------

  return (
    <div className="w-full h-full">
      {openModal && <M_modal closeModal={setOpenModal}/>}
      {loading && (
        <div className="flex items-center justify-center h-screen">
          <SpinnerCircularFixed
            size={50}
            thickness={121}
            speed={150}
            color="rgba(133, 19, 78, 1)"
            secondaryColor="rgba(231, 208, 220, 1)"
          />
        </div>
      )}

      {!loading && (
        <>
          <div id="root-modal" className="p-4 w-full h-screen max-h-screen flex flex-col items-center justify-start">
            <div className="w-full flex items-start justify-start">
              <div className="pt-10 w-52 h-52">
                <img src={data.cover} className="object-cover rounded-2xl" />
              </div>

              <div className="h-52 flex flex-col items-start justify-end mx-5 ">
                <h5 className="text-sm text-slate-500 font-semibold">Artist</h5>
                <h1 className="text-6xl text-slate-500 font-bold my-1.5">
                  {data.title}
                </h1>

                <div className="flex items-center justify-center mt-2">
                  <div className="w-6 h-6 ">
                    <img
                      src={data.artist.photo}
                      className="rounded-full object-cover"
                    />
                  </div>
                  <h5 className="text-sm text-slate-500 font-semibold mx-2 rounded-xl">
                    {data.artist.alias}
                  </h5>
                </div>
              </div>
            </div>

            <div className="w-full flex flex-col items-start justify-start">
              <div className="flex items-center justify-start mt-20 pt-5">
                <button className="cursor-pointer hover:bg-gray-200 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-1.5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                  </svg>
                  <span>Modifier</span>
                </button>

                <button className="cursor-pointer hover:bg-gray-200 text-gray-800 font-bold py-2 px-2 rounded-full inline-flex items-center mx-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-7 w-7 cursor-pointer text-gray-500 hover:text-gray-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                    />
                  </svg>
                </button>
              </div>

              {/* Hero */}
              {canSell && (
              <div className="rdc w-full flex flex-col items-center justify-start  shadow rounded-lg overflow-clip mb-5">
                <div className="flex items-center justify-between w-full h-3/4 pl-6 pt-3 pb-14">
                  <div className="flex flex-col h-full w-2/4 p-1">
                    <h3 className="text-sm font-normal text-gray-400 mb-1.5">
                      Paiement le plus recent
                    </h3>
                    <div className="flex items-center justify-start">
                      <h1 className="text-4xl text-slate-600 font-bold">
                        {pay_info.amount} {pay_info.currency}
                      </h1>
                      <div className="flex items-center justify-center bg-green-600 w-12 h-6 px-1 py-0.5 mx-2 rounded text-white text-xs font-semibold">
                        Soldé
                      </div>
                    </div>
                    <h3 className="text-xl my-1 font-normal text-slate-100 font-bold">
                      {pay_info.number} Ventes
                    </h3>
                    <h4 className="text-xs my-1 font-normal text-slate-100">
                      Envoyé le {date}.
                    </h4>
                  </div>

                  <div className="flex flex-col h-full w-2/4 p-1">
                    <h3 className="text-sm font-normal text-gray-400 mb-1.5">
                      Prochain paiement
                    </h3>
                    <div className="flex items-center justify-start">
                      <h1 className="text-4xl text-gray-400 font-bold">
                        0.000 FCFA
                      </h1>
                      <div className="flex items-center justify-center bg-gray-300 w-12 h-6 px-1 py-0.5 mx-2 rounded text-white text-xs font-semibold">
                        Estimé
                      </div>
                    </div>
                    
                  </div>
                </div>

                <div className="flex items-center justify-between w-full h-1/2ujj pl-6 pt-3 pb-14">

                {dataPay.map((info, index) => (
                  <div className="flex flex-col h-full w-2/4 p-1">
                    <h3 className="text-sm font-normal text-gray-400 mb-1.5">
                    {info.channel}
                    </h3>
                    <div className="flex items-center justify-start">
                      <h1 className="text-sm text-slate-600 font-bold">
                      {info.amount} {info.currency}
                      </h1>
                      <div className="flex items-center justify-center bg-pink-800  h-6 px-1 py-0.5 mx-2 rounded text-white text-xs font-semibold">
                        {info.number} vente(s)
                      </div>
                    </div>
                  </div>
                ))}

                </div>
              </div>
              )}


              <div className=" w-full flex flex-col items-center justify-center">

                <div className="w-28 h-28 my-1">
                  <img src={lib} className="object-cover" />
                </div>

                <p className="text-center text-base font-medium my-5 w-72">
                  Ajouter des sons a votre artist pour avoir des statistiques.
                </p>

                <button
                    className="cursor-pointer bg-pink-800 hover:bg-pink-900 text-white font-bold py-2 px-4 rounded"
                    onClick={()=>{
                      setOpenModal(true)
                    }}
                >
                  Ajouter les sons
                </button>

                {/* ajouter la liste des musiques de l'artist */}

                  <div className="card-panel w-full mt-5">
                    <DataTable
                        columns={columns}
                        data={artist}
                        onRowClicked={(row) => {
                          handleSelectArtist(row.id);
                        }}
                        progressPending={loading}
                        progressComponent={
                          <SpinnerCircularFixed
                              size={50}
                              thickness={121}
                              speed={150}
                              color="rgba(133, 19, 78, 1)"
                              secondaryColor="rgba(231, 208, 220, 1)"
                              className="mt-12"
                          />
                        }
                        // pagination={artist.length > 10}
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        fixedHeader={true}
                        fixedHeaderScrollHeight={"h-full"} // 600px
                        persistTableHead
                        pointerOnHover
                        responsive
                    />
                    {/*{artist.length}*/}
                    {/*{artist.map(artists => (*/}
                    {/*    <p>{artists.title}</p>*/}
                    {/*))}*/}
                  </div>

              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ArtistDetails;
