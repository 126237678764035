import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import "../../../styles/App.css";
import { Link } from "react-router-dom";
import uil from "../../../assets/Uploading-rafiki.png";
import { SpinnerCircularFixed } from "spinners-react";
import useUser from "../../../hooks/useUser";
import Audiotables from "../../../components/audiotables";
import EpisodesContext from "../../../datamanager/contexts/episodes";
import PodcastsContext from "../../../datamanager/contexts/podcasts";
import AreaChart from "./components/AreaChart";
import MapChart from "./components/MapChart";


function Dashboard({ isActive }) {
  // Get data from the global context
  const { episodes } = useContext(EpisodesContext);
  const { podcasts } = useContext(PodcastsContext)

  const [loading, setLoading] = useState(true);

  // UseEffect section
  useEffect(() => {
    if (episodes && podcasts) {
      setLoading(false)
    }
  }, [episodes, podcasts])

  return (
      <>
        {loading ? (
            <div className="flex items-center justify-center h-screen">
              <SpinnerCircularFixed
                  size={50}
                  thickness={121}
                  speed={150}
                  color="rgba(133, 19, 78, 1)"
                  secondaryColor="rgba(231, 208, 220, 1)"
              />
            </div>
        ) : podcasts.count && episodes.count > 0 ? (
            <div className="flex flex-col items-start justify-start w-full min-h-screen p-5">
              {/* Entetet */}
              <div className="flex flex-col items-start justify-start w-full mt-20">
                <h1 className="text-5xl font-bold text-slate-500 text-left">
                  Dashboard
                </h1>
                <h4 className="text-xs font-medium text-left text-gray-500 mt-2">
                  Suivez les donnees genere par votre contenu sur Colorfol Artist
                </h4>
              </div>
              {/* Section Row de bouton d'entete */}
              <div className="row w-full mt-5">
                <div className="col s12 m6 l2 h-32">
                  <Link to="/app/publishPodcasts">
                    <a href="#">
                      <div className="card-panel h-full rounded-2xl bg-red-900 hoverable">
                        <div className="flex items-center justify-between p-2">
                          <div className="flex items-center justify-center h-16 w-16 rounded-full">
                            <i className="material-icons text-white text-7xl">
                              cloud_upload</i>
                          </div>
                          <h3 className="text-4xl white-text right-align font-bold brti">
                            Upload
                            <p className="text-sm white-text font-semibold brti">New Video</p>
                          </h3>
                        </div>
                      </div>
                    </a>
                  </Link>
                </div>
                <div className="col s12 m6 l2 h-32">
                  <Link to="#">
                    <a href="#">
                      <div className="card-panel h-full rounded-2xl hoverable">
                        <div className="flex items-center justify-between p-2">
                          <div className="flex items-center justify-center h-16 w-16 rounded-full">
                            <i className="material-icons grey-text text-darken-3 text-7xl">
                              audiotrack</i>
                          </div>
                          <h3 className="text-4xl right-align text-red-900 font-bold brti">
                            {episodes.count}
                            <p className="text-sm grey-text font-semibold brti">Total Tracks</p>
                          </h3>
                        </div>
                      </div>
                    </a>
                  </Link>
                </div>
                <div className="col s12 m6 l2 h-32">
                  <Link to="">
                    <a href="#">
                      <div className="card-panel h-full rounded-2xl hoverable">
                        <div className="flex items-center justify-between p-2">
                          <div className="flex items-center justify-center h-16 w-16 rounded-full">
                            <i className="material-icons grey-text text-darken-3 text-7xl">
                              album
                            </i>
                          </div>
                          <h3 className="text-4xl right-align text-red-900 font-bold brti">
                            {podcasts.count}
                            <p className="text-sm grey-text font-semibold brti">Total Podcasts</p>
                          </h3>
                        </div>
                      </div>
                    </a>
                  </Link>
                </div>
                <div className="col s12 m6 l2 h-32">
                  <Link to="#">
                    <a href="#">
                      <div className="card-panel h-full rounded-2xl hoverable">
                        <div className="flex items-center justify-between p-2">
                          <div className="flex items-center justify-center h-16 w-16 rounded-full">
                            <i className="material-icons grey-text text-darken-3 text-7xl">
                              queue_music</i>
                          </div>
                          <h3 className="text-4xl right-align text-red-900 font-bold brti">
                            2,556
                            <p className="text-sm grey-text font-semibold brti">Total Unit</p>
                          </h3>
                        </div>
                      </div>
                    </a>
                  </Link>
                </div>

                <div className="col s12 m6 l2 h-32">
                  <Link to="#">
                    <a href="#">
                      <div className="card-panel h-full rounded-2xl hoverable">
                        <div className="flex items-center justify-between p-2">
                          <div className="flex items-center justify-center h-16 w-16 rounded-full">
                            <i className="material-icons grey-text text-darken-3 text-7xl">
                              queue_music</i>
                          </div>
                          <h3 className="text-4xl right-align text-red-900 font-bold brti">
                            2,556
                            <p className="text-sm grey-text font-semibold brti">Total Unit</p>
                          </h3>
                        </div>
                      </div>
                    </a>
                  </Link>
                </div>

                <div className="col s12 m6 l2 h-32">
                  <Link to="#">
                    <a href="#">
                      <div className="card-panel h-full rounded-2xl hoverable">
                        <div className="flex items-center justify-between p-2">
                          <div className="flex items-center justify-center h-16 w-16 rounded-full">
                            <i className="material-icons grey-text text-darken-3 text-7xl">
                              queue_music</i>
                          </div>
                          <h3 className="text-4xl right-align text-red-900 font-bold brti">
                            2,556
                            <p className="text-sm grey-text font-semibold brti">Total Unit</p>
                          </h3>
                        </div>
                      </div>
                    </a>
                  </Link>
                </div>
              </div>
              {/* filtres */}
              <div className="row w-full mt-5">
                <div className="col s12 m6 l3 h-32">
                  <div className="row w-full px-5">
                    <label for="countries" class="block mb-2  text-gray-900 dark:text-white"><p className="text-sm font-bold">Filter by Artists</p></label>
                    <select id="countries" style={{width:'100%'}} className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option selected>Choose an Artist</option>
                      <option value="Yemi">Yemi</option>
                      <option value="Yemi">Yemi</option>
                      <option value="Yemi">Yemi</option>
                      <option value="Yemi">Yemi</option>
                    </select>

                  </div>
                </div>
                <div className="col s12 m6 l3 h-32">
                  <div className="row w-full px-5">
                    <label htmlFor="countries" className="block mb-2  text-gray-900 dark:text-white"><p className="text-sm font-bold">Filter by Podcasts/ by Titles</p></label>
                    <select id="countries" style={{width:'100%'}} className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option selected>Choose an Podcast or a Title</option>
                      <option value="Yemi">Yemi</option>
                      <option value="Yemi">Yemi</option>
                      <option value="Yemi">Yemi</option>
                      <option value="Yemi">Yemi</option>
                    </select>
                  </div>
                </div>
                <div className="col s12 m6 l3 h-32">
                  <div className="row w-full px-5">
                    <label htmlFor="countries" className="block mb-2  text-gray-900 dark:text-white"><p className="text-sm font-bold">Filter by Unit types</p></label>
                    <select id="countries" style={{width:'100%'}} className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option selected>Choose a Unit type</option>
                      <option value="Yemi">Yemi</option>
                      <option value="Yemi">Yemi</option>
                      <option value="Yemi">Yemi</option>
                      <option value="Yemi">Yemi</option>
                    </select>
                  </div>
                </div>
                <div className="col s12 m6 l3 h-32">
                  <div className="row w-full px-5">
                    <label htmlFor="countries" className="block mb-2  text-gray-900 dark:text-white"><p className="text-sm font-bold">Filter by Territories</p></label>
                    <select id="countries" style={{width:'100%'}} className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option selected>Choose a territory</option>
                      <option value="Yemi">Yemi</option>
                      <option value="Yemi">Yemi</option>
                      <option value="Yemi">Yemi</option>
                      <option value="Yemi">Yemi</option>
                    </select>
                  </div>
                </div>
              </div>
              {/* Chart Area */}
              <div className="row w-full">
                <div className="card-panel rounded-2xl h-full w-full">
                  <div className="row w-full">
                    <div className="col s12 m6 l3">
                      <p className="text-5xl grey-text text-darken-4 font-bold">Last Week</p>
                    </div>
                    <div className="col s12 m2 l5"></div>
                    <div className="col s12 m2 l4">
                      <div className="flex-row w-full justify-between">
                        <span className="orange-text text-darken-4 font-semibold border-b-2 border-red-600 pb-2">Week</span>
                        <span className="grey-text text-darken-4 font-semibold">Month</span>
                        <span className="grey-text text-darken-4 font-semibold">Last 3 Months</span>
                        <span className="grey-text text-darken-4 font-semibold">Year</span>
                        <span className="grey-text text-darken-4 font-semibold">Custom Date</span>
                      </div>
                    </div>
                  </div>
                  <AreaChart/>
                </div>
              </div>
              {/* Div Chart for Geographic and === */}
              <div className="row w-full">
                <div className="col s12 m8 l6 ">
                  <div className="card-panel rounded-2xl w-full h-full">
                    <MapChart/>
                  </div>
                </div>
                <div className="col s12 m8 l6">
                  <div className="card-panel rounded-2xl w-full h-full"></div>
                </div>
              </div>
            </div>
        ) : (
            <>
              <img src={uil} className="esi" />
              <span className="est">Aucun contenu disponible ici !</span>
              <Link exact to="/app/publishPodcasts" className="link">
                <span className="escta">Publiez votre premier contenu</span>
              </Link>
            </>
        )}
      </>
  );
}
export default Dashboard;
