import UserApi from "../../../api/users";
import {sleep} from "../../../utils/functions";

export async function handleSubmitForm ({payload , setIsSubmitting, setMsg, setConnected, setBackErr}) {

  // Initialization of an instance of UserApi class
  const userApi = new UserApi();

  // Some handlers
  console.log("===handle Submitting=======", setBackErr);
  setIsSubmitting(true);

  try {
    const response = await userApi.login(payload);

    setMsg("");
    setIsSubmitting(false);

    localStorage.setItem("access_token", response.data.access_token);

    await sleep(200);

    if (localStorage.getItem("access_token")) {
      if (response.data.user.is_artist) {
        document.location.href = "/app/dashboard";
      }
      if (response.data.user.is_podcaster) {
        document.location.href = "/app/dashboardPodcast";
      }
      if (response.data.user.is_label) {
        document.location.href = "/app/dashboardLabel";
      }
    }

    setConnected(true);
  } catch (err) {
    setMsg("Error, User Name or Password Incorrect :( ");
    setIsSubmitting(false);
    setBackErr(true);
    console.log("===handle Submitting errrrr =======", setBackErr);
  }
}