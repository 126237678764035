import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import "../../../styles/App.css";
import "../../../styles/publish.css";
import {
  ArrowBackOutline,
  CloseOutline,
  CloudUploadOutline, MusicalNotesSharp,
  Warning,
} from "react-ionicons";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Formik, useField, useFormikContext } from "formik";
import * as Yup from "yup";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import en from "date-fns/locale/en-US";
import moment from "moment";
import { SpinnerCircularFixed } from "spinners-react";
import Confetti from "react-confetti";
import "moment/locale/fr";
import CurrentUserContext from "../../../datamanager/contexts/currentUser";
import toast from 'react-hot-toast'
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import PodcastApi from "../../../api/podcast";
import { TestEnvAPI } from "../../../envs";

moment.locale("fr");
registerLocale("en", en);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
        personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
  };
}

export function MultipleSelectChip(...field) {
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setPersonName(
  //       // On autofill we get a stringified value.
  //       typeof value === 'string' ? value.split(',') : value,
  //   );
  // };

  return (
      <div>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-chip-label">Chip</InputLabel>
          <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={personName}
              onChange={field.handleChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                        <Chip key={value} label={value} />
                    ))}
                  </Box>
              )}
              MenuProps={MenuProps}
          >
            {names.map((name) => (
                <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, personName, theme)}
                >
                  {name}
                </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
  );
}

// ===================================================================================================================================================================

const SUPPORTED_FORMATS = ["image/jpeg", "image/jpg", "image/png"];
const SignupSchema = Yup.object().shape({
  title: Yup.string()
      .min(2, "Trop court!")
      .max(50, "Trop long!")
      .required("Required"),
  description: Yup.string().min(2, "Trop court!").max(550, "Trop long!").required("veillez entrer une description"),
  cover: Yup.mixed()
      .nullable()
      .required("Veuillez uploader votre cover!")
      .test(
          "FILE_SIZE",
          "L'image est trop lourde pour etre publiee",
          (value) => !value || (value && value.size <= 8024 * 8024)
      )
      .test(
          "FILE_FORMAT",
          "Veuillez choisir uniquement les fichers ayant les formats suivants : JPG, JPEG et  PNG",
          (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
      ),
  // sell_category: Yup.number().required("Veuillez choisir une catégorie"),
});

function PublishEpisode() {
  // Get data from the global state
  const { currentUser: user } = useContext(CurrentUserContext);

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };


  const [sfn, setSfn] = useState(null);
  const [sfp, setSfp] = useState(null);

  const inputAudio = useRef(null);
  const [selectedAudio, setSelectedAudio] = useState(null);


  const [page, setPage] = useState(1);
  const inputFile = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [backErr, setBackErr] = useState(false);
  const [msg, setMsg] = useState(
      "Oupss...les informations que vous avez fournis sont incorrectes !"
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [connected, setConnected] = useState(false);
  // const [sellCategories, setSellCategories] = useState([]);
  // const [isSetting, setIsSetting] = useState(false)
  const [settingStates, setSettingStates] = useState([]);
  const [metaDataStates, setMetaDataStates] = useState([]);
  const [customizations, setCustomizations] = useState([]);
  const [metaDataCustomizations, setMetaDataCustomizations] = useState([]);
  const [metaDataWritterNames, setMetaDataWriterNames] = useState([]);


  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadSpeed, setUploadSpeed] = useState(0);
  const [isFinishedUpload, setIsFinishedUpload] = useState(false);

  //
  // const customizationFields = [
  //   { label: <span className="text-xl font-bold">Titre</span>, type: "text" },
  //   { label: <span className="text-xl font-bold">Featuring<span className="text-white text-sm font-thin"> Entrer les noms seprares par des virgules <i className="material-icons text-sm">help</i> </span></span>, type: "text" },
  //   { label: <span className="text-xl font-bold">Producteurs</span>, type: "text" },
  //   { label: <span className="text-xl font-bold">Explicit</span>, type: "text" },
  //   // { label: "Field 2", type: "radio", options: ["Option 1", "Option 2"] },
  //   { label: <span className="text-xl font-bold">Sous genres</span>, type: "select", options: ["sous genre 1 ", "sous genre 2", "sous genre 3"] },
  //   { label: <span className="text-xl font-bold">ISRC</span>, type: "text" },
  // ]; // Liste des champs de personnalisation avec leurs types et options
  //

  // ... autres fonctions et gestionnaires

  const toggleSetting = (index) => {
    const newSettingStates = [...settingStates];
    newSettingStates[index] = !newSettingStates[index];
    setSettingStates(newSettingStates);

  };

  const toggleMetadata = (index) => {
    const newMetadatatates = [...metaDataStates];
    newMetadatatates[index] = !newMetadatatates[index];
    setMetaDataStates(newMetadatatates);

  };

  // const handleCustomizationsChange = (index, key, value) => {
  //   const newCustomizationsList = [...customizations];
  //   newCustomizationsList[index] = {
  //     ...newCustomizationsList[index],
  //     [key]: value,
  //   };
  //   setCustomizations(newCustomizationsList);
  // };

  const handleCustomizationFieldChange = (index, field, value) => {
    const newCustomizationsList = [...customizations];
    if (!newCustomizationsList[index]) {
      newCustomizationsList[index] = {};
    }
    newCustomizationsList[index][field] = value;
    setCustomizations(newCustomizationsList);
  };

  const handleSongTitleFieldChangeOnPreview = (value) => {
    const newSelectedAudios = [...selectedAudio];
    newSelectedAudios[0].name = value;
    setSelectedAudio(newSelectedAudios)
  }

  const handleMetaDataCustomizationFieldChange = (index, field, value) => {
    const newCustomizationsList = [...metaDataCustomizations];
    if (!newCustomizationsList[index]) {
      newCustomizationsList[index] = {};
    }
    newCustomizationsList[index][field] = value;
    setMetaDataCustomizations(newCustomizationsList);
  };

  const format1 = "DD MMM YYYY";
  const format2 = "HH:mm";
  const format3 = "YYYY-MM-DDThh:mm";
  const token = localStorage.getItem("access_token");

  const handleErrors = (errors) => {
    for (let error of errors) {
      switch (error.field) {
        case "category": {
          toast.error("Veuillez choisir une categorie", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          break;
        }

        case "album": {
          toast.error("Veuillez choisir un album", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          break;
        }

        case "title": {
          toast.error("Veuillez fournir un titre", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          break;
        }

        case "cover": {
          toast.error("Veuillez choisir une photo de couverture", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          break;
        }

        case "src": {
          toast.error("Veuillez charger un episode", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          break;
        }

        default:
          break;
      }
    }
  };

  const handleNextPage = (errors) => {
    const array = Object.entries(errors);

    if (array.length === 0) {
      setPage(prev => prev + 1);
    } else {
      for (let error of array) {
        toast(error.join(": "), {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeButton: true,
          newestOnTop: true,
        })
      }
    }
  }

  const DatePickerField = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    return (
        <DatePicker
            locale="en"
            className="w-full h-1 p-10 date_text"
            showTimeSelect
            dateFormat="Pp"
            {...field}
            {...props}
            selected={(field.value && new Date(field.value)) || null}
            onChange={(val) => {
              setFieldValue(field.name, moment(val).format(format3)).then(
                r => { }
              );
            }}
        />
    );
  };

  const handleReorderAudio = (oldIndex, newIndex) => {
    const newSelectedAudio = [...selectedAudio];
    const [movedItem] = newSelectedAudio.splice(oldIndex, 1);
    newSelectedAudio.splice(newIndex, 0, movedItem);

    // Mettre à jour les personnalisations en conséquence
    const newCustomizations = [...customizations];
    const movedCustomization = newCustomizations.splice(oldIndex, 1)[0];
    newCustomizations.splice(newIndex, 0, movedCustomization);

    // Mettre à jour les personnalisations en conséquence
    const newMetaDataCustomizations = [...metaDataCustomizations];
    const movedMetaDataCustomization = newMetaDataCustomizations.splice(oldIndex, 1)[0];
    newMetaDataCustomizations.splice(newIndex, 0, movedMetaDataCustomization);

    setSelectedAudio(newSelectedAudio);
    setCustomizations(newCustomizations);
    setMetaDataCustomizations(newMetaDataCustomizations)
  };


  let history = useNavigate();
  const goToPreviousPath = () => {
    history(-1);
  };
  const [steps] = useState([
    "Definissez de l'episode",
    "Ajoutez l'episode'",
    "personnalisez l'episode et publier",
  ]);

  const handleDeleteAudio = (index) => {
    const newSelectedAudio = [...selectedAudio];
    newSelectedAudio.splice(index, 1);
    setSelectedAudio(newSelectedAudio);
  };

  let temp;

  const handleResetMetaDataCustomization = (index) => {
    const newCustomizationsList = [...metaDataCustomizations];
    if (newCustomizationsList[index]) {
      // Réinitialiser les valeurs des champs de l'index courant
      newCustomizationsList[index] = {};
    }
    setMetaDataCustomizations(newCustomizationsList);
  };

  return (
      <div>
        {
          user ? (
              <Formik
                  initialValues={{
                    title: "",
                    cover: "",
                    src: [],
                    description: "",
                    date:  moment(Date()).format(format3),
                    nb_of_download: 0,
                    can_sell: false,
                    price: 0,
                    category: null,
                    pre_order: false,
                    pre_order_end_date: null,
                    artist: null,
                    media_accessibility : null,
                    // sell_category: 0,
                    area:"",
                  }}
                  validationSchema={SignupSchema}
                  onSubmit={ async (values) => {
                    setIsSubmitting(true);
                    let formData = new FormData();

                    // for (const property in values) {
                    //   formData.append(property, values[property]);
                    // }

                    // definition du titre dans le formData
                    formData.append("title", values["title"]);

                    // definition de la taille du fichier
                    // formData.append("length", null);

                    // definition de la date de publication
                    formData.append("publication_date", values["date"]);


                    // definition du prix du single
                    formData.append("price", values["price"]);

                    // definition de la cover
                    formData.append("cover", values["cover"]);

                    // definition de la source
                    formData.append("src", values["src"][0]);

                    // definition de la description
                    formData.append("description", values["description"]);

                    // definition de l'accessibilite du media
                    // formData.append("media_accessibility", values["media_accessibility"]);

                    // definition du single ou pas
                    formData.append("is_single", true);

                    // definition de du lyric
                    // formData.append("lyric", null);

                    // definition de du nombre de telechargements
                    // formData.append("nb_of_download", values["nb_of_download"]);

                    // definition de la source
                    // formData.append("source", null);

                    // definition des meta-donnees
                    let metadata = customizations[0];
                    metadata["additional_meta_data"] = metaDataCustomizations[0];
                    metadata["upc"] = values["upc"]
                    formData.append("metadata", JSON.stringify(metadata));

                    // definition de possibiliter de pre-achat
                    // formData.append("pre_order_listen", false);

                    // definition de l'explicite
                    // formData.append("explicit", false);

                    // definition du nombre d'ecoute
                    // formData.append("nb_of_listenning", 0);

                    // definition de l'album'
                    // formData.append("album", null);

                    // definition de l'auteur
                    formData.append("author", values.artist);

                    // definition de la cathegorie
                    formData.append("category", values.category);

                    // definition des likers
                    // formData.append("likers", null);

                    const podcastApi = new PodcastApi(token);

                    const AxiosConfig = {
                      onUploadProgress: progressEvent => {
                        const percentage = (progressEvent.loaded / progressEvent.total) * 100;
                        setUploadPercentage(percentage);
                        if(percentage === 100)
                          setIsFinishedUpload(true)

                        const currentTime = Date.now();
                        const startTime = Date.now();
                        const elapsedTime = (currentTime - startTime) / 1000; // in seconds
                        const speed = progressEvent.loaded / elapsedTime; // bytes per second
                        setUploadSpeed(speed);
                      },
                    };

                    const response = await podcastApi.publishEpisode(formData, AxiosConfig);


                    if (response.data) {
                      await sleep(800);
                      setConnected(true);

                      toast.success("Votre episode a été publié avec succès", {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                      });

                      await sleep(120000000);
                      document.location = "/app/dashboardPodcast";
                    } else {
                      const err = response.error;

                      const errors = Object.values(response.error.response.data.errors);

                      handleErrors(errors);

                      // Access Token was expired
                      if (
                          err ===
                          "Failed to load resource: the server responded with a status of 401 (Unauthorized)"
                      ) {
                        const storedToken = JSON.parse(
                            localStorage.getItem("auth").data
                        );

                        try {
                          const rs = await axios.post(
                              `${TestEnvAPI}/user-api/auth/token/refresh/`,
                              {
                                refresh: storedToken.refresh_token,
                              }
                          );

                          const { access } = rs.data;

                          localStorage.setItem("access_token", access);

                          return;
                        } catch (_error) {
                          return Promise.reject(_error);
                        }
                      }
                      if (err === "Error: Request failed with status code 400") {
                        setMsg(
                            "Oupss...les informations que vous avez fournis sont incorrectes !"
                        );
                      } else {
                        setMsg(
                            "Oupss...une erreur est survenue ! Verifier votre connection internet."
                        );
                      }
                      await sleep(800);
                      setIsSubmitting(false);
                      setBackErr(true);
                    }

                    // axios
                    //     .post(`${TestEnvAPI}/media-api/albums/`, formData, {
                    //       mode: "no-cors",
                    //       headers: {
                    //         Authorization: `Bearer ${token}`,
                    //         "Content-Type": "multipart/form-data",
                    //       },
                    //     })
                    //     .then(async (response) => {
                    //       if (!response) {
                    //         setMsg(
                    //             "Oupss...une erreur est survenue ! Verifier votre connection internet."
                    //         );
                    //         await sleep(800);
                    //         setIsSubmitting(false);
                    //         setBackErr(true);
                    //       } else {
                    //         if (response.type === "opaque") {
                    //           const storedToken = JSON.parse(localStorage.getItem("auth"))
                    //               .data.refreshs_token;
                    //           try {
                    //             const rs = await axios.post(
                    //                 `${TestEnvAPI}/user-api/auth/token/refresh/`,
                    //                 {
                    //                   refresh: storedToken,
                    //                 },
                    //                 {
                    //                   mode: "no-cors",
                    //                   headers: {
                    //                     Authorization: `Bearer ${token}`,
                    //                     "Content-Type": "multipart/form-data",
                    //                   },
                    //                 }
                    //             );
                    //
                    //             const { access } = rs.data;
                    //
                    //             localStorage.setItem(
                    //                 "auth".data.access_token,
                    //                 JSON.stringify(access)
                    //             );
                    //
                    //             await sleep(120000000);
                    //             document.location = "/app/dashboard";
                    //             return;
                    //           } catch (_error) {
                    //             return Promise.reject(_error);
                    //           }
                    //         } else {
                    //           await sleep(800);
                    //           setConnected(true);
                    //           await sleep(120000000);
                    //           document.location = "/app/dashboard";
                    //         }
                    //       }
                    //     })
                    //     .catch(async (err) => {
                    //       // Access Token was expired
                    //       if (
                    //           err ===
                    //           "Failed to load resource: the server responded with a status of 401 (Unauthorized)"
                    //       ) {
                    //         const storedToken = JSON.parse(
                    //             localStorage.getItem("auth").data
                    //         );
                    //
                    //         try {
                    //           const rs = await axios.post(
                    //               `${TestEnvAPI}/user-api/auth/token/refresh/`,
                    //               {
                    //                 refresh: storedToken.refresh_token,
                    //               }
                    //           );
                    //
                    //           const { access } = rs.data;
                    //
                    //           localStorage.setItem(
                    //               "auth".data.access_token,
                    //               JSON.stringify(access)
                    //           );
                    //
                    //           return;
                    //         } catch (_error) {
                    //           return Promise.reject(_error);
                    //         }
                    //       }
                    //       if (err === "Error: Request failed with status code 400") {
                    //         setMsg(
                    //             "Oupss...les informations que vous avez fournis sont incorrectes !"
                    //         );
                    //       } else {
                    //         setMsg(
                    //             "Oupss...une erreur est survenue ! Verifier votre connection internet."
                    //         );
                    //       }
                    //       await sleep(800);
                    //       setIsSubmitting(false);
                    //       setBackErr(true);
                    //     });
                  }}
              >
                {({
                    errors,
                    touched,
                    values,
                    handleChange,
                    setFieldValue,
                    handleSubmit,
                    isValid
                  }) => (
                    <form>
                      <div class="nav snv">
                        <span class="ptt">Ajoutez un episode Audio</span>
                        <ul class="ac">
                          <li>
                            <a>{page}/4</a>
                          </li>
                          <li>
                            <a>
                              <CloseOutline
                                  onClick={goToPreviousPath}
                                  color={"#6a0f3e"}
                                  title={"Meli-Melo"}
                                  height="36px"
                                  width="36px"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>

                      {isSubmitting ? (
                          <>
                            <div className={connected ? "upss" : "lcd"}>
                              {connected && (
                                  <>
                                    <Confetti
                                        className="ziz"
                                        width={1600}
                                        height={620}
                                        numberOfPieces={300}
                                        colors={[
                                          "#55476a",
                                          "#ae3d63",
                                          "#db3853",
                                          "#f45c44",
                                          "#f8b646",
                                        ]}
                                    />

                                    <span className="rcov">
                            <img
                                alt="cover"
                                src={URL.createObjectURL(selectedImage)}
                            />
                          </span>
                                    <span className="psttl">Vous y êtes</span>
                                  </>
                              )}
                              {isSubmitting && !connected && (
                                  <div className="m-auto text-3xl">
                                    {!isFinishedUpload ?<>
                                          <SpinnerCircularFixed
                                              size={50}
                                              thickness={121}
                                              speed={150}
                                              color="rgba(133, 19, 78, 1)"
                                              secondaryColor="rgba(231, 208, 220, 1)"
                                              className="m-auto"
                                          /> {uploadPercentage.toFixed(2)} %
                                          <p>Publication de l'episode {values.title} sur Colorfol Artist...</p>
                                        </>:
                                        <p>Finalisation des dernieres modifications, Merci de patienter quelques instants ...</p>
                                    }
                                  </div>
                              )}
                            </div>
                          </>
                      ) : backErr ? (
                          <>
                            <div className="lcd">
                              <Warning
                                  color={"rgba(133, 19, 78, 1)"}
                                  title={"Connecte"}
                                  height="56px"
                                  width="56px"
                              />
                              <p className="load-title">{msg}</p>
                              <span
                                  className="mt-16 form-sub"
                                  onClick={() => {
                                    setBackErr(false);
                                  }}
                              >
                        Ressayer
                      </span>
                            </div>
                          </>
                      ) : (
                          <div className="pf-card ">
                            <div className="w-full desktop:w-4/6 ">
                              <div className="w-full h-1/7 py-10">
                                <Stepper activeStep={page-1}>
                                  {steps.map((label) => (
                                      <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                      </Step>
                                  ))}
                                </Stepper>
                              </div>
                              <div className="if-conp">
                                {page === 1 && (
                                    <div className="form-barp">
                                      <div className="form-lbp w-full">
                                        <p className="text-5xl text-gray-700 font-bold pb-5">Definissez les details de l'episode</p>
                                        <div className="flex-row-center mb-20">
                                          {/* Input d'upload de la cover */}
                                          <input
                                              id="cover"
                                              ref={inputFile}
                                              type="file"
                                              className="myImage"
                                              accept="image/png, image/jpg, image/jpeg"
                                              name="cover"
                                              onChange={(event) => {
                                                let file = event.target.files[0];
                                                if (file && !file.name) {
                                                  window.alert("Aucun fichier n'a ete selectionne");
                                                }
                                                if (file.size > 10e6) {
                                                  window.alert("Veillez choisir une image de moins de 10 Mo");
                                                }
                                                handleChange(event);
                                                setFieldValue("cover", file).then(
                                                    () => {
                                                        setSelectedImage(file);
                                                    }
                                                );
                                              }}
                                          />

                                          {/* affichage de la cover uploade */}
                                          {selectedImage && (
                                              <>
                                                <div
                                                    className="cover-con"
                                                    onClick={() => inputFile.current.click()}
                                                >
                                                  <img
                                                      alt="cover"
                                                      src={URL.createObjectURL(selectedImage)}
                                                  />
                                                </div>
                                              </>
                                          )}

                                          {/* affichage de la cover avant l'upload */}
                                          {!selectedImage && (
                                              <>
                                                <div
                                                    className="cover-i"
                                                    onClick={() => inputFile.current.click()}
                                                >
                                                  + Cover <span className="text-red-600">*</span>
                                                </div>
                                              </>
                                          )}

                                          {/* enonce des directives de lacover */}
                                          <div className="imgl">
                                            <p className="text-3xl font-semibold">Choisissez un fichier image</p>
                                            <strong>Directives concernant l'image</strong>
                                            <ul>
                                              <li>Forme carrée, minimum <span className="font-semibold">500x500 px</span></li>
                                              <li>
                                                Format du fichier : PNG (favoris) ou JPEG
                                              </li>
                                            </ul>

                                            <p>
                                              Le respect de ses regles est primordiales pour
                                              la prise en charge de votre publication.
                                            </p>
                                          </div>
                                        </div>

                                        {/* formulaire etape 1 */}
                                        <div className="row w-full">
                                          <div className="col l6">
                                            <label htmlFor="title"><p className="text-1xl text-gray-700 font-bold">Titre de l'episode<span className="text-red-600">*</span></p></label>
                                            <input
                                                id="title"
                                                name="title"
                                                type="text"
                                                style={{
                                                  width: '100%'
                                                }}
                                                onChange={handleChange}
                                                value={values.title}
                                                placeholder="Entrer le titre de votre episode"
                                            />
                                          </div>

                                          <div className="col l6">
                                            <label htmlFor="upc"><p className="text-1xl text-gray-700 font-bold">UPC</p></label>
                                            <input
                                                id="upc"
                                                name="upc"
                                                type="text"
                                                style={{
                                                  width: '100%'
                                                }}
                                                onChange={handleChange}
                                                value={values.upc}
                                                placeholder="Entrer l'UPC de l'album si disponnible"
                                            />
                                          </div>
                                        </div>

                                        <div className="row w-full">
                                          <div className="col l12">
                                            <label htmlFor="description" className="mt-16 font-bold">
                                              <p className="text-1xl text-gray-700 font-bold">Veuillez décrire votre episode. <span className="text-red-600">*</span></p>
                                            </label>
                                            <textarea
                                                className="w-full h-32 border-2 border-gray-400 rounded p-3 flex-auto"
                                                id="description"
                                                name="description"
                                                style={{
                                                  width:'100%'
                                                }}
                                                placeholder="Veiller entrer la description de l'album ici"
                                                value={values.description}
                                                onChange={handleChange}
                                                rows={12}
                                                cols={12}
                                            ></textarea>
                                          </div>
                                          {errors.description ? <div className="text-red-600">{errors.description}</div>:null}
                                        </div>

                                        {/* erreur titre */}
                                        {errors.title ? <div>{errors.title}</div> : null}

                                        {errors.cover ? <div>{errors.cover}</div> : null}

                                        {/* erreur titre */}
                                        {errors.title ? <div>{errors.title}</div> : null}

                                        {/* erreurs etape 1 */}
                                        <div className="flex-row mt-12">
                                          {!errors.title &&
                                          values.cover !== "" &&
                                          values.description !== "" ? (
                                              <button
                                                  className="mr-12 fbv"
                                                  type="button"
                                                  onClick={() => handleNextPage(errors)}
                                              >
                                                Suivant
                                              </button>
                                          ) : (
                                              <button className="mr-12 fbvdi" type="button">
                                                Suivant
                                              </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                )}

                                {page === 2 && (
                                    <>
                                      <div className="form-bar">
                                        <div className="form-lbp w-full">
                                          <p className="text-5xl text-gray-700 font-bold pb-5">Uploadez l'audio</p>
                                          {/*<div className="flex-row-center mb-12">*/}
                                          {/*  <div className="cover-iu">*/}
                                          {/*    <CloudUploadOutline*/}
                                          {/*      color={"#6a0f3e"}*/}
                                          {/*      title={"musique"}*/}
                                          {/*      height="40px"*/}
                                          {/*      width="40px"*/}
                                          {/*      className="mb-12"*/}
                                          {/*    />*/}
                                          {/*    {values.title}*/}
                                          {/*  </div>*/}
                                          {/*  <div className="imgl">*/}
                                          {/*    <strong>*/}
                                          {/*      Directives concernant la publication*/}
                                          {/*    </strong>*/}
                                          {/*    <ul>*/}
                                          {/*      <li>Prix de vente par defaut: 0 euro</li>*/}
                                          {/*    </ul>*/}

                                          {/*    <p>*/}
                                          {/*      L'album "{values.title}" seras sousmis a une*/}
                                          {/*      verification par notre equipe avant d'etre*/}
                                          {/*      publie.*/}
                                          {/*    </p>*/}
                                          {/*  </div>*/}
                                          {/*</div>{" "}*/}
                                          {/*<div className="flex-row">*/}
                                          {/*  <div className="flex-column ">*/}
                                          {/*    <label>Votre album est-il en vente ?</label>*/}
                                          {/*    <div>*/}
                                          {/*      <label htmlFor="radioFalse"> Oui</label>*/}
                                          {/*      <input*/}
                                          {/*          id="radioFalse"*/}
                                          {/*        type="radio"*/}
                                          {/*        name="can_sell"*/}
                                          {/*        className="sm-check ml-1 mr-2"*/}
                                          {/*        value={true}*/}
                                          {/*        onChange={handleChange}*/}
                                          {/*      ></input>*/}
                                          {/*      <label htmlFor="radioTrue"> Non</label>*/}
                                          {/*      <input*/}
                                          {/*          id="radioTrue"*/}
                                          {/*        type="radio"*/}
                                          {/*        name="can_sell"*/}
                                          {/*        className="sm-check ml-1 mr-2"*/}
                                          {/*        onChange={handleChange}*/}
                                          {/*        value={false}*/}
                                          {/*      ></input>*/}
                                          {/*    </div>*/}
                                          {/*  </div>*/}
                                          {/*</div>*/}
                                          {/*{values.can_sell ? (*/}
                                          {/*  <div className="flex-column mt-16">*/}
                                          {/*    <label htmlFor="price">*/}
                                          {/*      Definissez le prix de vente de votre album*/}
                                          {/*    </label>*/}
                                          {/*    <input*/}
                                          {/*      className="ml-12 sm-input"*/}
                                          {/*      type="number"*/}
                                          {/*      id="price"*/}
                                          {/*      name="price"*/}
                                          {/*      onChange={handleChange}*/}
                                          {/*      placeholder="Prix en euro "*/}
                                          {/*    ></input>*/}
                                          {/*  </div>*/}
                                          {/*) : null}*/}

                                          <input
                                              id="src"
                                              accept="audio/mp3, audio/acc, audio/ma4"
                                              ref={inputAudio}
                                              type="file"
                                              name="src"
                                              className="li myAudio"
                                              onChange={(event) => {
                                                handleChange(event);

                                                const selectedFiles = Array.from(event.target.files); // Convertir FileList en tableau
                                                setFieldValue("src", selectedFiles).then(
                                                    () => {
                                                      setSelectedAudio(selectedFiles);

                                                      // Si vous souhaitez afficher les noms et les aperçus des fichiers sélectionnés, vous pouvez les mapper ainsi
                                                      const fileNames = selectedFiles.map(file => file.name);
                                                      setSfn(fileNames);

                                                      const filePreviews = selectedFiles.map(file => URL.createObjectURL(file));
                                                      setSfp(filePreviews);
                                                    }
                                                );

                                              }}
                                              // multiple // Ajout de l'attribut multiple pour prendre en charge plusieurs fichiers
                                          />

                                          <div className="flex-row-center mb-20">
                                            {!selectedAudio && (
                                                <>
                                                  <div
                                                      className="cover-i"
                                                      onClick={() =>
                                                          inputAudio.current.click()
                                                      }
                                                  >
                                                    <p className="text-2xl font-bold">+ Audio<span className="text-red-600 text-2xl">*</span></p>
                                                  </div>
                                                </>
                                            )}
                                            {selectedAudio && (
                                                <>
                                                  <div
                                                      className="cover-iu p-5"
                                                      onClick={() =>
                                                          inputAudio.current.click()
                                                      }
                                                  >
                                                    {/*<MusicalNotesSharp*/}
                                                    {/*    color={"#6a0f3e"}*/}
                                                    {/*    title={"musique"}*/}
                                                    {/*    height="40px"*/}
                                                    {/*    width="40px"*/}
                                                    {/*    className="mb-12"*/}
                                                    {/*/>*/}
                                                    <i className="material-icons text-7xl" style={{cursor : "pointer"}}>playlist_add</i>
                                                  </div>
                                                </>
                                            )}
                                            <div className="imgl">
                                              <p className="text-3xl font-bold pb-5">Choisissez l'Audio MP3</p>
                                              <strong>
                                                Directives concernant l'audio
                                              </strong>
                                              <ul>
                                                <li>
                                                  Taille du fichier: maximum 100 Mb
                                                </li>
                                                <li>
                                                  Format du fichier : MP3 (96-320kpbs)
                                                </li>
                                              </ul>

                                              <p>
                                                Le respect de ses regles est
                                                primordiales pour la prise en charge de
                                                votre publication.
                                              </p>
                                            </div>
                                          </div>
                                          <div className="p-5 w-full border-dotted border-2 border-pink-900 rounded-2xl">
                                            {selectedAudio ? selectedAudio.map((audio, index)=>(
                                                <div className="w-full row mybg rounded-2xl p-5">
                                                  <div className="flex justify-between">
                                                    <span className="text-2xl text-white font-bold">{audio.name} | <span className="text-sm font-bold text-white"><span className="text-green-700">Uploaded <i className="material-icons text-sm">check_circle</i></span></span></span>
                                                    <i className="material-icons text-white" onClick={()=>handleDeleteAudio(index)} style={{ cursor: 'pointer' }}>delete</i>
                                                  </div>
                                                </div>
                                            )):<p className="center">
                                              <span className="text-3xl">Ajoutez/Supprimez l'audio</span>
                                              <p>uploadez un audios.</p>
                                            </p>}
                                          </div>
                                          <div className="mt-12  flex-row ">
                                            <button className='sbbv' type='button' onClick={() => setPage(page - 1)}><ArrowBackOutline color={'#f2f2f2'} title={"Meli-Melo"} height="28px" width="28px" /></button>
                                            {selectedAudio ? (
                                                <button
                                                    className="mlr-12 fbv"
                                                    type="submit"
                                                    onClick={() => handleNextPage(errors)}
                                                >
                                                  Suivant
                                                </button>
                                            ) : (
                                                <button className="mlr-12 fbvdi" type="button">
                                                  Suivant
                                                </button>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                )}

                                {page === 3 && (
                                    <>
                                      <div className="form-bar ">
                                        <div className="form-lbp w-full">
                                          <p className="text-gray-700 text-4xl font-bold mb-10">Personnalisez les meta donnees de l'episode</p>
                                          <div className="p-5 w-full border-dotted border-2 border-pink-900 rounded-2xl constraint overflow-auto">
                                            {selectedAudio.map((audio, index) => (
                                                <div className="w-full row mybg rounded-2xl p-5" key={audio.name}>
                                                  <div
                                                      className="w-full row"
                                                      draggable
                                                      onDragStart={(event) => event.dataTransfer.setData('text/plain', index)}
                                                      onDragOver={(event) => event.preventDefault()}
                                                      onDrop={(event) => {
                                                        event.preventDefault();
                                                        const oldIndex = Number(event.dataTransfer.getData('text/plain'));
                                                        handleReorderAudio(oldIndex, index);
                                                      }}
                                                  >
                                                    <div className="flex justify-between"
                                                         style={{
                                                           cursor:'move'
                                                         }}
                                                    >
                                                      <span className="text-2xl text-white font-bold">
                                                        {audio.name} |{" "}
                                                        <span className="text-sm font-bold text-white">
                                                          <span className="text-green-700">
                                                            Uploaded <i className="material-icons text-sm">check_circle</i>
                                                          </span>
                                                        </span>
                                                      </span>
                                                      <i
                                                          className="material-icons text-white"
                                                          onClick={() => toggleSetting(index)}
                                                          style={{ cursor: 'pointer' }}
                                                      >
                                                        {settingStates[index] ? "close" : "settings"}
                                                      </i>
                                                    </div>
                                                    {settingStates[index] ? (
                                                        <div className="row w-full p-10 text-white">
                                                          <div className="row w-full">
                                                            <div className="col l5">
                                                              <label htmlFor="music_title"><p className="text-white font-bold">Titre<span className="text-black text-sm">*</span></p></label>
                                                              <input
                                                                  id=""
                                                                  type="text"
                                                                  className="input_text"
                                                                  style={{
                                                                    width: '100%'
                                                                  }}
                                                                  onChange={(event) => {
                                                                    if (event.target.value) {
                                                                      handleCustomizationFieldChange(index, "title", event.target.value);
                                                                    } else {
                                                                      handleCustomizationFieldChange(index, "title", ""); // Réinitialiser à une chaîne vide si le champ est vide
                                                                    }
                                                                    handleChange(event);
                                                                  }}
                                                                  value={customizations[index]?.title || ""}
                                                                  placeholder={customizations[index]?.title ? customizations[index].title:audio.name}
                                                              />
                                                            </div>
                                                            <div className="col l1"></div>
                                                            <div className="col l5">
                                                              <label htmlFor="music_featuring"><p className="text-white font-bold">Featurings<span className="text-gray-400 text-sm"> ( separer les noms par des virgules )</span></p></label>
                                                              <input
                                                                  id=""
                                                                  type="text"
                                                                  className="input_text"
                                                                  style={{
                                                                    width: '100%'
                                                                  }}
                                                                  onChange={(event) => {
                                                                    if (event.target.value) {
                                                                      handleCustomizationFieldChange(index, "feats", event.target.value);
                                                                    } else {
                                                                      handleCustomizationFieldChange(index, "feats", ""); // Réinitialiser à une chaîne vide si le champ est vide
                                                                    }
                                                                    handleChange(event);
                                                                  }}
                                                                  value={customizations[index]?.feats || ""}
                                                                  placeholder={customizations[index]?.feats ? customizations[index].feats:"artiste 1, artiste 2, ..."}
                                                              />
                                                            </div>
                                                          </div>
                                                          <div className="row w-full">
                                                            <div className="col l5">
                                                              <label htmlFor="music_title"><p className="text-white font-bold">Producteur(s)<span className="text-black text-sm">*</span></p></label>
                                                              <input
                                                                  id=""
                                                                  type="text"
                                                                  className="input_text"
                                                                  style={{
                                                                    width: '100%'
                                                                  }}
                                                                  onChange={(event) => {
                                                                    if (event.target.value) {
                                                                      handleCustomizationFieldChange(index, "Producteur", event.target.value);
                                                                    } else {
                                                                      handleCustomizationFieldChange(index, "Producteur", ""); // Réinitialiser à une chaîne vide si le champ est vide
                                                                    }
                                                                    handleChange(event);
                                                                  }}
                                                                  value={customizations[index]?.Producteur || ""}
                                                                  placeholder={customizations[index]?.Producteur ? customizations[index].Producteur:"prod 1, prod 2, pord 3"}
                                                              />
                                                            </div>
                                                            <div className="col l1"></div>
                                                            <div className="col l5 w-full">
                                                              <label htmlFor="music_featuring"><p className="text-white font-bold">Explicit<span className="text-gray-400 text-sm"></span></p></label>
                                                              <select
                                                                  id=""
                                                                  className="select_text"
                                                                  style={{
                                                                    width: '100%',
                                                                    display:'block'
                                                                  }}
                                                                  onChange={(event) => {
                                                                    if (event.target.value) {
                                                                      handleCustomizationFieldChange(index, "Explicit", event.target.value);
                                                                    } else {
                                                                      handleCustomizationFieldChange(index, "Explicit", ""); // Réinitialiser à une chaîne vide si le champ est vide
                                                                    }
                                                                    handleChange(event);
                                                                  }}
                                                                  value={customizations[index]?.Explicit || ""}
                                                                  placeholder={customizations[index]?.Explicit ? customizations[index].Explicit:"selectionner l'explicite"}
                                                              >
                                                                <option value="">selectionner l'explicite</option>
                                                                <option value="false">non</option>
                                                                <option value="true">oui</option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                          <div className="row w-full">
                                                            <div className="col l11">
                                                              <label htmlFor="music_title"><p className="text-white font-bold">ISRC<span className="text-black text-sm">*</span></p></label>
                                                              <input
                                                                  id=""
                                                                  type="text"
                                                                  className="input_text"
                                                                  style={{
                                                                    width: '100%'
                                                                  }}
                                                                  onChange={(event) => {
                                                                    if (event.target.value) {
                                                                      handleCustomizationFieldChange(index, "irsc", event.target.value);
                                                                    } else {
                                                                      handleCustomizationFieldChange(index, "irsc", ""); // Réinitialiser à une chaîne vide si le champ est vide
                                                                    }
                                                                    handleChange(event);
                                                                  }}
                                                                  value={customizations[index]?.irsc || ""}
                                                                  placeholder={customizations[index]?.irsc ? customizations[index].irsc:"entrer le code IRSC s'il existe"}
                                                              />
                                                            </div>
                                                          </div>
                                                          <p>Meta donnees supplementaires<i
                                                              className="material-icons text-white text-sm"
                                                              onClick={() => toggleMetadata(index)}
                                                              style={{ cursor: 'pointer' }}
                                                          >
                                                            {metaDataStates[index] ? "arrow_drop_down" : "arrow_right"}
                                                          </i></p>
                                                          <div>
                                                            {metaDataStates[index] ? (
                                                                <div className="row w-full p-10 text-white">
                                                                  <div className="row w-full">
                                                                    <div className="col w-full l6">
                                                                      <label htmlFor="music_copyright"><p className="text-white font-bold">Copyright de l'auteur de l'episode</p></label>
                                                                      <input
                                                                          id=""
                                                                          type="text"
                                                                          className="input_text"
                                                                          style={{
                                                                            width: '100%'
                                                                          }}
                                                                          onChange={(event) => {
                                                                            if (event.target.value) {
                                                                              handleMetaDataCustomizationFieldChange(index, "music_copyright", event.target.value);
                                                                            } else {
                                                                              handleMetaDataCustomizationFieldChange(index, "music_copyright", ""); // Réinitialiser à une chaîne vide si le champ est vide
                                                                            }
                                                                            handleChange(event);
                                                                          }}
                                                                          value={metaDataCustomizations[index]?.music_copyright || ""}
                                                                          placeholder={metaDataCustomizations[index]?.music_copyright ? metaDataCustomizations[index].music_copyright:"( separer les noms par des virgules )"}
                                                                      />
                                                                    </div>
                                                                    <div className="col w-full l6">
                                                                      <label htmlFor="music_producer"><p className="text-white font-bold">nom(s) de producteur(s)</p></label>
                                                                      <input
                                                                          id=""
                                                                          type="text"
                                                                          className="input_text"
                                                                          style={{
                                                                            width: '100%'
                                                                          }}
                                                                          onChange={(event) => {
                                                                            if (event.target.value) {
                                                                              handleMetaDataCustomizationFieldChange(index, "music_producer", event.target.value);
                                                                            } else {
                                                                              handleMetaDataCustomizationFieldChange(index, "music_producer", ""); // Réinitialiser à une chaîne vide si le champ est vide
                                                                            }
                                                                            handleChange(event);
                                                                          }}
                                                                          value={metaDataCustomizations[index]?.music_producer || ""}
                                                                          placeholder={metaDataCustomizations[index]?.music_producer ? metaDataCustomizations[index].music_producer:"( separer les noms par des virgules )"}
                                                                      />
                                                                    </div>
                                                                  </div>

                                                                  <div className="row w-full">
                                                                    <div className="col w-full l4">
                                                                      <label htmlFor="music_p_line"><p className="text-white font-bold">ligne P</p></label>
                                                                      <input
                                                                          id=""
                                                                          type="text"
                                                                          className="input_text"
                                                                          style={{
                                                                            width: '100%'
                                                                          }}
                                                                          onChange={(event) => {
                                                                            if (event.target.value) {
                                                                              handleMetaDataCustomizationFieldChange(index, "music_p_line", event.target.value);
                                                                            } else {
                                                                              handleMetaDataCustomizationFieldChange(index, "music_p_line", ""); // Réinitialiser à une chaîne vide si le champ est vide
                                                                            }
                                                                            handleChange(event);
                                                                          }}
                                                                          value={metaDataCustomizations[index]?.music_p_line || ""}
                                                                          placeholder={metaDataCustomizations[index]?.music_p_line ? metaDataCustomizations[index].music_p_line:""}
                                                                      />
                                                                    </div>
                                                                    <div className="col w-full l4">
                                                                      <label htmlFor="music_catalog_number"><p className="text-white font-bold">numero de catalogue</p></label>
                                                                      <input
                                                                          id=""
                                                                          type="text"
                                                                          className="input_text"
                                                                          style={{
                                                                            width: '100%'
                                                                          }}
                                                                          onChange={(event) => {
                                                                            if (event.target.value) {
                                                                              handleMetaDataCustomizationFieldChange(index, "music_catalog_number", event.target.value);
                                                                            } else {
                                                                              handleMetaDataCustomizationFieldChange(index, "music_catalog_number", ""); // Réinitialiser à une chaîne vide si le champ est vide
                                                                            }
                                                                            handleChange(event);
                                                                          }}
                                                                          value={metaDataCustomizations[index]?.music_catalog_number || ""}
                                                                          placeholder={metaDataCustomizations[index]?.music_catalog_number ? metaDataCustomizations[index].music_catalog_number:""}
                                                                      />
                                                                    </div>
                                                                    <div className="col w-full l4">
                                                                      <label htmlFor="music_label"><p className="text-white font-bold">nom(s) de label(s)</p></label>
                                                                      <input
                                                                          id=""
                                                                          type="text"
                                                                          className="input_text"
                                                                          style={{
                                                                            width: '100%'
                                                                          }}
                                                                          onChange={(event) => {
                                                                            if (event.target.value) {
                                                                              handleMetaDataCustomizationFieldChange(index, "music_label", event.target.value);
                                                                            } else {
                                                                              handleMetaDataCustomizationFieldChange(index, "music_label", ""); // Réinitialiser à une chaîne vide si le champ est vide
                                                                            }
                                                                            handleChange(event);
                                                                          }}
                                                                          value={metaDataCustomizations[index]?.music_label || ""}
                                                                          placeholder={metaDataCustomizations[index]?.music_label ? metaDataCustomizations[index].music_label:"( separer les noms par des virgules )"}
                                                                      />
                                                                    </div>
                                                                  </div>

                                                                  <div className="row w-full">
                                                                    <div className="col w-full l6">
                                                                      <label htmlFor="music_version"><p className="text-white font-bold">Version</p></label>
                                                                      <input
                                                                          id=""
                                                                          type="text"
                                                                          className="input_text"
                                                                          style={{
                                                                            width: '100%'
                                                                          }}
                                                                          onChange={(event) => {
                                                                            if (event.target.value) {
                                                                              handleMetaDataCustomizationFieldChange(index, "music_version", event.target.value);
                                                                            } else {
                                                                              handleMetaDataCustomizationFieldChange(index, "music_version", ""); // Réinitialiser à une chaîne vide si le champ est vide
                                                                            }
                                                                            handleChange(event);
                                                                          }}
                                                                          value={metaDataCustomizations[index]?.music_version || ""}
                                                                          placeholder={metaDataCustomizations[index]?.music_version ? metaDataCustomizations[index].music_version:""}
                                                                      />
                                                                    </div>
                                                                    <div className="col w-full l6">
                                                                      <label htmlFor="music_distributor"><p className="text-white font-bold">Distributeur(s)</p></label>
                                                                      <input
                                                                          id=""
                                                                          type="text"
                                                                          className="input_text"
                                                                          style={{
                                                                            width: '100%'
                                                                          }}
                                                                          onChange={(event) => {
                                                                            if (event.target.value) {
                                                                              handleMetaDataCustomizationFieldChange(index, "music_distributor", event.target.value);
                                                                            } else {
                                                                              handleMetaDataCustomizationFieldChange(index, "music_distributor", ""); // Réinitialiser à une chaîne vide si le champ est vide
                                                                            }
                                                                            handleChange(event);
                                                                          }}
                                                                          value={metaDataCustomizations[index]?.music_distributor || ""}
                                                                          placeholder={metaDataCustomizations[index]?.music_distributor ? metaDataCustomizations[index].music_distributor:""}
                                                                      />
                                                                    </div>
                                                                  </div>

                                                                  <div className="row w-full">
                                                                    <div className="col w-full l6">
                                                                      <label htmlFor="music_iswc"><p className="text-white font-bold">ISWC(s)</p></label>
                                                                      <input
                                                                          id=""
                                                                          type="text"
                                                                          className="input_text"
                                                                          style={{
                                                                            width: '100%'
                                                                          }}
                                                                          onChange={(event) => {
                                                                            if (event.target.value) {
                                                                              handleMetaDataCustomizationFieldChange(index, "music_iswc", event.target.value);
                                                                            } else {
                                                                              handleMetaDataCustomizationFieldChange(index, "music_iswc", ""); // Réinitialiser à une chaîne vide si le champ est vide
                                                                            }
                                                                            handleChange(event);
                                                                          }}
                                                                          value={metaDataCustomizations[index]?.music_iswc || ""}
                                                                          placeholder={metaDataCustomizations[index]?.music_iswc ? metaDataCustomizations[index].music_iswc:""}
                                                                      />
                                                                    </div>
                                                                    <div className="col w-full l6">
                                                                      <label htmlFor="music_musical_work_names"><p className="text-white font-bold">nom(s) des travaux musicaux</p></label>
                                                                      <input
                                                                          id=""
                                                                          type="text"
                                                                          className="input_text"
                                                                          style={{
                                                                            width: '100%'
                                                                          }}
                                                                          onChange={(event) => {
                                                                            if (event.target.value) {
                                                                              handleMetaDataCustomizationFieldChange(index, "music_musical_work_names", event.target.value);
                                                                            } else {
                                                                              handleMetaDataCustomizationFieldChange(index, "music_musical_work_names", ""); // Réinitialiser à une chaîne vide si le champ est vide
                                                                            }
                                                                            handleChange(event);
                                                                          }}
                                                                          value={metaDataCustomizations[index]?.music_musical_work_names || ""}
                                                                          placeholder={metaDataCustomizations[index]?.music_musical_work_names ? metaDataCustomizations[index].music_musical_work_names:"separe par des virgules"}
                                                                      />
                                                                    </div>
                                                                  </div>

                                                                  <div className="flex justify-center m-auto"><a className="text-black font-black" onClick={()=>{
                                                                    handleResetMetaDataCustomization(index);
                                                                  }} style={{cursor:'pointer'}}>effacer toutes les MetaDonnees Additionnelles</a></div>

                                                                  {/*<div className="row w-full">*/}
                                                                  {/*  <div className="col w-full l6">*/}
                                                                  {/*    <label htmlFor="music_song_writter"><p className="text-white font-bold">nom(s) des ecrivain(s)</p></label>*/}
                                                                  {/*    <input*/}
                                                                  {/*        id=""*/}
                                                                  {/*        type="text"*/}
                                                                  {/*        className="input_text"*/}
                                                                  {/*        style={{*/}
                                                                  {/*          width: '100%'*/}
                                                                  {/*        }}*/}
                                                                  {/*        onChange={(event) => {*/}
                                                                  {/*          if (event.target.value) {*/}
                                                                  {/*            handleMetaDataCustomizationFieldChange(index, "music_song_writter", event.target.value);*/}
                                                                  {/*          } else {*/}
                                                                  {/*            handleMetaDataCustomizationFieldChange(index, "music_song_writter", ""); // Réinitialiser à une chaîne vide si le champ est vide*/}
                                                                  {/*          }*/}
                                                                  {/*          handleChange(event);*/}
                                                                  {/*        }}*/}
                                                                  {/*        value={metaDataCustomizations[index]?.music_song_writter || ""}*/}
                                                                  {/*        placeholder={metaDataCustomizations[index]?.music_song_writter ? metaDataCustomizations[index].music_song_writter:"( separer les noms par des virgules )"}*/}
                                                                  {/*    />*/}
                                                                  {/*  </div>*/}
                                                                  {/*  <div className="col w-full l2">*/}
                                                                  {/*    <label htmlFor="music_catalog_number"><p className="text-white font-bold">No catalogue</p></label>*/}
                                                                  {/*    <input*/}
                                                                  {/*        id=""*/}
                                                                  {/*        type="text"*/}
                                                                  {/*        className="input_text"*/}
                                                                  {/*        style={{*/}
                                                                  {/*          width: '100%'*/}
                                                                  {/*        }}*/}
                                                                  {/*        onChange={(event) => {*/}
                                                                  {/*          if (event.target.value) {*/}
                                                                  {/*            handleMetaDataCustomizationFieldChange(index, "music_catalog_number", event.target.value);*/}
                                                                  {/*          } else {*/}
                                                                  {/*            handleMetaDataCustomizationFieldChange(index, "music_catalog_number", ""); // Réinitialiser à une chaîne vide si le champ est vide*/}
                                                                  {/*          }*/}
                                                                  {/*          handleChange(event);*/}
                                                                  {/*        }}*/}
                                                                  {/*        value={metaDataCustomizations[index]?.music_catalog_number || ""}*/}
                                                                  {/*        placeholder={metaDataCustomizations[index]?.music_catalog_number ? metaDataCustomizations[index].music_catalog_number:""}*/}
                                                                  {/*    />*/}
                                                                  {/*  </div>*/}
                                                                  {/*  <div className="col w-full l2">*/}
                                                                  {/*    <label htmlFor="music_label"><p className="text-white font-bold">nom(s) de label(s)</p></label>*/}
                                                                  {/*    <input*/}
                                                                  {/*        id=""*/}
                                                                  {/*        type="text"*/}
                                                                  {/*        className="input_text"*/}
                                                                  {/*        style={{*/}
                                                                  {/*          width: '100%'*/}
                                                                  {/*        }}*/}
                                                                  {/*        onChange={(event) => {*/}
                                                                  {/*          if (event.target.value) {*/}
                                                                  {/*            handleMetaDataCustomizationFieldChange(index, "music_label", event.target.value);*/}
                                                                  {/*          } else {*/}
                                                                  {/*            handleMetaDataCustomizationFieldChange(index, "music_label", ""); // Réinitialiser à une chaîne vide si le champ est vide*/}
                                                                  {/*          }*/}
                                                                  {/*          handleChange(event);*/}
                                                                  {/*        }}*/}
                                                                  {/*        value={metaDataCustomizations[index]?.music_label || ""}*/}
                                                                  {/*        placeholder={metaDataCustomizations[index]?.music_label ? metaDataCustomizations[index].music_label:"( separer les noms par des virgules )"}*/}
                                                                  {/*    />*/}
                                                                  {/*  </div>*/}
                                                                  {/*  <div className="col w-full l2 pt-2">*/}
                                                                  {/*    <button className="bg-yellow-500 flex justify-center align-middle content-center mx-auto mt-7 py-4 px-10 font-semibold text-2xl">Ajouter</button>*/}
                                                                  {/*  </div>*/}
                                                                  {/*</div>*/}

                                                                  {/*<div className="border-l-2 border-white">*/}
                                                                  {/*  {metaDataWritterNames[index].map((writter)=>(*/}
                                                                  {/*      <div className="row">*/}
                                                                  {/*        <div className="col l6 bg-gray-600">*/}
                                                                  {/*          <span>{writter.music_song_writter}</span>*/}
                                                                  {/*        </div>*/}
                                                                  {/*        <div className="col l2 bg-gray-600">*/}
                                                                  {/*          <span>{writter.music_isni?writter.music_song_writter:""}</span>*/}
                                                                  {/*        </div>*/}
                                                                  {/*        <div className="col l2 bg-gray-600">*/}
                                                                  {/*          <span>{writter.music_ipi?writter.music_song_writter:""}</span>*/}
                                                                  {/*        </div>*/}
                                                                  {/*        <div className="col w-full l2 pt-2">*/}
                                                                  {/*          <button className="bg-transparent flex justify-center align-middle content-center mx-auto mt-7 py-4 px-10 font-semibold text-red-600 text-2xl">remove</button>*/}
                                                                  {/*        </div>*/}
                                                                  {/*      </div>*/}
                                                                  {/*  ))}*/}
                                                                  {/*</div>*/}

                                                                </div>
                                                            ) : null }
                                                          </div>
                                                        </div>
                                                    ) : null}
                                                  </div>
                                                </div>
                                            ))}
                                          </div>
                                          <div className="mt-12  flex-row ">
                                            <button className=' sbbv' type='button' onClick={() => setPage(page - 1)}><ArrowBackOutline color={'#f2f2f2'} title={"Meli-Melo"} height="28px" width="28px" /></button>
                                            <button
                                                className="mlr-12 fbv"
                                                type="submit"
                                                onClick={handleSubmit}
                                            >
                                              Publier
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                )}
                              </div>
                            </div>
                            <div className="sta-form desktop:right">
                                {page === 1 && (
                                    <>
                                      <span className="fcov"></span>
                                      <span className="ftit"></span>
                                      <span className="fart"></span>
                                      <span className="rlp"></span>
                                    </>
                                )}
                                {page > 1 && (
                                  <>
                                    <span className="rcov">
                                      <img
                                          alt="cover"
                                          src={URL.createObjectURL(selectedImage)}
                                      />
                                    </span>
                                    <span className="psttl">{values.title}</span>
                                    <div className="w-full previewTitle text-white justify-center center mt-5">
                                      {selectedAudio ? selectedAudio.map((audio, index)=>(
                                          <p className="psauth m-auto">{customizations[index]?.title ? customizations[index].title:audio.name}</p>
                                      )):null}
                                      <p className="psauth m-auto">{values.price ? `${values.price} Xaf` : null}</p>
                                      <p className="psauth m-auto">{values.pre_order_end_date ? `fin de pre-commande : ${moment(values.pre_order_end_date).format(format1)} à ${moment(values.pre_order_end_date).format(format2)}` : null}</p>
                                    </div>

                                    <span className="rlp">
                                      Publie le&nbsp;
                                                  <strong>{moment(values.date).format(format1)}</strong>
                                                  &nbsp;à&nbsp;
                                                  <strong>
                                        {moment(values.date).format(format2)}
                                      </strong>{" "}
                                    </span>
                                  </>
                                )}
                          </div>
                          </div>
                      )}
                    </form>
                )}
              </Formik>
          ) : <Navigate to="/app/dashboardPodcast" />
        }
      </div>
  );
}
export default PublishEpisode;
