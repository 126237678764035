import React, { useContext } from "react";
import {useCurrentPage} from "../../../../datamanager/contexts/locals/publishAudio/currentPage";
import StateButtonWithImage from "../../../../components/StateButtonWithImage";
import translate from "../../../../i18n/translate";
import { useMetaData } from "../../../../datamanager/contexts/locals/publishAudio/metaData";
import { useSelectedAudio } from "../../../../datamanager/contexts/locals/publishAudio/selectedAudio";
import CurrentUserContext from "../../../../datamanager/contexts/currentUser";

export default function StepperActionsIntermediairySingle({ values, errors, isValid }) {

  const { handleNextPage, currentPage } = useCurrentPage();
  const { handleInitMetaData} = useMetaData();
  const { selectedAudio} = useSelectedAudio();
  const { currentUser} = useContext(CurrentUserContext);

  const initMetaData = ()=>{
    if(currentPage < 2){
      const names = selectedAudio.map(file => values.title);
      const year = new Date().getFullYear();
      handleInitMetaData( selectedAudio.length, names, values.category, year, currentUser.id, currentUser.alias, values.description);
    }
    handleNextPage(errors);
  }

  return (
    <div className="publish_audio_form_stepper__actions">
      {values.title !== "" && values.cover ?
        <StateButtonWithImage action={() => initMetaData()} enable={true} text={translate("next")} />: <StateButtonWithImage action={() => {}} enable={false} text={translate("next")} />
      }
    </div>
  );
}