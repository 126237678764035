import toast from "react-hot-toast";
import {LOCALES} from "../i18n";
import english from "../assets/new_images/english.svg";
import french from "../assets/new_images/french.svg";
import arabic from "../assets/new_images/arabic.svg";

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

// create a function that takes a time of posted comment and display it formated with time unit
export const formatTimePosted = (postedTime) => {
  const currentTime = new Date(); // Current time
  postedTime = new Date(postedTime);
  const timeDifference = currentTime - postedTime; // Difference in milliseconds

  // Define time units in milliseconds
  const minute = 60 * 1000;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30;
  const year = day * 365;

  // Determine the appropriate time unit
  if (timeDifference < minute) {
    return 'Just now';
  } else if (timeDifference < hour) {
    const minutesAgo = Math.floor(timeDifference / minute);
    return `${minutesAgo} minute${minutesAgo > 1 ? 's' : ''} ago`;
  } else if (timeDifference < day) {
    const hoursAgo = Math.floor(timeDifference / hour);
    return `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
  } else if (timeDifference < week) {
    const daysAgo = Math.floor(timeDifference / day);
    return `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
  } else if (timeDifference < month) {
    const weeksAgo = Math.floor(timeDifference / week);
    return `${weeksAgo} week${weeksAgo > 1 ? 's' : ''} ago`;
  } else if (timeDifference < year) {
    const monthsAgo = Math.floor(timeDifference / month);
    return `${monthsAgo} month${monthsAgo > 1 ? 's' : ''} ago`;
  } else {
    const yearsAgo = Math.floor(timeDifference / year);
    return `${yearsAgo} year${yearsAgo > 1 ? 's' : ''} ago`;
  }
}

export const removeAudioFileExtension = (fileName) => {
  // Find the last index of the dot (.) character in the fileName
  const dotIndex = fileName.lastIndexOf(".");

  // Check if a dot was found and if it's not the first character in the fileName
  if (dotIndex !== -1 && dotIndex !== 0) {
    // Extract the file name without the extension
    return fileName.slice(0, dotIndex);
  } else {
    // If no dot was found or it's the first character, return the fileName as is
    return fileName;
  }
};

// format 2024-04-28T00:00:00Z to 28 April 2024
export const formatDateToString = (date) => {
  const d = new Date(date);
  return `${d.getDate()} ${d.toLocaleString('default', { month: 'long' })} ${d.getFullYear()}`;
};

export const generateMetaData = (selectedAudio, currentUser, values) => {
  if (!selectedAudio || selectedAudio.length === 0) {
    return [];
  }

  const newMetaData = selectedAudio.map((audio) => ({
    producer: currentUser.id,
    explicit: false,
    gender: values.category,
    year: "2024",
    description: values.description,
    title: audio.name,
    author: currentUser.id,
    artist: currentUser.id,
  }));

  return newMetaData;
};

export const top5music = (singles) => {
  const singlesCopy = [...singles];
  return singlesCopy.sort((a, b) => b.nb_of_listening - a.nb_of_listening).slice(0, 5);
}

export const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export function changeActiveIndex(newIndex, setActiveIndex) {
  localStorage.setItem("lastActiveIndex", newIndex);
  setActiveIndex(newIndex);
}

export function getPath(path) {
  if (path.charAt(0) !== "/") {
    return "/" + path;
  }
  return path;
}

export const getRole = (user) => {
  if (user.is_podcaster === true) {
    return "podcast"
  }
  if (user.is_label === true) {
    return "label"
  }
  if (user.is_artist === true) {
    return "artist"
  }
}

export const formatDownloadSpeed = (downloadSpeed) => { // download speed in B/s
  const kb = downloadSpeed / 1024;
  const mb = kb / 1024;
  if (mb > 1) {
    return `${mb.toFixed(2)} Mo/s`;
  } else if (kb > 1) {
    return `${kb.toFixed(2)} Ko/s`;
  } else {
    return `${downloadSpeed.toFixed(2)} B/s`;
  }
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["o", "Ko", "Mo", "Go"]; //, "To", "Po", "EB", "ZB", "YB"
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
export const formatTime = (time) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);
  const formattedHours = hours > 0 ? `${hours.toString().padStart(2, '0')}:` : '';
  return `${formattedHours}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

// funcion that return date to string like 12 january 2024
export function formatDate(date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();
  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  return `${day}/${month}/${year}`;
}

export const getAudioDuration = (url) => {
  return new Promise((resolve, reject) => {
    const audio = new Audio(url);
    audio.onloadedmetadata = () => {
      resolve(audio.duration);
    };
    audio.onerror = () => {
      reject(new Error("Failed to get audio duration"));
    };
  });
};

export const convertTime = (seconds) =>  {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  let result = '';

  if (hours > 0) {
    result += `${hours} hour${hours > 1 ? 's' : ''}`;
  }
  if (minutes > 0) {
    if (result !== '') {
      result += ` ${minutes} minute${minutes > 1 ? 's' : ''}`;
    } else {
      result = `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
  }
  if (result === '' || remainingSeconds > 0) {
    if (result !== '') {
      result += ` ${remainingSeconds} second${remainingSeconds > 1 ? 's' : ''}`;
    } else {
      result = `${remainingSeconds} second${remainingSeconds > 1 ? 's' : ''}`;
    }
  }

  return result;
}

export const transformUrl = (url, size) => {
  // Validate the input size
  const validSizes = ['sml', 'normal', 'lg'];
  if (!validSizes.includes(size)) {
    throw new Error('Invalid size parameter. Must be one of "sml", "normal", or "lg".');
  }

  // Parse the URL and split it into parts
  const urlParts = url.split('/');
  const lastSegment = urlParts.pop();

  // Split the last segment to separate the name and extension
  const [name] = lastSegment.split('.');

  // Construct the new last segment with .jpg extension
  const newLastSegment = `${name}_size_${size}.jpg`;

  // Reconstruct the URL
  return [...urlParts, newLastSegment].join('/');
}

export const handleErrors = (errors, handleSetMsg, handleSetSubmitting) => {
  console.log(errors)
  // itterate over the object errors
  if (!isEmptyObject(errors)) {
    // get errors keys and values
    const errorArray = [];
    let entries = Object.entries(errors);
    entries.forEach(([key_, value]) => {
      console.log(key_, value);
      value && value.map((error) => {
        toast.error(key_+": "+error, {
          position: "bottom-left",
          autoClose: 20000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        errorArray.push(key_+": "+error);
      });
    });
    handleSetMsg(errorArray);
    handleSetSubmitting(false);
  }
};

export const handleErrors2 = (errors) => {
  for (let error of errors) {
    switch (error.field) {
      case "category": {
        toast.error("Veuillez choisir une categorie", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        break;
      }

      case "album": {
        toast.error("Veuillez choisir un album", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        break;
      }

      case "title": {
        toast.error("Veuillez fournir un titre", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        break;
      }

      case "cover": {
        toast.error("Veuillez choisir une photo de couverture", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        break;
      }

      case "src": {
        toast.error("Veuillez charger un single", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        break;
      }

      case "description": {
        toast.error("Veuillez fournir une description", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        break;
      }

      case "media_acessibility": {
        toast.error("Veuillez fournir une disponibilité", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        break;
      }

      default:
        break;
    }
  }
};


export const getLanguage = (language) => {
  return (() => {
    switch (language) {
      case LOCALES.ENGLISH:
        return english;
      case LOCALES.FRANCAIS:
        return french;
      case LOCALES.ARABE:
        return arabic;
      default:
        return null;
    }
  })();
}