import { useSelectedAudio } from "../../../../datamanager/contexts/locals/publishAudio/selectedAudio";
import {generateMetaData, sleep} from "../../../../utils/functions";
import {useMetaData} from "../../../../datamanager/contexts/locals/publishAudio/metaData";
import {useContext} from "react";
import CurrentUserContext from "../../../../datamanager/contexts/currentUser";

export default function AddSinglesToAlbum({ fileInputRef, setFieldValue, values }) {
  const { handleAddAudios } = useSelectedAudio();
  const { setMetaData: handleSetMetaData } = useMetaData();
  const { currentUser } = useContext(CurrentUserContext);

  const handleFileInput = (event) => {
    const files = event.target.files;
    handleSetMetaData(
      generateMetaData(
        handleAddAudios(
          setFieldValue,
          Array.from(files)
        ),
        currentUser,
        values
      )
    );
  };

  return (
    <div className="publish_audio_form_fields_col1__dropzone" style={{ display: 'none' }}>
      <input
        type="file"
        multiple
        ref={fileInputRef}
        onChange={(e) => handleFileInput(e)}
        accept="audio/mpeg, audio/aac, audio/mp3, audio/wav, audio/ogg, audio/flac, audio/wma"
      />
    </div>
  );
}
