import React, {createContext, useCallback, useContext, useState} from 'react';

const StoredDatasContext = createContext();

export const useStoreData = () => useContext(StoredDatasContext);

export const StoreDataProvider = ({ children }) => {
  const [storedData, setStoredData] = useState(null);

  const handleUpdateStoreData = useCallback((value) => {
    if (value !== storedData) {
      setStoredData(value);
    }
  }, [storedData]);

  return (
    <StoredDatasContext.Provider value={{ storedData, handleUpdateStoreData }}>
      {children}
    </StoredDatasContext.Provider>
  );
};
