import React, { useContext } from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "../../../styles/App.css";
import { Link } from "react-router-dom";
import uil from "../../../assets/vynil.png";
import useFetchData from "../../../hooks/useFetchData";
import { SpinnerCircularFixed } from "spinners-react";
import useUser from "../../../hooks/useUser";
import Podcaststable from "../../../components/podcaststable";
import PodcastsContext from "../../../datamanager/contexts/podcasts";
import styles from './styles/podcast.module.css'

function Podcast({ isActive }) {
  // Get data from the global context
  const { podcasts } = useContext(PodcastsContext)
  
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
    },
    {
      name: "Year",
      selector: (row) => row.year,
    },
  ];

  const dataset = [
    {
      id: 1,
      title: "Beetlejuice",
      year: "1988",
    },
    {
      id: 2,
      title: "Ghostbusters",
      year: "1984",
    },
  ];
  useEffect(() => {
    if (podcasts) {
      setLoading(false)
    }
  }, [podcasts]);

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center h-screen">
          <SpinnerCircularFixed
            size={50}
            thickness={121}
            speed={150}
            color="rgba(133, 19, 78, 1)"
            secondaryColor="rgba(231, 208, 220, 1)"
          />
        </div>
      ) : podcasts.count > 0 ? (
        <>
          <div className="justify-start w-full min-h-screen p-5">
            <div className="flex flex-col items-start justify-start w-full my-16 relative">
              <h1 className="text-5xl font-bold text-slate-500 text-left">
                Podcasts
              </h1>
              <h4 className="text-xs font-medium text-left text-gray-500 mt-2">
                Trackez les donnees genere par vos podcasts sur Colorfol Artist
              </h4>

              <Link to="/app/publishPodcasts/podcast">
                <button className={styles.btnAddPodcast}>Ajouter un Podcast</button>
              </Link>
            </div>
            <div className="my-auto w-full">
              <Podcaststable />
            </div>
          </div>
        </>
      ) : (
        <>
          <img src={uil} className="esi" />
          <span className="est">Aucun podcast disponible ici !</span>
          <Link exact to="/app/publishPodcasts/podcast" className="link">
            <span className="escta">Publiez votre premier podcast</span>
          </Link>
        </>
      )}
    </>
  );
}
export default Podcast;
