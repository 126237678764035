import React, {useContext} from "react";
import CurrentUserContext from "../../datamanager/contexts/currentUser";
import certif from "../../assets/new_images/CircleWavyWarning.svg";
import dropbutton from "../../assets/new_images/CaretCircleDown.svg";
import {transformUrl} from "../../utils/functions";

export default function SideBarProfile() {

  const {currentUser} = useContext(CurrentUserContext);

  return (
    <div className="artsect">
      <img alt="cover" className="userprofile" src={transformUrl(currentUser.cover, "sml")}/>
      <div className="arttyp">
        <p className="artname">{currentUser.alias}<img alt="certif" className="certif" src={certif}/></p>
      </div>
    </div>
  );
}
