import {sleep} from "../../../../utils/functions";
import toast from "react-hot-toast";
import { Navigate} from "react-router-dom";

export default async (handleSetConnected, handleSetIsFinishedUpload, handleSetBackErr) => {
  await sleep(800);
  handleSetConnected(true);
  toast.success("Votre fichier a été publié avec succès", {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
  });

  await sleep(120000000);

}