import "./styles/InputField.css";
import "./styles/RadioInput.css";
import {useEffect, useState} from "react";
import {useMetaData} from "../datamanager/contexts/locals/publishAudio/metaData";
import translate from "../i18n/translate";

export default function RadioInput({ optionList, setFieldValue, value, field }) {

  // create a state for the selected option base on the value switch the case of the value
  const [selectedOption, setSelectedOption] = useState((value === "ST") ? translate("streaming") : (value === "HY") ? translate("hybrid") : translate("forsale"));

  const handleOptionClick = (option, index) => {
    switch (index) {
      case 0:
        setFieldValue(field, "ST");
        break;
      case 1:
        setFieldValue(field, "HY");
        break;
      case 2:
        setFieldValue(field, "SE");
        break;
    }
    console.log(option)
    setSelectedOption(option);
  };

  const selectTheChoosenValue = (value) => {
    switch (value) {
      case "streaming":
        return translate("streaming");
      case "hybrid":
        return translate("hybrid");
      case "forsale":
        return translate("ForSale");
    }
  }

  return (
    <div className="radio-container">
      {optionList.map((option, index) => (
        <div key={index} className="radio-option" onClick={() => handleOptionClick(option, index)}>
          <div className={ ( selectedOption === option || selectTheChoosenValue(value) === option) ? "radio-button selected" : "radio-button"} ></div>
          <span className="radio-label">{option}</span>
        </div>
      ))}
    </div>
  );
}