export default function FillFormDataAlbumSingle(values, metaData, singleID, albumID) {
  let formData = new FormData();
  const likersIds = [2];
  let my_metadata = metaData[singleID];
  formData.append("title", my_metadata.title);
  formData.append("publication_date", values.publication_date);
  formData.append("price", values?.price);
  formData.append("cover", values?.cover);
  formData.append("src", values?.src[singleID]);
  formData.append("description", my_metadata.description);
  formData.append("is_single", false);
  formData.append("metadata", JSON.stringify(my_metadata));
  formData.append("author",  my_metadata?.producer);
  formData.append("category", my_metadata.gender);
  likersIds.forEach(id => {
    formData.append('likers', id);
  });
  formData.append("album", albumID);
  formData.append("artist", my_metadata?.producer);

  return formData;
}