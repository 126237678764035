import React from "react";
import { Chart } from "react-google-charts";

export const data = [
  ["Country", "Nombre d'ecoutes"],
  ["Germany", 200],
  ["United States", 300],
  ["Brazil", 400],
  ["Canada", 500],
  ["France", 600],
  ["RU", 700],
  ["Cameroon", 350],
];

export default function MapChart() {
  return (
    <Chart
      chartEvents={[
        {
          eventName: "select",
          callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart();
            const selection = chart.getSelection();
            if (selection.length === 0) return;
            const region = data[selection[0].row + 1];
          },
        },
      ]}
      chartType="GeoChart"
      width="100%"
      height="100%"
      options={{
        colors: ["#7c2c55"]
      }}
      data={data}
    />
  );
}
