import * as Yup from "yup";
import {SUPPORTED_FORMATS} from "../../../../utils/Constants";

export const SignUpSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Trop court!")
    .max(50, "Trop long!")
    .required("Required"),
  upc: Yup.string()
    .min(2, "Trop court!")
    .max(50, "Trop long!"),
  cover: Yup.mixed()
    .nullable()
    .required("Veuillez uploader votre cover!")
    .test(
      "FILE_SIZE",
      "L'image est trop lourde pour etre publiée",
      (value) => !value || (value && value.size <= 8024 * 8024)
    )
    .test(
      "FILE_FORMAT",
      "Veuillez choisir uniquement les fichers ayant les formats suivants : JPG, JPEG et  PNG",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
    ),
  // sell_category: Yup.number().required("Veuillez choisir une catégorie"),
});