import React from "react";

export default function MostListennedListElt({cover, title, nb_of_listening, name}) {
  return (
    <div className="lect">
      <img src={cover} className="lect-img"/>
      <span className="lect-side">
        <span className="lect-up">
          <span className="lect-up-title">{title}</span><span
          className="lect-up1">{nb_of_listening}</span>
        </span>
        <span className="lect-up-artisname">{name}</span>
      </span>
    </div>
  );
}