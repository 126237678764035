import styles from '../styles/mobileMenu.module.css'
import logo from "../../../assets/colorfol artists.png";
import { CloseSharp } from 'react-ionicons'
import translate from '../../../i18n/translate';

const MobileMenu = ({ open, onClose }) => {

  return (
    <>
      <div className={`${styles.mobileMenuContainer} ${!open && styles.mobileMenuAnimate}`}>
        <div className={styles.mobileMenuFirst}>
          <img
            className={styles.mobileMenuLogo}
            src={logo}
          />

          <div className={styles.mobileMenuClose} onClick={onClose}>
            <CloseSharp />
          </div>
        </div>

        <nav className={styles.mobileMenuContent}>
          <a href="#obj">{translate("obj")}</a>
          <a href="#dis">{translate("dis")}</a>
          <a href="#aid">{translate("aid")}</a>
          <a href="/app/access">{translate("con")}</a>
        </nav>
      </div>

      {
        open && (
          <div className={styles.mobileMenuBg} onClick={onClose} />
        )
      }
    </>
  )
}

export default MobileMenu