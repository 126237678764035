import Stepper from "../../../../components/Stepper";
import React, {useContext, useRef} from "react";
import { uploadSteps } from "../../../../utils/Constants";
import StepperActions from "./StepperActions";
import translate from "../../../../i18n/translate";
import UploadSongList from "../../../../components/PublishAudio/UploadSongList";
import AddSinglesToAlbum from "./AddSinglesToAlbum";

export default function AudioSettings({ errors, values, handleSubmit, isValid, setFieldValue }) {

  const fileInputRef = useRef(null);
  const showAddSingle = false;

  const handleAddSingles = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="publish_audio_form">
      <div className="publish_audio_form_medias_container">
        <div className="publish_audio_form_medias">
          <span className="publish_audio_form_medias__title">{translate("trackListOrder")}</span>
          { showAddSingle && <span className="publish_audio_form_medias__televerser"
                 onClick={() => handleAddSingles()}>{translate('addSingleToAlbum')}</span>}
        </div>
        <span className="publish_audio_form_medias__subtitle">{translate("downloadedFiles")}</span>

        <UploadSongList values={values} />
        <AddSinglesToAlbum fileInputRef={fileInputRef} setFieldValue={setFieldValue} values={values} />
      </div>
      <div className="publish_audio_form_stepper">
        <Stepper steps={uploadSteps} />
        <StepperActions errors={errors} values={values} handleSubmit={handleSubmit} isValid={isValid} />
      </div>
    </div>
  );
}
