import React, {createContext, useCallback, useContext, useState} from 'react';

const UploadPercentageContext = createContext();

export const useUploadPercentage = () => useContext(UploadPercentageContext);

export const UploadPercentageProvider = ({ children }) => {
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const handleSetUploadPercentage = useCallback((value) => {
    if (value !== uploadPercentage) {
      setUploadPercentage(value);
    }
  }, [uploadPercentage]);
  return (
    <UploadPercentageContext.Provider value={{ uploadPercentage, handleSetUploadPercentage }}>
      {children}
    </UploadPercentageContext.Provider>
  );
};
