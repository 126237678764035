import React from "react";
import translate from "../../i18n/translate";

export default function TermsAndContitions() {
  return (
    <div className="cpright my-4">
      <span className="copyr">{translate("copyright")}</span>
      <span className="termss">
          <a href="https://www.colorfol.com/privacy-policy" target="_blank">{translate("privacy")}</a>
          <a href="https://www.colorfol.com/cgu-premium" target="_blank">{translate("terms")}</a>
        </span>
    </div>
  );
}