import { useContext, useEffect } from "react"
import CurrentUserContext from "../datamanager/contexts/currentUser"
import ArtistsContext from "../datamanager/contexts/artists"
import ArtistAPI from "../api/artist"

const useArtists = () => {
  // Get data from the global context
  const { addArtists } = useContext(ArtistsContext)
  const { currentUser } = useContext(CurrentUserContext)

  // UseEffect section
  useEffect(() => {
    if (currentUser) {
      handleFetchArtists().then(
        (result) => {
          console.log(result)
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }, [currentUser])

  const handleFetchArtists = async () => {
    const token = localStorage.getItem("access_token")

    const artistApi = new ArtistAPI(token)

    const response = await artistApi.getAllArtists(currentUser.id)

    if (response.data) {
      addArtists(response.data)
    }
  }
}

export default useArtists