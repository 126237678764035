import axios from "axios";
import { TestEnvAPI } from "../envs";

function createAxiosInstance() {
  return axios.create({
    baseURL: TestEnvAPI,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
}

export class BaseApi {
  axiosInstance = null;

  constructor() {
    this.axiosInstance = createAxiosInstance();
  }

  // Insert token to the request header
  insertToken(token) {
    this.axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`

    return this.axiosInstance
  }
}
