import {useStats} from "../../datamanager/contexts/Stats"
import NotificationElt from "./NotificationElt";
import translate from "../../i18n/translate";
export default function Notifications() {

  const { stat } = useStats();

  return (
    <div className="greed2">
      <NotificationElt priority={0} info1={translate("reachNumber")} mainInfo={stat ? stat.nb_listenning : 0 } info3={translate("listenningOnColorfol")} />
      <NotificationElt priority={1} info1={translate("congrats")} mainInfo={stat ? stat?.total_album_sold?.all_time?.count : 0} info3={translate("albumsSales")} />
    </div>
  );
}