import React, {useCallback, useState} from 'react';
import { useDropzone } from 'react-dropzone';
import downn from "../assets/new_images/Download.svg";
import { useSelectedAudio } from "../datamanager/contexts/locals/publishAudio/selectedAudio";
import { useStoreData } from "../datamanager/contexts/StoreDatas";

export function MyDropzone({ setFieldValue, values, acceptedFiles = "audio/mpeg, audio/aac, audio/mp3, audio/wav, audio/ogg, audio/flac, audio/wma", callback, text, textSucess, maxFiles = 1 }) {

  const { selectedAudio, handleSetSelectedAudio } = useSelectedAudio();
  const [ errorMsg, setErrorMsg ] = useState(null);
  const { storedData } = useStoreData();

  console.log("rendered one more time ");

  if (storedData && storedData.acceptedFiles && storedData.acceptedFiles.length > 0) {
    handleSetSelectedAudio(storedData.acceptedFiles);
  }

  const onDrop = useCallback((droppedFiles) => {
    console.log("onDrop", droppedFiles);
    // Check if the dropped files match the accepted file types and the maximum number of files
    const invalidFiles = droppedFiles.filter(file => !acceptedFiles.includes(file.type));
    const invalidMaxFiles = droppedFiles.length > maxFiles;

    if (invalidFiles.length > 0 || invalidMaxFiles) {
      // Handle invalid file types or maximum number of files here - display the error message
      setErrorMsg("Invalid file types or maximum number of files detected");
    } else {
      setErrorMsg(null);
      // Valid files - proceed with setting field value and selected audio
      if (callback) {
        callback(droppedFiles);
      } else {
        setFieldValue('src', droppedFiles);
        handleSetSelectedAudio(droppedFiles);
      }
    }
  }, [acceptedFiles, handleSetSelectedAudio, maxFiles]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'audio/mpeg': [],
      'audio/aac': [],
      'audio/mp3': [],
      'audio/wav': [],
      'audio/ogg': [],
      'audio/flac': [],
      'audio/wma': []
    },
    maxFiles: maxFiles
  });

  return (
    <div className="relative w-full">
      <div {...getRootProps()} >
        <input {...getInputProps({ accept: acceptedFiles })}
          accept={acceptedFiles}
        />
        <div className="publish_audio_form_fields_col1__dropzone">
          <div className={selectedAudio ? `${errorMsg ? "dropz--error" : "dropz--success"}` : "dropz"}>
            {selectedAudio && !errorMsg && (
              <span className="dropztitle-success">{textSucess}</span>
            )}
            {!selectedAudio && (
              <>
                <img alt="drop" src={downn} className="dropzimg" />
                <span className="dropztitle">{text}</span>
              </>
            )}
            {selectedAudio && errorMsg && (
              <span className="dropztitle-error">{errorMsg}</span>
            )}
          </div>
        </div>
      </div>
      {isDragActive && <div className="overlay_black" />}
    </div>
  );
}
