import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import "../../styles/App.css";
import { Link } from "react-router-dom";
import uil from "../../assets/Uploading-rafiki.png";
import { SpinnerCircularFixed } from "spinners-react";
import useUser from "../../hooks/useUser";
import Audiotables from "../../components/audiotables";
import SinglesContext from "../../datamanager/contexts/singles";
import AlbumsContext from "../../datamanager/contexts/albums";
import translate from "../../i18n/translate";
import searchico from "../../assets/new_images/MagnifyingGlass.svg";
import up from "../../assets/new_images/Up.svg";
import down from "../../assets/new_images/Down.svg";
import charttt from "../../assets/new_images/chart.svg";
import songgoing from "../../assets/new_images/songgoing.svg";

function Royalties({ isActive }) {
  // Get data from global context
  const { singles } = useContext(SinglesContext)
  const { albums } = useContext(AlbumsContext)
  
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (singles && albums) {
      setLoading(false)
    }
  }, [singles, albums])

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center h-screen">
          <SpinnerCircularFixed
            size={50}
            thickness={121}
            speed={150}
            color="rgba(133, 19, 78, 1)"
            secondaryColor="rgba(231, 208, 220, 1)"
          />
        </div>
      ) : albums.count || singles.count ? (
        <div className="flex flex-col items-start justify-start main-dash">
          <div className="search-utils">
            <div className="search">
              <input type="text" placeholder={translate('searchholder')} className="search-text"/>
              <img src={searchico} className="search-ico"/>
            </div>
          </div>
          <div className="royalities-elt">
            <span className="royalities-title1">{translate('your_incomes')}</span>
            <span className="royalities-subtitle">{translate('with_colorfol')}</span>
          </div>
          <div className="royalities-elt">
            <div className="royalities-menu">{translate("yourdata")}</div>
            <div className="royalities-cards">
              <div className="royalities-card">
                <span className="royalities-card-title">{translate('estimatedrevenue')}</span>
                <span className="royalities-card-main">1.02M</span>
                <span className="royalities-card-foot">En FCFA</span>
              </div>
              <div className="royalities-card">
                <span className="royalities-card-title">{translate('numberofsales')}</span>
                <span className="royalities-card-main">1.76K</span>
                <span className="royalities-card-foot"><img src={down}
                                                  className="monit-ico"/> +3,788% {translate('thismonth')}</span>
              </div>
              <div className="royalities-card">
                <span className="royalities-card-title">{translate('listenning')}</span>
                <span className="royalities-card-main">822K</span>
                <span className="royalities-card-foot"> 73778 FCFA le 14.02.24</span>
              </div>
            </div>
          </div>
          <div className="royalities-elt">
            <span className="royalities-title">{translate('details_on_incomes_by_sales')}</span>
            <div className="topfan">
              <div className="topfan-top">
                <span className="topfan-title">{translate('your_albums')}</span>
                <span className="topfan-top-right">
                  <div className="search2">
                    <input type="text" placeholder={translate('searchasingle').toString()} className="search2-text"/>
                    <img src={searchico} className="search2-ico"/>
                  </div>
                  <select className='listdrop2' value={["popularite", "ecouts"]}
                          id="single-filter">
                    {
                      ["Popularite", "ecouts"].map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))
                    }
                  </select>
                </span>
              </div>
              <div className="divv2">
                <div className="subdivvv2"></div>
              </div>
              <div className="topfan-titles">
                <span className="topfan-titlee">{translate('title')}</span>
                <span className="topfan-titlee">{translate('salesnumber')}</span>
              </div>
              <div className="topfan-titles">
                        <span className="topfan-r">
                          <span className="text-gray-600">1</span><img src={charttt} className="topfan-elt-img"/>
                          <span className="topfan-r1">
                            <span>Rick Fallo</span>
                            <span className="topfan-r2">
                              <span>rickfallo@237</span>
                              <span>rickfallo@gmail.com</span>
                            </span>
                          </span>
                        </span>
                <span className="topfan-r">
                          2<img src={songgoing} className="topfan-elt-img2"/><img src={songgoing}
                                                                                  className="topfan-elt-img2"/>
                        </span>
              </div>
              <div className="topfan-titles">
                        <span className="topfan-r">
                          <span className="text-gray-600">1</span><img src={charttt} className="topfan-elt-img"/>
                          <span className="topfan-r1">
                            <span>Rick Fallo</span>
                            <span className="topfan-r2">
                              <span>rickfallo@237</span>
                              <span>rickfallo@gmail.com</span>
                            </span>
                          </span>
                        </span>
                <span className="topfan-r">
                          2<img src={songgoing} className="topfan-elt-img2"/><img src={songgoing}
                                                                                  className="topfan-elt-img2"/>
                        </span>
              </div>
              <div className="topfan-titles">
                        <span className="topfan-r">
                          <span className="text-gray-600">1</span><img src={charttt} className="topfan-elt-img"/>
                          <span className="topfan-r1">
                            <span>Rick Fallo</span>
                            <span className="topfan-r2">
                              <span>rickfallo@237</span>
                              <span>rickfallo@gmail.com</span>
                            </span>
                          </span>
                        </span>
                <span className="topfan-r">
                          2<img src={songgoing} className="topfan-elt-img2"/>
                        </span>
              </div>
              <div className="topfan-titles">
                        <span className="topfan-r">
                          <span className="text-gray-600">1</span><img src={charttt} className="topfan-elt-img"/>
                          <span className="topfan-r1">
                            <span>Rick Fallo</span>
                            <span className="topfan-r2">
                              <span>rickfallo@237</span>
                              <span>rickfallo@gmail.com</span>
                            </span>
                          </span>
                        </span>
                <span className="topfan-r">
                          2<img src={songgoing} className="topfan-elt-img2"/>
                        </span>
              </div>
              <div className="topfan-titles">
                        <span className="topfan-r">
                          <span className="text-gray-600">1</span><img src={charttt} className="topfan-elt-img"/>
                          <span className="topfan-r1">
                            <span>Rick Fallo</span>
                            <span className="topfan-r2">
                              <span>rickfallo@237</span>
                              <span>rickfallo@gmail.com</span>
                            </span>
                          </span>
                        </span>
                <span className="topfan-r">
                          2<img src={songgoing} className="topfan-elt-img2"/><img src={songgoing}
                                                                                  className="topfan-elt-img2"/>
                        </span>
              </div>
              <div className="topfan-titles">
                        <span className="topfan-r">
                          <span className="text-gray-600">1</span><img src={charttt} className="topfan-elt-img"/>
                          <span className="topfan-r1">
                            <span>Rick Fallo</span>
                            <span className="topfan-r2">
                              <span>rickfallo@237</span>
                              <span>rickfallo@gmail.com</span>
                            </span>
                          </span>
                        </span>
                <span className="topfan-r">
                          2<img src={songgoing} className="topfan-elt-img2"/><img src={songgoing}
                                                                                  className="topfan-elt-img2"/>
                        </span>
              </div>
            </div>

          </div>

          {/* Entetet */}
          <div className="flex flex-col items-start justify-start w-full mt-20">
            <h1 className="text-5xl font-bold text-slate-500 text-left">
              Redevances
            </h1>
            <h4 className="text-xs font-medium text-left text-gray-500 mt-2">
              Suivez l'argent genere par votre contenu sur Colorfol Artist
            </h4>
          </div>

          {/* Hero */}
          <div className="rdc flex flex-col items-center justify-start  shadow rounded-lg   my-12  overflow-clip">
            <div className="flex items-center justify-between w-full h-3/4 pl-6 pt-3 pb-14">
              <div className="flex flex-col h-full w-2/4 p-1">
                <h3 className="text-sm font-normal text-gray-400 mb-1.5">
                  Paiement le plus recent
                </h3>
                <div className="flex items-center justify-start">
                  <h1 className="text-4xl text-slate-600 font-bold">
                    0.000 FCFA
                  </h1>
                  <div
                    className="flex items-center justify-center bg-green-600 w-12 h-6 px-1 py-0.5 mx-2 rounded text-white text-xs font-semibold">
                    Soldé
                  </div>
                </div>
                <h4 className="text-xs my-1 font-normal text-slate-100">
                  Envoyé le 28 mars 2022.
                </h4>
              </div>

              <div className="flex flex-col h-full w-2/4 p-1">
                <h3 className="text-sm font-normal text-gray-400 mb-1.5">
                  Prochain paiement
                </h3>
                <div className="flex items-center justify-start">
                  <h1 className="text-4xl text-gray-400 font-bold">
                    0.000 FCFA
                  </h1>
                  <div
                    className="flex items-center justify-center bg-gray-300 w-12 h-6 px-1 py-0.5 mx-2 rounded text-white text-xs font-semibold">
                    Estimé
                  </div>
                </div>
                <h4 className="text-xs my-1 font-normal text-gray-300">
                  Sera envoyé le 30 mai 2022.
                </h4>
              </div>
            </div>
            <div className="flex items-center justify-start w-full h-1/4 bg-gray-100"></div>
          </div>

          {/* Par album */}
          {albums.count > 0 ? (
            <>
              <div className="flex items-center justify-between  w-full">
                <h1 className="text-3xl font-bold text-slate-500 text-left">
                  Par albums
                </h1>
                <div className="flex items-center justify-start">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={1.5}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <select
                    className="flex items-center justify-center border border-2 rounded-lg border-gray-200 p-2 pr-1 text-sm font-medium mx-2">
                    <option>Mai 2022</option>
                    <option>Avril 2022</option>
                    <option>Mars 2022</option>
                    <option>Fevrier 2022</option>
                    <option>Janvier 2022</option>
                  </select>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-4  rounded-lg w-full h-auto py-10">
                {albums.results.map((album, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-start justify-start w-full"
                  >
                    <div className="h-36 w-36 ">
                      <img src={album.cover} className="object-cover"/>
                    </div>
                    <h1 className="text-xl font-semibold text-slate-500 mt-1.5">
                      {album.title}
                    </h1>
                    <h3 className="text-base font-medium text-slate-300 mt-1">
                      {album.price}
                    </h3>
                  </div>
                ))}
              </div>
            </>
          ) : null}

          {/* Par single */}
          <div className="flex items-center justify-between  w-full">
            <h1 className="text-3xl font-bold text-slate-500 text-left">
              Par singles
            </h1>
            <div className="flex items-center justify-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={1.5}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <select
                className="flex items-center justify-center border border-2 rounded-lg border-gray-200 p-2 pr-1 text-sm font-medium mx-2">
                <option>Mai 2022</option>
                <option>Avril 2022</option>
                <option>Mars 2022</option>
                <option>Fevrier 2022</option>
                <option>Janvier 2022</option>
              </select>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4  rounded-lg w-full h-auto py-10">
            {singles.results.map((album, index) => (
              <div
                key={index}
                className="flex flex-col items-start justify-start w-full"
              >
                <div className=" h-36 w-36 ">
                  <img src={album.cover} className="object-cover"/>
                </div>
                <h1 className="text-xl font-semibold text-slate-500 mt-1.5">
                  {album.title}
                </h1>
                <h3 className="text-base font-medium text-slate-300 mt-1">
                  {album.price} FCFA
                </h3>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          <img src={uil} className="esi"/>
          <span className="est">Veuillez d'abord publier du contenu!</span>
          <Link exact to="/app/publish" className="link">
            <span className="escta">Publiez votre premier contenu</span>
          </Link>
        </>
      )}
    </>
  );
}
export default Royalties;
