import {useContext, useState} from "react";
import CurrentUserContext from "../../../datamanager/contexts/currentUser";
import style from "../styles/SignUpPopUp.module.css"

export default function SignUpPopUp(){

  const { currentUser } = useContext(CurrentUserContext);

  const displyWhatsappPhoneNUmber = (givenUserNumber) => {
    if (givenUserNumber.startWith(["655", "657", "69"])){
      return "+237 657 278 710"
    }else if (givenUserNumber.startWith(["67", "652", "650"])){
      return "+237 678 376 581"
    }else {
      return "+237 657 278 710" || "+237 678 376 581"
    }
  }

  return (
    <div className={style.SignUpPopUp}>
      <div>Bienvenu sur Colorfol For Artists, {currentUser.alias}</div>
      <div>Nous te Prions de nous envoyer une video de 30s au numero Whatsapp suivant: {displyWhatsappPhoneNUmber(currentUser.phone)}</div>
    </div>
  );
}