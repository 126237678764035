import React from "react";
import { useNavigate } from "react-router-dom";
import "../../../styles/App.css";
import mn from "../../../assets/musical-notes-sharp.svg";
import logo from "../../../assets/colorfol artists.png";
import { Link } from "react-router-dom";
import hc1 from "../../../assets/micro.png";
import hc2 from "../../../assets/microphone.png";
import hc3 from "../../../assets/cd.png";
import { CloseOutline } from "react-ionicons";
import styles from "./styles/SignUp.module.css";

function Login() {
  let history = useNavigate();
  const goToPreviousPath = () => {
    history(-1);
  };
  return (
    <main className="w-full p-0 m-0">
      <div className="mhcl">
        <div className={`nav ${styles.startNavbar}`}>
          <Link exact to="/" className="link">
            <span className="nav-logo">
              <img alt={"label"} src={logo} />
            </span>
          </Link>
          <ul className="ac">
            <li>
              Déja de l'équipe ?{" "}

              <Link to="/app/access" className="link">
                connectez-vous
              </Link>
            </li>
            <li>
              <span className={styles.startClose}>
                <CloseOutline
                  onClick={goToPreviousPath}
                  color={"#6a0f3e"}
                  title={"Meli-Melo"}
                  height="36px"
                  width="36px"
                />
              </span>
            </li>
          </ul>
        </div>

        <div className={`hsl ${styles.startContainerLogin}`}>
          <img alt={"label"} src={mn} className="hvl" />

          <h2 className={`mmy-10 ${styles.startHeader}`}>
            Accedez à Colorfol Artists
          </h2>
          <h4 className={`mmb-6 ${styles.startSubHeader}`}>
            Premièrement, dites-nous qui vous êtes.
          </h4>

          <div className={`ccl ${styles.startTypes}`}>
            <Link exact to="/start/music" className="link">
              <div className="lcca">
                <span>Musicien</span>
                <img alt={"label"} src={hc1} className="mmy-3" />
              </div>
            </Link>
            <Link exact to="/start/podcast" className="link">
              <div className="lcca">
                <span>Podcasteur</span>
                <img alt={"label"} src={hc2} />
              </div>
            </Link>
            <Link exact to="/start/label" className="link">
              <div className="lcca">
                <span>Label</span>
                <img alt={"label"} src={hc3} />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Login;
