import styles from "../styles/album.module.css";
import {Skeleton} from "@mui/material";
import React from "react";

const SqueletonElt = () => {
  return (
    <div className={styles.singles_elt}>
      <div className={styles.singles_elt_left}>
        <Skeleton className={styles.singles_elt_index} variant={"text"} width={"1.5rem"} animation={"wave"}
                  sx={{bgcolor: "#141414"}}/>
        <Skeleton className={styles.singles_elt_title} variant={"text"} width={"20rem"} animation={"wave"}
                  sx={{bgcolor: "#141414"}}/>
      </div>
      <div className={styles.singles_elt_right}>
        <Skeleton className={styles.singles_elt_duration} width={"4rem"} variant={"text"} animation={"wave"}
                  sx={{bgcolor: "#141414"}}/>
        <Skeleton className={styles.singles_elt_add} width={"1.5rem"} height={"1.5rem"} variant={"circular"} animation={"wave"}
                  sx={{bgcolor: "#141414"}}/>
        <Skeleton className={styles.singles_elt_more} width={"1.5rem"} height={"1.5rem"} variant={"circular"} animation={"wave"}
                  sx={{bgcolor: "#141414"}}/>
      </div>
    </div>
  );
}

export default function LoaderSqueleton() {
  return (
    <div className={styles.singles_list}>
      <SqueletonElt />
      <SqueletonElt />
      <SqueletonElt />
    </div>
  );
}