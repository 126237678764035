import React from "react";
import {Link} from "react-router-dom";
import ImageComponent from "../../../../components/resizeImage";

export default function AlbumEltCover({ album }) {

  return (
    <div className={"toppfan-elt-img2__container"}>
      <Link to={"/app/albums"} state={{ album: album }} >
        <ImageComponent imageUrl={album.cover} size="sml" />
        {/* <img alt="cover" src={album.cover} className="topfan-elt-img3"/> */}
      </Link>
    </div>
  );
} 