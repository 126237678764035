import SwitchUnderlined from "./baseComponents/SwitchUnderlined";
import React from "react";
import {useSwitchUnderlinedContext} from "../datamanager/contexts/locals/switchUnderlined";

export default function SwitchSlider ({ switchValues }){

  const {selected} = useSwitchUnderlinedContext();

  return (
    <>
      <div className="topfan-top">
        <SwitchUnderlined values={switchValues}/>
      </div>
      <div className="divv2">
        <div className={selected === 1 ? "subdivvv2" : `subdivvv22`}></div>
      </div>
    </>
  )
}