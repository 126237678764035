import style from "../styles/components/CommentNotif.module.css";
import { CommentOutlined } from "@mui/icons-material";
import {formatTimePosted} from "../../../../utils/functions";
import {useContext} from "react";
import ModalContext from "../../../../datamanager/contexts/modalContext";

export default function CommentNotif({ userProfile, commentID, userID, userName, userComment, userPostTime }){
  const { openModal } = useContext(ModalContext);

  const params = {
    user: {
      alias: userName,
      cover: userProfile,
      id: userID
    },
    comment: {
      description: userComment,
      id: commentID
    }
  }

  return (
    <div className={style.commentNotifContainer}>
      <CommentOutlined className={style.commentNotifIcon}/>
      <div className={style.commentNotifContent}>
        <div className={style.commentNotifContentTop}>
          <div className={style.commentNotifContentUserProfileContainer}>{userProfile &&
            <img alt={"user profile"} className={style.commentNotifContentUserProfile} src={userProfile}/>}
          </div>
          <span className={style.commentNotifContentRespond} onClick={() => openModal("respondtocomment", "Answer Comment", params)} >Answer</span>
        </div>
        <div className={style.commentNotifContentUserNameContainer}>
          <span className={style.commentNotifContentUserName}>{userName}</span> à commenter votre publication
        </div>
        <div className={style.commentNotifContentComment}>
          {userComment}
        </div>
        <div className={style.commentNotifContentPostTime}>
          {formatTimePosted(userPostTime)}
        </div>
      </div>
    </div>
  );
}