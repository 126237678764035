import FileUploadDirectives from "./FileUploadDirectives";
import React from "react";
import translate from "../../i18n/translate";
import {DropImageSingle} from "../DropImageSingle";
import {DropFileSingle} from "../DropFileSingle";
import FileUploadDirectivesAudios from "./FileUploadDirectivesAudios";

export default function DropFilesZonesSingle({ errors, setFieldValue, isValid, text1, text2, values }){

  return (
    <div className="publish_audio_form_fields_col1">
      <span className="publish_audio_form_fields_col1__title">{text1}<span className="input-required">*</span></span>
      <FileUploadDirectives/>
      <DropImageSingle setFieldValue={setFieldValue}
                       text={translate("dragAndDropYourCoverHereOrChooseOne")}
                       textSucess={translate("dragCoverSucess")}
      />
      {/*{errors.cover ? (<span className="emt">*/}
      {/*                                    {errors.cover}*/}
      {/*                                  </span>) : null}*/}
      <span className="publish_audio_form_fields_col1__title">{text2}<span className="input-required">*</span></span>
      <FileUploadDirectivesAudios/>
      <DropFileSingle setFieldValue={setFieldValue} maxFiles={1}
                  text={translate("dragAndDropFileHereOrChooseOne")}
                  textSucess={translate("dragFIleSucess")} values={values}
      />
      {/*{errors.src ? (<span className="emt">*/}
      {/*                                    {errors.src}*/}
      {/*                                  </span>) : null}*/}
    </div>
  );
}