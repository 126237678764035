import React, { createContext, useContext, useState } from 'react';

const UploadFileTypeContext = createContext();

export const useUploadFileType = () => useContext(UploadFileTypeContext);

export const UploadFileTypeProvider = ({ children }) => {
  const [uploadFileType, setUploadFileType] = useState(null);

  return (
    <UploadFileTypeContext.Provider value={{ uploadFileType, setUploadFileType }}>
      {children}
    </UploadFileTypeContext.Provider>
  );
};
