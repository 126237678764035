import React, {createContext, useCallback, useContext, useState} from 'react';

const AlbumCategoriesContext = createContext();

export const useAlbumCategories = () => useContext(AlbumCategoriesContext);

export const AlbumCategoriesProvider = ({ children }) => {
  const [albumCategories, setAlbumCategories] = useState(null);

  const handleSetAlbumCategories = useCallback((value) => {
    if (value !== albumCategories) {
      setAlbumCategories(value);
    }
  }, [albumCategories]);


  return (
    <AlbumCategoriesContext.Provider value={{ albumCategories, handleSetAlbumCategories }}>
      {children}
    </AlbumCategoriesContext.Provider>
  );
};
