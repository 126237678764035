import {createContext, useCallback, useContext, useState} from 'react';
import PostAPI from "../../api/posts";

const CommentsContext = createContext();

export const useComments = () => useContext(CommentsContext);

export const CommentsProvider = ({ children }) => {

  // declare the metaData state with the length of selectedAudio
  const [ comments, setComments ] = useState([]);

  const handleSetComments = useCallback((value) => {
    if (value !== comments) {
      setComments(value);
    }
  }, [comments]);

  const access_token = localStorage.getItem("access_token");

  // function to add elementin comments
  const addPostComments = (data) => {
    if (data) {
      console.log("adding comments")
      setComments((prevComments) => [...prevComments, ...data.results]);
      // handleOrderComments()
    }
  }

  const resetComments = () => {
    setComments([]);
  }


  // function that find all comment with storyID equals to the given one
  const getCommentOfStory = (storyID) => {
    return comments.filter(comment => comment.story_id === storyID)
  }


  const fetchAllPostComments = async (postId) => {
    const postApi = new PostAPI(access_token);

    const response = await postApi.getAllPostComments(postId);
    if (response.data) {
      console.log("receiving comments", response.data)
      addPostComments(response.data);
    }
  }

  // handle order comment by date
  const handleOrderComments = () => {
    console.log("ordering comments")
    setComments(comments.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)));
  }

  return (
    <CommentsContext.Provider value={{ comments, handleSetComments, fetchAllPostComments, handleOrderComments, resetComments }}>
      {children}
    </CommentsContext.Provider>
  );
};