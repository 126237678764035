import style from "../styles/editModal.module.css"
import translate from "../../../i18n/translate";

export default function UploadSingleModal({ handleUpload }) {

  return (
    <div className={style.exitmodal}>
      <p className={style.exitmodal__text}>{translate("noback")}</p>
      <div className={style.exitmodal__actions}>
        <div className={style.exitmodal__actions__logout} onClick={() => handleUpload()}>{translate("Oui")}</div>
      </div>
    </div>
  );
}