import "./styles/InputField.css";
import "./styles/RadioInput.css";
import { useState } from "react";
import {useMetaData} from "../datamanager/contexts/locals/publishAudio/metaData";
import translate from "../i18n/translate";

export default function RadioInputExplicit({ optionList, index}) {
  const { metaData, handleSetMetaData } = useMetaData();

  //UPDATE THE METADATA
  const handleOptionClick = (index2) => {
    console.log('The selected option', index2)
    const updatedMetaData = [...metaData];

    console.log("THE SELECTED RADIO INDEX" ,index2)
    switch (index2) {
    case 0:
        updatedMetaData[index].explicit = true;
        break;
    case 1:
        updatedMetaData[index].explicit = false;
        break;
    }
    handleSetMetaData(updatedMetaData);
  };


  return (
    <div className="radio-container">
      {optionList.map((option, index2) => (
        <div key={index2} className="radio-option" onClick={() => handleOptionClick(index2)}>
          <div className={ 
            ((index2 === 0) !== (!metaData[index].explicit)) ? "radio-button selected" : "radio-button" //USE OF XOR FUNCTION
            } ></div>
          <span className="radio-label">{option}</span>
        </div>
      ))}
    </div>
  );
}