import React from "react";
import {useMenuSwitchContext} from "../datamanager/contexts/locals/menuSwitch";

function SideItem({ name, icon, active, index }) {

  const { handleSwitchMenu } = useMenuSwitchContext();

  return (
    <li onClick={() => handleSwitchMenu(index)} className={active ? "sp active" : "sp"}>
      <img alt={"your music"} className="ico" src={icon}/>
      <span className="nam">{name}</span>
    </li>
  );
}

export default SideItem;
