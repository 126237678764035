import React, { useContext } from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "../../../styles/App.css";
import { Link } from "react-router-dom";
import { SpinnerCircularFixed } from "spinners-react";
import useUser from "../../../hooks/useUser";
import Albumstable from "../../../components/albumstable";
import uil from "../../../assets/clip.png";
import VideosContext from "../../../datamanager/contexts/video";

function Video({ isActive }) {
  // Get data from the global context
  const { videos } = useContext(VideosContext)

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (videos) {
      setLoading(false)
    }
  }, [videos])

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
    },
    {
      name: "Year",
      selector: (row) => row.year,
    },
  ];

  const dataset = [
    {
      id: 1,
      title: "Beetlejuice",
      year: "1988",
    },
    {
      id: 2,
      title: "Ghostbusters",
      year: "1984",
    },
  ];

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center h-screen">
          <SpinnerCircularFixed
            size={50}
            thickness={121}
            speed={150}
            color="rgba(133, 19, 78, 1)"
            secondaryColor="rgba(231, 208, 220, 1)"
          />
        </div>
      ) : videos.count > 0 ? (
        <>
          <div className="flex flex-col items-start justify-start w-full min-h-screen p-3">
            <div className="flex flex-col items-start justify-start w-full my-16">
              <h1 className="text-5xl font-bold text-slate-500 text-left">
                Clips vidéos
              </h1>
              <h4 className="text-xs font-medium text-left text-gray-500 mt-2">
                Trackez les donnees genere par vos clips vidéos sur Colorfol
                Artist
              </h4>
            </div>
            <div className="my-auto w-full">
              <Albumstable />
            </div>
          </div>
        </>
      ) : (
        <>
          <img src={uil} className="esi" />
          <span className="est">Aucun contenu vidéo disponible ici !</span>
          <Link exact to="/app/publish/video" className="link">
            <span className="escta">Publiez votre premier clip</span>
          </Link>
        </>
      )}
    </>
  );
}
export default Video;
