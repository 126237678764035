import { BaseApi } from "..";

export default class artistApi extends BaseApi {
    constructor(token = null) {
        super();

        this.token = token;
    }

    async getAllArtists(uid) {
        try {
            const response = await this.insertToken(this.token).get(
                `user-api/users/${uid}/albums`
            );
            return { data: response.data};
        } catch (err) {
            return { error: err };
        }
    }
}
