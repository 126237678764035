import InputField from "../InputField";
import SelectInput from "../SelectInput";
import RadioInput from "../RadioInput";
import { distrubutionMode } from "../../utils/Constants";
import CounterInput from "../CounterInput";
import TextAreaInput from "../TextAreaInput";
import React from "react";
import { useAlbumCategories } from "../../datamanager/contexts/locals/albumCategory";
import translate from "../../i18n/translate";
import DatePicker from "react-datepicker";
import { useLanguage } from "../../datamanager/contexts/Language";

export default function FieldSectionSingle({ handleChange, values, errors, setFieldValue, isValid }) {
  const { locale } = useLanguage();

  const { albumCategories } = useAlbumCategories();

  return (
    <div className="publish_audio_form_fields_col2__form">
      <InputField
        inputName="title"
        inputLabel={translate("singleTItle")}
        inputPlaceholder="Enter your Single title"
        inputType="text"
        bgColor="#1D1D1D"
        onChange={handleChange}
        value={values.title}
        required={true}
      />
      {isValid && errors.title ? (<span className="emt">
        {errors.title} </span>) : null
      }
      {/*{errors.title ? (<span className="emt">*/}
      {/*                                    {errors.title}*/}
      {/*                                  </span>) : null}*/}
      <span className="publish_audio_form_fields_col2__subtitle">{translate("category")}<span
        className="input-required">*</span></span>
      <SelectInput optionList={albumCategories} setFieldValue={setFieldValue}
        inputPlaceholder={translate("categoryPlaceholder")}
        value={values.category} />
      {/*{errors.category ? (<span className="emt">*/}
      {/*                                    You must specify a category*/}
      {/*                                  </span>) : null}*/}
      {/*<span*/}
      {/*  className="publish_audio_form_fields_col2__subtitle">{translate("distributionMode")}<span*/}
      {/*  className="input-required">*</span></span>*/}
      {/*<RadioInput setFieldValue={setFieldValue} optionList={distrubutionMode} value={values.media_accessibility}/>*/}
      {/*/!*{errors.media_accessibility ? (<span className="emt">*!/*/}
      {/*/!*                                    {errors.media_accessibility}*!/*/}
      {/*/!*                                  </span>) : null}*!/*/}
      {/*{values.media_accessibility && values.media_accessibility !== "ST" && (*/}
      {/*  <>*/}

      {/*  </>*/}
      {/*)}*/}
      {/*<span className="publish_audio_form_fields_col2__subtitle">Price</span>*/}
      {/*<CounterInput setFieldValue={setFieldValue} inputPlaceholder={translate("price")} value={values.price}/>*/}

      {/*<CounterInput setFieldValue={setFieldValue} inputPlaceholder={translate("price")} value={values.price}/>*/}
      {/*{errors.price ? (<span className="emt">*/}
      {/*                                    {errors.price}*/}
      {/*                                  </span>) : null}*/}
      <span className="publish_audio_form_fields_col2__subtitle">{translate("publicationDate")}</span>
      <DatePicker
        locale={locale}
        className="my_date_input text-white"
        name="publication_date"
        id="publication_date"
        placeholder="Entrer la date"
        selected={values.publication_date ? new Date(values.publication_date) : null}
        onChange={(date, e) => {
          handleChange(e);
          setFieldValue("publication_date", date.toISOString());
        }}
        showTimeSelect={false}
        dateFormat="P"
      />
      <span
        className="publish_audio_form_fields_col2__subtitle">{translate("description")}</span>
      <TextAreaInput setFieldValue={setFieldValue} inputPlaceholder={translate("descriptionPlaceHolder")}
        value={values.description} className="text-area-holder" />
      {/*{errors.description ? (<span className="emt">*/}
      {/*                                    {errors.description}*/}
      {/*                                  </span>) : null}*/}
    </div>
  );
}