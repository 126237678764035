import React, { useState } from "react";
import "./styles/InputField.css";
import "./styles/CounterInput.css";
import counterUp from "../assets/new_images/counterUp.svg";
import counterDown from "../assets/new_images/counterDown.svg";

export default function CounterInput({ inputPlaceholder, setFieldValue, value }) {
  const [counterValue, setCounterValue] = useState(Number(value));

  const handleChange = (e) => {
    setCounterValue(e.target.value);
    setFieldValue("price", e.target.value);
  };

  const handleIncrement = () => {
    setCounterValue((prevValue) => {
      const newValue = parseInt(prevValue) + 1;
      return isNaN(newValue) ? inputPlaceholder : newValue;
    });
    setFieldValue("price", counterValue);
  };

  const handleDecrement = () => {
    setCounterValue((prevValue) => {
      const newValue = parseInt(prevValue) - 1;
      return isNaN(newValue) ? inputPlaceholder : newValue;
    });
    setFieldValue("price", counterValue);
  };

  return (
    <div className="input-container">
      <div className="counter-container">
        <input
          className="input-field"
          type="number"
          value={counterValue || value}
          onChange={(e) => handleChange(e)}
        />
        <div className="counter-options">
          <img alt={"counter"} src={counterUp} className="counter-up" onClick={ handleIncrement} />
          <img alt={"counter"} className="counter-down" src={counterDown} onClick={handleDecrement} />
        </div>
      </div>
    </div>
  );
}