import React from "react";
import PreviewInfo from "./previewInfo";
import {useSelectedImage} from "../../datamanager/contexts/locals/publishAudio/selectedImage";

export default function PreviewTop({ values }) {

  const { selectedImage } = useSelectedImage();

  return (
    <div className="publish_audio_end_container_col2_container_header">
      <div className="publish_audio_end_container_col2_container_header__cover_container">
        {selectedImage && <img alt={"cover"} src={URL.createObjectURL(selectedImage)}
             className="publish_audio_end_container_col2_container_header__cover"/>}
        { values.price > 0 && <span className="publish_audio_end_container_col2_container_header__cover_text">{values.price} <span
          className="publish_audio_end_container_col2_container_header__cover_text_currency">FCFA</span></span>}
      </div>
      <PreviewInfo values={values}/>
    </div>
  );
}