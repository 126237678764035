import translate from "../../i18n/translate";
import React from "react";
import {useRealtimeSwitchContext} from "../../datamanager/contexts/locals/realTimeSwitch";

export default function RealtimeSwitch() {

  const { isRealtime, handleSwitchRealtime } = useRealtimeSwitchContext();

  return (
    <div className="trtl">
      <span className={isRealtime === 1 ? "trtl-ac" : "trtl-inac"} onClick={() => handleSwitchRealtime(1)}>
        {translate('realtime')}
      </span>
      <span className={isRealtime === 0 ? "trtl-ac" : "trtl-inac"} onClick={() => handleSwitchRealtime(0)}>
        {translate('televersement')}
      </span>
    </div>
  );
}