import React, {createContext, useCallback, useContext, useState} from 'react';

const MsgContext = createContext();

export const useMsg = () => useContext(MsgContext);

export const MsgProvider = ({ children }) => {
  const [msg, setMsg] = useState(null);

  const handleSetMsg = useCallback((value) => {
    if (value !== msg) {
      setMsg(value);
    }
  }, [msg]);
  return (
    <MsgContext.Provider value={{ msg, handleSetMsg }}>
      {children}
    </MsgContext.Provider>
  );
};
