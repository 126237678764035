import React from "react";

export default function SideBarActionButton({ type, icon, text, onClick }) {
  return (
    <li
      className={ type === 1 ? "ered" : "lgelt"}
      onClick={onClick}
    >
      <img alt="icon_sidebar" src={icon}/>
      <span>{text}</span>
    </li>
  );
}