import React from "react";
import { useSelectedAudio } from "../../datamanager/contexts/locals/publishAudio/selectedAudio";
import UploadSongListEltSingle from "./UploadSongListEltSingle";


export default function UploadSongListSingle(values , setFieldValue, handleChange) {

  const { selectedAudio } = useSelectedAudio();

  return (
    <div className="publish_audio_form_medias__audios">
      {
        selectedAudio && selectedAudio.length > 0 ? (
          selectedAudio.map((audio, index) => <UploadSongListEltSingle audio={audio} key={index} index={index} total={selectedAudio.length} values={values} setFieldValue={setFieldValue} handleChange={handleChange} />)
        ) : null
      }
    </div>
  );
}
