import uil from "../assets/single.png";
import {Link} from "react-router-dom";
import React from "react";

export default function NoContent({ path, contentType }) {
  return (
    <div className={"nocontent__container"}>
      <img alt={"esi"} src={uil} className="nocontent__img"/>
      <span className="nocontent__text"> Aucun contenu {contentType} disponible ici ! </span>
      <Link exact to={path} className="nocontent__link">Publiez votre premier {contentType}</Link>
    </div>
  );
}