import {Link} from "react-router-dom";
import "../../../App.css";
import translate from "../../../../i18n/translate";

export default function CommingSoon() {

  return (
    <div className="comming-soon">
      <div className="fond2"></div>
      <div className="fond"></div>
      <span className="comming-soon-title">{translate('commingsoon')}</span>
      <Link to={"/app/publish/select-content-type"} className="comming-soon-btn">{translate('commingsoonbtn')}</Link>
    </div>
  );
}