import { useContext, useEffect } from "react"
import CurrentUserContext from "../datamanager/contexts/currentUser"
import MusicApi from "../api/musics"
import AlbumsContext from "../datamanager/contexts/albums"
import {useLoadingContext} from "../datamanager/contexts/locals/Dashboard/loading";

const useAlbums = () => {
  // Get data from the global context
  const { addAlbums } = useContext(AlbumsContext)
  const { currentUser } = useContext(CurrentUserContext)
  const { setAlbumsFetching } = useLoadingContext();

  // UseEffect section
  useEffect(() => {
    if (currentUser) {
      setAlbumsFetching(true);
      handleFetchAlbums().then(
        (result) => {
          console.log(result)
          setAlbumsFetching(false);
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }, [currentUser])

  const handleFetchAlbums = async () => {
    const token = localStorage.getItem("access_token")

    const musicApi = new MusicApi(token)

    const response = await musicApi.getAllAlbums(currentUser.id)

    if (response.data) {
      addAlbums(response.data)
    }
  }
}

export default useAlbums