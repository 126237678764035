import React, {useCallback, useContext, useState} from 'react';
import downn from "../assets/new_images/Download.svg";
import { useSelectedAudio } from "../datamanager/contexts/locals/publishAudio/selectedAudio";
import { useStoreData } from "../datamanager/contexts/StoreDatas";
import Dropzone from "react-dropzone";
import {useMetaData} from "../datamanager/contexts/locals/publishAudio/metaData";
import CurrentUserContext from "../datamanager/contexts/currentUser";

export function DropFileSingle({ setFieldValue, values, acceptedFiles = "audio/mpeg, audio/aac, audio/mp3, audio/wav, audio/ogg, audio/flac, audio/wma", callback, text, textSucess, maxFiles = 1 }) {

  const { selectedAudio, handleSetSelectedAudio } = useSelectedAudio();
  const [errorMsg, setErrorMsg] = useState(null);
  const { storedData } = useStoreData();

  if (storedData && storedData.acceptedFiles && storedData.acceptedFiles.length > 0) {
    handleSetSelectedAudio(storedData.acceptedFiles);

    // extract names of audios files stored in local storage
    const names = storedData.acceptedFiles.map(file => file.name);
    // get current year example 2022
    const year = new Date().getFullYear();
  }

  const onDrop = useCallback((droppedFiles) => {

    // Check if the dropped files match the accepted file types and the maximum number of files
    const invalidFiles = droppedFiles.filter(file => !acceptedFiles.includes(file.type));
    const invalidMaxFiles = droppedFiles.length > maxFiles;

    if (invalidFiles.length > 0 || invalidMaxFiles) {
      // Handle invalid file types or maximum number of files here - display the error message
      setErrorMsg("Invalid file types or maximum number of files detected");
    } else {
      setErrorMsg(null);
      // Valid files - proceed with setting field value and selected audio
      if (callback) {
        callback(droppedFiles);
      } else {
        setFieldValue('src', droppedFiles);
        handleSetSelectedAudio(droppedFiles);
      }
    }
  }, [acceptedFiles, handleSetSelectedAudio, maxFiles]);

  return (
    <div className="relative w-full">
      <Dropzone
        accept={
          {
            'audio/mpeg': [],
            'audio/aac': [],
            'audio/mp3': [],
            'audio/wav': [],
            'audio/ogg': [],
            'audio/flac': [],
            'audio/wma': []
          }
        }
        onDrop={(droppedFiles) => onDrop(droppedFiles)}
        maxFiles={1}
        multiple={false}
        maxSize={10 * 1024 * 1024}
        noKeyboard
        minSize={0}
        onDropRejected={(event) => {
          setErrorMsg("Invalid file types or maximum number of files detected");
        }}
        onDropAccepted={(event) => {
          setErrorMsg(null);
        }}
      >
        {({getRootProps, getInputProps, isDragActive}) => (
          <div {...getRootProps({
            onDrop: (event) => event.preventDefault(),
          })}>
            <input {...getInputProps()} />
            <div className="publish_audio_form_fields_col1__dropzone">
              <div className={selectedAudio ? `${errorMsg ? "dropz--error" : "dropz--success"}` : "dropz"}>
                {selectedAudio && !errorMsg && (
                  <span className="dropztitle-success">{textSucess}</span>
                )}
                {!selectedAudio && (
                  <>
                    <img alt="drop" src={downn} className="dropzimg"/>
                    <span className="dropztitle">{text}</span>
                  </>
                )}
                {selectedAudio && errorMsg && (
                  <span className="dropztitle-error">{errorMsg}</span>
                )}
              </div>
            </div>
            {isDragActive && <div className="overlay_black" />}
          </div>
        )}
      </Dropzone>
    </div>
  );
}
