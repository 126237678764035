import {handleErrors, sleep} from "../../../../utils/functions";
import axios from "axios";
import {TestEnvAPI} from "../../../../envs";

export const handleRefresh = async () => {
  const storedToken = JSON.parse(
    localStorage.getItem("auth").data
  );

  try {
    const res = await axios.post(
      `${TestEnvAPI}/user-api/auth/token/refresh/`,
      {
        refresh: storedToken.refresh_token,
      }
    );

    const { access } = res.data;

    localStorage.setItem("access_token", access);
  } catch (_error) {
    return Promise.reject(_error);
  }
}

export default async (response, handleSetBackErr, handleSetMsg, handleSetSubmitting) => {

  const errors = Object.values(response.error.response.data);

  handleErrors(errors, handleSetMsg, handleSetSubmitting);

  if (response.error.response.status === 401) {
    await handleRefresh();
    return;
  }

  await sleep(800);
  handleSetSubmitting(false);
  handleSetBackErr(true);
}