import React from "react";
import { getLanguage } from "../../utils/functions";
import { useLanguage } from "../../datamanager/contexts/Language";
import select from "../../assets/new_images/select.svg";

export default function LanguageSelect({ }) {

  const { language, languageList, updateHotLanguage } = useLanguage();

  return (
    <div className="languagess">
      <div className="langgs">
        <img className="langgimgs" src={getLanguage(language)} alt="Selected flag" />
      </div>
      <select className='listdrop1' value={language} onChange={updateHotLanguage} id="languages">
        {
          languageList.map((item) => (
            <option key={item.code} value={item.value}>
              {item.code}
            </option>
          ))
        }
      </select>
    </div>
  );
}