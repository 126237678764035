import React, {createContext, useCallback, useContext, useState} from 'react';

const PlayingContext = createContext();

export const usePlaying = () => useContext(PlayingContext);

export const PlayingProvider = ({ children }) => {

  const [playing, setPlaying] = useState(null);

  const [audio, setAudio] = useState(null);

  audio && audio.addEventListener('ended', () => {
    setPlaying(null);
  });

  const handleSetPlaying = useCallback((newPlaying) => {
    // If the same song is clicked again, toggle play/pause
    if (playing && playing.single.id === newPlaying.single.id) {
      if (audio.paused) {
        audio.play();
        setPlaying({ ...playing, isGoing: true });
      } else {
        audio.pause();
        setPlaying({ ...playing, isGoing: false });
      }
    } else {
      // Stop the ongoing song if a different song is selected
      audio && audio.pause();

      // Start playing the new song
      const newAudio = new Audio(newPlaying.single.src);
      newAudio.addEventListener('ended', () => {
        setPlaying(null);
      });
      setAudio(newAudio);
      newAudio.play().then(
        () => {
          setPlaying({ ...newPlaying, isGoing: true });
        }
      );
    }
  }, [playing, audio]);


  return (
    <PlayingContext.Provider value={{ playing, handleSetPlaying, audio }}>
      {children}
    </PlayingContext.Provider>
  );
};
