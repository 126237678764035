import React from "react";
import { useNavigate } from "react-router-dom";
import "../../../styles/App.css";
import logo from "../../../assets/colorfol artists.png";
import { Link } from "react-router-dom";
import hc2 from "../../../assets/vynil.png";
import hc1 from "../../../assets/single.png";
import hc3 from "../../../assets/clip.png";
import uil from "../../../assets/Uploading-rafiki.png";
import mn from "../../../assets/musical-notes-sharp.svg";
import {
  PersonCircleOutline,
  CloseOutline,
  MusicalNotesOutline,
  VideocamOutline,
  CashOutline,
  CloudUploadOutline,
  NotificationsOutline,
} from "react-ionicons";
import useUser from "../../../hooks/useUser";
import Avatar from "../../../components/Avatar";

function Publish() {
  let history = useNavigate();
  const goToPreviousPath = () => {
    history(-1);
  };
  const user = useUser();
  return (
    <main className="w-full h-screen max-h-screen overflow-y-hide p-0 m-0">
      <div class="mhcl">
        <div class="nav">
          <Link exact to="/" className="link">
            <span class="nav-logo">
              <img src={logo} />
            </span>
          </Link>
          <ul class="ac">
            <li>
              <a>
                <CloseOutline
                  onClick={goToPreviousPath}
                  color={"#6a0f3e"}
                  title={""}
                  height="36px"
                  width="36px"
                />
              </a>
            </li>
          </ul>
        </div>

        <div class="hsl">
          <img src={mn} class="hvl" />
          <h2 className="mmy-10">Publiez du contenu sur Colorfol Music</h2>
          <h4 className="mmb-6">Choisissez le type de contenu à publier.</h4>
          <div className="ccl flex-row flex-wrap">
            <Link exact to="/app/publishPodcasts/episode" className="link">
              <div className="lcca">
                <span>Episode</span>
                <img src={hc1} className="mmy-3" />
              </div>
            </Link>
            <Link exact to="/app/publishPodcasts/podcast" className="link">
              <div className="lcca">
                <span>Podcast</span>
                <img src={hc2} />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Publish;
