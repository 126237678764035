import React, { useState, useRef, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../../styles/App.css";
import "../../../styles/publish.css";
import logo from "../../../assets/colorfol artists.png";
import check from "../../../assets/icons8-ok-250.png";
import { Link } from "react-router-dom";
import { CloseOutline, ArrowBackOutline } from "react-ionicons";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Formik, Form, Field, form } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import useUser from "../../../hooks/useUser";
import moment from "moment";
import "moment/locale/fr";
import fr from "date-fns/locale/fr";
import CurrentUserContext from "../../../datamanager/contexts/currentUser";
import MusicApi from "../../../api/musics";
import AlbumsContext from "../../../datamanager/contexts/albums";
registerLocale("fr", fr);
moment.locale("fr");
registerLocale("fr", fr);

const SUPPORTED_FORMATS = ["image/jpeg", "image/jpg", "image/png"];

const SignupSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  album: Yup.number().nullable(),
  category: Yup.number().nullable(),
  si_cat: Yup.string(),
  //   .min(2, "Too Short!")
  //   .max(50, "Too Long!")
  //   .required("Required"),
  cover: Yup.mixed().nullable().required("Veuillez uploader votre cover!")
  .test(
    "FILE_SIZE",
    "L'image est trop lourde pour etre publiee",
    (value) => {
      return value && value.size <= 1024 * 1024 * 1024 * 2
    }
  )
  .test(
    "FILE_FORMAT",
    "Veuillez choisir uniquement les fichers ayant les formats suivants : JPG, JPEG et  PNG",
    (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
  ),
  src: Yup.mixed().required("Veuillez uploader votre son!"),
});

function PublishVideo() {
  // Get data from the global context
  const { currentUser: user } = useContext(CurrentUserContext);
  const { albums } = useContext(AlbumsContext)

  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const inputFile = useRef(null);
  const inputAudio = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [categories, setCategories] = useState([])


  const format1 = "DD MMM YYYY";
  const format2 = "HH:mm";
  const format3 = "YYYY-MM-DDThh:mm";

  let history = useNavigate();
  const goToPreviousPath = () => {
    history(-1);
  };

  // UseEffect section
  useEffect(() => {
    const handleFetchCategoriesVideos = async () => {
      const token = localStorage.getItem("access_token");

      const musicApi = new MusicApi(token);

      const response = await musicApi.getMusicCategories(1);

      if (response.data) {
        setCategories(response.data);
      }
    }

    if (categories.length === 0) {
      handleFetchCategoriesVideos().then(
        (result) => {
          console.log(result)
        },
        (error) => {
          console.log(error)
        }
      );
    }
  }, [categories.length])

  // Some handlers
  const handleSubmitForm = async (data) => {
    const token = localStorage.getItem("access_token");

    const musicApi = new MusicApi(token);

    const response = await musicApi.publishVideo(data);

    if (response.data) {

    } else {

    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          title: "",
          cover: "",
          src: "",
          publication_date: null,
          author: user.id,
          myLyrics: "",
          si_cat: "",
          album: null,
          category: null
        }}
        validationSchema={SignupSchema}
        onSubmit={(values) => {
          const formData = new FormData();

          formData.append("title", values.title);
          formData.append("cover", values.cover);
          formData.append("src", values.src);
          formData.append("publication_date", values.publication_date.toLocaleDateString());
          formData.append("author", values.author);
          formData.append("myLyrics", values.myLyrics);
          formData.append("album", values.album);
          formData.append("category", +values.category);

          handleSubmitForm(formData).then(
            (result) => {
              console.log(result)
            },
            (error) => {
              console.log(error)
            }
          );
        }}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form>
            <div class="nav snv">
              <span class="ptt">Ajoutez un clip vidéo</span>
              <ul class="ac">
                <li>
                  <a></a>
                </li>
                <li>
                  <a>
                    <CloseOutline
                      onClick={goToPreviousPath}
                      color={"#6a0f3e"}
                      title={"Meli-Melo"}
                      height="36px"
                      width="36px"
                    />
                  </a>
                </li>
              </ul>
            </div>

            <div className="pf-card">
              <div className="dyn-form">
                {/* <div className='logo-conp'>
                <img src={logo} />
    </div> */}
                <div className="if-conp">
                  {page === 1 && (
                    <div className="form-barp">
                      <div className="form-lbp">
                        <div className="flex-column-center">
                          {selectedImage && (
                            <div
                              className="cover-con"
                              onClick={() => inputFile.current.click()}
                            >
                              <img
                                alt="cover"
                                src={URL.createObjectURL(selectedImage)}
                              />
                              {/*<CloseOutline color={'#6a0f3e'} title={"Meli-Melo"} className='close-btn' onClick={() => {setFieldValue('MyImage', null) ;setSelectedImage('')}} height="30px" width="30px" />*/}
                            </div>
                          )}
                          {!selectedImage && (
                            <>
                              <div
                                className="cover-i"
                                onClick={() => inputFile.current.click()}
                              >
                                + Cover
                              </div>
                              <input
                                id="cover"
                                ref={inputFile}
                                type="file"
                                className="myImage"
                                accept="image/png, image/jpg, image/jpeg"
                                name="cover"
                                onChange={(event) => {
                                  handleChange(event);
                                  setFieldValue("cover", event.target.files[0]).then(
                                    () => setSelectedImage(event.target.files[0])
                                  );
                                }}
                              />{" "}
                            </>
                          )}
                        </div>

                        <label>Quel est le titre de votre clip ?</label>
                        <input
                          id="title"
                          className="li"
                          name="title"
                          type="text"
                          onChange={handleChange}
                          value={values.title}
                          placeholder="Entrer le titre de votre clip"
                        ></input>

                        {errors.title && touched.title ? (
                          <div>{errors.title}</div>
                        ) : null}

                        <div className="flex-column mt-5">
                          <label>Choisir un album pour votre clip</label>

                          <select
                            className="ml-12 sm-input"
                            id="album"
                            name="album"
                            onChange={handleChange}
                            value={values.album}
                            style={{ height: "45px" }}
                          >
                            <option value={null}>Album</option>

                            {albums.results.map((alb, index) => {
                              return (
                                <option key={index} value={Number(alb.id)}>
                                  {alb.title}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <div className="flex-row mt-12">
                          {!errors.title && values.cover != "" ? (
                            <button
                              className="mlr-12 fbv"
                              type="button"
                              onClick={() => setPage(page + 1)}
                            >
                              Suivant
                            </button>
                          ) : (
                            <button className="mlr-12 fbvdi" type="button">
                              Suivant
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {page === 2 && (
                    <>
                      <div className="form-bar">
                        <div className="form-lbp">
                          <label>
                            Veuillez choisir la source du clip vidéo
                          </label>
                          <input
                            className="li"
                            id="src"
                            accept="video/* "
                            ref={inputAudio}
                            type="file"
                            name="src"
                            onChange={(event) => {
                              handleChange(event);
                              setFieldValue("src", event.target.files[0]).then(
                                () => setSelectedAudio(event.target.files[0])
                              );
                            }}
                            value={values.myAudio}
                          />
                        </div>
                      </div>

                      <div className="form-bar">
                        <div className="form-lbp">
                          <label>
                            Veuillez définir une categorie pour votre clip
                          </label>

                          <select
                            className="ml-12 sm-input"
                            id="category"
                            name="category"
                            onChange={handleChange}
                            value={values.category}
                            style={{ height: "45px" }}
                          >
                            <option value={null}>Choisissez une categorie</option>

                            {categories.map((cat, index) => {
                              return (
                                <option key={index} value={Number(cat.id)}>
                                  {cat.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="form-bar">
                        <div className="form-lbp">
                          <label>Quelle est sa date de publication ?</label>
                          <div className="flex-row">
                            <DatePicker
                              locale="fr"
                              className="sm-input"
                              name="publication_date"
                              placeholder="Entrer la date"
                              selected={values.publication_date}
                              onChange={(date, e) => {
                                handleChange(e);
                                setFieldValue("publication_date", date).then(
                                  () => console.log(values.publication_date)
                                );
                              }}
                              showTimeSelect
                              dateFormat="Pp"
                            />
                          </div>
                          <div className="mt-24  flex-row-center ">
                            {!values.myAudio && (
                              <button
                                className="ml-96 sbbv"
                                type="button"
                                onClick={() => setPage(page - 1)}
                              >
                                <ArrowBackOutline
                                  color={"#f2f2f2"}
                                  title={"Meli-Melo"}
                                  height="28px"
                                  width="28px"
                                />
                              </button>
                            )}
                            {!errors.pub_date && values.myAudio != "" ? (
                              <button
                                className="mlr-12 fbv"
                                type="button"
                                onClick={() => setPage(page + 1)}
                              >
                                Suivant
                              </button>
                            ) : (
                              <button className="mlr-12 snbvdi" type="button">
                                Suivant
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {page === 3 && (
                    <>
                      <Loader
                        type="TailSpin"
                        color="#6a0f3e"
                        height={100}
                        width={100}
                        timeout={3000}
                      />
                      <div className="dsn">
                        {setTimeout(() => setPage(page + 1), 3000)}
                      </div>
                    </>
                  )}

                  {page === 4 && (
                    <>
                      <div className="flex-column-center">
                        <div className="cover-web">
                          <video controls width="250">
                            <source
                              src={URL.createObjectURL(selectedAudio)}
                              type="video/mp4"
                            />
                            Sorry, your browser doesn't support embedded videos.
                          </video>
                          {/*<CloseOutline color={'#6a0f3e'} title={"Meli-Melo"} className='close-btn' onClick={() => {setFieldValue('MyImage', null) ;setSelectedImage('')}} height="30px" width="30px" />*/}
                        </div>

                        <div className="flex-column-center">
                          <span className="psttl">{values.au_ttl}</span>
                          <span className="psauth">{values.si_cat}</span>
                        </div>

                        <div className=" mt-16 flex-row-center">
                          <Link exact to="/app" className="link">
                            <button
                              className="sbbv"
                              type="button"
                              onClick={() => setPage(page - 1)}
                            >
                              Annuler
                            </button>
                          </Link>
                          <button
                            className="mlr-12 snbv"
                            onClick={handleSubmit}
                            type="submit"
                          >
                            Publier
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {page === 5 && (
                    <>
                      <Loader
                        type="TailSpin"
                        color="#6a0f3e"
                        height={100}
                        width={100}
                        timeout={3000}
                      />
                      ;
                      <div className="dsn">
                        {setTimeout(() => setPage(page + 1), 3000)}
                      </div>
                    </>
                  )}

                  {page === 6 && (
                    <>
                      <div className="flex-column-center">
                        <img src={check} width={140} height={140}></img>
                        <div className="mt-24">
                          <span className="psttl">"{values.au_ttl}"</span> a
                          bien été publié sur colorfol
                        </div>
                        <Link exact to="/app" className="link">
                          <button
                            className="mt-20 snbv"
                            type="button"
                            onClick={() => setPage(page - 1)}
                          >
                            Ok!
                          </button>
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/* previsuel */}
              <div className="sta-form">
                {page === 1 && (
                  <>
                    <span className="fcov"></span>
                    <span className="ftit"></span>
                    <span className="fart"></span>
                    <span className="rlp"></span>
                  </>
                )}
                {page > 1 && (
                  <>
                    <span className="rcov">
                      <img
                        alt="cover"
                        src={URL.createObjectURL(selectedImage)}
                      />
                    </span>
                    <span className="psttl">{values.title}</span>
                    <span className="psauth">{user.username}</span>

                    {/*{selectedAudio && 
                           <>
                           {/*<audio ref={audioPlayer} src={URL.createObjectURL(selectedAudio)}  controls>Your browser does not support the<code>audio</code> element.</audio>
                           <span className='mcp'>
                                <span className='mtml'>
                                <span className='span1'>0:00</span>
                                <div className='slider'><span className='sidi'></span></div>
                                <span className='span2'>0:00</span>
                                </span>
                                
                                <div className='cntrl'>
                                    <button className='cibut'><Shuffle  color={'#b3b3b3'} title={"shuffle"} height="22px" width="22px" /></button>
                                    <button className='cibut'><PlaySkipBack  color={'#b3b3b3'} title={"shuffle"} height="22px" width="22px" /></button>
                                    <button className='ppbut' onClick={toggle}>
                                        {isPlaying ? <Pause  color={'#1b0410'} className='mt-10' title={"shuffle"} height="20.7px" width="20.7px" /> : <Play  color={'#1b0410'} className='mt-10' title={"shuffle"} height="20px" width="20px" /> }
                                        
                                        </button>
                                    <button className='cibut'><PlaySkipForward  color={'#b3b3b3'} title={"shuffle"} height="22px" width="22px" /></button>
                                    <button className='cibut'><Repeat  color={'#b3b3b3'} title={"shuffle"} height="22px" width="22px" /></button>
                                </div>

                            </span>
                            </>
                            }
                        */}
                    <span className="rlp">
                      Sortie le&nbsp;
                      <strong>
                        {moment(values.publication_date).format(format1)}
                      </strong>
                      &nbsp;à&nbsp;
                      <strong>
                        {moment(values.publication_date).format(format2)}
                      </strong>{" "}
                    </span>
                  </>
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default PublishVideo;
